import React, { useState, useEffect, useContext } from 'react';
import { getFittingDetails } from '../../api/fittingApi';
import { getGroups } from '../../api/groupApi';
import { deleteTestEvents, getTestEventGroups, getTestEvents } from '../../api/testEventApi';
import { pluralizer } from '../../utils/pluralizer';
import { store } from '../../utils/store';
import BatchDeleteConfirmation from '../components/BatchDeleteConfirmation';
import CreateTestEventModal from '../components/CreateTestEventModal';
import ModalView from '../components/ModalView';
import TestEventTable from '../components/TestEventTable';
import Show from '../groups/fittings/Show';
import Groups from '../test-event/Groups';

export default ({site, isQueryPending, batchLength}) => {

  const { dispatch } = useContext(store);

  const [testEvents, setTestEvents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [testEventDetails, setTestEventDetails] = useState(null);
  const [isDeletingTestEvents, setIsDeletingTestEvents] = useState(false);
  const [displayAddTestEventModal, setDisplayAddTestEventModal] = useState(false);
  const [testEvent, setTestEvent] = useState(null);
  const [displayBatchDeleteConfirmationModal, setDisplayBatchDeleteConfirmationModal] = useState(false);
  const [selectedTestEvents, setSelectedTestEvents] = useState([]);
  const [fitting, setFitting] = useState(null);
  const [testEventPageNum, setTestEventPageNum] = useState(1);
  const [testEventTotal, setTestEventTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const openAddTestEventModal = () => {
    setDisplayAddTestEventModal(true);
  }

  const showTestEvent = (testEvent) => {
    setTestEventDetails(testEvent);
    updateTestEventGroups(testEvent)
  }

  const updateTestEventGroups = (testEvent) => {
    getTestEventGroups(testEvent.id, '').then(response => {
      setGroups(response.data);
    })
  }

  const batchDeleteTestEvents = () => {
    
  }

  const infinityLoad = () => {
    
  }

  const saveAddTestEvent = (e) => {
    e.preventDefault();
    
  }

  const showTestEventFittingDetails = (fitting) => {
    setFitting(fitting);
  }

  const closeAddTestEventModal = () => {
    setDisplayAddTestEventModal(false);
  }

  const confirmBatchDeleteTestEvents = (e) => {
    e.preventDefault();
    deleteTestEvents({data: selectedTestEvents}).then(response => {
      setDisplayBatchDeleteConfirmationModal(false);
      let newTestEvents = testEvents.filter(te => {
        return selectedTestEvents.filter(ste => {
          return te.id === ste.id;
        }).length === 0;
      })
      setTestEventTotal(newTestEvents.length);
      setTestEvents(newTestEvents);
      setIsDeletingTestEvents(false);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Logbooks Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }
  
  const closeBatchDeleteConfirmationModal = () => {
    setDisplayBatchDeleteConfirmationModal(false);
  }

  const testEventAdded = () => {
    refreshTestEvents();
    setDisplayAddTestEventModal(false);
    dispatch({ type: 'SET POPUP', payload: 'success' });
    dispatch({ type: 'SET POPUP MESSAGES', payload: ['Logbook Created'] });
    setTimeout(() => {
      dispatch({ type: 'SET POPUP', payload: '' })
    }, 3000)
  }

  const testEventAddError = (error) => {
    dispatch({ type: 'SET POPUP', payload: 'danger' });
    dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
    setTimeout(() => {
      dispatch({ type: 'SET POPUP', payload: '' })
    }, 3000)
  }

  const selectTestEvent = (testEvent) => {
    let testEventsNew = testEvents;
    testEventsNew.forEach(te => {
      if (te.id === testEvent.id) {
        te.checked = !testEvent.checked;
      }
    })
    setTestEvents(testEventsNew);

    let selected = [];
    testEventsNew.forEach(te => {
      if (te.checked) {
        selected.push(te);
      }
    })
    setSelectedTestEvents(selected);
  }

  const openDeleteTestEvents = () => {
    setDisplayBatchDeleteConfirmationModal(true);
  }

  const goBack = () => {
    setTestEventDetails(null);
    refreshTestEvents();
  }

  const toggleDeleteTestEvents = () => {
    setIsDeletingTestEvents(!isDeletingTestEvents);
  }

  const loadMoreTestEvents = () => {
    if (Math.ceil(testEvents.length / 10) < testEventTotal) {
      setTestEventPageNum(testEventPageNum + 1);
    }
  }

  const updateGroups = () => {
    const groupsFilter = '?page[number]=1&page[size]=10&site=' + site.id;
    getGroups(site.id, groupsFilter).then(response => {
      setGroups(response.data);
    })
  }

  const fittingDeleted = () => {
    setTestEventDetails(null);
    setFitting(null);
  }

  const showFittingDetails = (fitting) => {
    setFitting(fitting);
  }

  const updateTestEvent = (testEvent, duplicate) => {
    setTestEventDetails(testEvent);
    updateTestEvents();
    updateGroups();
  }

  const updateTestEvents = () => {
    const testEventsFilter = '?page[number]=' + testEventPageNum + '&page[size]=10&site=' + site.id;
    setLoading(true);
    getTestEvents(site.id, testEventsFilter).then(response => {
      setTestEvents(testEvents.concat(response.data));
      setTestEventTotal(response.meta['total-objects']);
      setLoading(false);
    })
  }

  const refreshTestEvents = () => {
    const testEventsFilter = '?page[number]=1&page[size]=10&site=' + site.id;
    setTestEventPageNum(1);
    setTestEvents([]);
    getTestEvents(site.id, testEventsFilter).then(response => {
      setTestEvents(response.data);
      setTestEventTotal(response.meta['total-objects']);
    })
  }

  useEffect(() => {
    updateTestEvents();
    updateGroups();
  }, [])

  useEffect(() => {
    updateTestEvents();
  }, [testEventPageNum])

  return (
    <div className='fittings-index'>
      {testEventDetails ? (
        <Groups 
          site={site}
          testEventGrps={groups}
          testEvent={testEventDetails} 
          onBack={goBack}
          onShowTestEventFittingDetails={showTestEventFittingDetails}
          onUpdateTestEvent={updateTestEvent}
        />
      ) : (
        <TestEventTable 
          loading={loading}
          testEvents={testEvents}
          testEventTotal={testEventTotal}
          isDeletingTestEvents={isDeletingTestEvents}
          onOpenAddTestEventModal={openAddTestEventModal}
          onShowTestEvent={showTestEvent}
          onBatchDeleteTestEvents={batchDeleteTestEvents}
          infinityLoad={infinityLoad}
          selectedTestEvents={selectedTestEvents}
          onSelectTestEvent={selectTestEvent}
          onDeleteTestEvents={openDeleteTestEvents}
          onToggleDeleteTestEvents={toggleDeleteTestEvents}
          onLoadMore={loadMoreTestEvents}
        />
      )}

      {fitting && (
        <Show 
          fitting={fitting}
          onUpdateFitting={showFittingDetails} 
          onFittingDeleted={fittingDeleted}
          onUpdateMaintenance={showFittingDetails}
        />
      )}

      {displayAddTestEventModal && (
        <CreateTestEventModal 
          testEvent={testEvent} 
          save={saveAddTestEvent} 
          site={site}
          isQueryPending={isQueryPending} 
          onCloseModal={closeAddTestEventModal}
          onAddTestEvent={testEventAdded}
          onAddTestEventError={testEventAddError}
        />
      )}

      {displayBatchDeleteConfirmationModal && (
        <ModalView>
          <BatchDeleteConfirmation
            batch={selectedTestEvents}
            batchName='Test Event'
            onConfirm={confirmBatchDeleteTestEvents}
            onClose={closeBatchDeleteConfirmationModal}
            isQueryPending={isQueryPending}
          >
            Are you sure you want to delete {pluralizer(batchLength, "this")} {pluralizer(batchLength, "test event")}? Deleting can’t be undone.
          </BatchDeleteConfirmation>
        </ModalView>
      )}
    </div>
  )
}
