import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import logo_white from '../assets/icons/logo-white.svg';
import invite_icon from '../assets/icons/invite.svg';
import icon_close from '../assets/images/icon-close.svg';
import ModalView from './components/ModalView';
import FormValidatedSelect from './components/FormValidatedSelect';
import { getInvite, acceptInvite, acceptSiteTransfer, getTransferInvite } from '../api/inviteApi';
import { getOrganisation, getOrganisationFiltered, getOrganisations } from '../api/organisationApi';
import { validateSelectedAccount } from '../utils/validators';

export default ({ mod, isQueryPending, sitenametmp, onAddNewOrg, onSetCurrentUser}) => {

  // const queryParameters = new URLSearchParams(window.location.search);
  // const branch_match_id = queryParameters.get("_branch_match_id");
  // const branch_referrer = queryParameters.get("_branch_referrer");

  const location = useLocation();
  const navigate = useNavigate();

  const [isInvitePending, setIsInvitePending] = useState(true);
  const [isInviteExpired, setIsInviteExpired] = useState(false);
  const [isInviteAccepted, setIsInviteAccepted] = useState(false);
  const [displayTransferSitesModal, setDisplayTransferSitesModal] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [invite, setInvite] = useState(null);
  const [inviteToken, setInviteToken] = useState('');
  const [user, setUser] = useState(null);
  const [siteTransferObj, setSiteTransferObj] = useState(null);
  const [transferFrom, setTransferFrom] = useState('');

  // Site transfer account
  const [selectedAccount, setSelectedAccount] = useState('');
  const [errorMessageAccount, setErrorMessageAccount] = useState('');
  const [showErrorMessageAccount, setShowErrorMessageAccount] = useState(false);

  const acceptTransfer = () => {
    if (checkSelectedAccount(selectedAccount)) {
      const data = {
        data: {
          type: "invites",
          attributes: {
            id: siteTransferObj.id,
            newOrgId: selectedAccount
          }
        }
      } 
      acceptSiteTransfer(siteTransferObj.id, data).then(response => {
        console.log(response)
        onSetCurrentUser(user, selectedAccount, true);
        navigate('/sites');
      })
    }
  }

  const skip = () => {
    onSetCurrentUser(user, organisation.id, true);
    navigate('/sites');
  }

  const accept = () => {
    const data = {
      data: {
        type: 'invites',
        attributes: {
          token: inviteToken
        }
      }
    };
    acceptInvite(invite.id, data).then(response => {
      let inviteUser = user;
      let newOrg = {
        label: organisation.attributes.name,
        value: organisation.id
      }
      onAddNewOrg(newOrg);
      onSetCurrentUser(inviteUser, organisation.id, true);
      navigate('/sites');
    })
  }

  const openTransferSiteToUserModal = () => {
    setDisplayTransferSitesModal(true);
  }

  const closeTransferSiteToUserModal = () => {
    setDisplayTransferSitesModal(false);
  }

  const changeSelectedAccount = (e) => {
    setSelectedAccount(e.target.value)
    checkSelectedAccount(e.target.value)
  }

  const checkSelectedAccount = (input) => {
    const validateResult = validateSelectedAccount(input);
    if (validateResult.validated) {
      setShowErrorMessageAccount(false);
      setErrorMessageAccount('');
    } else {
      setShowErrorMessageAccount(true);
      setErrorMessageAccount(validateResult.message);
    }
    return validateResult.validated;
  }

  useEffect(() => {
    if (location.state && location.state.inviteObj) {
      let inviteObj = location.state.inviteObj;
      if (inviteObj.sitetransfertouser) {
        setSiteTransferObj(inviteObj);
        getTransferInvite(inviteObj.id).then(response => {
          setTransferFrom(response.data[0].attributes['first-name'] + ' ' + response.data[0].attributes['last-name'])
        })
        const filters = '?filter[role]=owner,admin'
        getOrganisations(filters).then(response => {
          let orgs = [];
          response.data.forEach(org => {
            orgs.push({
              label: org.attributes['name'],
              value: org.id
            })
          })
          setOrganisations(orgs);
        })
      } else {
        setInviteToken(inviteObj.token);
        const filters = '?include=organisation';
        getInvite(inviteObj.id, filters).then(response => {
          setInvite(response.data);
          setOrganisation(response.included[0]);
          let isInviteAccepted = response.data.attributes['status'] === 'accepted';
          let isInviteExpired = response.data.attributes['status'] === 'expired';
          let isInvitePending = response.data.attributes['status'] === 'pending';
          setIsInviteAccepted(isInviteAccepted);
          setIsInviteExpired(isInviteExpired);
          setIsInvitePending(isInvitePending);
        })
      }
    }

    if (location.state && location.state.user) {
      setUser(location.state.user);
    }
  }, [location.state]);


  return (
    <>
      <div id="invite" className="auth-page">
        <div className="content">
          <img src={logo_white} className="logo-large" />
          <div className="title">
            <img src={invite_icon} className='invite-icon' alt="Icon Invite" />
            <h3>Invite to {organisation && organisation.attributes['name']}</h3>
          </div>
          {!siteTransferObj && isInvitePending && (
            <>
              <p>You have been invited to a new organisation {organisation && organisation.attributes['name']}. </p>
              <p>Would you like to accept and join the team?</p>
            </>
          )}
          {!siteTransferObj && isInviteExpired && (
            <p>This invite has expired.</p>
          )}
          {!siteTransferObj && isInviteAccepted && (
            <p>You've already accepted this invite.</p>
          )}

          {siteTransferObj && (
            <>
              <p>Site transfer from {transferFrom}</p>
              You have been nominated to take ownership of the emergency lighting data for {siteTransferObj.sitename}
            </>
          )}

          <div className="actions">
            {isInvitePending && siteTransferObj && (
              <>
                <button className="button green large auth-submit" disabled={isQueryPending} onClick={openTransferSiteToUserModal} >Accept Transer</button>
                <button className="button large auth-submit" onClick={skip}>Skip</button>
              </>
            )}
            {isInvitePending && !siteTransferObj && (
              <>
                <button className="button green large auth-submit" disabled={isQueryPending} onClick={accept}>Accept Invite</button>
                <button className="button large auth-submit" onClick={skip} >Skip</button>
              </>
            )}
            {!isInvitePending && siteTransferObj && (
              <>
                <button className="button green large auth-submit" disabled={isQueryPending} onClick={acceptTransfer}>Accept Transfer</button>
                <button className="button large auth-submit" onClick={skip} >Skip</button>
              </>
            )}
            {!isInvitePending && !siteTransferObj && (
              <button className="button green large auth-submit" onClick={skip}>Continue</button>
            )}
            
          </div>
        </div>

        {displayTransferSitesModal && (
          <ModalView>
            <div>
              <div className="modal-header">
                <h2 className="form-heading" style={{color: 'black'}}>Transfer Site</h2>
                <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeTransferSiteToUserModal} />
              </div>


              <div className="modal-content">
                <div className="inner" style={{color: 'black'}}>
                  {organisations && organisations.length > 0 ? (
                    <>
                      <p className="message">Select a business to transfer this site to.</p>

                      <div className="form">
                        <div className="row row-animated">
                          <FormValidatedSelect type='text' name='selectedAccount' options={organisations} value={selectedAccount} showErrorMessage={showErrorMessageAccount} errorMessage={errorMessageAccount} labelText='Business' onChange={changeSelectedAccount}/>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>You can only transfer sites to other businesses where you are an owner or administrator.</p>
                  )}
                </div>

                <div className="modal-actions add-action">
                  {organisations && organisations.length > 0 ? (
                    <div className="button standard blue add-site-submit" onClick={acceptTransfer}>
                      Transfer
                    </div>
                  ) : (
                    <div className="button standard blue add-site-submit" onClick={closeTransferSiteToUserModal} >
                      OK
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModalView>
        )}

      </div>
    </>
  )
}


