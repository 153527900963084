import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation  } from "react-router-dom";
import { getEmployment, removeEmployment } from '../api/employmentApi';
import { getSitePermissions } from '../api/otherApi';
import icon_add_white from '../assets/images/icon-add-white.svg';
import icon_close from '../assets/images/icon-close.svg';
import { store } from '../utils/store';
import InviteToSiteForm from './components/forms/InviteToSiteForm';
import ModalView from './components/ModalView';
import SitePermissionsPanel from './components/SitePermissionsPanel';
import UserPanel from './components/UserPanel';

export default ({children, currentOrg, isQueryPending, infinityLoad, technicians, selectedSites, usersRestrictedSites, copyPermissions}) => {

  const userId = useLocation().state.userId;
  const { dispatch } = useContext(store);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [employment, setEmployment] = useState(null);
  const [sitePermissions, setSitePermissions] = useState([]);
  const [canModify, setCanModify] = useState(false);
  const [displayInviteToSitesModal, setDisplayInviteToSitesModal] = useState(false);
  const [displayDeleteUserModal, setDisplayDeleteUserModal] = useState(false);

  const showInviteToSitesModal = (e) => {
    e.preventDefault();
    setDisplayInviteToSitesModal(true);
  }

  const showDeleteUserModal = () => {
    setDisplayDeleteUserModal(true);
  }

  const closeInviteToSitesModal = (e) => {
    e.preventDefault();
    setDisplayInviteToSitesModal(false);
  }

  const closeDeleteUserModal = () => {
    setDisplayDeleteUserModal(false);
  }

  const deleteUser = () => {
    removeEmployment(employment.id).then(() => {
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['User Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
      navigate('/users');
    })
  }

  const sitePermitted = () => {
    setDisplayInviteToSitesModal(false);
    updateSitePermissions();
  }

  const permissionRemoved = () => {
    dispatch({ type: 'SET POPUP', payload: 'success' });
    dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Access has been removed'] });
    setTimeout(() => {
      dispatch({ type: 'SET POPUP', payload: '' })
    }, 3000)
    updateSitePermissions();
  }

  const updateSitePermissions = () => {
    if (currentOrg && user) {
      const filters = '?include=user,site&filter[user-id]=' + user.id + '&organisationId=' + currentOrg + '&page[number]=1&page[size]=1000';
      getSitePermissions(currentOrg, filters).then(response => {
        let sitePermissions = [];
        response.data.forEach(sitePermission => {
          let sitePerm;
          response.included.forEach(include => {
            if (include.type === 'sites' && include.id === sitePermission.relationships.site.data.id) {
              sitePerm = sitePermission;
              sitePerm.businessName = include.attributes['business-name'];
              sitePerm.address = include.attributes['address'];
              sitePermissions.push(sitePerm);
            }
          })
        })
        setSitePermissions(sitePermissions);
      })
    }
  }

  useEffect(() => {
    const filters = '?include=invite,user,organisation';
    getEmployment(userId, filters).then((response) => {
      setEmployment(response.data);
      if (response.data.attributes['role'] === "technician") setCanModify(true);
      response.included.forEach(include => {
        if (include.type === 'users') {
          setUser(include);
        }
      })
    })
  }, [])

  useEffect(() => {
    updateSitePermissions();
  }, [currentOrg, user])

  return (
    <div>
      {children}

      <div id="user-page" className="page main-page">
        <div className="header">
          <h2>{user && (user.attributes['first-name'] + ' ' + user.attributes['last-name'])}</h2>
          {canModify && (
            <div className="page-header-actions">
              <button className="action button icon green" onClick={showInviteToSitesModal} >
                <img className="icon" src={icon_add_white} alt="Add Icon"/>
                Invite to Sites
              </button>
            </div>
          )}
        </div>
        <div className="user-content">
          <UserPanel user={user} employment={employment} onDeleteUser={showDeleteUserModal} />
          {canModify && (
            <SitePermissionsPanel user={user} sitePermissions={sitePermissions} isQueryPending={isQueryPending} infinityLoad={infinityLoad} onPermissionRemoved={permissionRemoved}/>
          )}
        </div>
      </div>
      
      {displayInviteToSitesModal && (
        <ModalView>
          <div>
            <div className="modal-header">
              <h2 className="form-heading">Grant Access to Sites</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeInviteToSitesModal}/>
            </div>

            <div className="modal-content">
              <InviteToSiteForm 
                user={user}
                currentOrg={currentOrg}
                technicians={technicians}
                selectedSites={selectedSites}
                usersRestrictedSites={usersRestrictedSites}
                isQueryPending={isQueryPending}
                copyPermissions={copyPermissions}
                onSitePermitted={sitePermitted}
              />
            </div>
          </div>
        </ModalView>
      )}

      {displayDeleteUserModal && (
        <ModalView>
          <div>
            <div className="modal-header">
              <h2 className="form-heading">Delete User</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeDeleteUserModal} />
            </div>

            <div className="modal-content">
              <div className="inner">
                <p>Are you sure you want to delete this user? This will remove their access to all sites in your organisation.</p>
              </div>
              <div className="modal-actions">
                <div className="button small red" disabled={isQueryPending} onClick={deleteUser} >
                  Yes, Delete
                </div>
              </div>
            </div>
          </div>
        </ModalView>
      )}
    </div>
  )
}