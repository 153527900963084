import React, { useState, useEffect, useContext } from 'react';
import close_icon from '../../assets/images/icon-close.svg'

export default ({onClose, onReinstateFitting}) => {

  return (
    <>
      <div className="modal-header">
        <h2 className="form-heading">Reinstate Fitting</h2>
        <img className="modal-close" src={close_icon} alt="Close Icon" onClick={onClose}/>
      </div>

      <div className="modal-content">
        <div className="inner">
          <p>
            Are you sure you want to reinstate this fitting? The fitting will be placed back into it's original
            group if it exists. If the group has been deleted, we'll recreate it.
          </p>
        </div>
        <div className="modal-actions">
          <div className="button standard blue" onClick={onClose}>
            Cancel
          </div>
          <button className="button standard green" onClick={onReinstateFitting}>
            Reinstate
          </button>
        </div>
      </div>
    </>
  )
}