import React, { useState, useEffect } from 'react';
import { getDeletedFittings } from '../../api/fittingApi';
import DeletedFittingTable from '../components/DeletedFittingTable';
import Show from '../groups/fittings/Show';

export default ({site}) => {

  const [deletedFittings, setDeletedFittings] = useState([]);
  const [fitting, setFitting] = useState(null);
  const [deletedFittingPageNum, setDeletedFittingPageNum] = useState(1);
  const [deletedFittingTotal, setDeletedFittingTotal] = useState(0);

  const infinityLoad = () => {

  }

  const fittingDeleted = () => {
    setFitting(null);
  }

  const showFittingDetails = (fitting) => {
    setFitting(fitting);
  }

  const loadMoreDeletedFittings = () => {
    if (Math.ceil(deletedFittings.length / 10) < deletedFittingTotal) {
      setDeletedFittingPageNum(deletedFittingPageNum + 1);
    }
  }

  const updateDeletedFittings = () => {
    const deletedFittingsFilter = '?page[number]=' + deletedFittingPageNum + '&page[size]=10&site=' + site.id;
    getDeletedFittings(site.id, deletedFittingsFilter).then(response => {
      setDeletedFittings(deletedFittings.concat(response.data));
      setDeletedFittingTotal(response.meta['total-objects']);
    })
  }

  const refreshDeletedFittings = () => {
    setFitting(null);
    const deletedFittingsFilter = '?page[number]=1&page[size]=10&site=' + site.id;
    getDeletedFittings(site.id, deletedFittingsFilter).then(response => {
      setDeletedFittings(response.data);
      setDeletedFittingTotal(response.meta['total-objects']);
    })
  }

  useEffect(() => {
    updateDeletedFittings();
  }, [])

  useEffect(() => {
    updateDeletedFittings();
  }, [deletedFittingPageNum])

  return (
    <>
      <div className="header">
        <h2>Deleted Fittings</h2>
      </div>
      <div className="fittings-index">
        <div className="fittings-list">
          <DeletedFittingTable 
            deletedFittings={deletedFittings} 
            deletedFittingTotal={deletedFittingTotal}
            showFittingDetails='showFittingDetails' 
            infinityLoad={infinityLoad}
            onShowFitting={showFittingDetails}
            onLoadMore={loadMoreDeletedFittings}
          />
        </div>
        
        {fitting && (
          <Show 
            fitting={fitting}
            isDeleted={true}
            onUpdateFitting={showFittingDetails} 
            onFittingDeleted={fittingDeleted}
            onUpdateMaintenance={showFittingDetails}
            onRefresh={refreshDeletedFittings}
          />
        )}
      </div>
    </>
  )
}