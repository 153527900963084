import React, { useState, useEffect, useContext } from 'react';
import AuthValidatedInput from '../AuthValidatedInput';

export default ({ email, didValidate, isQueryPending, isValidEmail, errorMessageEmail, showErrorMessageEmail, onChangeEmail, onRequestPasswordReset }) => {

  return (
    <form id='forgot-password-form' className="form auth-form forgot-password-form">
      <div className="row row-animated email">
        <AuthValidatedInput 
          name={'email'} 
          value={email} 
          didValidate={didValidate} 
          labelText={'Email'} 
          isValid={isValidEmail}
          errorMessage={errorMessageEmail}
          showErrorMessage={showErrorMessageEmail}
          onChange={onChangeEmail} 
        />
      </div>
      <div className="actions">
        <button type="submit" className="button green large  auth-submit" disabled={isQueryPending} onClick={onRequestPasswordReset}>Reset Password</button>
      </div>
    </form>
  )
}