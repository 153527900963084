import { BASE_URL, API_VERSION, ORGANISATIONS, ACCESS_REQUESTS } from "../settings/apiUrls";
import api from "./api";

export const accessRequests = (organisationId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + organisationId + ACCESS_REQUESTS + filters
    }).then((response) => {
        return response.data;
    });
}

export const requestSiteAccess = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + ACCESS_REQUESTS,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const approveAccess = (accessRequestId) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + ACCESS_REQUESTS + '/' + accessRequestId + '/approve'
    }).then((response) => {
        return response.data;
    });
}

export const declineAccess = (accessRequestId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + ACCESS_REQUESTS + '/' + accessRequestId
    }).then((response) => {
        return response.data;
    });
}