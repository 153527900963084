import React, { useState, useEffect, useContext } from 'react';
import { getAllFittings } from '../../api/fittingApi';
import { addGroup, deleteGroups, getGroups } from '../../api/groupApi';
import icon_close from '../../assets/images/icon-close.svg';
import { pluralizer } from '../../utils/pluralizer';
import { store } from '../../utils/store';
import { validateGroupName } from '../../utils/validators';
import BatchDeleteConfirmation from '../components/BatchDeleteConfirmation';
import BatchDeleteError from '../components/BatchDeleteError';
import GroupForm from '../components/forms/GroupForm';
import GroupTable from '../components/GroupTable';
import ModalView from '../components/ModalView';
import Fittings from '../groups/Fittings';

export default ({site, isQueryPending}) => {

  const { dispatch } = useContext(store);

  const [groups, setGroups] = useState([]);
  const [groupDetails, setGroupDetails] = useState(null);
  const [displayAddGroupModal, setDisplayAddGroupModal] = useState(false);
  const [displayBatchConfirmationModal, setDisplayBatchConfirmationModal] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [displayBatchErrorModal, setDisplayBatchErrorModal] = useState(false);
  const [infinityLoad, setInfinityLoad] = useState(false);
  const [batchDeleteGroups, setBatchDeleteGroups] = useState(null);
  const [groupPageNum, setGroupPageNum] = useState(1);
  const [groupTotal, setGroupTotal] = useState(0);
  const [isDeletingGroups, setIsDeletingGroups] = useState(false);

  // Group
  const [group, setGroup] = useState('');
  const [errorMessageGroup, setErrorMessageGroup] = useState('');
  const [showErrorMessageGroup, setShowErrorMessageGroup] = useState(false);

  const confirmBatchDeleteGroups = (e) => {
    e.preventDefault();
    deleteGroups({data: selectedGroups}).then(response => {
      setDisplayBatchConfirmationModal(false);
      let newGroups = groups.filter(g => {
        return selectedGroups.filter(sg => {
          return g.id === sg.id;
        }).length === 0;
      })
      setGroupTotal(newGroups.length);
      setGroups(newGroups);
      setIsDeletingGroups(false);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Groups Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    }).catch(error => {
      dispatch({ type: 'SET POPUP', payload: 'danger' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const closeBatchConfirmationModal = () => {
    setDisplayBatchConfirmationModal(false);
  }

  const closeBatchErrorModal = () => {

  }

  const toggleDeleteGroups = () => {
    setIsDeletingGroups(!isDeletingGroups);
  }

  const checkGroup = (input) => {
    const validateResult = validateGroupName(input);
    if (validateResult.validated) {
      setShowErrorMessageGroup(false);
      setErrorMessageGroup('');
      return true;
    } else {
      setShowErrorMessageGroup(true);
      setErrorMessageGroup(validateResult.message);
      return false;
    }
  }

  const changeGroup = (e) => {
    checkGroup(e.target.value);
    setGroup(e.target.value);
  }

  const saveGroup = (e) => {
    e.preventDefault();

    if (checkGroup(group)) {
      const data = {
        data: {
          attributes: {
            "name": group,
            "fittings-count": null,
            "faulty-fittings-count": null,
            "created-at": null,
            "commissioned-fittings-count": null,
            "not-commissioned-fittings-count": null
          },
          relationships: {
            site: {
              data: {
                type: "sites",
                id: site.id
              }
            }
          },
          type: "groups"
        }
      }
      addGroup(data).then(response => {
        setDisplayAddGroupModal(false);
        // setGroups([response.data, ...groups]);
        // setGroupTotal(groupTotal + 1);
        refreshGroups();
        dispatch({ type: 'SET POPUP', payload: 'success' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: ['Group Added'] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      }).catch(error => {
        if (error) {
          dispatch({ type: 'SET POPUP', payload: 'danger' });
          dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
          setTimeout(() => {
            dispatch({ type: 'SET POPUP', payload: '' })
          }, 3000)
        }
      });
    }

  }

  const openAddGroup = () => {
    setDisplayAddGroupModal(true);
  }

  const closeAddGroup = () => {
    setShowErrorMessageGroup(false);
    setErrorMessageGroup('');
    setGroup('');
    setDisplayAddGroupModal(false);
  }

  const openDeleteGroups = () => {
    setDisplayBatchConfirmationModal(true);
  }

  const selectGroup = (group) => {
    let groupsNew = groups;
    groupsNew.forEach(g => {
      if (g.id === group.id) {
        g.checked = !group.checked;
      }
    })
    setGroups(groupsNew);

    let selected = [];
    groupsNew.forEach(g => {
      if (g.checked) {
        selected.push(g);
      }
    })
    setSelectedGroups(selected);
  }

  const showGroupDetails = (group) => {
    setGroupDetails(group);
  }

  const goBack = () => {
    setGroupDetails(null);
  }

  const loadMoreGroups = () => {
    if (Math.ceil(groups.length / 10) < groupTotal) {
      setGroupPageNum(groupPageNum + 1);
    }
  }

  const updateGroup = (groupName) => {
    let newGroup = groupDetails;
    newGroup.attributes['name'] = groupName;
    setGroupDetails(newGroup);
    let newGroups = groups;
    newGroups.forEach(g => {
      if (g.id === groupDetails.id) {
        g.attributes['name'] = groupName;
      }
    })
    setGroups(newGroups);
  }

  const updateGroups = () => {
    const groupsFilter = '?page[number]=' + groupPageNum + '&page[size]=10&site=' + site.id;
    getGroups(site.id, groupsFilter).then(response => {
      setGroups(groups.concat(response.data));
      setGroupTotal(response.meta['total-objects']);
    })
  }

  const refreshGroups = () => {
    const groupsFilter = '?page[number]=1&page[size]=10&site=' + site.id;
    setGroupPageNum(1);
    setGroups([]);
    setGroup('');
    getGroups(site.id, groupsFilter).then(response => {
      setGroups(response.data);
      setGroupTotal(response.meta['total-objects']);
    })
  }

  const deleteGroup = () => {
    refreshGroups();
    setGroupDetails(null);
  }

  useEffect(() => {
    updateGroups();
  }, [])

  useEffect(() => {
    updateGroups();
  }, [groupPageNum])

  return (
    <div>
      {groupDetails ? (
        <Fittings site={site} group={groupDetails} sitesGroups={groups} onBack={goBack} onUpdateGroup={updateGroup} onDeleteGroup={deleteGroup} onChangeFittings={updateGroups}/>
      ) : (
        <GroupTable 
          groups={groups}
          selectedGroups={selectedGroups}
          infinityLoad={infinityLoad}
          toggleAddGroup={closeAddGroup}
          batchDeleteGroups={batchDeleteGroups}
          groupTotal={groupTotal}
          isDeletingGroups={isDeletingGroups}
          onAddGroup={openAddGroup}
          onSelectGroup={selectGroup}
          onDeleteGroups={openDeleteGroups}
          onShowGroupDetails={showGroupDetails}
          onLoadMore={loadMoreGroups}
          onToggleDeleteGroups={toggleDeleteGroups}
        />
      )}

      {displayAddGroupModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Add a Group</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeAddGroup} />
            </div>
        
            <div className="modal-content">
              <GroupForm group={group} onSaveGroup={saveGroup} isQueryPending={isQueryPending} errorMessage={errorMessageGroup} showErrorMessage={showErrorMessageGroup} onChange={changeGroup}/>
            </div>
          </>
        </ModalView>
      )}

      {displayBatchConfirmationModal && (
        <ModalView>
          <BatchDeleteConfirmation 
            batch={selectedGroups}
            batchName='group'
            onConfirm={confirmBatchDeleteGroups}
            closeBatchConfirmationModal={closeBatchConfirmationModal}
            isQueryPending={isQueryPending}
            onClose={closeBatchConfirmationModal}
          >
            Are you sure you want to delete {pluralizer(selectedGroups.length, 'this')} {pluralizer(selectedGroups.length, 'group')}? Deleting can’t be undone.
          </BatchDeleteConfirmation>
        </ModalView>
      )}

      {displayBatchErrorModal && (
        <ModalView>
          <BatchDeleteError 
            batchName='group'
            batchLength={selectedGroups.length}
            onClose={closeBatchErrorModal} 
          >
            To delete {pluralizer(selectedGroups.length, 'this')} {pluralizer(selectedGroups.length, 'its')}, you’ll first need to move {pluralizer(selectedGroups.length, 'group')} fittings into other groups.
          </BatchDeleteError>
        </ModalView>
      )}
    </div>
  )
}
