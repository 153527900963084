import React, { useState, useEffect } from 'react';

export default ({fileName, progress, uploadSuccess, onCancelUpload, onClose}) => {

  return (
    <div className='file-upload-progress-bar show'>
      {!uploadSuccess && (
        <div>
          <p className="progress-message">Uploading {fileName} {progress}%</p>
          <div className='progress-bar' style={{width: `${progress}%`}}></div>
          {!uploadSuccess && <p className="cancel" onClick={onCancelUpload} >Cancel</p>}
        </div>
      )}
      {uploadSuccess && (
        <div>
          <p className="progress-message success">Your spreadsheet uploaded successfully.</p>
          <p className="dismiss" onClick={onClose}>x</p>
        </div>
      )}
    </div>
  )
}
