import React, { useState, useEffect, useContext } from 'react';
import icon_alert_blue from '../../assets/images/icon-alert-blue.svg';
import delete_icon from '../../assets/icons/delete.svg';
import icon_close from '../../assets/images/icon-close.svg';
import { repairOptions } from '../../utils/variables';
import FormValidatedInput from './FormValidatedInput';
import FormValidatedSelect from './FormValidatedSelect';
import ModalView from './ModalView';
import UpdateFittingForm from './forms/UpdateFittingForm';
import MaintenanceDeleteConfirmation from './MaintenanceDeleteConfirmation';
import { validateRepairDate, validateRepaired } from '../../utils/validators';
import moment from 'moment';
import { removeMaintenance, updateMaintenance } from '../../api/maintenanceApi';

export default ({fitting, maintenance, shouldShowTestEventWarning, isDeleted, didValidate, isQueryPending, onUpdate}) => {

  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [showUpdateFittingModal, setShowUpdateFittingModal] = useState(false);
  const [showDeleteMaintenanceModal, setShowDeleteMaintenanceModal] = useState(false);

  // Repair date
  const [repairDate, setRepairDate] = useState('');
  const [errorMessageRepairDate, setErrorMessageRepairDate] = useState('');
  const [showErrorMessageRepairDate, setShowErrorMessageRepairDate] = useState(false);

  // Repair
  const [repair, setRepair] = useState('');
  const [errorMessageRepair, setErrorMessageRepair] = useState('');
  const [showErrorMessageRepair, setShowErrorMessageRepair] = useState(false);

  const [notes, setNotes] = useState('');

  const checkRepairDate = (input) => {
    const validateResult = validateRepairDate(input);
    if (validateResult.validated) {
      setShowErrorMessageRepairDate(false);
      setErrorMessageRepairDate('');
    } else {
      setShowErrorMessageRepairDate(true);
      setErrorMessageRepairDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeRepairDate = (value) => {
    setRepairDate(value)
    checkRepairDate(value)
  }

  const checkRepair = (input) => {
    const validateResult = validateRepaired(input);
    if (validateResult.validated) {
      setShowErrorMessageRepair(false);
      setErrorMessageRepair('');
    } else {
      setShowErrorMessageRepair(true);
      setErrorMessageRepair(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeRepair = (e) => {
    setRepair(e.target.value)
    checkRepair(e.target.value)
  }

  const edit = () => {
    setInputsDisabled(false);
  }

  const save = () => {
    let validatedRepairDate = checkRepairDate(repairDate);
    let validatedRepair = checkRepair(repair);
    if (validatedRepairDate && validatedRepair) {
      if (repair === 'fitting') {
        setShowUpdateFittingModal(true);
      } else {
        const maintenanceData = {
          data: {
            attributes: {
              "repair-date": moment(repairDate).format('YYYY-MM-DD'),
              "repair": repair,
              "notes": notes
            },
            relationships: {
              fitting: {
                data: {
                  type: "fittings",
                  id: fitting.id
                }
              }
            },
            type: "maintenances"
          }
        }
        updateMaintenance(maintenance.id, maintenanceData).then(res => {
          setInputsDisabled(true);
          onUpdate();
        })
      }
    }
  }

  const cancel = () => {
    setInputsDisabled(true);
    if (maintenance) {
      setRepairDate(new Date(maintenance.attributes['repair-date']));
      setRepair(maintenance.attributes['repair']);
      setNotes(maintenance.attributes['notes']);
      setShowErrorMessageRepairDate(false);
      setShowErrorMessageRepair(false);
      setErrorMessageRepair('');
      setErrorMessageRepairDate('');
    }
  }

  const clickShowDeleteMaintenanceModal = () => {
    setShowDeleteMaintenanceModal(true);
  }

  const closeUpdateFittingModal = () => {
    setShowUpdateFittingModal(false);
  }

  const closeDeleteMaintenanceModal = () => {
    setShowDeleteMaintenanceModal(false);
  }

  const updatedFitting = () => {
    setInputsDisabled(true);
    setShowUpdateFittingModal(false);
    onUpdate();
  }

  const deleteMaintenance = () => {
    removeMaintenance(maintenance.id).then(response => {
      onUpdate();
    })
  }

  useEffect(() => {
    if (maintenance) {
      setRepairDate(new Date(maintenance.attributes['repair-date']));
      setRepair(maintenance.attributes['repair']);
      setNotes(maintenance.attributes['notes']);
    }
  }, [maintenance])

  return (
    <div>
      <div className='maintenance-row'>
        <div className="header-label-row">
          <label>
            {inputsDisabled ? `Repaired ${maintenance.attributes['repair-date']}` : 'Edit Maintenance'}
          </label>
          {inputsDisabled && !isDeleted && <label className="edit" onClick={edit}>Edit</label>}
        </div>
        {shouldShowTestEventWarning && (
          <div className="test-event-warning">
            <img src={icon_alert_blue} alt="Alert Icon" />
            The repair date falls outside of this test event.
          </div>
        )}
        {!inputsDisabled && (
          <div className="row row-animated white">
            <FormValidatedInput type='date' name='repairDate' value={repairDate} didValidate={didValidate} labelText='Repair Date' showErrorMessage={errorMessageRepairDate} errorMessage={errorMessageRepairDate} onChange={changeRepairDate} maxDate={new Date(moment())}/>
          </div>
        )}
        <div className="row row-animated">
          <FormValidatedSelect type='text' name='repair' disabled={inputsDisabled} options={repairOptions} value={repair} didValidate={didValidate} labelText='Repaired' showErrorMessage={showErrorMessageRepair} errorMessage={errorMessageRepair} onChange={changeRepair}/>
        </div>
        <div className="row row-animated">
          <FormValidatedInput type='textarea' name='notes' value={notes} didValidate={didValidate} disabled={inputsDisabled} labelText='Notes' onChange={e => setNotes(e.target.value)}/>
        </div>
        {!inputsDisabled && (
          <div className="fitting-actions">
            <div className="delete-action">
              <div className="button small text-red" onClick={clickShowDeleteMaintenanceModal} >
                <img className='icon delete-icon' src={delete_icon}  alt="Delete Icon"/>
                Delete Maintenance
              </div>
            </div>
            <div className="button small blue edit-actions" disabled={isQueryPending} onClick={save}>
              Save
            </div>
            <div className="button small text-blue edit-actions" onClick={cancel}>
              Cancel
            </div>
          </div>
        )}
      </div>

      {showUpdateFittingModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Update Fitting Details</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeUpdateFittingModal} />
            </div>

            <div className="modal-content">
              <UpdateFittingForm fitting={fitting} maintenance={maintenance} newMaintenance={false} repairDate={repairDate} repair={repair} notes={notes} isQueryPending={isQueryPending} onUpdate={updatedFitting}/>
            </div>
          </>
        </ModalView>
      )}

      {showDeleteMaintenanceModal && (
        <ModalView>
          <MaintenanceDeleteConfirmation onDeleteMaintenance={deleteMaintenance} onCloseModal={closeDeleteMaintenanceModal} isQueryPending={isQueryPending} />
        </ModalView>
      )}
    </div>
  )
}


