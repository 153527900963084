import React, { useState, useEffect, useContext } from 'react';
import { removePermission } from '../../api/otherApi';
import illus_fittings from '../../assets/images/illus-fittings.svg';
import LoadMoreButton from './LoadMoreButton';

export default ({user, sitePermissions, infinityLoad, isQueryPending, onPermissionRemoved}) => {

  const removeAccess = (sitePermission) => {
    removePermission(sitePermission.id).then(() => {
      onPermissionRemoved();
    })
  }

  return (
    <div id="site-permission-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="businessName column">
              <div className="inner">
                Business Name
              </div>
            </th>
            <th className="address column">
              <div className="inner">
                Address
              </div>
            </th>
            <th className="rowAction"></th>
          </tr>
        </thead>
    
        <tbody>
          {sitePermissions.map(sitePermission => (
            <tr key={sitePermissions.indexOf(sitePermission)}>
              <td className='column businessName'>{sitePermission && sitePermission.businessName}</td>
              <td className='column address'>{sitePermission && sitePermission.address}</td>
              <td>
                <div className="row-action">
                  <button className="button small text-red" disabled={isQueryPending} onClick={() => removeAccess(sitePermission)} >
                    Remove Access
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {sitePermissions.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_fittings} alt="Blue Row Icon"/>
          </div>
          <div className="empty-message">
            This user currently has no site permissions.
          </div>
        </div>
      ) : (
        <div className="table-actions">
          <LoadMoreButton infinityLoad={infinityLoad} infinityModel={sitePermissions} modelBeingPaginated='Sites Permissions' numberOfItemsDisplayedText={`(Showing ${sitePermissions.length} of ${sitePermissions.length})`} />
        </div>
      )}
    </div>
  )
}