import React, { useState, useEffect } from "react";
import icon_back from "../../assets/images/icon-back.svg";
import test_event_grey from "../../assets/icons/test-event-grey.svg";
import loading_icon from "../../assets/images/CleverSparky_Blink.gif";
import icon_arrow_right_grey from "../../assets/images/icon-arrow-right-grey.svg";
import icon_arrow_right_navy from "../../assets/images/icon-arrow-right-navy.svg";
import illus_groups from "../../assets/images/illus-groups.svg";
import icon_add_white from "../../assets/images/icon-add-white.svg";
import TestEventGroupDropdownMenu from "./TestEventGroupDropdownMenu";
import LoadMoreButton from "./LoadMoreButton";

export default ({
  loading,
  testEvent,
  testEventGroups,
  missingGroups,
  infinityLoad,
  onBack,
  onShowAddGroupsModal,
  onShowTestEventGroupDetails,
  onEditDateRangeModal,
  onDuplicateTestEventModal,
  onArchiveConfirmationModal,
  onQuickTestDefaultsModal,
  onRemoveGroupsModal,
}) => {
  return (
    <div id="test-event-group-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="table-row">
              <div className="test-event-group-table-head">
                <div className="inner">
                  <div className="back">
                    <img
                      src={icon_back}
                      className="icon-back"
                      alt="Back icon"
                      onClick={onBack}
                    />
                    <img
                      src={test_event_grey}
                      className="icon-test-event icon-test-event-grey"
                      alt="Test event icon grey"
                    />
                    {testEvent.attributes["start-date"]} to{" "}
                    {testEvent.attributes["end-date"]}
                  </div>
                </div>
              </div>
              <div className="test-event-group-table-head">
                {testEvent.attributes["archived"] ? (
                  <div className="inner">
                    <div className="test-event-archived">archived</div>
                  </div>
                ) : (
                  <TestEventGroupDropdownMenu
                    testEventGroups={testEventGroups}
                    missingGroups={missingGroups}
                    onAddGroupsModal={onShowAddGroupsModal}
                    onRemoveGroupsModal={onRemoveGroupsModal}
                    onEditDateRangeModal={onEditDateRangeModal}
                    onDuplicateTestEventModal={onDuplicateTestEventModal}
                    onArchiveConfirmationModal={onArchiveConfirmationModal}
                    onQuickTestDefaultsModal={onQuickTestDefaultsModal}
                  />
                )}
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {testEventGroups &&
            testEventGroups.map((testEventGroup) => (
              <tr
                key={testEventGroups.indexOf(testEventGroup)}
                onClick={() => onShowTestEventGroupDetails(testEventGroup)}
              >
                <td className="table-row">
                  <div className="column name">
                    <div className="truncated">
                      {testEventGroup.attributes["group"] ? testEventGroup.attributes["group"].name : testEventGroup.attributes["name"]}
                    </div>
                  </div>
                  <div className="column row-indicator">
                    <div className="arrow-right-grey">
                      <img src={icon_arrow_right_grey} alt="Row Icon" />
                    </div>
                    <div className="arrow-right-grey-blue">
                      <img src={icon_arrow_right_navy} alt="Blue Row Icon" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <div className="loading">
          <img
            className="loading-icon"
            src={loading_icon}
            style={{ margin: "30px auto;" }}
          />
        </div>
      ) : testEventGroups.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_groups} alt="Blue Row Icon" />
          </div>
          {!testEvent.attributes["archived"] && (
            <>
              <div className="empty-message">
                To start populating this logbook, click on the add groups button
                below.
              </div>
              <div className="empty-actions">
                <button
                  className="action button icon green"
                  onClick={onShowAddGroupsModal}
                >
                  <img className="icon" src={icon_add_white} alt="Add Icon" />
                  Add Groups
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="table-actions">
          <div className="left">
            <LoadMoreButton
              infinityLoad={infinityLoad}
              modelBeingPaginated="Test Event Groups"
              numberOfItemsDisplayedText={`(Showing ${testEventGroups.length} of ${testEventGroups.length})`}
            />
          </div>
        </div>
      )}
    </div>
  );
};
