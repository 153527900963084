import { BASE_URL, API_VERSION, DASHBOARD, DIFF_SITES } from "../settings/apiUrls";
import api from "./api";

export const getComplianceDashboard = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + DASHBOARD,
        data,
    }).then((response) => {
        return response.data;
    });
}

export const getComplianceDashboardDetails = (section, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + section,
        data,
    }).then((response) => {
        return response.data;
    });
}

export const getDiffSites = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + DIFF_SITES,
        data,
    }).then((response) => {
        return response.data;
    });
}