import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import FittingInfoBanner from '../FittingInfoBanner';
import FormValidatedInput from '../FormValidatedInput';
import icon_alert_blue from '../../../assets/images/icon-alert-blue.svg';
import { validateInstallDate } from '../../../utils/validators';
import { updateFitting } from '../../../api/fittingApi';
import { store } from '../../../utils/store';
import { addMaintenance, updateMaintenance } from '../../../api/maintenanceApi';
import { lookupFittingType } from '../../../api/otherApi';

export default ({maintenance, didValidate, fitting, newMaintenance, isQueryPending, repairDate, repair, notes, onUpdate}) => {

  const { dispatch, state } = useContext(store);

  const [serialNumber, setSerialNumber] = useState('');
  const [fittingType, setFittingType] = useState('');

  // Install date
  const [installDate, setInstallDate] = useState('');
  const [errorMessageInstallDate, setErrorMessageInstallDate] = useState('');
  const [showErrorMessageInstallDate, setShowErrorMessageInstallDate] = useState(false);

  const [shouldShowTestEventWarning, setShouldShowTestEventWarning] = useState(false);

  const update = (e) => {
    e.preventDefault();
    let validatedInstallDate = checkInstallDate(installDate);
    if (validatedInstallDate) {
      let attributes = fitting.attributes;
      attributes["serial-number"]= serialNumber;
      attributes["fitting-type"]= fittingType;
      attributes["install-date"]= moment(installDate).format('YYYY-MM-DD');
      const data = {
        data: {
          id: fitting.id,
          attributes: attributes,
          relationships: {
            group: fitting.relationships.groups
          },
          type: "fittings"
        }
      }

      updateFitting(fitting.id, data).then(response => {
        const maintenanceData = {
          data: {
            attributes: {
              "repair-date": moment(repairDate).format('YYYY-MM-DD'),
              "repair": repair,
              "notes": notes
            },
            relationships: {
              fitting: {
                data: {
                  type: "fittings",
                  id: fitting.id
                }
              }
            },
            type: "maintenances"
          }
        }
        if (newMaintenance) {
          addMaintenance(maintenanceData).then(res => {
            onUpdate();
            setSerialNumber('');
            setInstallDate('');
            setFittingType('');
          })
        } else {
          updateMaintenance(maintenance.id, maintenanceData).then(res => {
            onUpdate();
          })
        }
      }).catch(error => {
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  const checkInstallDate = (input) => {
    const validateResult = validateInstallDate(input);
    if (validateResult.validated) {
      setShowErrorMessageInstallDate(false);
      setErrorMessageInstallDate('');
    } else {
      setShowErrorMessageInstallDate(true);
      setErrorMessageInstallDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeInstallDate = (value) => {
    setInstallDate(value)
    checkInstallDate(value)
  }

  const getBySerialNumber = () => {
    if (serialNumber) {
      const data = {
        data: {
          type: "fitting-lookups",
          attributes: {
            "serialNumber": serialNumber
          }
        }
      }
      lookupFittingType(data).then(response => {
        setFittingType(response.data.attributes['fitting-type']);
      })
    }
  }

  return (
    <form id="update-fitting-form" className="form fitting-form">
      <FittingInfoBanner inline={true} />
      <div className="form-content">
        <div className="form-instruction">
          Please add a new serial number, product code and install date for this fitting.
        </div>
        <div className="row row-animated">
          <FormValidatedInput type='text' name='serialNumber' value={serialNumber} didValidate={didValidate} labelText='Serial Number' onChange={e => setSerialNumber(e.target.value)} />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type='text' name='fittingType' value={fittingType} didValidate={didValidate} labelText='Product Code' onChange={e => setFittingType(e.target.value)} onFocus={getBySerialNumber} />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type='date' name='installDate' value={installDate} didValidate={didValidate} labelText='Install Date' maxDate={new Date(moment())} showErrorMessage={showErrorMessageInstallDate} errorMessage={errorMessageInstallDate} onChange={changeInstallDate}/>
        </div>
        {shouldShowTestEventWarning && (
          <div className="test-event-warning">
            <img src={icon_alert_blue} alt="Alert Icon" />
            The install date falls outside of this test event.
          </div>
        )}
      </div>
      <div className="form-actions">
        <button className="button small blue" disabled={isQueryPending} onClick={update}>
          Update
        </button>
      </div>
    </form>
  )
}