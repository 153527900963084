import { BASE_URL, API_VERSION, SITES, GROUPS } from "../settings/apiUrls";
import api from "./api";

export const getGroups = (siteId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + "/" + siteId + GROUPS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const getGroup = (groupId) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + GROUPS + '/' + groupId,
    }).then((response) => {
        return response.data;
    });
}

export const addGroup = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + GROUPS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const deleteGroups = (data) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + GROUPS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const updateGroup = (groupId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + GROUPS + '/' + groupId,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeGroup = (groupId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + GROUPS + '/' + groupId,
    }).then((response) => {
        return response.data;
    });
}