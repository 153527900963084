import { BASE_URL, API_VERSION, SITES, GROUPS, ALL_FITTINGS, FITTINGS, DELETED_FITTINGS, RELATIONSHIPS, FITTINGS_UPLOADS, REINSTATE } from "../settings/apiUrls";
import api from "./api";

export const getAllFittings = (siteId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + "/" + siteId + ALL_FITTINGS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const getFittings = (groupId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + GROUPS + "/" + groupId + FITTINGS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const addFitting = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + FITTINGS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const getDeletedFittings = (siteId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + "/" + siteId + DELETED_FITTINGS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const updateFitting = (fittingId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + FITTINGS + "/" + fittingId,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeFitting = (fittingId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + FITTINGS + "/" + fittingId,
    }).then((response) => {
        return response.data;
    });
}

export const getFittingDetails = (fittingId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + FITTINGS + "/" + fittingId + filters,
    }).then((response) => {
        return response.data;
    });
}

export const moveFittingsToGroup = (groupId, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + GROUPS + '/' + groupId + RELATIONSHIPS + FITTINGS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeFittings = (data) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + FITTINGS,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const fittingsUpload = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + FITTINGS_UPLOADS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const reinstateFitting = (fittingId) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + FITTINGS + '/' + fittingId + '/' + REINSTATE,
        data: {},
    }).then((response) => {
        return response.data;
    });
}