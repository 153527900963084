import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation  } from "react-router-dom";
import _ from 'lodash';
import { cancelInvites } from '../../api/inviteApi';
import icon_arrow_right_grey from '../../assets/images/icon-arrow-right-grey.svg';
import icon_arrow_right_navy from '../../assets/images/icon-arrow-right-navy.svg';
import { store } from '../../utils/store';
import { userTypeOptions } from '../../utils/variables';
import LoadMoreButton from './LoadMoreButton';
import { updateEmployment } from '../../api/employmentApi';

export default ({employments, isQueryPending, infinityLoad, onCancelInvite }) => {

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);

  const { dispatch, state } = useContext(store);

  const showUserDetail = (user) => {
    navigate('/users/' + user.id, {
      state: {
        userId: user.id
      }
    });
  }

  const changeUserType = (e, user) => {
    let newUsers = _.cloneDeep(users);
    newUsers.forEach(newUser => {
      if (newUser.id === user.id) {
        newUser.attributes['role'] = e.target.value;
        let data = {
          data: _.cloneDeep(newUser)
        };
        delete data.data.fullname;
        updateEmployment(data.data.id, data).then(() => {
          dispatch({ type: 'SET POPUP', payload: 'success' });
          dispatch({ type: 'SET POPUP MESSAGES', payload: ['User Type has been updated'] });
          setTimeout(() => {
            dispatch({ type: 'SET POPUP', payload: '' })
          }, 3000)
        })
      }
    })
    setUsers(newUsers);
  }

  const changeInviteType = (e, invite) => {
    let newInvites = _.cloneDeep(invites);
    newInvites.forEach(newInvite => {
      if (newInvite.id === invite.id) {
        newInvite.attributes['role'] = e.target.value;
        let data = {
          data: _.cloneDeep(newInvite)
        };
        delete data.data.email;
        updateEmployment(data.data.id, data).then(() => {
          dispatch({ type: 'SET POPUP', payload: 'success' });
          dispatch({ type: 'SET POPUP MESSAGES', payload: ['User Type has been updated'] });
          setTimeout(() => {
            dispatch({ type: 'SET POPUP', payload: '' })
          }, 3000)
        })
      }
    })
    setInvites(newInvites);
  }

  const cancelInvite = (invite) => {
    cancelInvites(invite.relationships.invite.data.id).then(() => {
      onCancelInvite();
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Invitation Cancelled'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  useEffect(() => {
    let users = [], invites = [];
    employments.forEach(employment => {
      if (employment.user) users.push(employment.user);
      if (employment.invite) invites.push(employment.invite);
    })
    setUsers(users);
    setInvites(invites);
  }, [employments])

  return (
    <div id="employment-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="user column">
              <div className="inner">
                User
              </div>
            </th>
            <th className="userType column">
              <div className="inner">
                User Type
              </div>
            </th>
            <th className="row-action"></th>
          </tr>
        </thead>
    
        <tbody>
          {users.map(user => (
            <tr key={users.indexOf(user)} className='userRow'>
              <td className="column user fullName" onClick={() => showUserDetail(user)} >{user.fullname}</td>
              <td className='column userType'>
                <div className="input">
                  <select name='userType' value={user.attributes['role']} disabled={isQueryPending} onChange={(e) => changeUserType(e, user)}>
                    {userTypeOptions.map(option => (
                      <option key={userTypeOptions.indexOf(option)} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </td>
              <td>
                <div className="row-action" onClick={() => showUserDetail(user)}>
                  <div className="row-indicator">
                    <div className="arrow-right-grey">
                      <img src={icon_arrow_right_grey} alt="Row Icon"/>
                    </div>
                    <div className="arrow-right-grey-blue">
                      <img src={icon_arrow_right_navy} alt="Blue Row Icon"/>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}

          {invites.map(invite => (
            <tr key={invites.indexOf(invite)} >
              <td className="column user">{invite.email}</td>
              <td className='column userType'>
                <div className="input">
                  <select name='userType' value={invite.attributes['role']} disabled={isQueryPending} onChange={(e) => changeInviteType(e, invite)}>
                    {userTypeOptions.map(option => (
                      <option key={userTypeOptions.indexOf(option)} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </td>
              <td>
                <div className="row-action">
                  <button className="button small text-red" disabled={isQueryPending} onClick={() => cancelInvite(invite)}>
                    Cancel Invitation
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-actions">
        <LoadMoreButton infinityLoad={infinityLoad} infinityModel={employments} modelBeingPaginated='Users' numberOfItemsDisplayedText={`(Showing ${employments.length} of ${employments.length})`}/>
      </div>
    </div>
  )
}