import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import icon_alert_blue from '../../../assets/images/icon-alert-blue.svg';
import illus_fittings from '../../../assets/images/illus-fittings.svg';
import delete_icon from '../../../assets/icons/delete.svg';
import icon_blue_add from '../../../assets/images/icon-blue-add.svg';
import ModalView from '../ModalView';
import TestDeleteConfirmation from '../TestDeleteConfirmation';
import TestAndStatusFormRows from './TestAndStatusFormRows';
import { validateRunTime, validateTestDate, validateTestStatus } from '../../../utils/validators';
import { addTest, removeTest, updateTest } from '../../../api/testApi';
import { updateFitting } from '../../../api/fittingApi';
import { store } from '../../../utils/store';

export default ({test, fitting, inputsDisabled, isEditing, isDeleted, isQueryPending, didValidate, onShowTestForm, onCancel, onUpdateFitting, onUpdateTest}) => {

  const { dispatch, state } = useContext(store);

  const [showTestForm, setShowTestForm] = useState(false);
  const [isShowingLatestTest, setIsShowingLatestTest] = useState(false);
  const [shouldShowTestEventWarning, setShouldShowTestEventWarning] = useState(false);
  const [isNotAddingNewTest, setIsNotAddingNewTest] = useState(true);
  const [showAddNewTestButton, setShowAddNewTestButton] = useState(true);
  const [isConfirmAddNewTest, setIsConfirmAddNewTest] = useState(true);
  const [displayDeleteTestModal, setDisplayDeleteTestModal] = useState(false);
  
  const [requiredDuration, setRequiredDuration] = useState('');
  const [nextTestDate, setNextTestDate] = useState('');
  const [batteryStatus, setBatteryStatus] = useState('');
  const [lampStatus, setLampStatus] = useState('');
  const [controlGearStatus, setControlGearStatus] = useState('');

  // Test status
  const [testStatus, setTestStatus] = useState('');
  const [errorMessageTestStatus, setErrorMessageTestStatus] = useState('');
  const [showErrorMessageTestStatus, setShowErrorMessageTestStatus] = useState(false);

  // Test date
  const [testDate, setTestDate] = useState('');
  const [errorMessageTestDate, setErrorMessageTestDate] = useState('');
  const [showErrorMessageTestDate, setShowErrorMessageTestDate] = useState(false);

  // Run time
  const [runTime, setRunTime] = useState('');
  const [errorMessageRunTime, setErrorMessageRunTime] = useState('');
  const [showErrorMessageRunTime, setShowErrorMessageRunTime] = useState(false);

  const showDeleteTestModal = () => {
    setDisplayDeleteTestModal(true);
  }

  const save = () => {
    let testStatusValidated = checkTestStatus(testStatus);
    let testDateValidated = checkTestDate(testDate);
    let runTimeValidated = checkRunTime(runTime);
    if (testStatusValidated && testDateValidated && runTimeValidated) {
      const data = {
        data: {
          attributes: {
            "test-status": testStatus,
            "test-date": moment(testDate).format('YYYY-MM-DD'),
            "run-time": runTime,
            "next-test-date": moment(nextTestDate).format('YYYY-MM-DD'),
            "required-duration": requiredDuration
          },
          relationships: {
            fitting: {
              data: {
                type: "fittings",
                id: fitting.id
              }
            }
          },
          "type": "tests"
        }
      }

      if (isEditing) {
        
        if (test) {
          updateTest(test.id, data).then(response => {
            const fittingData = {
              data: {
                attributes: {
                  "serial-number": fitting.attributes['serial-number'],
                  "battery-status": batteryStatus,
                  "control-gear-status": controlGearStatus,
                  "lamp-status": lampStatus
                },
                relationships: fitting.relationships,
                id: fitting.id,
                type: "fittings"
              }
            }
            updateFitting(fitting.id, fittingData).then(res => {
              let newFitting = res.data;
              onUpdateFitting(newFitting);
              onUpdateTest();
            })
          })
        }
        
      } else {

        addTest(data).then(response => {
        
          const fittingData = {
            data: {
              attributes: {
                "serial-number": fitting.attributes['serial-number'],
                "battery-status": batteryStatus,
                "control-gear-status": controlGearStatus,
                "lamp-status": lampStatus
              },
              relationships: fitting.relationships,
              id: fitting.id,
              type: "fittings"
            }
          }
          updateFitting(fitting.id, fittingData).then(res => {
            let newFitting = res.data;
            onUpdateFitting(newFitting);
          })
        })
      }
      
    }
  }

  const cancel = () => {
    setIsConfirmAddNewTest(false);
    if (!test) {
      setShowTestForm(false);
    }
    onCancel();
  }

  const confirm = () => {
    setShowTestForm(true);
    setTestStatus('');
    setTestDate('');
    setRunTime('');
    setRequiredDuration('');
    setNextTestDate('');
    onShowTestForm();
  }

  const addNewTest = () => {
    setIsConfirmAddNewTest(true);
  }

  const deleteTest = () => {
    removeTest(test.id).then(response => {
      setDisplayDeleteTestModal(false);
      onUpdateTest();
    })
  }

  const closeTestDelete = () => {
    setDisplayDeleteTestModal(false);
  }

  const checkTestStatus = (input) => {
    const validateResult = validateTestStatus(input);
    if (validateResult.validated) {
      setShowErrorMessageTestStatus(false);
      setErrorMessageTestStatus('');
    } else {
      setShowErrorMessageTestStatus(true);
      setErrorMessageTestStatus(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeTestStatus = (e) => {
    setTestStatus(e.target.value)
    checkTestStatus(e.target.value)
  }

  const checkTestDate = (input) => {
    const validateResult = validateTestDate(input);
    if (validateResult.validated) {
      setShowErrorMessageTestDate(false);
      setErrorMessageTestDate('');
    } else {
      setShowErrorMessageTestDate(true);
      setErrorMessageTestDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeTestDate = (value) => {
    setTestDate(value)
    checkTestDate(value)
  }

  const checkRunTime = (input) => {
    const validateResult = validateRunTime(input);
    if (validateResult.validated) {
      setShowErrorMessageRunTime(false);
      setErrorMessageRunTime('');
    } else {
      setShowErrorMessageRunTime(true);
      setErrorMessageRunTime(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeRunTime = (e) => {
    setRunTime(e.target.value)
    checkRunTime(e.target.value)
  }

  useEffect(() => {
    if (test) {
      setShowTestForm(true);
      setTestStatus(test.attributes['test-status']);
      setTestDate(new Date(test.attributes['test-date']));
      setRunTime(test.attributes['run-time']);
      setRequiredDuration(test.attributes['required-duration']);
      setNextTestDate(new Date(test.attributes['next-test-date']));
    } else {
      setShowTestForm(false);
      setTestStatus('');
      setTestDate('');
      setRunTime('');
      setRequiredDuration('');
      setNextTestDate('');
    }
  }, [test])

  useEffect(() => {
    if (fitting) {
      setBatteryStatus(fitting.attributes['battery-status']);
      setLampStatus(fitting.attributes['lamp-status']);
      setControlGearStatus(fitting.attributes['control-gear-status']);
      setShowErrorMessageRunTime(false);
      setShowErrorMessageTestDate(false);
      setShowErrorMessageTestStatus(false);
      setIsConfirmAddNewTest(false);
    } else {
      setBatteryStatus('');
      setLampStatus('');
      setControlGearStatus('');
    }
  }, [fitting])

  return (
    <div>
      <form id="test-and-status-form" className="form">
        {showTestForm ? (
          <div className="form-content">
            {isShowingLatestTest && (
              <div className="latest-test-header">
                Latest Test Result for {fitting.attributes['device-reference']}
              </div>
            )}
            {shouldShowTestEventWarning && (
              <div className="test-event-warning">
                <img src={icon_alert_blue} alt="Alert Icon" />
                The test date falls outside of this test event.
              </div>
            )}
            <TestAndStatusFormRows 
              inputsDisabled={inputsDisabled} 
              didValidate={didValidate} 
              testStatus={testStatus}
              testDate={testDate}
              runTime={runTime}
              requiredDuration={requiredDuration}
              nextTestDate={nextTestDate}
              batteryStatus={batteryStatus}
              lampStatus={lampStatus}
              controlGearStatus={controlGearStatus}
              errorMessageTestStatus={errorMessageTestStatus}
              showErrorMessageTestStatus={showErrorMessageTestStatus}
              errorMessageTestDate={errorMessageTestDate}
              showErrorMessageTestDate={showErrorMessageTestDate}
              errorMessageRunTime={errorMessageRunTime}
              showErrorMessageRunTime={showErrorMessageRunTime}
              onChangeTestStatus={changeTestStatus}
              onChangeTestDate={changeTestDate}
              onChangeRunTime={changeRunTime}
              onChangeRequiredDuration={e => setRequiredDuration(e.target.value)}
              onChangeNextTestDate={value => setNextTestDate(value)}
              onChangeBatteryStatus={e => setBatteryStatus(e.target.value)}
              onChangeLampStatus={e => setLampStatus(e.target.value)}
              onChangeControlGearStatus={e => setControlGearStatus(e.target.value)}
            />
          </div>
        ) : (
          <div className="no-latest-test">
            <div className="empty-image">
              <img src={illus_fittings} alt="Blue Row Icon"/>
            </div>
            <div className="empty-message">
              {true ? 
                <div>
                  There are no tests recorded for<br />
                  this fitting yet.
                </div>
                : "To add a new test, click on the 'Add New Test' button below."}
            </div>
          </div>
        )}

        {!isDeleted && (
          !inputsDisabled ? (
            <div className="fitting-actions">
              {isNotAddingNewTest && (
                <div className="delete-action">
                  <div className="button small text-red" onClick={showDeleteTestModal} >
                    <img className='icon delete-icon' src={delete_icon}  alt="Delete Icon"/>
                    Delete Test
                  </div>
                </div>
              )}
              <div className="button small blue edit-actions" disabled={isQueryPending} onClick={save}>
                Save
              </div>
              <div className="button small text-blue edit-actions" onClick={cancel}>
                Cancel
              </div>
            </div>
          ) : (
            
              isConfirmAddNewTest ? (
                <>
                  <div className="header-label-row">
                    <label className="confirmation-text">Are you sure you want to add a new test?</label>
                  </div>
                  <div className="fitting-actions">
                    <div className="button small blue edit-actions" disabled={{isQueryPending}} onClick={confirm}>
                      Yes, Add New Test
                    </div>
                    <div className="button small text-blue edit-actions" onClick={cancel}>
                      Cancel
                    </div>
                  </div>
                </>
              ) : (
                <div className="add-action">
                  <button className="button small text-blue" onClick={addNewTest}>
                    <img className="icon" src={icon_blue_add} alt="Add Icon"/>
                    Add New Test
                  </button>
                </div>
              )
            
          )
        )}

        
      </form>

      {displayDeleteTestModal && (
        <ModalView>
          <TestDeleteConfirmation isQueryPending={isQueryPending} onDelete={deleteTest} onClose={closeTestDelete} />
        </ModalView>
      )}
    </div>
  )
}


