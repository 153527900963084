import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation  } from "react-router-dom";
import { getGroups } from '../api/groupApi';
import { getSite } from '../api/siteApi';
import { store } from '../utils/store';
import FileUploadErrorModal from './components/FileUploadErrorModal';
import SiteHeader from './components/SiteHeader';
import SiteMenu from './components/SiteMenu';
import Fittings from './groups/Fittings';
import ReportModal from './partials/ReportModal';
import DeletedFittings from './site/DeletedFittings';
import Details from './site/Details';
import Faults from './site/Faults';
import Groups from './site/Groups';
import TestEvents from './site/TestEvents';

const Site = ({children}) => {

  const { dispatch, state } = useContext(store);
  const user = state.user;
  const siteId = useLocation().state.siteId;

  const [site, setSite] = useState(null);
  const [activeTab, setActiveTab] = useState('fittings');
  const [displayReportModal, setDisplayReportModal] = useState(false);
  const [displayErrorModal, setDisplayErrorModal] = useState(false);
  const [noGroups, setNoGroups] = useState(true);
  const [viewSiteTourNames, setViewSiteTourNames] = useState(null);
  const [currentEmployment, setCurrentEmployment] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);

  const uploadCsv = () => {

  }

  const cancelUpload = () => {
    
  }

  const closeErrorModal = () => {
    setDisplayErrorModal(false);
  }

  const closeReportModal = () => {
    setDisplayReportModal(false);
  }

  const saveEditSite = (data) => {
    setSite(data);
  }

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  }

  const toggleReportModal = (e) => {
    e.preventDefault();
    setDisplayReportModal(true);
  }

  const updateGroups = (site) => {
    const groupsFilter = '?page[number]=1&page[size]=10&site=' + site.id;

    getGroups(site.id, groupsFilter).then(response => {
      setNoGroups(response.data.length === 0);
    })
  }

  const fileUploadError = (errors) => {
    setDisplayErrorModal(true);
    setUploadErrors(errors);
  }

  useEffect(() => {
    getSite(siteId).then((response) => {
      setSite(response.data);
      updateGroups(response.data);
    })
  }, [])


  return (
    <div>
      {children}
      <div id="site" className="page site-page">
        {site && (
          <>
            <SiteHeader 
              user={user}
              site={site}
              siteName={site.attributes['business-name']}
              address={site.attributes['address']}
              noGroups={noGroups}
              onReportModal={toggleReportModal}
              onFileUploadError={fileUploadError}
            />
            <div className="body">
              <SiteMenu 
                site={site} 
                currentEmployment={currentEmployment} 
                canView={{deletedFittings: true}}
                isTestEventsEnabled={true}
                activeTab={activeTab}
                onChangeTab={changeActiveTab}
              />
              <div className="content">
                {activeTab === 'fittings' && <Fittings site={site} />}
                {activeTab === 'groups' && <Groups site={site} />}
                {activeTab === 'testEvents' && <TestEvents site={site} />}
                {activeTab === 'faults' && <Faults site={site} />}
                {activeTab === 'deletedFittings' && <DeletedFittings site={site} />}
                {activeTab === 'siteDetails' && <Details site={site} onSaveEdit={saveEditSite} />}
              </div>
            </div>
          </>
        )}
      </div>

      {displayReportModal && (
        <ReportModal site={site} user={user} onClose={closeReportModal}/>
      )}

      {displayErrorModal && (
        <FileUploadErrorModal uploadErrors={uploadErrors} csvTemplatePath="https://s3.ap-southeast-2.amazonaws.com/cleversparky.assets.com/files/Newexceltemplate.xlsx" onClose={closeErrorModal}/>
      )}
    </div>
  )
}

export default Site;