import React, { useState, useEffect, useContext } from 'react';

export default ({searchResults, onClickResult}) => {


    return (
        <div className='autocomplete-results'>
            {searchResults.map(result => {
                const displayResult = result.attributes['address-primary'] + ' ' + result.attributes['address-secondary'];
                let state, country = '';
                if (result.attributes['address-secondary']) {
                    state = result.attributes['address-secondary'].split(',').slice(-2)[0].trim();
                    country = result.attributes['address-secondary'].split(',').slice(-1)[0].trim();
                }

                return (
                    <div key={searchResults.indexOf(result)} className='autocomplete-result-item' onClick={() => onClickResult(displayResult, result.id, state, country)}>
                        {displayResult}
                    </div>
                )
            })}
        </div>
    )
}