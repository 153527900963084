import React, {createContext, useReducer} from 'react';

const initialState = {
    user: null,
    loaded: false,
    site: null,
    inviteObj: null,
    siteRequest: '',
    popupType: '',
    popupMessages: []
};
const store = createContext(initialState);
const { Provider } = store;

const reducer = (state, action) => {

    switch(action.type) {
        case 'SET USER':
            return { ...state, user: action.payload };

        case 'SET INVITE':
            return { ...state, inviteObj: action.payload };

        case 'SET LOAD':
            return { ...state, loaded: action.payload };

        case 'SET SITE':
            return { ...state, site: action.payload };
        
        case 'SET SITE REQUEST':
            return { ...state, siteRequest: action.payload };

        case 'SET POPUP':
            return { ...state, popupType: action.payload };
        
        case 'SET POPUP MESSAGES':
            return { ...state, popupMessages: action.payload };
        
        default:
            throw new Error("store");
    };
}

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }