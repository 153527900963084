import React, { useState, useEffect, useContext } from 'react';
import ModalView from '../components/ModalView';
import TestEventGroupTable from '../components/TestEventGroupTable';
import icon_close from '../../assets/images/icon-close.svg';
import TestEventAddGroupsForm from '../components/forms/TestEventAddGroupsForm';
import TestEventRemoveGroupsForm from '../components/forms/TestEventRemoveGroupsForm';
import TestEventModal from '../components/TestEventModal';
import TestEventForm from '../components/forms/TestEventForm';
import TestEventArchiveConfirmation from '../components/TestEventArchiveConfirmation';
import QuickTestDefaultsModal from '../components/QuickTestDefaultsModal';
import { getTestEventFittings, getTestEventGroups, getTestEventMissingGroups } from '../../api/testEventApi';
import TestEventFittingTable from '../components/TestEventFittingTable';
import FittingsFormNew from '../components/forms/FittingsFormNew';
import { store } from '../../utils/store';

export default ({site, testEvent, testEventGrps, isQueryPending, onBack, onShowTestEventFittingDetails, onUpdateTestEvent}) => {

  const { dispatch } = useContext(store);

  const [loading, setLoading] = useState(false);
  const [displayAddGroupsModal, setDisplayAddGroupsModal] = useState(false);
  const [displayRemoveGroupsModal, setDisplayRemoveGroupsModal] = useState(false);
  const [displayEditDateRangeModal, setDisplayEditDateRangeModal] = useState(false);
  const [displayDuplicateTestEventModal, setDisplayDuplicateTestEventModal] = useState(false);
  const [displayArchiveConfirmationModal, setDisplayArchiveConfirmationModal] = useState(false);
  const [displayQuickTestDefaultsModal, setDisplayQuickTestDefaultsModal] = useState(false);
  const [testEventGroups, setTestEventGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [missingGroups, setMissingGroups] = useState([]);
  const [type, setType] = useState('group');
  const [notCollectedFittings, setNotCollectedFittings] = useState([]);
  const [collectedFittings, setCollectedFittings] = useState([]);
  const [isNotCollected, setIsNotCollected] = useState(true);
  const [isCollected, setIsCollected] = useState(false);
  const [displayFittings, setDisplayFittings] = useState([]);
  const [displayAddFittingModal, setDisplayAddFittingModal] = useState(false);
  const [newFitting, setNewFitting] = useState(null);
  const [groupId, setGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [sorted, setSorted] = useState(false);

  const sortList = () => {
    let newDisplayFittings = JSON.parse(JSON.stringify(displayFittings));
    if (sorted) {
      newDisplayFittings = newDisplayFittings.sort((a, b) => {
        let textA = a.attributes['device-reference'].toLowerCase();
        let textB = b.attributes['device-reference'].toLowerCase();
        let newa = parseInt(a.attributes['device-reference'].match(/\d+/g));
        let newb =  parseInt(b.attributes['device-reference'].match(/\d+/g));
        if (newa && newb) {
          return (newa < newb) ? 1 : (newa > newb) ? -1 : 0;
        } else if (newa && !newb) {
          return -1;
        } else if (!newa && newb) {
          return 1;
        } else {
          return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
        }
      })
    } else {
      newDisplayFittings = newDisplayFittings.sort((a, b) => {
        let textA = a.attributes['device-reference'].toLowerCase();
        let textB = b.attributes['device-reference'].toLowerCase();
        let newa = parseInt(a.attributes['device-reference'].match(/\d+/g));
        let newb =  parseInt(b.attributes['device-reference'].match(/\d+/g));
        if (newa && newb) {
          return (newa < newb) ? -1 : (newa > newb) ? 1 : 0;
        } else if (newa && !newb) {
          return 1;
        } else if (!newa && newb) {
          return -1;
        } else {
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }
      })
    }
    setDisplayFittings(newDisplayFittings);
    setSorted(!sorted);
  }

  const infinityLoad = () => {

  }

  const showAddGroupsModal = () => {
    setDisplayAddGroupsModal(true);
  }

  const showRemoveGroupsModal = () => {
    setDisplayRemoveGroupsModal(true);
  }

  const showAddFittingModal = () => {
    setDisplayAddFittingModal(true);
  }

  const closeAddFitting = () => {
    setDisplayAddFittingModal(false);
  }

  const showTestEventGroupDetails = (group) => {
    setType('fitting');
    updateGroupFittings(group.id);
    setGroupName(group.attributes['name']);
    setGroupId(group.id);
  }

  const showEditDateRangeModal = () => {
    setDisplayEditDateRangeModal(true);
  }

  const showDuplicateTestEventModal = () => {
    setDisplayDuplicateTestEventModal(true);
  }

  const showArchiveConfirmationModal = () => {
    setDisplayArchiveConfirmationModal(true);
  }

  const showQuickTestDefaultsModal = () => {
    setDisplayQuickTestDefaultsModal(true);
  }

  const closeAddGroupsModal = () => {
    setDisplayAddGroupsModal(false);
  }

  const closeEditDateRangeModal = () => {
    setDisplayEditDateRangeModal(false);
  }

  const closeDuplicateTestEventModal = () => {
    setDisplayDuplicateTestEventModal(false);
  }

  const closeRemoveGroupsModal = () => {
    setDisplayRemoveGroupsModal(false);
  }

  const closeArchiveConfirmationModal = () => {
    setDisplayArchiveConfirmationModal(false);
  }

  const saveQuickTestDefaults = () => {

  }

  const closeQuickTestDefaultsModal = () => {
    setDisplayQuickTestDefaultsModal(false);
  }

  const addGroups = () => {
    setDisplayAddGroupsModal(false);
    updateGroups();
  }

  const deleteGroups = () => {
    setDisplayRemoveGroupsModal(false);
    updateGroups();
  }

  const goBack = () => {
    if (groupName) {
      updateGroups();
      setType('group');
      setGroupName('');
      setCollectedFittings([]);
      setNotCollectedFittings([]);
      onShowTestEventFittingDetails(null);
    } else {
      onBack();
    }
  }

  const updateGroups = () => {
    const filters = '?include=group&page[number]=1&page[size]=10&testEvent=' + testEvent.id;
    getTestEventGroups(testEvent.id, filters).then(response => {
      let testEventGroups = response.data;
      testEventGroups.forEach(testEventGroup => {
        if (response.included) {
          response.included.forEach(group => {
            if (group.id === testEventGroup.relationships.group.data.id) {
              testEventGroup.attributes['name'] = group.attributes['name'];
            }
          })
        }
      })
      setTestEventGroups(testEventGroups);
      if (response.included) {
        setGroups(response.included);
      }
    })

    const missingFilters = '?testEvent=' + testEvent.id;
    getTestEventMissingGroups(testEvent.id, missingFilters).then(response => {
      setMissingGroups(response.data);
    })
  }

  const updateFittings = (groupId) => {
    if (type === 'fitting') {
      updateAllFittings(groupId);
    } else if (type === 'group') {
      updateGroupFittings(groupId);
    }
  }

  const updateAllFittings = async (groupId) => {
    if (!groupId) groupId = 'undefined';
    const notCollectedFilters = '?include=fitting&filter[collected]=false&grouporallfittings=allfittings&page[number]=1&page[size]=10&siteid=' + site.id + '&testEventid=' + testEvent.id;
    const collectedFilters = '?include=fitting&filter[collected]=true&grouporallfittings=allfittings&page[number]=1&page[size]=10&siteid=' + site.id + '&testEventid=' + testEvent.id;
    setLoading(true);
    const responseNotCollected = await getTestEventFittings(groupId, notCollectedFilters);
    const responseCollected = await getTestEventFittings(groupId, collectedFilters);
    setLoading(false);
    if (responseNotCollected.included) {
      setNotCollectedFittings(responseNotCollected.included);
    } else {
      setNotCollectedFittings([]);
    }
    if (responseCollected.included) {
      setCollectedFittings(responseCollected.included);
    }else {
      setCollectedFittings([]);
    }
  }

  const updateGroupFittings = async (groupId) => {
    if (!groupId) groupId = 'undefined';
    const notCollectedFilters = "?include=fitting&filter[collected]=false&grouporallfittings=group&page[number]=1&page[size]=10&siteid=" + site.id + "&testEventGroup=" + groupId + "&testEventid=" + testEvent.id;
    const collectedFilters = "?include=fitting&filter[collected]=true&grouporallfittings=group&page[number]=1&page[size]=10&siteid=" + site.id + "&testEventGroup=" + groupId + "&testEventid=" + testEvent.id;
    setLoading(true);
    const responseNotCollected = await getTestEventFittings(groupId, notCollectedFilters);
    const responseCollected = await getTestEventFittings(groupId, collectedFilters);
    setLoading(false);
    if (responseNotCollected.included) {
      setNotCollectedFittings(responseNotCollected.included);
    } else {
      setNotCollectedFittings([]);
    }
    if (responseCollected.included) {
      setCollectedFittings(responseCollected.included);
    }else {
      setCollectedFittings([]);
    }
  }

  const collectedFilter = (collected) => {
    setIsNotCollected(!collected);
    setIsCollected(collected);
  }

  const confirmAddFitting = () => {
    setDisplayAddFittingModal(false);
    updateFittings(groupId);
    dispatch({ type: 'SET POPUP', payload: 'success' });
    dispatch({ type: 'SET POPUP MESSAGES', payload: ['Fitting Added'] });
    setTimeout(() => {
      dispatch({ type: 'SET POPUP', payload: '' })
    }, 3000)
  }

  const updateTestEvent = (testEvent) => {
    onUpdateTestEvent(testEvent);
    setDisplayEditDateRangeModal(false);
    setDisplayDuplicateTestEventModal(false);
    setDisplayArchiveConfirmationModal(false);
    setDisplayQuickTestDefaultsModal(false);
  }

  useEffect(() => {
    if (testEvent) {
      if (testEvent.attributes['viewb'] === 'groups') {
        setType('group');
        updateGroups();
      }
      if (testEvent.attributes['viewb'] === 'allfittings') {
        setType('fitting');
        updateAllFittings();
      }
    }
  }, [testEvent])

  useEffect(() => {
    if (isNotCollected) setDisplayFittings(notCollectedFittings);
  }, [notCollectedFittings])

  useEffect(() => {
    if (isCollected) setDisplayFittings(collectedFittings);
  }, [collectedFittings])

  useEffect(() => {
    if (isNotCollected) setDisplayFittings(notCollectedFittings);
  }, [isNotCollected])

  useEffect(() => {
    if (isCollected) setDisplayFittings(collectedFittings);
  }, [isCollected])
  
  useEffect(() => {
    if (testEventGrps) setTestEventGroups(testEventGrps);
  }, [testEventGrps])

  return (
    <div className='fittings-list'>
      {type === 'group' && (
        <TestEventGroupTable 
          loading={loading}
          site={site}
          testEvent={testEvent}
          testEventGroups={testEventGroups}
          missingGroups={missingGroups}
          infinityLoad={infinityLoad}
          onBack={onBack}
          onShowAddGroupsModal={showAddGroupsModal}
          showRemoveGroupsModal={showRemoveGroupsModal}
          onShowTestEventGroupDetails={showTestEventGroupDetails}
          onEditDateRangeModal={showEditDateRangeModal}
          onDuplicateTestEventModal={showDuplicateTestEventModal}
          onQuickTestDefaultsModal={showQuickTestDefaultsModal}
          onArchiveConfirmationModal={showArchiveConfirmationModal}
          onRemoveGroupsModal={showRemoveGroupsModal}
        />
      )}
      {type === 'fitting' && (
        <TestEventFittingTable
          loading={loading}
          displayFittings={displayFittings}
          isNotCollected={isNotCollected}
          isCollected={isCollected}
          testEventGroups={testEventGroups}
          groupId={groupId}
          groupName={groupName}
          testEvent={testEvent}
          notCollectedFittings={notCollectedFittings}
          collectedFittings={collectedFittings}
          onCollectedFilter={collectedFilter}
          onBack={goBack}
          onSort={sortList}
          onEditDateRangeModal={showEditDateRangeModal}
          onDuplicateTestEventModal={showDuplicateTestEventModal}
          onShowAddFittingModal={showAddFittingModal}
          onShowTestEventFittingDetails={onShowTestEventFittingDetails}
          onArchiveConfirmationModal={showArchiveConfirmationModal}
          onQuickTestDefaultsModal={showQuickTestDefaultsModal}
          onUpdateFittings={updateFittings}
        />
      )}
      
      {displayAddGroupsModal && (
        <ModalView>
          <div>
            <div className="modal-header">
              <h2 className="form-heading">Add Groups</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeAddGroupsModal} />
            </div>

            <div className="modal-content">
              <TestEventAddGroupsForm 
                testEvent={testEvent}
                missingGroups={missingGroups}
                isQueryPending={isQueryPending} 
                onAddGroups={addGroups}
              />
            </div>
          </div>
        </ModalView>
      )}

      {displayRemoveGroupsModal && (
        <ModalView>
          <div>
            <div className="modal-header">
              <h2 className="form-heading">Remove Groups</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeRemoveGroupsModal} />
            </div>

            <div className="modal-content">
              <TestEventRemoveGroupsForm
                testEvent={testEvent}
                groups={groups}
                isQueryPending={isQueryPending} 
                onClose={closeRemoveGroupsModal}
                onDeleteGroups={deleteGroups}
              />
            </div>
          </div>
        </ModalView>
      )}

      {displayEditDateRangeModal && (
        <TestEventModal type='edit' onClose={closeEditDateRangeModal}>
          <TestEventForm 
            testEvent={testEvent} 
            type='edit' 
            isQueryPending={isQueryPending}
            onUpdateTestEvent={updateTestEvent}
          />
        </TestEventModal>
      )}

      {displayDuplicateTestEventModal && (
        <TestEventModal type='duplicate' onClose={closeDuplicateTestEventModal}>
          <TestEventForm 
            testEvent={testEvent} 
            type='duplicate' 
            isQueryPending={isQueryPending}
            onUpdateTestEvent={updateTestEvent}
          />
        </TestEventModal>
      )}

      {displayArchiveConfirmationModal && (
        <ModalView>
          <TestEventArchiveConfirmation 
            testEvent={testEvent}
            isQueryPending={isQueryPending}
            onClose={closeArchiveConfirmationModal}
            onUpdateTestEvent={updateTestEvent}
          />
        </ModalView>
      )}

      {displayQuickTestDefaultsModal && (
        <QuickTestDefaultsModal 
          testEvent={testEvent} 
          isQueryPending={isQueryPending}
          onSave={saveQuickTestDefaults}
          onClose={closeQuickTestDefaultsModal} 
          onUpdateTestEvent={updateTestEvent}
        />
      )}

      {displayAddFittingModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Add a Fitting</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeAddFitting}/>
            </div>
        
            <div className="modal-content">
              <FittingsFormNew 
                fitting={newFitting} 
                site={site}
                isQueryPending={isQueryPending} 
                addFitting="addFitting" 
                onAddFitting={confirmAddFitting}
              />
            </div>
          </>
        </ModalView>
      )}
    </div>
  )
}
