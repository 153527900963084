import React, { useState, useEffect } from 'react';
import group_dropdown_menu_trigger from '../../assets/icons/group-dropdown-menu-trigger.svg';

export default ({ 
  onEditDateRangeModal, 
  onDuplicateTestEventModal, 
  onArchiveConfirmationModal, 
  onQuickTestDefaultsModal
}) => {

  const [isOpen, setIsOpen] = useState(false);

  const trigger = () => {
    setIsOpen(true);
  }

  const hideMenu = () => {
    setIsOpen(false);
  }

  const showEditDateRangeModal = () => {
    onEditDateRangeModal();
    setIsOpen(false);
  }
  
  const duplicateTestEventModal = () => {
    onDuplicateTestEventModal();
    setIsOpen(false);
  }

  const archiveConfirmationModal = () => {
    onArchiveConfirmationModal();
    setIsOpen(false);
  }

  const showQuickTestDefaultsModal = () => {
    onQuickTestDefaultsModal();
    setIsOpen(false);
  }
  
  return (
    <div className='dropdown-menu test-event-group-dropdown-menu' tabIndex="9999" onBlur={hideMenu}>
      <div className='dropdown-menu-trigger' onClick={trigger}>
          <img src={group_dropdown_menu_trigger} className='drop-trig' alt="Group icon blue"/>
      </div>

      {isOpen && (
        <div className="dropdown-menu-list">

          <div className="dropdown-menu-list-item label edit-date-range" onClick={showEditDateRangeModal} >
            Edit Date Range
          </div>

          <div className="dropdown-menu-list-item label duplicate-test-event" onClick={duplicateTestEventModal}>
            Duplicate Logbook
          </div>

          <div className="dropdown-menu-list-item label archive-test-event" onClick={archiveConfirmationModal}>
            Archive Logbook
          </div>

          <div className="dropdown-menu-list-item label set-defaults" onClick={showQuickTestDefaultsModal} >
            Edit Logbook Template
          </div>

        </div>
      )}
    </div>
  )
}
