import React, { useState, useEffect, useContext } from 'react';

function Terms() {

  return (
    <>
      <p className="p1"><span><strong>CLEVERTRONICS Licence agreement </strong></span></p>
      <p className="p2"><span>Clevertronics Pty. Ltd. (ACN 136 264 349) of 195 Chesterville Road, Moorabbin, Victoria 3189 (<strong>Clevertronics</strong>) provides the CleverSparky Application, which is an online application that allows users to upload, collect and view information in relation to tests which are conducted on exit signs and emergency lighting products including Clevertronics Products. </span></p>
      <p className="p2"><span>This document sets out the terms and conditions on which Clevertronics will grant a licence to use the CleverSparky Application and provide other ancillary services to a User who downloads or is otherwise provided with a copy of the CleverSparky Application.</span></p>
      <p className="p2"><span>By downloading, installing, accessing or using the CleverSparky Application, the User agrees that it has read, understood and will be bound by this licence agreement.<span className="Apple-converted-space">&nbsp; </span>If the User does not accept these terms and conditions, the User must not install or use the CleverSparky Application.</span></p>
      
      <div id="terms-page" className="terms-formatting">
        <table className='term'>
          <thead>
          <tr>
            <th className="heading-order">1.</th>
            <th><strong>Definitions and interpretation</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">1.1</td>
              <td className=""><strong>Definitions</strong></td>
            </tr>
            <tr>
              <td></td>
              <td>In this licence agreement, unless the context otherwise requires:
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span><strong>Android Device</strong> has the meaning given to the term &lsquo;Device&rsquo; in the Google Play Developer Distribution Agreement.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span><strong>Apple</strong> means Apple Inc., a California corporation with its principal place of business at 1 Infinite Loop, Cupertino, California 95014, U.S.A. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span><strong>Apple Device</strong> means the iPhone, iPad or other authorised iOS devices.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(d)</td>
                      <td><span><strong>Australian Consumer Law</strong> means Schedule 2 of the <em>Competition and Consumer Act 2010</em> (Cth) (or any equivalent State or Territory legislation).</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(e)</td>
                      <td><span><strong>Business User</strong> has the meaning given in the definition of &lsquo;User&rsquo; in clause 1.1(aa); </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(f)</td>
                      <td><span><strong>CleverSparky Application</strong> means the online application that allows users to upload, collect and view information in relation to tests which are conducted on exit signs and emergency lighting products including Clevertronics Products and which is downloaded by or otherwise provided to the User and licenced to the User pursuant to this licence agreement. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(g)</td>
                      <td><span><strong>Clevertronics </strong>means Clevertronics Pty. Ltd. (ACN 136 264 349).</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(h)</td>
                      <td><span><strong>Clevertronics Generated Information</strong> means the information and data generated by the CleverSparky Application (including as a result of data manually entered by the User) including information about faults, required maintenance and fitting status of exit signs and emergency lighting products including Clevertronics Products, and which is made available to the User through the CleverSparky Application.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(i)</td>
                      <td><span><strong>Clevertronics Infrastructure</strong> means the computer servers or other hardware used by Clevertronics in connection with its provision of the CleverSparky Application.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(j)</td>
                      <td><span><strong>Clevertronics Products</strong> means Clevertronics&rsquo; &rsquo;Clevertest&rsquo; range of single point unit emergency exit lighting solutions and any other products or services sold or promoted by Clevertronics and notified by Clevertronics to the User from time to time.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(k)</td>
                      <td><span><strong>Commencement Date</strong> means the earliest of the date the User clicks the &lsquo;I accept&rsquo; button, installs the CleverSparky Application, first uses the CleverSparky Application or otherwise confirms the User&rsquo;s acceptance of this licence agreement electronically. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(l)</td>
                      <td><span><strong>Consequential Loss</strong> means Loss beyond the normal measure of damages and includes indirect Loss, Loss of revenue, Loss of reputation, Loss of data, Loss of profits, Loss of actual or anticipated savings, Loss of bargain, lost opportunities, including opportunities to enter into arrangements with third parties, Loss of use, cost of capital or costs of substitute goods, facilities or services.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(m)</td>
                      <td><span><strong>Device</strong> means an Android Device or an Apple Device, as the context requires.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(n)</td>
                      <td><span><strong>Documentation</strong> means any manuals, guides, reference materials or other similar documents in any form made available by Clevertronics to the User in connection with the CleverSparky Application.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(o)</td>
                      <td><span><strong>Force Majeure Event</strong> means the occurrence of an event or circumstances beyond the reasonable control of the party affected by it, including war (declared or undeclared) civil commotion, military action, or an act of sabotage, strike, lockout or industrial action, storm, tempest, fire, flood, earthquake or other natural calamity or an ongoing internet or telecommunications outage or impairment. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(p)</td>
                      <td><span><strong>Google</strong> means Google Inc., a Delaware corporation with its principal place of business at 1600 Amphitheatre Parkway, Mountain View, California 94043, U.S.A. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(q)</td>
                      <td><span><strong>Google Play</strong> means the digital distribution platform operated by Google for applications for the Android operating system.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(r)</td>
                      <td><span><strong>Improvement </strong>means any modification, alteration, development, new use or other change to the CleverSparky Application which makes it more accurate, more useful, more functional, more efficient, more cost effective or in any other way improves the performance of the CleverSparky Application.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(s)</td>
                      <td><span><strong>Indirect Tax</strong> means a good and services tax, a value added tax, a consumption tax or a tax of a similar kind.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(t)</td>
                      <td><span><strong>Intellectual Property</strong> includes all patents, designs, copyright, trade marks or circuit layout rights and any right to apply for the registration or grant of any of the above. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(u)</td>
                      <td><span><strong>Loss</strong> means any loss, liability, cost, claim, expense, damage, charge, penalty, outgoing or payment however arising, whether present, unascertained, immediate, future or contingent and whether direct loss or Consequential Loss.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(v)</td>
                      <td><span><strong>Permitted Purpose </strong>means personally accessing and using the CleverSparky Application to upload (either manually or, where available, through a wireless connection with a Clevertronics Product) User Content to help enable the CleverSparky Application and the User to assess certain characteristics of exit signs and emergency lighting products including Clevertronics Products and to log and access and view Clevertronics Generated Information in accordance with this licence agreement and in accordance with and as permitted by:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>in respect of any use of the CleverSparky Application on an Apple Device, the Usage Rules set out in Apple&rsquo;s App Store Terms of Service; or</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>in respect of any use of the CleverSparky Application on an Android Device, the Usage Rules set out in the Google Play Terms of Service.<span className="Apple-converted-space">&nbsp; </span> </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(w)</td>
                      <td><span><strong>Term</strong> has the meaning given in clause 12.1 of this licence agreement.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(x)</td>
                      <td><span><strong>Third Party Application</strong> means any product, service, system, application or internet site that is integrated or interfaced with the CleverSparky Application or otherwise interacts with the CleverSparky Application and that is owned or operated by a third party.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(y)</td>
                      <td><span><strong>Unacceptable Acts </strong>means any acts which Clevertronics reasonably considers to be unacceptable, including, without limitation, any acts which:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>may impose a burden or make excessive traffic demands on the Clevertronics Infrastructure;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>may violate the rights of any person, including copyright, trade secret or privacy right; or</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iii)</td>
                              <td><span>use any automated device, software, process or means to access, retrieve, scrape or index the CleverSparky Application or any content on the CleverSparky Application.</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(z)</td>
                      <td><span><strong>Unacceptable Content</strong> means any: </span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>content which, in Clevertronics&rsquo; reasonably held opinion, is obscene, offensive, upsetting, defamatory, illegal or inappropriate, infringes or appears to infringe the intellectual property rights of any person or contravenes or appears to contravene any applicable laws, regulations or codes of conduct;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>content which is incorrect, false or misleading;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iii)</td>
                              <td><span>files with any viruses, malicious code or other conditions which could damage or interfere with data, hardware or software; or</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iv)</td>
                              <td><span>any content that, in Clevertronics&rsquo; reasonably held opinion, is objectionable or which may expose Clevertronics, its affiliates or users to any harm or liability. </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(aa)</td>
                      <td><span><strong>User</strong> means the person who downloads, installs, accesses or uses the CleverSparky Application and who enters into this licence agreement. If the User creates a CleverSparky business account for and on behalf of another legal entity (<strong>Business User</strong>), references in this licence agreement to User will be taken to include the Business User.&nbsp;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(bb)</td>
                      <td><span><strong>User Content</strong> means all data, comments, text, or other material uploaded either manually or, where available, through a wireless connection with exit signs and emergency lighting products including the Clevertronics Products by or on behalf of the User via the CleverSparky Application.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(cc)</td>
                      <td><span><strong>User Data </strong>means all information, communications and other data uploaded by the User or which is generated or comes into existence as a result of the User using the CleverSparky Application, including site data, test data, location data in respect of exit signs and emergency lighting products including Clevertronics Products, Clevertronics Generated Information and information and metadata but excludes the User Content.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">1.2</td>
              <td><strong>Interpretation</strong></td>
            </tr>
            <tr>
              <td></td>
              <td><span>In this licence agreement, headings are inserted for convenience only and do not affect the interpretation of this licence agreement and, unless the context otherwise requires:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>the singular includes the plural and vice versa;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>if a word or phrase is defined, its other grammatical forms have a corresponding meaning;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>the meaning of general words is not limited by specific examples introduced by &lsquo;includes&rsquo;, &lsquo;including&rsquo;, &lsquo;for example&rsquo;, &lsquo;such as&rsquo; or similar expressions; </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(d)</td>
                      <td><span>a reference to a person includes an individual, a partnership, a corporation or other corporate body, a joint venture, a firm, a trustee, a trust, an association (whether incorporated or not), a government and a government authority or agency; and</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(e)</td>
                      <td><span>no provision of this licence agreement will be construed to the disadvantage of a party merely because that party was responsible for the preparation of the licence agreement or the inclusion of the provision in the licence agreement.&nbsp;</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">2.</th>
            <th><strong>Licence</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">2.1</td>
              <td className="li2"><span><strong>Grant of licence </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Subject to the restrictions set out in clause 2.2, Clevertronics grants the User a personal, non-exclusive, worldwide, non-transferable, not-for resale or sub license, restricted right to access and use the CleverSparky Application and any Clevertronics Generated Information, User Content and User Data for the Permitted Purpose during the Term on any Devices owned or controlled by the User. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">2.2</td>
              <td className="li2"><span><strong>Licence restrictions</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The licence granted to the User under clause 2.1 permits the User to access and use the CleverSparky Application solely for the Permitted Purpose and the User must not access or use the CleverSparky Application for any other purpose. Without limiting the above, the User must not:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>permit or allow any other person to use the CleverSparky Application through the User&rsquo;s Device;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>make any copies of the CleverSparky Application (other than copies that are made to download the CleverSparky Application and install the CleverSparky Application for the Permitted Purpose on any Devices owned or controlled by the User as permitted by this licence agreement and by the provisions of Apple&rsquo;s App Store Terms of Service or the Google Play Terms of Service (as applicable));<span className="Apple-converted-space">&nbsp; &nbsp;</span></span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>modify, adapt, translate, reverse engineer, de-compile, disassemble or copy all or any part of the CleverSparky Application or otherwise interfere or attempt to interfere with the proper working of the CleverSparky Application;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(d)</td>
                      <td><span>attempt to circumvent or break any licence keys or any other encryption, decryption or other security device or technological protection measure contained in the CleverSparky Application;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(e)</td>
                      <td><span>distribute any part of the CleverSparky Application for commercial purposes or otherwise sub-licence or resell the CleverSparky Application;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(f)</td>
                      <td><span>create derivative works from all or any part of the CleverSparky Application;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(g)</td>
                      <td><span>transfer, assign, rent, lease, lend, sell or otherwise dispose of all or any part of the CleverSparky Application; and</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(h)</td>
                      <td><span>publish, communicate or otherwise make any part of the CleverSparky Application publically available.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">2.3</td>
              <td className="li2"><span><strong>Protection measures</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User acknowledges that the CleverSparky Application may contain licence keys or other technological protection measures and Clevertronics may use the licence keys or other protection measures to disable or suspend the User&rsquo;s access to the CleverSparky Application:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>following the termination of this licence agreement; or</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>if the User breaches (or Clevertronics reasonably suspects the User has breached) any provision of this licence agreement. </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">2.4</td>
              <td className="li2"><span><strong>Monitoring Use and Access</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>For security, statistical and other related purposes Clevertronics may monitor the User's access to and use of the CleverSparky Application and record or otherwise capture data or other information relating to the User's access to and use of the CleverSparky Application.</span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">3.</th>
            <th><strong>Clevertronics Generated Information</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">3.1</td>
              <td className="li2"><span><strong>User Acknowledgements </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User acknowledges and agrees that:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>Clevertronics provides information that may assist the User in making decisions regarding whether and when to carry out maintenance on, or replace, Clevertronics Products or particular components of certain Clevertronics Products;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>the Clevertronics Generated Information is indicative only and does not constitute a recommendation by Clevertronics or any other person regarding whether or not a User should carry out any maintenance on, or replace, any Clevertronics Products;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>Clevertronics does not guarantee the accuracy of any Clevertronics Generation Information;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(d)</td>
                      <td><span>Clevertronics does not provide any warranties that use of the CleverSparky Application will have any particular outcome, benefit or result for the User;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(e)</td>
                      <td><span>Clevertronics retains complete discretion regarding the type of Clevertronics Generated Information which will be made available through the CleverSparky Application and the format in which such Clevertronics Generated Information will be made available; </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(f)</td>
                      <td><span>the Clevertronics Generated Information can only be used by the User for the purpose of the User obtaining information about the test results of exit signs and emergency lighting products including Clevertronics Products and other characteristics of the relevant Clevertronics Products;<span className="Apple-converted-space">&nbsp; &nbsp;</span></span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(g)</td>
                      <td><span>the only representations and warranties that Clevertronics makes in relation to the Clevertronics Products are those set out in the Clevertronics warranty statement which can be accessed via the Clevertronics website, www.clevertronics.com.au;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(h)</td>
                      <td><span>without limiting clause 3.1(e) the User cannot use or attempt to use the Clevertronics Generated Information as the basis (whether wholly or partially) for:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>the rejection of any Clevertronics Products; or</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>any claim by the User that any Clevertronics Products do not comply with any standards, specifications or other requirements for Clevertronics Products; and</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(i)</td>
                      <td><span>to the maximum extent permitted by law, Clevertronics makes no representations either expressly or impliedly concerning Clevertronics Products or any Clevertronics Generated Information and Clevertronics accepts no liability whatsoever in connection with any Clevertronics Products or any Clevertronics Generated Information.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">3.2</td>
              <td className="li2"><span><strong>Confidentiality</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>In return for Clevertronics allowing the User to access the CleverSparky Application the User agrees that it will:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>not make any use of Clevertronics Generated Information other than as expressly permitted under this licence agreement; </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>take all precautions necessary to prevent unauthorised persons from obtaining access to Clevertronics Generated Information by any direct or indirect means; and</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>immediately notify Clevertronics of any potential, suspected or actual unauthorised access, disclosure, copying or use of Clevertronics Generated Information.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">4.</th>
            <th><strong>Updates and support</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">4.1</td>
              <td className="li2"><span><strong>No obligation to develop updates </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User acknowledges that nothing in this licence agreement imposes obligations on Clevertronics to develop, release or install for the User any updates, upgrades, patches, bug fixes, new releases or new versions in respect of the CleverSparky Application or any Improvements (<strong>Updates</strong>), provided however that if Clevertronics does develop or release any Updates, it may require that all such Updates be downloaded and used by the User. The User also acknowledges that nothing in this licence agreement imposes obligations on Clevertronics to provide any services to the User in respect of the CleverSparky Application including (without limitation) development or customisation services, technical support, training or maintenance services.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">4.2</td>
              <td className="li2"><span><strong>No obligation to provide new features</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User acknowledges and agrees that Clevertronics retains the sole discretion regarding the features of the CleverSparky Application and nothing in this licence agreement requires Clevertronics to provide functions and other benefits of the CleverSparky Application or maintain any features, functions or other benefits in respect of the CleverSparky Application. The User also acknowledges and agrees that Clevertronics may discontinue or alter any such features, functions or other benefits as Clevertronics sees fit from time to time. </span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">5.</th>
            <th><strong>Documentation</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>The User must use only the most recent version of the Documentation provided or made available by Clevertronics, and must follow any directions in the Documentation regarding the use of the CleverSparky Application. The User must also ensure that its access to and use of the CleverSparky Application complies with all current Clevertronics Policies. </span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">6.</th>
            <th><strong>Third Party Applications</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>The User acknowledges and agrees that the CleverSparky Application may interact with Third Party Applications or require Third Party Applications to be used to provide particular features or functionality. <span className="Apple-converted-space">&nbsp; </span>Where such Third Party Applications are used, the User acknowledges and agrees that:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>Clevertronics makes no representations or warranties relating to the Third Party Applications;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>Clevertronics is not related or associated with the providers of any Third Party Applications;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iii)</td>
                              <td><span>the inclusion of any link to or integration with any Third Party Application does not constitute or imply any affiliation with, or sponsorship, endorsement or approval by Clevertronics of the Third Party Application or the provider of the Third Party Application;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iv)</td>
                              <td><span>the User will abide by any obligations imposed upon it by the provider of the Third Party Application; and</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iv)</td>
                              <td><span>access to, and use of, Third Party Applications is at the User&rsquo;s risk and Clevertronics will in no way be responsible for any Loss that may result from the User&rsquo;s access to, and use of, any Third Party Application, notwithstanding that any such Third Party Application may interface or interact with the CleverSparky Application.</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>If the User does not agree to use any Third Party Applications or the terms of service of such Third Party Applications, the User acknowledges and accepts that certain features of the CleverSparky Application may not be available to the User.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">7.</th>
            <th><strong>Improper use of the CleverSparky Application</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">7.1</td>
              <td className="li2"><span><strong>Improper use</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User must not upload or post any Unacceptable Content to the CleverSparky Application or Clevertronics Infrastructure or perform any Unacceptable Acts.&nbsp;</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">7.2</td>
              <td className="li2"><span><strong>No obligation to monitor</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Nothing in this licence agreement imposes obligations on Clevertronics to monitor the CleverSparky Application for Unacceptable Content or Unacceptable Acts and the User acknowledges and agrees that it is responsible for monitoring its own use of the CleverSparky Application.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">7.3</td>
              <td className="li2"><span><strong>Clevertronics may remove User Content</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Clevertronics may, in its absolute discretion, remove any User Content where Clevertronics reasonably considers the User Content to contain Unacceptable Content.</span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">8.</th>
            <th><strong>Intellectual Property</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">8.1</td>
              <td className="li2"><span><strong>Clevertronics&rsquo;s Intellectual Property</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User acknowledges that, subject to clause 2.1, nothing in this licence agreement grants the User any ownership of or rights in respect of the Intellectual Property in the CleverSparky Application or any Documentation or any Improvements to the CleverSparky Application or any Documentation. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">8.2</td>
              <td className="li2"><span><strong>User Content</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Clevertronics acknowledges that the User will retain ownership of its Intellectual Property in respect of the User Content.</span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">9.</th>
            <th><strong>Security of CleverSparky Application</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>Clevertronics does not guarantee the security and safety of the User Data, User Content or any other information regarding the User and Clevertronics will not be liable for any Loss suffered by the User which arises out of or in connection with:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>any computer viruses being transferred by or obtained as a result of the use of the CleverSparky Application;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>any hacking into or other similar attacks on the CleverSparky Application or the Clevertronics Infrastructure; or </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>any other data security issues in respect of the CleverSparky Application or the Clevertronics Infrastructure. </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">10.</th>
            <th><strong>Back ups</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>The User acknowledges that Clevertronics does not provide back up or other similar services in respect of the Clevertronics Generated Information, User Content and User Data and the User is responsible for implementing its own back up and data retrieval procedures in respect of the Clevertronics Generated Information, User Content and User Data. </span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">11.</th>
            <th><strong>Subcontractors</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>The User agrees and acknowledges that Clevertronics may subcontract the performance of any of its obligations or the exercise of any of its rights under this licence agreement to any sub-contractor without the User&rsquo;s consent and on such terms and conditions as Clevertronics deems fit from time to time. </span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">12.</th>
            <th><strong>Term and termination</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">12.1</td>
              <td><strong>Term</strong></td>
            </tr>
            <tr>
              <td></td>
              <td><span>This licence agreement commences on the Commencement Date and will continue until terminated pursuant to this clause 12. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">12.2</td>
              <td><strong>Termination</strong>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>Clevertronics may terminate this licence agreement and the licence granted under clause 2.1 at any time effective immediately:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>by removing the CleverSparky Application from the Apple App Store or Google Play (as applicable);</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>by notification to the User;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iii)</td>
                              <td><span>if the User requests a refund under clause 14.3;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iv)</td>
                              <td><span>if the User uses the CleverSparky Application in any way which Clevertronics considers may damage the reputation, brand or goodwill of Clevertronics or the CleverSparky Application; or</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(v)</td>
                              <td><span>if the CleverSparky Application is removed from the App Store by Apple or Google Play by Google (as applicable).</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>Either party may terminate this licence agreement and the licence granted under clause 2.1 effective immediately:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>if the other party breaches a term or condition of this licence agreement and fails to remedy such breach within seven days after being provided with written notice of the breach by the terminating party; or</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>if the other party breaches a term or condition of this licence agreement which the terminating party considers cannot be remedied (including, without limitation, in the case of the User posting any Unacceptable Content or performing any Unacceptable Acts).</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className='sub-heading-row'>
                      <td className="sub-heading-order">12.3</td>
                      <td><strong>Post termination obligations </strong></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td><span>Immediately on termination of this licence agreement the User must:</span>
                        <table>
                          <tbody>
                            <tr>
                              <td className="letter-order">(a)</td>
                              <td><span>cease all use of the CleverSparky Application; and </span></td>
                            </tr>
                            <tr>
                              <td className="letter-order">(b)</td>
                              <td><span>permanently delete all copies of the CleverSparky Application from any devices in its possession, custody or control. </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className='sub-heading-row'>
                      <td className="sub-heading-order">12.4</td>
                      <td><strong>Survival of certain terms</strong></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td><span>The party&rsquo;s obligations under clauses 2.2, 3.2, 7, 8, 12, 13, 14, 15, 16 and 17 of this licence agreement will survive notwithstanding any termination of this licence agreement.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">13.</th>
            <th><strong>Liability of Clevertronics</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>Clevertronics acknowledges that: </span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>in respect of the goods or services supplied by Clevertronics under this licence agreement, the User may have certain rights under law (including the Australian Consumer Law) ; and</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>in respect of any Clevertronics Products supplied by Clevertronics to the User, the User may have certain rights pursuant to the agreement under which the Clevertronics Products were supplied and also under law (including the Australian Consumer Law),</span></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td><span>and nothing in this licence agreement should be interpreted as attempting to exclude, restrict or modify the application of any such rights.</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td><span>and nothing in this licence agreement should be interpreted as attempting to exclude, restrict or modify the application of any such rights.</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>If the User makes a claim against Clevertronics regarding the goods or services provided under this licence agreement in respect of a breach or alleged breach by Clevertronics of any consumer guarantees or implied warranties imposed under any consumer law (including, without limitation, the consumer guarantee provisions under the Australian Consumer Law), then to the extent permitted by that law and subject to clause 13(a), Clevertronics&rsquo; liability to the User for such breach will be limited, at the election and discretion of Clevertronics, to:</span>
      
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>if the breach relates to goods:</span>
                                <table className="capital-letter-table">
                                  <tbody>
                                    <tr>
                                      <td className="capital-letter-order">(A)</td>
                                      <td><span>the replacement of the goods or the supply of the equivalent goods;</span></td>
                                    </tr>
                                    <tr>
                                      <td className="capital-letter-order">(B)</td>
                                      <td><span>the repair of the goods;</span></td>
                                    </tr>
                                    <tr>
                                      <td className="capital-letter-order">(C)</td>
                                      <td><span>the payment of the cost of replacing the goods or of acquiring equivalent goods; or</span></td>
                                    </tr>
                                    <tr>
                                      <td className="capital-letter-order">(D)</td>
                                      <td><span>the payment of the cost of having the goods repaired (provided that, for the avoidance of doubt, nothing in this clause operates to make Clevertronics responsible for any labour costs incurred in having the goods repaired); or</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>it the breach relates to services:</span>
                                <table className="capital-letter-table">
                                  <tbody>
                                    <tr>
                                      <td className="capital-letter-order">(A)</td>
                                      <td><span>the supply of the services again; or</span></td>
                                    </tr>
                                    <tr>
                                      <td className="capital-letter-order">(B)</td>
                                      <td><span>the payment of the cost of having the services supplied again.</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>Subject to clause 13(b), and notwithstanding any other provision of this licence agreement, the maximum aggregate liability of Clevertronics to the User under or in connection with this licence agreement (including for breach of this licence agreement by Clevertronics or any negligent act or omission of Clevertronics) shall be limited to greater of the total amount of the licence fees (if any) paid to Clevertronics by the User for the CleverSparky Application or the sum of AUD $100. </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(d)</td>
                      <td><span>Clevertronics will not be liable to the User for any Consequential Loss incurred by the User under or in connection with this licence agreement or as a result of the User&rsquo;s use of the CleverSparky Application, even if Clevertronics has been advised of the possibility of such Consequential Loss being incurred. </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">14.</th>
            <th><strong>Warranties and liability</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">14.1</td>
              <td className="li2"><span><strong>User warranties</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Where the User is an individual who creates a CleverSparky business account for and on behalf of a Business User, the User represents and warrants to Clevertronics that they have full legal authority to enter into this licence agreement on behalf of the Business User and bind the Business User to the terms of this licence agreement.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">14.2</td>
              <td className="li2"><span><strong>Clevertronics warranty exclusions</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Clevertronics does not warrant that the CleverSparky Application or any portion of the CleverSparky Application will meet the User&rsquo;s requirements, the operation of the CleverSparky Application will be uninterrupted or error-free, the CleverSparky Application will be compatible with the User&rsquo;s other software or systems or that the Documentation will be error free. All express or implied warranties in relation to the CleverSparky Application are expressly excluded o the extent permitted by law. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">14.3</td>
              <td className="li2"><span><strong>Process for warranty claims</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Un the event of any failure of the CleverSparky Application to conform to any applicable warranty, the User may notify Apple or Google (as applicable), and Apple or Google (as applicable) may refund the purchase price for the CleverSparky Application to the User (if any) and, to the maximum extent permitted by applicable law, Apple or Google (as applicable) will have no other warranty obligation whatsoever with respect to the CleverSparky Application, and any other claim, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Clevertronics (subject to clauses 13 and 14.1). </span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">15.</th>
            <th><strong>Indemnity</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>The User will indemnify Clevertronics and each of its directors, officers, employees, agents, contractors and related bodies corporate (<strong>Indemnified Parties</strong>), and keep each of them indemnified, against any claim made against an Indemnified Party or Loss suffered or incurred by an Indemnified Party which arises from or in respect of the User&rsquo;s use of the CleverSparky App including:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>any breach by the User of this licence agreement; or</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>any negligent, or wilful acts or omission, theft, misconduct, dishonesty or fraud committed by the User, its officers, directors, employees, agents, representatives, delegates or contractors. </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">16.</th>
            <th><strong>Taxes</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>Unless expressly stated otherwise, all fees and other amounts payable by the User under or in connection with this licence agreement have been calculated without regard to any Indirect Tax.<span className="Apple-converted-space">&nbsp; </span>Notwithstanding anything to the contrary in this licence agreement, if Indirect Tax is imposed upon or is payable by Clevertronics under or in connection with this licence agreement, the User must pay to Clevertronics an amount equal to the Indirect Tax upon demand by Clevertronics. </span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">17.</th>
            <th><strong>General Provisions</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.1</td>
              <td className="li2"><span><strong>Governing law</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>This licence agreement will be governed by and construed exclusively under the laws of the State of Victoria, Australia and the parties submit to the non-exclusive jurisdiction of the courts of the State of Victoria and the Federal Court of Australia. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.2</td>
              <td className="li2"><span><strong>International conventions </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The United Nations Convention on the International Sale of Goods (the Vienna Convention) will not apply to this licence agreement or the subject matter hereof. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.3</td>
              <td className="li2"><span><strong>Privacy policy </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Clevertronics will collect, handle and store information (including personal information regarding the User) in accordance with Clevertronics&rsquo; privacy policy, a copy of which is available at <a href="http://clevertronics.com.au"><span className="s4">http://clevertronics.com.au</span></a> /privacy-policy/ or by requesting a copy from Clevertronics. </span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.4</td>
              <td className="li2"><span><strong>Assignment</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User may not assign or otherwise deal with any of its rights or obligations under this licence agreement without the prior written consent of Clevertronics.<span className="Apple-converted-space">&nbsp; </span>Clevertronics may assign, novate or otherwise deal with its rights under this licence agreement at any time effective immediately upon Clevertronics notifying the User of the assignment or novation.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.5</td>
              <td className="li2"><span><strong>Entire agreement</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>This licence agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes all previous communications, representations, inducements, undertakings, agreements or arrangements between the parties or their respective officers, employees or agents.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.6</td>
              <td className="li2"><span><strong>Waiver and variation </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>Clevertronics may vary the terms of this licence agreement by publishing new terms via an update to the CleverSparky Application. No failure to exercise or delay in exercising any right given by or under this licence agreement to a party constitutes a waiver and the party may still exercise that right in the future.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.7</td>
              <td className="li2"><span><strong>Force Majeure Event</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>If a party is prevented, hindered or delayed from performing its obligations under this licence agreement by a Force Majeure Event, then as long as that situation continues, that party will be excused from performance of the obligation to the extent it is so prevented, hindered or delayed, and the time for performance of the obligation will be extended accordingly. If a party is affected by a Force Majeure Event it will immediately give the other party a notice of its occurrence and its effect or likely effect, and use all reasonable endeavours to minimise the effect of the Force Majeure Event and to bring it to an end.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.8</td>
              <td className="li2"><span><strong>Liability of parties </strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>If a party consists of more than one person:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>an obligation of those parties is a joint obligation of all of them and a several obligation of each of them;</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>a right given to those parties is a right given jointly and severally to each of them, and if exercised by one of them, is deemed to be exercised jointly; and</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>a representation, warranty or undertaking made by those parties Is made by each of them.</span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.9</td>
              <td className="li2"><span><strong>User&rsquo;s Legal Compliance</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User represents and warrants that he or she is not:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &lsquo;terrorist supporting&rsquo; country; and</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>listed on any U.S. Government list of prohibited or restricted parties. </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.10</td>
              <td className="li2"><span><strong>Severability</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>If any provision of this licence agreement is invalid or not enforceable in accordance with its terms in any jurisdiction, it is to be read down, if possible, so as to be valid and enforceable and will otherwise be capable of being severed to the extent of the invalidity or unenforceability without affecting the remaining provisions of this licence agreement or affecting the validity or enforceability of that provision in any other jurisdiction.</span></td>
            </tr>
            <tr className='sub-heading-row'>
              <td className="sub-heading-order">17.11</td>
              <td className="li2"><span><strong>Contacting Clevertronics</strong></span></td>
            </tr>
            <tr>
              <td></td>
              <td><span>The User may contact Clevertronics by email or telephone. Any questions, complaints or claims with respect to this licence agreement or the CleverSparky Application should be directed to Clevertronics by phone on +61 3 9559 2700 or email at <a href="mailto:support@cleversparky.com.au"><span className="s4">support@cleversparky.com.au</span></a>.</span></td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">18.</th>
            <th><strong>Additional Apple Terms</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>If the CleverSparky Application is downloaded from the App Store or is otherwise used on an Apple Device, the following clauses apply and are incorporated into this licence agreement in respect of the use of the CleverSparky Application on the Apple Device:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>if there is any inconsistency between the provisions of this licence agreement and the provisions of Apple&rsquo;s App Store Terms of Service, the provisions of Apple&rsquo;s App Store Terms of Service will prevail to the extent of the inconsistency; </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>Clevertronics and the User acknowledge that: </span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>this licence agreement is concluded between Clevertronics and the User only, and not with Apple; and </span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>Clevertronics, and not Apple, is solely responsible for the CleverSparky Application and the content thereof;</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>the User and Clevertronics acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the CleverSparky Application (whether under this licence agreement or pursuant to any applicable law);</span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(d)</td>
                      <td><span>Clevertronics and the User acknowledge that, in the event of any third party claim that the CleverSparky Application or the User&rsquo;s possession and use of the CleverSparky Application infringes that third party&rsquo;s intellectual property rights, Clevertronics (and not Apple) will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim; </span></td>
                    </tr>
                    <tr>
                      <td className="letter-order">(e)</td>
                      <td><span>the User and Clevertronics acknowledge that Clevertronics (and not Apple) is responsible for addressing any claims of the User or any third party relating to the CleverSparky Application or the User&rsquo;s possession and/or use of the CleverSparky Application, including, but not limited to:</span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>product liability claims;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>any claim that the CleverSparky Application fails to conform to any applicable legal or regulatory requirement; and</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iii)</td>
                              <td><span>any claim arising under consumer protection or other similar legislation; and </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(f)</td>
                      <td><span>Clevertronics and the User acknowledge and agree that Apple, and Apple&rsquo;s subsidiaries, are third party beneficiaries of this licence agreement and that, upon the User&rsquo;s acceptance of the terms and conditions of this licence agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this licence agreement against the User as a third party beneficiary thereof. </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      
        <table className="term">
          <thead>
          <tr>
            <th className="heading-order">19.</th>
            <th><strong>Additional Android Terms</strong></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td><span>If the CleverSparky Application is downloaded from Google Play or is otherwise used on an Android Device, the following clauses apply and are incorporated into this licence agreement in respect of the use of the CleverSparky Application on the Android Device:</span>
                <table className="letter-table">
                  <tbody>
                    <tr>
                      <td className="letter-order">(a)</td>
                      <td><span>if there is any inconsistency between the provisions of this licence agreement, the provisions of the Google Play Terms of Service and/or the provisions of the Google Play Developer Distribution Agreement, the following order of precedence shall apply to the extent of the inconsistency: </span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>the provisions of the Google Play Developer Distribution Agreement;</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>the provisions of the Google Play Terms of Service; and</span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(iii)</td>
                              <td><span>the terms of this licence agreement; </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(b)</td>
                      <td><span>Clevertronics and the User acknowledge that: </span>
                        <table className="numerals-table">
                          <tbody>
                            <tr>
                              <td className="numeral-order">(i)</td>
                              <td><span>this licence agreement is concluded between Clevertronics and the User only, and not with Google; and </span></td>
                            </tr>
                            <tr>
                              <td className="numeral-order">(ii)</td>
                              <td><span>Clevertronics, and not Google, is solely responsible for the CleverSparky Application and the content thereof; and</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="letter-order">(c)</td>
                      <td><span>the User and Clevertronics acknowledge that Google has no obligation whatsoever to furnish any maintenance and support services with respect to the CleverSparky Application (whether under this licence agreement or pursuant to any applicable law). </span></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
)}

export default Terms;
