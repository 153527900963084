import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import illus_fittings from '../../assets/images/illus-fittings.svg';
import LoadMoreButton from './LoadMoreButton';
import { accessRequests, approveAccess, declineAccess } from '../../api/accessRequestApi';

export default ({currentOrg, user, isQueryPending, onSetCurrentUser}) => {

  const [siteRequests, setSiteRequests] = useState([]);
  const [siteRequestPageNum, setSiteRequestPageNum] = useState(1);
  const [siteRequestTotal, setSiteRequestTotal] = useState(0);

  const approve = (requestId) => {
    approveAccess(requestId).then(() => {
      updateAccessRequests(true, 1);
      setSiteRequestPageNum(1);
    })
  }

  const decline = (requestId) => {
    declineAccess(requestId).then(() => {
      updateAccessRequests(true, 1);
      setSiteRequestPageNum(1);
    })
  }

  const loadMoreAccessRequests = () => {
    if (Math.ceil(siteRequests.length / 10) < siteRequestTotal) {
      setSiteRequestPageNum(siteRequestPageNum + 1);
    }
  }

  const updateAccessRequests = (refresh, pageNum) => {
    if (currentOrg) {
      const filters = '?include=user,site&currentOrganisationId=' + currentOrg + '&page[number]=' + pageNum + '&page[size]=10';
      accessRequests(currentOrg, filters).then(response => {
        let newSiteRequests = _.cloneDeep(response.data);
        newSiteRequests.forEach(siteRequest => {
          let siteId = siteRequest.relationships.site.data.id;
          let userId = siteRequest.relationships.user.data.id;
          response.included.forEach(include => {
            if (include.type === 'users' && include.id === userId) {
              siteRequest.relationships.user.data = include;
            } else if (include.type === 'sites' && include.id === siteId) {
              siteRequest.relationships.site.data = include;
            }
          })
        })
        if (refresh) {
          setSiteRequests(newSiteRequests);
        } 
        else {
          setSiteRequests(siteRequests.concat(newSiteRequests));
        }
        setSiteRequestTotal(response.meta['total-objects']);
      })
    }
  }

  useEffect(() => {
    updateAccessRequests(true, siteRequestPageNum);
  }, [currentOrg])

  useEffect(() => {
    if (siteRequestPageNum !== 1) {
      updateAccessRequests(false, siteRequestPageNum);
    }
  }, [siteRequestPageNum])

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const organisationId = queryParameters.get("organisation-id");
    // const accessRequestId = queryParameters.get("access-request-id");
    
    if (user && organisationId) {
      onSetCurrentUser(user, organisationId, true);
      localStorage.setItem('currentOrganisation', organisationId);
      // approve(accessRequestId);
    }
  }, [user])

  return (
    <div id="site-request-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="first-name column">
              <div className="inner">
                First Name
              </div>
            </th>
            <th className="last-name column">
              <div className="inner">
                Last Name
              </div>
            </th>
            <th className="business-name column">
              <div className="inner">
                Business Name
              </div>
            </th>
            <th className="site-address column">
              <div className="inner">
                Site Address
              </div>
            </th>
            <th className="row-action"></th>
          </tr>
        </thead>
        <tbody>
          {siteRequests.map(request => (
            <tr key={siteRequests.indexOf(request)}>
              <td className='first-name column'>
                {request.relationships.user.data.attributes['first-name']}
              </td>
              <td className='last-name column'>
                {request.relationships.user.data.attributes['last-name']}
              </td>
              <td className='business-name column'>
                {request.relationships.site.data.attributes['business-name']}
              </td>
              <td className='site-address column'>
                {request.relationships.site.data.attributes['address']}
              </td>
              <td className="row-action">
                <div className="action-wrapper">
                  <button className="button small text-green" disabled={isQueryPending} onClick={() => approve(request.id)} >
                    Approve
                  </button>
                  <div className="divider"/>
                  <button className="button small text-red" disabled={isQueryPending} onClick={() => decline(request.id)}>
                    Decline
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {siteRequests.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_fittings} alt="Blue Row Icon"/>
          </div>
          <div className="empty-message">
            There are currently no site requests for this business.
          </div>
        </div>  
      ) : (
        <div className="table-actions">
          <LoadMoreButton 
            canLoad={siteRequests.length < siteRequestTotal} 
            loadText='Load More Access Requests' 
            numberOfItemsDisplayedText={`(Showing ${siteRequests.length} of ${siteRequestTotal})`} 
            onLoadMore={loadMoreAccessRequests}
          />
        </div>
      )}
    </div>
  )
}