import React, { useState, useEffect, useContext } from 'react';
import icon_close from '../../assets/images/icon-close.svg'

export default ({isQueryPending, onClose, onDeleteFitting}) => {

  return (
    <>
      <div className="modal-header">
        <h2 className="form-heading">Delete Fitting</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
      </div>
      
      <div className="modal-content">
        <div className="inner">
          <p>Are you sure you want to delete this fitting?</p>
        </div>
        <div className="modal-actions">
          <button className="button small red" disabled={isQueryPending} onClick={onDeleteFitting}>
            Delete Fitting
          </button>
        </div>
      </div>
    </>
  )
}