import React, { useState, useEffect } from 'react';

export default ({site, viewSiteTourNames, activeTab, isTestEventsEnabled, canView, isShoppingCartEnabled, showOrderItemsCount, orderItemsCount, onChangeTab }) => {

  return (
    <div className='site-menu'>
      <div stopName='groupsTab' tourNames={viewSiteTourNames}>
        <a className={activeTab === 'fittings' ? 'menu-actions active' : 'menu-actions'} onClick={() => onChangeTab('fittings')}>All Fittings</a>
      </div>
      <div stopName='groupsTab' tourNames={viewSiteTourNames}>
        <a className={activeTab === 'groups' ? 'menu-actions active' : 'menu-actions'} onClick={() => onChangeTab('groups')}>Groups</a>
      </div>
      {isTestEventsEnabled && (
        <div stopName='testEventsTab' tourNames={viewSiteTourNames}>
          <a className={activeTab === 'testEvents' ? 'menu-actions active' : 'menu-actions'} onClick={() => onChangeTab('testEvents')}>Logbooks</a>
        </div>
      )}
      <div stopName='faultsTab' tourNames={viewSiteTourNames}>
        <a className={activeTab === 'faults' ? 'menu-actions active' : 'menu-actions'} onClick={() => onChangeTab('faults')}>Faults</a>
      </div>
      {canView.deletedFittings && (
        <div stopName='deletedFittingsTab' tourNames={viewSiteTourNames}>
          <a className={activeTab === 'deletedFittings' ? 'menu-actions active' : 'menu-actions'} onClick={() => onChangeTab('deletedFittings')}>Deleted Fittings</a>
        </div>
      )}
      <div stopName='siteDetailsTab' tourNames={viewSiteTourNames}>
        <a className={activeTab === 'siteDetails' ? 'menu-actions active' : 'menu-actions'} onClick={() => onChangeTab('siteDetails')}>Site Details</a>
      </div>
      {isShoppingCartEnabled && (
        <a className={activeTab === 'shoppingCart' ? 'menu-actions shopping-cart active' : 'menu-actions shopping-cart'} onClick={() => onChangeTab('shoppingCart')}>
          Replacement Products
          <span className={`order-item-count ${!showOrderItemsCount && 'hide'}`} >
            {orderItemsCount}
          </span>
        </a>
      )}
    </div>
  )
}
