import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { getGroups } from '../../../api/groupApi';
import FormValidatedInput from '../FormValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';
import ReportModalDelete from '../ReportModalDelete';
import ValidatedMultiSelect from '../ValidatedMultiSelect';
import { addTemplate, getTemplates, removeTemplate } from '../../../api/templateApi';
import { createReport, getReport } from '../../../api/reportApi';
import { getTestEvents } from '../../../api/testEventApi';
import { validateGroups, validateTestEvent } from '../../../utils/validators';

export default ({site, didValidate, isQueryPending, sendreporttxt, onPreview, onChangeSendreporttxt, onSendReport}) => {

  const cstatementText = 'This Emergency lighting duration test report has been collected as per the record keeping requirements of AS/NZS2293.2 sections 2.7.2 & 2.7.3.\n'
      + 'The information contained in this report demonstrates compliance with the 6 monthly inspection and record keeping tasks listed in AS/NZS2293.2- Appendix A- Table A2- Schedule 2- Parts 2.1, 2.7, 2.8 and 2.10.';

  const [fileFormat, setFileFormat] = useState('pdf');
  const [contentType, setContentType] = useState('groups');
  const [reportType, setReportType] = useState('device-details');
  const [cstatebool, setCstatebool] = useState(true);
  const [cstatebooloff, setCstatebooloff] = useState(false);
  const [cstatement, setCstatement] = useState(cstatementText);
  const [groups, setGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [testEventOptions, setTestEventOptions] = useState([]);
  const [selectedTestEvent, setSelectedTestEvent] = useState('');
  const [isTestEventsEnabled, setIsTestEventsEnabled] = useState(true);
  const [newbuttonpushed, setNewbuttonpushed] = useState(false);
  const [newtempname, setNewtempname] = useState('');
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [textchangedcstatement, setTextchangedcstatement] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [showErrorGroups, setShowErrorGroups] = useState(false);
  const [errorMessageGroups, setErrorMessageGroups] = useState('');
  const [showErrorTestEvents, setShowErrorTestEvents] = useState(false);
  const [errorMessageTestEvents, setErrorMessageTestEvents] = useState('');

  const changeFileFormat = () => {
    if (fileFormat === 'pdf') {
      setFileFormat('csv');
    } else {
      setFileFormat('pdf');
    }
  }

  const changeContentType = () => {
    if (contentType === 'groups') {
      setContentType('test-events');
    } else {
      setContentType('groups');
    }
  }

  const newTemplate = () => {
    setNewbuttonpushed(true);
    setSelectedTemplate('');
  }

  const inputActionEventTrigger = () => {

  }

  const toggleDeleteModal = () => {
    setDisplayDeleteModal(true);
  }

  const tamplateSave = () => {
    const data = {
      data: {
        attributes: {
          name: newtempname,
          fulltext: cstatement
        },
        type: "templates"
      }
    }
    addTemplate(data).then(response => {
      let newTemplateOptions = _.cloneDeep(templateOptions);
      newTemplateOptions.push({
        label: response.data.attributes['name'],
        value: response.data.id,
        text: response.data.attributes['fulltext']
      })
      setTemplateOptions(newTemplateOptions);
      setSelectedTemplate(response.data.id);
      setNewbuttonpushed(false);
      setNewtempname('');
    })
  }

  const sendReport = (e) => {
    e.preventDefault();
    if ((contentType === 'groups' && checkGroups(selectedGroups)) || (contentType === 'test-events' && checkTestEvent(selectedTestEvent))) {
      let data = {
        data: {
          type: "reports",
          attributes: {
            "file-format": fileFormat,
            "cstatebool": cstatebool,
            "cstatement": cstatement
          },
          relationships: {
            site: {
              data: {
                id: site.id,
                type: "sites"
              }
            }
          }
        }
      }
      if (contentType === 'groups') {
        let groups = Array.from(selectedGroups, group => ({
          id: group.value,
          type: "groups"
        }))
        data.data.relationships['groups'] = {data: groups};
      }
      if (contentType === 'test-events') {
        data.data.relationships['test-event'] = {data: {
          id: selectedTestEvent,
          type: "test-events"
        }};
      }
  
      createReport(reportType, data).then(() => {
        onSendReport();
        setNewbuttonpushed(false);
        setNewtempname('');
        setCstatement(cstatementText);
      })
    }    
  }

  const closeDeleteModal = () => {
    setDisplayDeleteModal(false);
  }

  const updateGroups = () => {
    const groupsFilter = '?&site=' + site.id;
    getGroups(site.id, groupsFilter).then(response => {
      setGroups(response.data);
    })
  }

  const removeGroupOption = (item) => {
    let newSelectedGroups = _.cloneDeep(selectedGroups);
    if (item.value === 'all') {
      setSelectedGroups([]);
    } else {
      newSelectedGroups = newSelectedGroups.filter(option => {
        return option.value !== item.value
      })
      setSelectedGroups(newSelectedGroups);
    }
  }

  const selectionsChanged = (e) => {
    let selection = Array.from(e.target.selectedOptions, option => ({
      label: option.label,
      value: option.value
    }));
    selection.forEach(selected => {
      if (selected.value === 'all') {
        selection = groupOptions.filter(option => {
          return option.value !== 'all';
        })
      }
    })
    setSelectedGroups(selection);
  }

  const changeCstatement = (e) => {
    setCstatement(e.target.value);
    onChangeSendreporttxt('Finish Editing And Email Report');
  }

  const tamplateChanged = (e) => {
    setSelectedTemplate(e.target.value);
    templateOptions.forEach(option => {
      if (option.value === e.target.value) {
        setCstatement(option.text);
      }
    })
  }

  const updateTemplates = () => {
    getTemplates().then(response => {
      let templateOptions = Array.from(response.data, option => ({
        label: option.attributes['name'],
        value: option.id,
        text: option.attributes['fulltext']
      }))
      setTemplateOptions(templateOptions);
      setCstatement(cstatementText);
    })
  }

  const changeNewTemplateName = (e) => {
    setNewtempname(e.target.value);
  }

  const deleteTemplate = () => {
    removeTemplate(selectedTemplate).then(() => {
      setDisplayDeleteModal(false);
      setSelectedTemplate('');
      updateTemplates();
    })
  }
  
  const changeCstate = (status) => {
    if (status === 'on') {
      setCstatebool(true);
      setCstatebooloff(false);
    }
    if (status === 'off') {
      setCstatebool(false);
      setCstatebooloff(true);
    }
  }

  const testEventChanged = (e) => {
    setSelectedTestEvent(e.target.value);
  }

  const updateTestEvents = () => {
    const testEventsFilter = '?site=' + site.id;
    getTestEvents(site.id, testEventsFilter).then(response => {
      setTestEvents(response.data);
    })
  }

  const getFileUrl = (cb) => {
    let data = {
      data: {
        type: "reports",
        attributes: {
            "file-format": fileFormat,
            "cstatebool": cstatebool ? 'true' : 'false',
            "cstatement": cstatement
        },
        relationships: {
          site: {
            data: {
              id: site.id,
              type: "sites"
            }
          }
        }
      }
    }
    if (fileFormat === 'csv') {
      data.data.attributes.csvurllink = 'true';
    } else if (fileFormat === 'pdf') {
      data.data.attributes.pdfurllink = 'true';
    }
    if (contentType === 'groups') {
      let groups = Array.from(selectedGroups, group => ({
        id: group.value,
        type: "groups"
      }))
      data.data.relationships['groups'] = {data: groups};
    }
    if (contentType === 'test-events') {
      data.data.relationships['test-event'] = {data: {
        id: selectedTestEvent,
        type: "test-events"
      }};
    }

    getReport(reportType, data).then(response => {
      cb(response.data);
    })
  }

  const downloadFile = (e) => {
    e.preventDefault();
    if ((contentType === 'groups' && checkGroups(selectedGroups)) || (contentType === 'test-events' && checkTestEvent(selectedTestEvent))) {
      getFileUrl(url => {
        fetch(url).then(response => {
          response.blob().then(blob => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement('a');
              alink.href = fileURL;
              if (fileFormat === 'pdf') {
                alink.download = 'report.pdf';
              } else {
                alink.download = 'report.csv';
              }
              alink.click();
          })
        })
      });
    }
  }

  const previewFile = (e) => {
    e.preventDefault();
    if ((contentType === 'groups' && checkGroups(selectedGroups)) || (contentType === 'test-events' && checkTestEvent(selectedTestEvent))) {
      getFileUrl(url => {
        onPreview(url);
      });
    }
  }

  const checkGroups = (selection) => {
    const validateResult = validateGroups(selection);
    if (validateResult.validated) {
      setShowErrorGroups(false);
      setErrorMessageGroups('');
      return true;
    } else {
      setShowErrorGroups(true);
      setErrorMessageGroups(validateResult.message);
      return false;
    }
  }

  const checkTestEvent = (selection) => {
    const validateResult = validateTestEvent(selection);
    if (validateResult.validated) {
      setShowErrorTestEvents(false);
      setErrorMessageTestEvents('');
      return true;
    } else {
      setShowErrorTestEvents(true);
      setErrorMessageTestEvents(validateResult.message);
      return false;
    }
  }

  useEffect(() => {
    if (contentType === 'test-events' && reportType === 'faulty-fittings') {
      setReportType('device-details')
    }
  }, [contentType])

  useEffect(() => {
    updateGroups();
    updateTemplates();
    updateTestEvents();
  }, [])

  useEffect(() => {
    if (groups) {
      let groupOptions = [];
      groups.forEach(group => {
        groupOptions.push({
          label: group.attributes['name'],
          value: group.id
        })
      })
      setGroupOptions(groupOptions);
    }
  }, [groups])

  useEffect(() => {
    if (testEvents) {
      let testEventOptions = [];
      testEvents.forEach(testEvent => {
        testEventOptions.push({
          label: testEvent.attributes['start-date'] + ' to ' + testEvent.attributes['end-date'],
          value: testEvent.id
        })
      })
      setTestEventOptions(testEventOptions);
    }
  }, [testEvents])

  useEffect(() => {
    if (selectedGroups && selectedGroups.length > 0) {
      setShowErrorGroups(false);
      setErrorMessageGroups('');
    }
  }, [selectedGroups])

  useEffect(() => {
    if (selectedTestEvent) {
      setShowErrorTestEvents(false);
      setErrorMessageTestEvents('');
    }
  }, [selectedTestEvent])

  return (
    <div id='report-form' className="form">
      <div className="form-content">
    
        <div className="report-format">
          <p className="message">Select a file format for your report:</p>
          <div className="ember-radio-button round">
            <input type="radio" value="pdf" name="pdf" id="pdf" checked={fileFormat === 'pdf'} onChange={changeFileFormat}/>
            <label htmlFor="pdf">PDF</label>
          </div>
          <div className="ember-radio-button round">
            <input type="radio" value="csv" name="csv" id="csv" checked={fileFormat === 'csv'} onChange={changeFileFormat}/>
            <label htmlFor="csv">CSV</label>
          </div>
        </div>
    
        <div className="divider"></div>
    
        <div className="report-category">
          <p>What type of report would you like to send?</p>
          <div className="report-category-options">
            <label htmlFor="device-details" className={`ember-radio-button ${reportType === 'device-details' && 'checked'}`} onClick={() => setReportType('device-details')}>
              <input type="radio" value="device-details" name="device-details" id="device-details" />
              Device Details
            </label>

            <label htmlFor="maintenance" className={`ember-radio-button ${reportType === 'maintenance' && 'checked'}`} onClick={() => setReportType('maintenance')}>
              <input type="radio" value="maintenance" name="maintenance" id="maintenance" />
              Maintenance
            </label>

            {contentType === 'groups' && (
              <label htmlFor="faulty-fittings" className={`ember-radio-button ${reportType === 'faulty-fittings' && 'checked'}`} onClick={() => setReportType('faulty-fittings')}>
                <input type="radio" value="faulty-fittings" name="faulty-fittings" id="faulty-fittings" />
                Fault
              </label>
            )}

            <label htmlFor="test-results" className={`ember-radio-button ${reportType === 'test-results' && 'checked'}`}  onClick={() => setReportType('test-results')}>
              <input type="radio" value="test-results" name="test-results" id="test-results" />
              Test
            </label>
          </div>
        </div>
    
        <div className="divider"></div>
    
        {isTestEventsEnabled && (
          <div className="report-content">
            <p className="message">Select which content you would like:</p>
            <div className="ember-radio-button round">
              <input type="radio" value="groups" name="groups" id="groups" checked={contentType === 'groups'} onChange={changeContentType} />
              <label htmlFor="groups">Groups</label>
            </div>

            <div className="ember-radio-button round">
              <input type="radio" value="test-events" name="test-events" id="test-events" checked={contentType === 'test-events'} onChange={changeContentType} />
              <label htmlFor="test-events">Logbooks</label>
            </div>
          </div>
        )}
    
        {contentType === 'groups' ? (
          <div className="row row-animated">
            <ValidatedMultiSelect 
              name='selectedGroups' 
              options={groupOptions} 
              selection={selectedGroups} 
              selectionModel='Groups' 
              didValidate={didValidate} 
              labelText='Groups' 
              showErrorMessage={showErrorGroups}
              errorMessage={errorMessageGroups}
              onSelectionsChanged={selectionsChanged}
              onRemoveItem={removeGroupOption}
            />
          </div>
        ) : (
          <div className="row row-animated">
            <FormValidatedSelect 
              name='selectedTestEvent' 
              options={testEventOptions} 
              value={selectedTestEvent} 
              showErrorMessage={showErrorTestEvents}
              errorMessage={errorMessageTestEvents}
              didValidate={didValidate} 
              labelText='Logbook'
              onChange={testEventChanged}
            />
          </div>
        )}
    
    
        {reportType === 'test-results' && (
          <>
            <div className="report-content">
              <p className="message">Display Compliance Statement:</p>
              <div className="ember-radio-button round">
                <input type="radio" value={cstatebool} name="cstatebool" id="cstatebool" checked={cstatebool} onChange={() => changeCstate('on')}/>
                <label htmlFor="cstatebool">On</label>
              </div>
              <div className="ember-radio-button round">
                <input type="radio" value={cstatebooloff} name="cstatebooloff" id="cstatebooloff" checked={cstatebooloff} onChange={() => changeCstate('off')}/>
                <label htmlFor="cstatebooloff">Off</label>
              </div><p></p>
              <br/>
            </div>

            <div>
              <label style={{display: "inline-block", margin: 0}}> Test Report Compliance Templates  </label><button type="button" className="buttontmpgreen green" disabled={isQueryPending} onClick={newTemplate} >+ New</button>
              {newbuttonpushed ? (
                <div className="row row-animated required-duration-row">
                  <FormValidatedInput type='text' name='newtempname' value={newtempname} didValidate={didValidate} labelText='New Template Name Input' onChange={changeNewTemplateName}/>
                </div>
              ) : (
                <>
                  <br/>
                  <div className="row row-animated">
                    <FormValidatedSelect onChange={tamplateChanged} name='selectedTemplates' options={templateOptions} value={selectedTemplate} didValidate={didValidate} labelText='Select saved template' />
                  </div>
                  <br/>
                </>
              )}
        
              <div className="row row-animated business-name" onInput={inputActionEventTrigger}>
                <FormValidatedInput type='textarea' name='cstatement' value={cstatement} didValidate={didValidate} disabled={inputsDisabled} labelText='Compliance Report Statement(Displays on PDF reports only)' onChange={changeCstatement} />
              </div>
            </div>
          </>
        )}
    
      </div>

      <div className="form-actions">

        {reportType === 'test-results' && selectedTemplate && (
          <button type="button" className="button standard blue" disabled={isQueryPending} onClick={toggleDeleteModal}>Delete Template</button>
        )}

        {reportType === 'test-results' && newbuttonpushed && (
          <button type="button" className="button standard blue" disabled={isQueryPending} onClick={tamplateSave}>Save Template</button>
        )}

        {reportType === 'test-results' && textchangedcstatement && (
          <button type="button" className="button standard blue" disabled={isQueryPending} onClick={tamplateSave}>Save Template</button>
        )}

        {fileFormat === 'pdf' && (
          <button type="button" className="button standard blue" disabled={isQueryPending} onClick={previewFile}>Preview</button>
        )}

        <button type="button" className="button standard blue" disabled={isQueryPending} onClick={downloadFile}>Download</button>

        <button type="button" className="button standard blue" disabled={isQueryPending} onClick={sendReport}>{sendreporttxt}</button>

      </div>
    
      {displayDeleteModal && (
        <ReportModalDelete selectedTemplate={selectedTemplate} onClose={closeDeleteModal} isQueryPending={isQueryPending} onDeleteTemplate={deleteTemplate}/>
      )}
    </div>
  )
}