import React, { useState, useEffect, useRef } from 'react';
import icon_back from '../assets/images/icon-back.svg';
import NavBar from './components/NavBar';
import Profile from './edit/Profile';

const Edit = ({children, canView}) => {

  return (
    <>
      {children}
      <div id='edit' className="page">
        <div className="inner">
          {!canView.userManagement() && (
            <div className="back-button">
              <a href="/sites" className="header-action-styles">
                <img className="icon large" src={icon_back} alt="Back Icon"/>
                  {' Back to Sites'} 
              </a>
            </div>
          )}
          <Profile />
        </div>
      </div>
    </>
  )
}

export default Edit;