import React, { useState, useEffect, useContext } from 'react';
import SitePermissionTable from './SitePermissionTable';

export default ({user, sitePermissions, isQueryPending, infinityLoad, onPermissionRemoved}) => {

  return (
    <div>
      <div className="site-permissions-panel">
        <div className="site-permissions-panel-header">
          <div className="subheading">Site Permissions</div>
        </div>
        <div className="site-permissions-list">
          <SitePermissionTable user={user} sitePermissions={sitePermissions} isQueryPending={isQueryPending} infinityLoad={infinityLoad} onPermissionRemoved={onPermissionRemoved} />
        </div>
      </div>
    </div>
  )
}