export const countries = [
    {label: 'Australia', value: 'Australia'},
    {label: 'New Zealand', value: 'New Zealand'}
];

export const australianStates = [
    {label: 'Australian Capital Territory', value: 'Australian Capital Territory'},
    {label: 'New South Wales', value: 'New South Wales'},
    {label: 'Northern Territory', value: 'Northern Territory'},
    {label: 'Queensland', value: 'Queensland'},
    {label: 'South Australia', value: 'South Australia'},
    {label: 'Tasmania', value: 'Tasmania'},
    {label: 'Victoria', value: 'Victoria'},
    {label: 'Western Australia', value: 'Western Australia'}
];

export const newZealandStates = [
    {label: 'Auckland', value: 'Auckland'},
    {label: 'Bay of Plenty', value: 'Bay of Plenty'},
    {label: 'Christchurch', value: 'Christchurch'},
    {label: 'Dunedin', value: 'Dunedin'},
    {label: 'Hamilton', value: 'Hamilton'},
    {label: 'Hastings', value: 'Hastings'},
    {label: 'Invercargill', value: 'Invercargill'},
    {label: 'Manawatu', value: 'Manawatu'},
    {label: 'Napier', value: 'Napier'},
    {label: 'Nelson', value: 'Nelson'},
    {label: 'New Plymouth', value: 'New Plymouth'},
    {label: 'Palmerston North', value: 'Palmerston North'},
    {label: 'Queenstown', value: 'Queenstown'},
    {label: 'Rotorua', value: 'Rotorua'},
    {label: 'Tauranga', value: 'Tauranga'},
    {label: 'The South Island', value: 'The South Island'},
    {label: 'Timaru', value: 'Timaru'},
    {label: 'Wairarapa', value: 'Wairarapa'},
    {label: 'Wanganui', value: 'Wanganui'},
    {label: 'Wellington', value: 'Wellington'},
    {label: 'Whangarei', value: 'Whangarei'}
];

export const durationOptions = [
    { label: '30', value: 30 },
    { label: '90', value: 90 },
    { label: '120', value: 120 }
];

export const viewOptions = [
    { label: 'All Fittings', value: 'allfittings' },
    { label: 'Groups', value: 'groups' }
];

export const testStatusOptions = [
    { label: 'Valid Pass', value: 'valid_pass' },
    { label: 'Test Active', value: 'test_active' },
    { label: 'Failed Unknown', value: 'failed_unknown' },
    { label: 'Failed Battery', value: 'failed_battery' },
    { label: 'Failed Lamp', value: 'failed_lamp' },
    { label: 'Failed Control', value: 'failed_control' },
    { label: 'Failed No Output', value: 'failed_no_output' }
];

export const statusOptions = [
    { label: 'OK', value: 'ok' },
    { label: 'Faulty', value: 'faulty' }
];

export const repairOptions = [
    { label: 'Replaced battery', value: 'battery' },
    { label: 'Replaced fitting', value: 'fitting' },
    { label: 'Replaced control gear', value: 'control_gear' },
    { label: 'Replaced lamp', value: 'lamp' },
    { label: 'Other', value: 'other' }
];

export const userTypeOptions = [
    { label: 'Owner', value: 'owner' },
    { label: 'Admin', value: 'admin' },
    { label: 'Technician', value: 'technician' }
];

export const passTestStatusOptions = [
    { label: 'Valid Pass', value: 'valid_pass' }
];

export const failTestStatusOptions = [
    { label: 'Failed Unknown', value: 'failed_unknown' },
    { label: 'Failed Battery', value: 'failed_battery' },
    { label: 'Failed Lamp', value: 'failed_lamp' },
    { label: 'Failed Control', value: 'failed_control' },
    { label: 'Failed No Output', value: 'failed_no_output' }
];
