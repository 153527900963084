import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import LoadMoreButton from './LoadMoreButton';
import icon_arrow_sort from '../../assets/images/icon-arrow-sort.svg';
import illus_site from '../../assets/images/illus-site.svg'
import icon_add_white from '../../assets/images/icon-add-white.svg'
import { requestSiteAccess } from '../../api/accessRequestApi';
import { updateSite } from '../../api/siteApi';
import { store } from '../../utils/store';


export default ({
  sites, 
  postOnboardingTourNames, 
  viewRestrictedSitesTourNames, 
  isQueryPending, 
  siteTotal,
  noSearchResults, 
  hasTestActivated,
  onSort,
  onAddSite,
  onLoadMore,
  onUpdateSites
}) => {

  const navigate = useNavigate();
  const { dispatch } = useContext(store);

  const toggleSort = (type) => {
    onSort(type)
  }

  const showSite = (site) => {
    navigate('/sites/' + site.id, {
      state: {
        siteId: site.id
      }
    });
  }

  const requestAccess = (site) => {
    const data = {
      data: {
        type: 'access-requests',
        relationships: {
          site: {
            data: {
              type: 'sites',
              id: site.id
            }
          }
        }
      }
    };
    requestSiteAccess(data).then(response => {
      onUpdateSites();
    })
  }

  const changeTestActivated = (e, site) => {
    let data = {
      data: site
    }
    data.data.attributes['selftest'] = e.target.checked;
    updateSite(site.id, data).then(response => {
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Updated'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  return (
    <div id="site-table" className="table">
      <table>
        <thead>
          <tr>
            <th className="unit column">
              <div className="inner">
                Unit/level
                <div className="sort-icon" onClick={() => toggleSort('unit')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>
            <th className="address column">
              <div className="inner">
                Address
                <div className="sort-icon" onClick={() => toggleSort('address')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>
            <th className="business-name column">
              <div className="inner">
                Business Name
                <div className="sort-icon" onClick={() => toggleSort('business-name')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>
            <th className="building-name column">
              <div className="inner">
                Building Name
                <div className="sort-icon" onClick={() => toggleSort('building-name')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>
            <th className="next-test column">
              <div className="inner">
                Next Test
                <div className="sort-icon" onClick={() => toggleSort('nexttestdate')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>
    
    
            <th className="created-at column">
              <div className="inner">
                Created At
                <div className="sort-icon" onClick={() => toggleSort('created-at')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>

            <th className="test-activated column">
              <div className="inner">
                CTP Self-test activated
                <div className="sort-icon" onClick={() => toggleSort('selftest')} >
                  <img src={icon_arrow_sort} alt="Sort Icon"/>
                </div>
              </div>
            </th>
            <th className="row-indicator"></th>
    
    
          </tr>
        </thead>
    
        <tbody>
          {sites.map(site => (
            <tr key={sites.indexOf(site)} onClick={site.attributes['permitted'] ? (() => showSite(site)) : null} className={`${site.notPermitted && 'not-permitted'}`} >
              <td className='column unit'>{site.attributes['unit']}</td>
              <td className='column address'>{site.attributes['address']}</td>
              <td className='column business-name'>{site.attributes['business-name']}</td>
              <td className='column building-name'>{site.attributes['building-name']}</td>
              {site.attributes['nexttestdate'] ? 
                <td className='column business-name'>{moment(site.attributes['nexttestdate']).format('DD/MM/YYYY')}</td>
                : 
                <td className='column business-name'> <a href=" " title="Create a logbook or display the next test date by making sure that your logbook test date is set, Site > Logbooks > Appropriate Logbook > Top right 3 button menu > Edit Logbook Template "> Hover here </a></td>
              }
    
              <td className='column business-name'>
                {site.attributes['permission-requested'] ? 
                  <div className='button small text-brown pending-access'>Access Pending</div>
                  :
                  !site.attributes['permitted'] ?
                    <div stopName='requestAccess' tourNames={viewRestrictedSitesTourNames}>
                      <button className='button small text-green request-access' disabled={isQueryPending} onClick={() => requestAccess(site)} >Request Access</button>
                    </div>
                    :
                    moment(site.attributes['created-at']).format('hh:mma DD/MM/YYYY')
                }
              </td>
              {hasTestActivated && (
                <td className='column test-activated' onClick={(e) => e.stopPropagation()}>
                  <label class="switch">
                    <input type="checkbox" checked={site.attributes['selftest'] ? true : false} onClick={(e) => changeTestActivated(e, site)}/>
                    <span class="slider round"></span>
                  </label>
                  <span>{site.attributes['selftest'] ? 'Yes' : 'No'}</span>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {sites.length === 0 ? 
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_site} alt="Blue Row Icon"/>
          </div>
          {noSearchResults ?
            <div className="empty-message">
              No results matched your search.<br />To add a new site, click on the add site button below.
            </div>
            :
            <div className="empty-message">
              To start populating your sites, click on the add site button below.
            </div>
          }
          <div className="empty-actions">
            <div stopName='newSiteAction' tourNames={postOnboardingTourNames}>
              <button className="action button icon green" sites={sites} onClick={onAddSite} >
                <img className="icon" src={icon_add_white} alt="Add Icon"/>
                Add Site
              </button>
            </div>
          </div>
        </div>
        :
        <div className="table-actions">
          <LoadMoreButton 
            canLoad={sites.length < siteTotal} 
            loadText='Load More Sites' 
            numberOfItemsDisplayedText={`(Showing ${sites.length} of ${siteTotal})`} 
            onLoadMore={onLoadMore}
          />
        </div>
      }
    </div>
  )
}



