import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import SiteForm from '../components/forms/SiteForm';
import ModalView from '../components/ModalView';
import SiteDetailsDropdownMenu from '../components/SiteDetailsDropdownMenu';
import FormValidatedSelect from '../components/FormValidatedSelect';
import icon_close from '../../assets/images/icon-close.svg';
import FormValidatedInput from '../components/FormValidatedInput';
import { removeSite, getSiteDrawings, postSiteDrawing } from '../../api/siteApi';
import { store } from '../../utils/store';
import { getOrganisations } from '../../api/organisationApi';
import { validateOrganisation } from '../../utils/validators';
import { signedRequestSiteDrawings, transferSite, uploadFile } from '../../api/otherApi';
import {useDropzone} from 'react-dropzone';

export default ({site, currentEmployment, onSaveEdit}) => {

  const { dispatch, state } = useContext(store);
  const navigate = useNavigate();

  const [isQueryPending, setIsQueryPending] = useState(false);
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] = useState(false);
  const [displayTransferSitesModal, setDisplayTransferSitesModal] = useState(false);
  const [hasOrganisationsToTransferTo, setHasOrganisationsToTransferTo] = useState(true);
  const [displayTransferSitesToUserModal, setDisplayTransferSitesToUserModal] = useState(false);
  const [didValidate, setDidValidate] = useState(false);
  const [emailaddress, setEmailaddress] = useState('');
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [files, setFiles] = useState([]);
  const [siteDrawings, setSiteDrawings] = useState([]);
  const [canEditSiteDrawings, setCanEditSiteDrawings] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  // Organisation
  const [organisationId, setOrganisationId] = useState('');
  const [errorMessageOrganisation, setErrorMessageOrganisation] = useState('');
  const [showErrorMessageOrganisation, setShowErrorMessageOrganisation] = useState(false);

  const edit = () => {
    setCanEdit(true);
  }

  const editSiteDrawings = () => {
    setCanEditSiteDrawings(true);
  }

  const openTransferSiteToUserModal = () => {
    setDisplayTransferSitesToUserModal(true);
  }

  const openTransferSiteModal = () => {
    setDisplayTransferSitesModal(true);
  }

  const openDeleteSite = () => {
    setDisplayDeleteConfirmationModal(true);
  }

  const moveSite = () => {
    const data = {
      data: {
        type: "organisations",
        id: organisationId
      }
    }
    transferSite(site.id, data).then(response => {
      navigate('/sites', {
        state: {
          updated: true
        }
      });
      setDisplayTransferSitesModal(false);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Transfered'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const closeTransferSiteModal = () => {
    setDisplayTransferSitesModal(false);
  }

  const cancelDelete = () => {
    setDisplayDeleteConfirmationModal(false);
  }

  const cancelEdit = () => {
    setCanEdit(false);
  }

  const cancelEditSiteDrawings = () => {
    // setCanEditSiteDrawings(false);
    setFiles([]);
  }

  const saveEdit = (data) => {
    setCanEdit(false);
    onSaveEdit(data);
  }

  const confirmDeleteSite = () => {
    removeSite(site.id).then(() => {
      navigate('/sites', {
        state: {
          updated: true
        }
      });
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const closeTransferSiteToUserModal = () => {
    setDisplayTransferSitesToUserModal(false);
  }

  const transferSiteOwner = () => {
    const data = {
      data: {
        type: "users",
        id: state.user.id,
        newowner: emailaddress
      }
    }
    transferSite(site.id, data).then(response => {
      setDisplayTransferSitesToUserModal(false);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Transfer Email Sent'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const closeDisplayTransferSitesToUserModal = () => {
    setDisplayTransferSitesToUserModal(false);
  }

  const changeOrganisation = (e) => {
    setOrganisationId(e.target.value)
    checkOrganisation(e.target.value)
  }

  const checkOrganisation = (input) => {
    const validateResult = validateOrganisation(input);
    if (validateResult.validated) {
      setShowErrorMessageOrganisation(false);
      setErrorMessageOrganisation('');
      return true;
    } else {
      setShowErrorMessageOrganisation(true);
      setErrorMessageOrganisation(validateResult.message);
      return false;
    }
  }

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 88,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: 100,
    objectFit: 'contain'
  };

  const fileName = {
    overflowWrap: 'anywhere',
    marginTop: 5
  };

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
      'application/pdf': [],
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => {
        if (file.type === 'application/pdf') {
          return Object.assign(file, {
            preview: require('./../../assets/icons/pdf.png')
          })
        } else {
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        }
      }));
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
        <span style={fileName}>{file.name}</span>
      </div>
    </div>
  ));

  const uploadFiles = async () => {
    if (files && files.length > 0) {
      setIsUploading(true);
      files.forEach(file => {
        upload(file);
      })
      setTimeout(() => {
        // setCanEditSiteDrawings(false);
        setFiles([]);
        setIsUploading(false);
        // updateSiteDrawings();
      }, 3000)
    }
  }

  const upload = async (file) => {
    let uploadingFile = null;
    let randomFileName = ''; 
    let blob = '';
    const formDataFile = new FormData();
    randomFileName = Math.floor(Math.random() * 100000) + 1 + file.name;
    
    formDataFile.append('file', randomFileName);
    formDataFile.append('siteid', site.id);
    let fileUrlRaw = await signedRequestSiteDrawings(formDataFile);

    blob = file.slice(0, file.size, file.type);
    uploadingFile = new File([blob], randomFileName, {type: file.type});
    uploadFile(uploadingFile, fileUrlRaw, (event) => {
      let percent = (event.loaded / event.total) * 100;
      if (percent === 100) {
        const uploadData = new FormData();
        uploadData.append('newsitedrawing', true);
        uploadData.append('siteid', site.id);
        uploadData.append('sitedrawingurl', fileUrlRaw);
        uploadData.append('filename', file.name);
        postSiteDrawing(uploadData).then(res => {
          setTimeout(() => {
            updateSiteDrawings();
          }, 1000)
        });
      }
    });
  }

  const updateSiteDrawings = () => {
    getSiteDrawings(site.id).then(response => {
      let drawings = response;
      if (!drawings.data) {
        drawings.forEach(drawing => {
          drawing.sitedrawingurl = drawing.sitedrawingurl.slice(0, drawing.sitedrawingurl.indexOf('?'));
        })
      }
      setSiteDrawings(drawings);
    });
  }

  useEffect(() => {
    const filters = '?filter[role]=owner,admin'
    getOrganisations(filters).then(response => {
      let orgs = [];
      response.data.forEach(org => {
        orgs.push({
          label: org.attributes['name'],
          value: org.id
        })
      })
      setOrganisations(orgs);
    })
    updateSiteDrawings();
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [])


  return (
    <div id="site-details">
      <div id="details">
        <SiteDetailsDropdownMenu 
          onEdit={edit}
          onSiteDrawings={editSiteDrawings}
          onDeleteSite={openDeleteSite}
          currentEmployment={currentEmployment}
          onOpenTransferSiteToUserModal={openTransferSiteToUserModal}
          onOpenTransferSiteModal={openTransferSiteModal}
          canView={{
            "transfer-site": true,
            "delete-site": true
          }}
        />
        
        <SiteForm 
          isQueryPending={isQueryPending}
          canEdit={canEdit}
          site={site}
          onCancel={cancelEdit}
          onSave={saveEdit}
        />
      </div>

      <div id="site-drawings">
        {siteDrawings && siteDrawings.length === 0 && (
          <p className='no-drawings'>There are currently no site drawings</p>
        )}
        {siteDrawings && siteDrawings.length > 0 && (
          <p className='has-drawings'>Site Drawings</p>
        )}
        {canEditSiteDrawings && (
          <div className='edit-drawings'>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to upload files</p>
            </div>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
            
            <div className="actions">
              {files.length > 0 && (
                <button className='button standard blue' type="button" name="Cancel" disabled={isUploading} onClick={cancelEditSiteDrawings} >Cancel</button>
              )}
              <button className='button standard green space-right' type="button" name="Save" disabled={files.length === 0 || isUploading} onClick={uploadFiles}>Upload</button>
            </div>
            
          </div>
        )}
        {siteDrawings && siteDrawings.length > 0 && siteDrawings.map(drawing => {
          if (drawing.name.split('.').at(-1) === 'pdf') {
            return (
              <div className='drawing' key={siteDrawings.indexOf(drawing)}>
                <a href={drawing.sitedrawingurl} target='_blank'>
                  <img key={drawing.id} src={require('./../../assets/icons/pdf.png')} className="site-drawing-pdf"/>
                </a>
                <span>{drawing.name}</span>
              </div>
          )} else return (
            <div className='drawing' key={siteDrawings.indexOf(drawing)}>
              <a href={drawing.sitedrawingurl} target='_blank'>
                <img key={drawing.id} src={drawing.sitedrawingurl} className="site-drawing"/>
              </a>
              <span>{drawing.name}</span>
            </div>
          )
        })}
      </div>

      {displayDeleteConfirmationModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Delete Site</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={cancelDelete} />
            </div>
        
            <div className="modal-content">
              <div className="inner">
                <p>Are you sure you want to delete this site? Deleting can't be undone.</p>
              </div>
              <div className="modal-actions add-action">
                <div className="button standard red add-site-submit" onClick={() => confirmDeleteSite(site)} >
                  Delete Site
                </div>
              </div>
            </div>
          </>
        </ModalView>
      )}  

      {displayTransferSitesModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Transfer Site</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeTransferSiteModal}/>
            </div>

            <div className="modal-content">
              <div className="inner">

                {hasOrganisationsToTransferTo ? (
                  <>
                    <p className="message">Select a business to transfer this site to.</p>

                    <div className="form">
                      <div className="row row-animated">
                        <FormValidatedSelect 
                          type='text' 
                          name='selectedAccount' 
                          options={organisations} 
                          value={organisationId} 
                          allowNullValue={true} 
                          didValidate={didValidate} 
                          labelText='Business'
                          errorMessage={errorMessageOrganisation} 
                          showErrorMessage={showErrorMessageOrganisation}
                          onChange={changeOrganisation}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p>You can only transfer sites to other businesses where you are an owner or administrator.</p>
                )}
              </div>

              <div className="modal-actions add-action">
                {hasOrganisationsToTransferTo ? (
                  <div className="button standard blue add-site-submit" onClick={moveSite} >
                    Transfer
                  </div>
                ) : (
                  <div className="button standard blue add-site-submit" onClick={closeTransferSiteModal}>
                    OK
                  </div>
                )}
              </div>
            </div>
          </>
        </ModalView>
      )}

      {displayTransferSitesToUserModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Transfer Site</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeTransferSiteToUserModal} />
            </div>
        
        
            <div className="modal-content">
              <div className="inner">
        
                {hasOrganisationsToTransferTo ? (
                  <>
                    <p className="message">Transfer site to another user using their email address.</p>
          
                    <div className="form">
                      <div className="row row-animated">
                        <FormValidatedInput 
                          type='text' 
                          name='selectedAccount' 
                          value={emailaddress} 
                          disabled={inputsDisabled} 
                          didValidate={didValidate} 
                          labelText='Email address'
                          onChange={e => setEmailaddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Transfer site to another user using their email address.</p>
                )}
              </div>
        
              <div className="modal-actions add-action">
                {hasOrganisationsToTransferTo ? (
                  <div className="button standard blue add-site-submit" onClick={transferSiteOwner} >
                    Transfer
                  </div>
                ) : (
                  <div className="button standard blue add-site-submit" onClick={closeDisplayTransferSitesToUserModal}>
                    OK
                  </div>
                )}
              </div>
            </div>
          </>
        </ModalView>
      )}
    </div>
  )
}