import React, { useState, useEffect, useContext } from 'react';
import icon_add_white from '../assets/images/icon-add-white.svg';
import EmploymentTable from './components/EmploymentTable';
import ModalView from './components/ModalView';
import icon_close from '../assets/images/icon-close.svg';
import InviteUserForm from './components/forms/InviteUserForm';
import { getEmployments } from '../api/employmentApi';

export default ({children, currentOrg, infinityLoad, isQueryPending}) => {

  const [displayInviteUserModal, setDisplayInviteUserModal] = useState(false);
  const [employments, setEmployments] = useState([]);

  const showInviteUserModal = () => {
    setDisplayInviteUserModal(true);
  }

  const closeInviteUserModal = () => {
    setDisplayInviteUserModal(false);
  }

  const sentInvites = () => {
    setDisplayInviteUserModal(false);
    updateEmployments();
  }

  const updateEmployments = () => {
    const filters = '?include=invite,user&currentOrganisationId=' + currentOrg + '&page[number]=1&page[size]=100';
    getEmployments(currentOrg, filters).then(response => {
      let employments = [];
      response.data.forEach(employment => {
        if (employment.relationships.user.data) {
          const userId = employment.relationships.user.data.id;
          response.included.forEach(inc => {
            if (inc.type === 'users' && inc.id === userId) {
              employment.fullname = inc.attributes['first-name'] + ' ' + inc.attributes['last-name'];
            }
          })
          employments.push({user: employment});
        } else if (employment.relationships.invite.data) {
          const inviteId = employment.relationships.invite.data.id;
          response.included.forEach(inc => {
            if (inc.type === "invites" && inc.id === inviteId) {
              employment.email = inc.attributes['email'];
            }
          })
          employments.push({invite: employment});
        }
      })
      setEmployments(employments);
    })
  }

  const cancelInvite = () => {
    updateEmployments();
  }

  useEffect(() => {
    if (currentOrg) {
      updateEmployments();
    }
  }, [currentOrg])


  return (
    <div>
      {children}

      <div id="users" className="page main-page">
        <div className="header">
          <h2>Users</h2>
          <div className="page-header-actions">
            <button className="action button icon green" onClick={showInviteUserModal}>
              <img className="icon" src={icon_add_white} alt="Add Icon"/>
              Invite User
            </button>
          </div>
        </div>
        <EmploymentTable 
          employments={employments}
          isQueryPending={isQueryPending}
          infinityLoad={infinityLoad}
          showUserDetail='showUserDetail'
          onCancelInvite={cancelInvite}
          changeUserType='changeUserType'

        />
      </div>

      {displayInviteUserModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Invite User</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeInviteUserModal} />
            </div>

            <div className="modal-content">
              <InviteUserForm currentOrg={currentOrg} isQueryPending={isQueryPending} onSentInvites={sentInvites}/>
            </div>
          </>
        </ModalView>
      )}
    </div>
  )
}