import { BASE_URL, API_VERSION, ORGANISATIONS, EMPLOYMENTS } from "../settings/apiUrls";
import api from "./api";

export const getEmployments = (organisationId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + organisationId + EMPLOYMENTS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const updateEmployment = (employmentId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + EMPLOYMENTS + '/' + employmentId,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const getEmployment = (employmentId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + EMPLOYMENTS + '/' + employmentId + filters,
    }).then((response) => {
        return response.data;
    });
}

export const removeEmployment = (employmentId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + EMPLOYMENTS + '/' + employmentId,
    }).then((response) => {
        return response.data;
    });
}