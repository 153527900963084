import React, { useState, useEffect } from 'react';
import icon_close from '../../assets/images/icon-close.svg';
import { pluralizer } from '../../utils/pluralizer';

export default ({children, isQueryPending, batch, batchLength, batchName, onClose, onConfirm}) => {

  return (
    <div>
      <div className="modal-header">
        <h2 className="form-heading">Delete {pluralizer(batch.length, batchName)}</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
      </div>
      
      <div className="modal-content">
        <div className="inner">
          <p className="confirmation-message">{children}</p>
        </div>
        <div className="modal-actions add-action">
          <button className="button standard red confirm-action" disabled={isQueryPending} onClick={onConfirm}>
            Delete {pluralizer(batchLength, batchName)}
          </button>
        </div>
      </div>
    </div>
  )
}


