import axios from 'axios';

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const setupInterceptors = () => {
  api.interceptors.request.use(
    (config) => {
      const sessionToken = localStorage.getItem('sessionToken');
      const sessionId = localStorage.getItem('sessionId');

      if (sessionToken && sessionId) {
        config.headers['X-Session-Token'] = sessionToken;
        config.headers['X-Session-Id'] = sessionId;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {

      if (error.response.status === 401) {
        return Promise.reject(error.response.data.errors);
      }

      if (error.response.status === 400) {
        console.log(error);
        return Promise.reject(error);
      }

      if (error.response.status === 404) {
        return Promise.reject(error.response.data.errors);
      }

      return Promise.reject(error);
    },
  );
};

setupInterceptors();

export function addSessionToken(sessionToken, sessionId) {
  if (sessionToken && sessionId) {
    return {
      headers: { 
        'X-Session-Token': sessionToken,
        'X-Session-Id': sessionId
      },
    };
  }
}

export function createCancelToken() {
  return axios.CancelToken.source();
}

export default api;
