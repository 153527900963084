import React, { useState, useEffect, useContext } from 'react';
import AutoComplete from '../AutoComplete';
import FormValidatedInput from '../FormValidatedInput';

export default ({
    unit, 
    address, 
    businessName, 
    buildingName, 
    contactName, 
    contactEmail, 
    adminEmail, 
    isValidAddress,
    errorMessageAddress,
    showErrorMessageAddress,
    isValidBusinessName,
    errorMessageBusinessName,
    showErrorMessageBusinessName,
    inputsDisabled, 
    didValidate,
    searchResults,
    onClickResult,
    onChangeUnit,
    onChangeAddress,
    onChangeBusinessName,
    onChangeBuildingName,
    onChangeContactName,
    onChangeContactEmail,
    onChangeAdminEmail
  }) => {

  return (
    <>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='unit' value={unit} disabled={inputsDisabled} didValidate={didValidate} labelText='Unit/Level' onChange={onChangeUnit}/>
      </div>
      <div className="row row-animated autocomplete">
        <FormValidatedInput type='text' name='address' value={address} disabled={inputsDisabled} didValidate={didValidate} labelText='Address' errorMessage={errorMessageAddress} showErrorMessage={showErrorMessageAddress} onChange={onChangeAddress}>
          <AutoComplete searchResults={searchResults} onClickResult={onClickResult} />
        </FormValidatedInput>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='businessName' value={businessName} disabled={inputsDisabled} didValidate={didValidate} labelText='Business Name' errorMessage={errorMessageBusinessName} showErrorMessage={showErrorMessageBusinessName} onChange={onChangeBusinessName}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='buildingName' value={buildingName} disabled={inputsDisabled} didValidate={didValidate} labelText='Building Name' onChange={onChangeBuildingName}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='contactName' value={contactName} disabled={inputsDisabled} didValidate={didValidate} labelText='Contact Name' onChange={onChangeContactName}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='contactEmail' value={contactEmail} disabled={inputsDisabled} didValidate={didValidate} labelText='Contact Email' onChange={onChangeContactEmail}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='adminEmail' value={adminEmail} disabled={inputsDisabled} didValidate={didValidate} labelText='Admin Email' onChange={onChangeAdminEmail}/>
      </div>
    </>
  )
}


