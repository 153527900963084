import React, { useState, useEffect } from 'react';
import illus_groups from '../../assets/images/illus-groups.svg';
import icon_add_white from '../../assets/images/icon-add-white.svg';
import groups_grey from '../../assets/icons/groups-grey.svg';
import groups_blue from '../../assets/icons/groups-blue.svg';
import delete_icon from '../../assets/icons/delete.svg';
import icon_arrow_right_grey from '../../assets/images/icon-arrow-right-grey.svg';
import icon_arrow_right_navy from '../../assets/images/icon-arrow-right-navy.svg';
import LoadMoreButton from './LoadMoreButton';
import moment from 'moment';

export default ({groups, groupTotal, selectedGroups, isDeletingGroups, onAddGroup, onSelectGroup, onDeleteGroups, onToggleDeleteGroups, onShowGroupDetails, onLoadMore}) => {

  return (
    <div id="group-table" className="table">
      <table>
        <thead>
          <tr className="row">
            <th className="table-row">
              <div className="name column heading">
                <div className="inner">
                  Name
                </div>
              </div>
              <div className="fittings column heading">
                <div className="inner">
                  Fittings
                </div>
              </div>
              <div className="faults column heading">
                <div className="inner">
                  Faults
                </div>
              </div>
              <div className="created-at column heading">
                <div className="inner">
                  Created At
                </div>
              </div>
              <div className="column row-indicator"/>
            </th>
          </tr>
        </thead>
    
        <tbody className='multiselect-checkboxes'>
          {groups.map(group => (
            <tr key={groups.indexOf(group)} className="row" onClick={!isDeletingGroups && (() => onShowGroupDetails(group))} >
              <td className="table-row">
                <div className='column name'>
                  {isDeletingGroups ? (
                    <>
                      <input type="checkbox" id={group.id} checked={group.checked} onClick={() => onSelectGroup(group)} />
                      <label htmlFor={group.id}></label>
                    </>
                  ) : (
                    <>
                      <img src={groups_grey} className='icon-groups icon-groups-grey' alt="Group icon grey"/>
                      <img src={groups_blue} className='icon-groups icon-groups-blue' alt="Group icon blue"/>
                    </>
                  )}
                  <div className="truncated">
                    {group.attributes['name']}
                  </div>
                </div>
                <div className='column fittings'>{group.attributes['fittings-count']}</div>
                <div className='column faults'>{group.attributes['faulty-fittings-count']}</div>
                <div className='column created-at'>{moment(group.attributes['created-at']).format('hh:mma DD/MM/YYYY')}</div>
                <div className="column row-indicator">
                  <div className="arrow-right-grey">
                    <img src={icon_arrow_right_grey} alt="Row Icon"/>
                  </div>
                  <div className="arrow-right-grey-blue">
                    <img src={icon_arrow_right_navy} alt="Blue Row Icon"/>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {groups.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_groups} alt="Blue Row Icon"/>
          </div>
          <div className="empty-message">
            To start populating this site, click on the add groups button below.
          </div>
          <div className="empty-actions">
            <button className="action button icon green" onClick={onAddGroup}>
              <img className="icon" src={icon_add_white} alt="Add Icon"/>
              Add Group
            </button>
          </div>
        </div>
      ) : (
        <div className="table-actions">
          <div className="left">
            <LoadMoreButton 
              canLoad={groups.length < groupTotal} 
              loadText='Load More Groups' 
              numberOfItemsDisplayedText={`(Showing ${groups.length} of ${groupTotal})`} 
              onLoadMore={onLoadMore}
            />
          </div>
    
          <div className="right">
            {isDeletingGroups ? (
              <>
                <button className="button blue standard cancel" onClick={onToggleDeleteGroups}>
                  Cancel
                </button>
                <button className="button red delete-multiple-groups" disabled={selectedGroups.length === 0} onClick={onDeleteGroups} >
                  Delete Groups
                </button>
              </>
            ) : (
              <>
                <div className="button small text-red delete-groups" onClick={onToggleDeleteGroups} >
                  <img className='icon' src={delete_icon} alt="Delete Icon"/>
                  Delete Groups
                </div>
                <button className="action button icon green" onClick={onAddGroup}>
                  <img className="icon" src={icon_add_white} alt="Add Icon"/>
                  Add Group
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}