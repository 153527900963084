import { BASE_URL, USERS, ME, API_VERSION, SITES, GROUPS, ORGANISATIONS, SITE_DRAWINGS, EXPORT_SITES } from "../settings/apiUrls";
import api from "./api";

export const getSites = (filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + USERS + ME + SITES + filters,
    }).then((response) => {
        return response.data;
    });
}

export const getSitesById = (siteId, filter) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + siteId + SITES + filter,
    }).then((response) => {
        return response.data;
    });
}

export const getSite = (siteId) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + '/' + siteId,
    }).then((response) => {
        return response.data;
    });
}

export const updateSite = (siteId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + SITES + '/' + siteId,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const addSite = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SITES,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeSite = (siteId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + SITES + '/' + siteId,
    }).then((response) => {
        return response;
    });
}

export const getSiteGroups = (siteId) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + "/" + siteId + GROUPS,
    }).then((response) => {
        return response.data;
    });
}

export const postSiteDrawing = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SITE_DRAWINGS,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
        return response.data;
    });
}

export const getSiteDrawings = (siteId) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SITE_DRAWINGS + "?showall=true&siteid=" + siteId,
    }).then((response) => {
        return response.data;
    });
}

export const exportSites = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + EXPORT_SITES,
        data: data,
    }).then((response) => {
        return response.data;
    });
}