import React, { useState, useEffect, useContext } from 'react';
import FormValidatedInput from '../FormValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';
import icon_add_white from '../../../assets/images/icon-add-white.svg';
import { validateDate, validateLabel, validateDuration } from '../../../utils/validators';
import { durationOptions, viewOptions } from '../../../utils/variables';
import moment, { duration } from 'moment';
import { store } from '../../../utils/store';
import { updateTestEvent } from '../../../api/testEventApi';

export default ({testEvent, isQueryPending, isCreatingTestEvent, onUpdateTestEvent, onSubmit}) => {

  const { dispatch } = useContext(store);

  const [didValidate, setDidValidate] = useState(false);
  
  const [viewOption, setViewOption] = useState('');

  // Label
  const [label, setLabel] = useState('');
  const [errorMessageLabel, setErrorMessageLabel] = useState('');
  const [showErrorMessageLabel, setShowErrorMessageLabel] = useState(false);

  // Test date
  const [testDate, setTestDate] = useState('');
  const [errorMessageTestDate, setErrorMessageTestDate] = useState('');
  const [showErrorMessageTestDate, setShowErrorMessageTestDate] = useState(false);

  // Next test date
  const [nextTestDate, setNextTestDate] = useState('');
  const [errorMessageNextTestDate, setErrorMessageNextTestDate] = useState('');
  const [showErrorMessageNextTestDate, setShowErrorMessageNextTestDate] = useState(false);

  // Duration
  const [requiredDuration, setRequiredDuration] = useState('');
  const [errorMessageDuration, setErrorMessageDuration] = useState('');
  const [showErrorMessageDuration, setShowErrorMessageDuration] = useState(false);

  const checkLabel = (input) => {
    const validateResult = validateLabel(input);
    if (validateResult.validated) {
      setShowErrorMessageLabel(false);
      setErrorMessageLabel('');
      return true;
    } else {
      setShowErrorMessageLabel(true);
      setErrorMessageLabel(validateResult.message);
      return false;
    }
  }

  const changeTestDate = (value) => {
    checkTestDate(value);
    setTestDate(value);
  }

  const checkTestDate = (input) => {
    const validateResult = validateDate(input);
    if (validateResult.validated) {
      setShowErrorMessageTestDate(false);
      setErrorMessageTestDate('');
      return true;
    } else {
      setShowErrorMessageTestDate(true);
      setErrorMessageTestDate(validateResult.message);
      return false;
    }
  }

  const checkDuration = (value) => {
    const validateResult = validateDuration(value);
    if (validateResult.validated) {
      setShowErrorMessageDuration(false);
      setErrorMessageDuration('');
      return true;
    } else {
      setShowErrorMessageDuration(true);
      setErrorMessageDuration(validateResult.message);
      return false;
    }
  }

  const changeDuration = (e) => {
    checkDuration(e.target.value);
    setRequiredDuration(e.target.value);
  }

  const changeNextTestDate = (value) => {
    checkNextTestDate(value);
    setNextTestDate(value);
  }

  const checkNextTestDate = (input) => {
    const validateResult = validateDate(input);
    if (validateResult.validated) {
      setShowErrorMessageNextTestDate(false);
      setErrorMessageNextTestDate('');
      return true;
    } else {
      setShowErrorMessageNextTestDate(true);
      setErrorMessageNextTestDate(validateResult.message);
      return false;
    }
  }

  const changeLabel = (e) => {
    checkLabel(e.target.value);
    setLabel(e.target.value);
  }

  const submit = (e) => {
    e.preventDefault();
    const validDuration = checkDuration(requiredDuration);
    const validNextTestDate = checkNextTestDate(nextTestDate);
    if (!validDuration || !validNextTestDate) {
      return;
    }

    let data = {
      data: testEvent
    };
    data.data.attributes['label'] = label;
    data.data.attributes['default-test-date'] = moment(testDate).format('YYYY-MM-DD');
    data.data.attributes['default-required-duration'] = requiredDuration;
    data.data.attributes['nexttestdate'] = moment(nextTestDate).format('YYYY-MM-DD');
    data.data.attributes['viewb'] = viewOption;
    updateTestEvent(testEvent.id, data).then(response => {
      onUpdateTestEvent(response.data);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Test Event Template Updated'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    }).catch(error => {
      dispatch({ type: 'SET POPUP', payload: 'danger' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: [error.response.data.errors[0]] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const submitNewTestEvent = (e) => {
    e.preventDefault();
    const validDuration = checkDuration(requiredDuration);
    const validNextTestDate = checkNextTestDate(nextTestDate);
    if (!validDuration || !validNextTestDate) {
      return;
    }
    onSubmit(label, testDate, nextTestDate, requiredDuration, viewOption);
  }

  useEffect(() => {
    if (testEvent) {
      setLabel(testEvent.attributes['label']);
      if (testEvent.attributes['default-test-date']) {
        setTestDate(new Date(moment(testEvent.attributes['default-test-date'])));
      }
      setRequiredDuration(testEvent.attributes['default-required-duration']);
      if (testEvent.attributes['nexttestdate']) {
        setNextTestDate(new Date(moment(testEvent.attributes['nexttestdate'])));
      }
      setViewOption(testEvent.attributes['viewb']);
    }
  }, [testEvent])

  useEffect(() => {
    if (!nextTestDate && testDate) {
      const newNextTestDate = new Date(moment(testDate).add(182, 'days'));
      setNextTestDate(newNextTestDate);
      checkNextTestDate(newNextTestDate);
    }
  }, [testDate])

  return (
    <form id="quick-test-defaults-form" className="form">
      <div className="form-content">
    
        <div className="row row-animated required-duration-row">
          <FormValidatedInput type='text' name='defaultLabel' value={label} didValidate={didValidate} labelText='Label' errorMessage={errorMessageLabel} showErrorMessage={showErrorMessageLabel} onChange={changeLabel}/>
        </div>
        <div className="row row-animated test-date-row">
          <FormValidatedInput type='date' name='defaultTestDate' value={testDate} didValidate={didValidate} labelText='Test Date' errorMessage={errorMessageTestDate} showErrorMessage={showErrorMessageTestDate} onChange={changeTestDate}/>
        </div>
    
        <div className="row row-animated required-duration-row">
          <FormValidatedSelect type='text' name='defaultRequiredDuration' options={durationOptions} value={requiredDuration} didValidate={didValidate} errorMessage={errorMessageDuration} showErrorMessage={showErrorMessageDuration} labelText='Required Duration' onChange={changeDuration} />
        </div>
        <div className="row row-animated test-date-row">
          <FormValidatedInput type='date' name='updatenexttestdate' value={nextTestDate} didValidate={didValidate} labelText='Next Test Date' errorMessage={errorMessageNextTestDate} showErrorMessage={showErrorMessageNextTestDate} onChange={changeNextTestDate}/>
        </div>
    
        <div className="row row-animated required-duration-row">
          <FormValidatedSelect type='text' name='viewb' options={viewOptions} value={viewOption} didValidate={didValidate} labelText='View' onChange={e => setViewOption(e.target.value)} />
        </div>
      </div>
      <div className="form-actions">
        {isCreatingTestEvent ? (
          <>
            <button className="button standard blue" disabled={isQueryPending} onClick={submitNewTestEvent} >
              <img className="icon" src={icon_add_white} alt="Alert Icon" />
              Add Logbook
            </button>
          </>
        ) : (
          <button className="button standard blue" disabled={isQueryPending} onClick={submit} >Save</button>
        )}
      </div>
    </form>
  )
}