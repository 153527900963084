import React, { useState, useEffect } from 'react';
import group_dropdown_menu_trigger from '../../assets/icons/group-dropdown-menu-trigger.svg';
import DeleteGroup from '../groups/fittings/DeleteGroup';
import EditGroup from '../groups/fittings/EditGroup';

export default ({fittings, group, canDeleteGroup, isShoppingCartEnabled, hasNoFittings, onUpdateGroup, onToggleMoveFittings, onDeleteGroup, onDeleteFittings}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isShowingEditGroup, setIsShowingEditGroup] = useState(false);
  const [isShowingDeleteGroup, setIsShowingDeleteGroup] = useState(false);

  const trigger = () => {
    setIsOpen(true);
  }

  const hideMenu = () => {
    setIsOpen(false);
  }

  const toggleMoveFittings = () => {
    setIsOpen(false);
    onToggleMoveFittings();
  }

  const showOrderReplacements = () => {

  }

  const showEditGroupName = (e) => {
    e.preventDefault();
    setIsShowingEditGroup(true);
    setIsOpen(false);
  }

  const closeEditGroup = () => {
    setIsShowingEditGroup(false);
  }

  const showDeleteGroup = () => {
    setIsShowingDeleteGroup(true);
    setIsOpen(false);
  }

  const closeDeleteGroup = () => {
    setIsShowingDeleteGroup(false);
  }

  return (
    <div className='dropdown-menu group-dropdown-menu' tabIndex="9999" onBlur={hideMenu}>
      <div className='dropdown-menu-trigger' onClick={trigger}>
        <img src={group_dropdown_menu_trigger} className='drop-trig' alt="Group icon blue"/>
      </div>

      {isOpen && (
        <div className="dropdown-menu-list">

          {fittings && fittings.length > 0 ? (
            <div className="dropdown-menu-list-item label move-fittings" disabled={false} onClick={toggleMoveFittings}>
              Move Fittings
            </div>
          ) : (
            <button className="dropdown-menu-list-item label move-fittings" disabled={true} >
              Move Fittings
            </button>
          )}
      
          <div className='dropdown-menu-list-item label edit edit-group' onClick={showEditGroupName}>
            Edit Group Name
          </div>
      
          {isShoppingCartEnabled && (
            <div className="dropdown-menu-list-item label order-replacements" disabled={hasNoFittings} onClick={showOrderReplacements}>
              Order Replacements
            </div>
          )}
      
          <div className='dropdown-menu-list-item label delete delete-group' onClick={showDeleteGroup}>
            Delete Group
          </div>

          {fittings && fittings.length > 0 ? (
            <div className="dropdown-menu-list-item label delete delete-fittings" disabled={false} onClick={onDeleteFittings}>
              Delete Fittings
            </div>
          ) : (
            <button className="dropdown-menu-list-item label delete delete-fittings" disabled={true} >
              Delete Fittings
            </button>
          )}
      
        </div>
      )}

      {isShowingEditGroup && (
        <EditGroup group={group} onClose={closeEditGroup} onUpdateGroup={onUpdateGroup}/>
      )}

      {isShowingDeleteGroup && (
        <DeleteGroup group={group} canDeleteGroup={canDeleteGroup} onDelete={onDeleteGroup} onClose={closeDeleteGroup}/>
      )}
    </div>
  )
}
