import { BASE_URL, API_VERSION, SITES, FAULTS } from "../settings/apiUrls";
import api from "./api";

export const getFaults = (siteId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + "/" + siteId + FAULTS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const addFault = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + FAULTS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}