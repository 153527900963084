import React, { useState, useEffect } from 'react';
import icon_close from '../../assets/images/icon-close.svg';
import { pluralizer } from '../../utils/pluralizer';
import FormValidatedSelect from './FormValidatedSelect';

export default ({children, isQueryPending, batchLength, groupOptions, selectedGroup, errorMessageMoveGroup, showErrorMessageMoveGroup, didValidate, onChange, onClose, onConfirm}) => {

  return (
    <div>
      <div className="modal-header">
        <h2 className="form-heading">Move {pluralizer(batchLength, 'Fitting')}</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon"  onClick={onClose}/>
      </div>
      
      <div className="modal-content">
        <div className="inner">
          <p className="confirmation-message">{children}</p>
      
          <div className="form">
            <div className="row row-animated">
              <FormValidatedSelect type='text' name='selectedGroup' options={groupOptions} value={selectedGroup} errorMessage={errorMessageMoveGroup} showErrorMessage={showErrorMessageMoveGroup} didValidate={didValidate} labelText='Group' onChange={onChange} />
            </div>
          </div>
        </div>
      
        <div className="modal-actions add-action">
          <button className="button standard blue add-site-submit confirm-action" disabled={isQueryPending} onClick={onConfirm}>
            Move {pluralizer(batchLength, 'Fitting')}
          </button>
        </div>
      </div>
    </div>
  )
}


