import moment from "moment"

export const validateEmail = (email) => {
    if (!email) return {
        validated: false,
        message: 'Please enter your email address'
    } 
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        return {
            validated: true,
            message: ''
        }
    }
    return {
        validated: false,
        message: 'Please enter a valid email address'
    }
}

export const validateCurrentPassword = (password) => {
    if (!password) return {
        validated: false,
        message: 'Please enter your current password'
    } 
    if (password.length < 8){
        return {
            validated: false,
            message: 'Password must be at least 8 characters long'
        }
    }
    return {
        validated: true,
        message: ''
    }
}

export const validatePassword = (password) => {
    if (!password) return {
        validated: false,
        message: 'Please enter your password'
    } 
    if (password.length < 8){
        return {
            validated: false,
            message: 'Password must be at least 8 characters long'
        }
    }
    return {
        validated: true,
        message: ''
    }
}

export const validatePasswordConfirmation = (password, passwordConfirmation) => {
    if (!passwordConfirmation) return {
        validated: false,
        message: 'Please confirm your password'
    } 
    if (password !== passwordConfirmation){
        return {
            validated: false,
            message: 'Your passwords don’t match, please try again'
        }
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateFirstName = (firstName) => {
    if (!firstName) return {
        validated: false,
        message: 'Please enter your first name'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateLastName = (lastName) => {
    if (!lastName) return {
        validated: false,
        message: 'Please enter your last name'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateNumber = (number) => {
    if (!number) return {
        validated: false,
        message: 'Please enter your mobile number'
    } 
    if (/^\d+$/.test(number)){
        return {
            validated: true,
            message: ''
        }
    }
    return {
        validated: false,
        message: 'Phone number must only include numbers'
    }
}

export const validateNumberOnly = (number) => {
    if (/^\d+$/.test(number) || !number){
        return {
            validated: true,
            message: ''
        }
    }
    return {
        validated: false,
        message: 'Phone number must only include numbers'
    }
}

export const validateCountry = (country) => {
    if (!country) return {
        validated: false,
        message: 'Please select your country'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateState = (state) => {
    if (!state) return {
        validated: false,
        message: 'Please select your state'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateBusinessName = (name) => {
    if (!name) return {
        validated: false,
        message: 'Please enter your business name'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateAddress = (address, selected) => {
    if (!address) return {
        validated: false,
        message: 'Please enter an address'
    }
    if (!selected) return {
        validated: false,
        message: 'Please select a valid address from the dropdown'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateSerialNumber = (serialNumber) => {
    if (serialNumber.length > 8) return {
        validated: false,
        message: 'Serial number is too long, must be less than 8 characters'
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateTestEvent = (testEvent) => {
    if (!testEvent) return {
        validated: false,
        message: "Please select a logbook"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateGroup = (group) => {
    if (!group) return {
        validated: false,
        message: "Couldn't find group"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateGroups = (groups) => {
    if (groups.length === 0) return {
        validated: false,
        message: "Please select groups"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateGroupName = (group) => {
    if (!group) return {
        validated: false,
        message: "Please enter a group name"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateGroupNone = (group) => {
    if (!group) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateDeviceReference = (deviceReference) => {
    if (!deviceReference) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateLabel = (label) => {
    if (!label) return {
        validated: false,
        message: "Please enter a label"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateDate = (date) => {
    if (!date) return {
        validated: false,
        message: "Please enter a date"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateStartDate = (date) => {
    if (!date) return {
        validated: false,
        message: "Please enter a start date"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateDuration = (date) => {
    if (!date) return {
        validated: false,
        message: "Please select a duration"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateEndDate = (startDate, endDate) => {
    if (!endDate) return {
        validated: false,
        message: "Please enter an end date"
    }
    if (!(moment(startDate) < moment(endDate))) return {
        validated: false,
        message: "End date must be after start date"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateOrganisation = (organisation) => {
    if (!organisation) return {
        validated: false,
        message: "Please select a business"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateTestStatus = (testStatus) => {
    if (!testStatus) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateTestDate = (date) => {
    if (!date) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateRepairDate = (date) => {
    if (!date) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateInstallDate = (date) => {
    if (!date) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateRepaired = (repair) => {
    if (!repair) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateSelectedAccount = (account) => {
    if (!account) return {
        validated: false,
        message: "This field can't be blank"
    }
    return {
        validated: true,
        message: ''
    }
}

export const validateRunTime = (runTime) => {
    if (/^\d+$/.test(runTime) && runTime > 254){
        return {
            validated: false,
            message: 'This field must be less than or equal to 254'
        }
    }
    if ((/^\d+$/.test(runTime) && runTime < 255) || !runTime){
        return {
            validated: true,
            message: ''
        }
    }
    return {
        validated: false,
        message: 'This field must be a number'
    }
}