import React, { useState, useEffect, useContext } from 'react';

export default ({name, labelText, value, placeholder, errorMessage, warningMessage, showErrorMessage, isValid, showPasswordToggle, showWarningMessage, onChange}) => {

  const [focus, setFocus] = useState(false);
  const [showPasswordText, setShowPasswordText] = useState(true);

  const toggleShowPassword = () => {
    setShowPasswordText(!showPasswordText);
  }

  return (
    <div className={`input${focus || value ? ' focus' : ''}`}>
      <label htmlFor={name} className="label label-animated">{labelText}</label>
      <input 
        name={name} 
        type={showPasswordText && showPasswordToggle ? 'password' : 'text'} 
        value={value} 
        placeholder={placeholder} 
        autoComplete='new-password' 
        className={(showErrorMessage ? 'error' : '') + ' ' + (isValid ? 'valid' : '')} 
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          if (!value) setFocus(false);
        }}
      />
      
      {showPasswordToggle && value.length !== 0 && (
        <p className="password-toggle" onClick={toggleShowPassword}>{showPasswordText ? 'Show' : 'Hide'}</p>
      )}

      {isValid && <span className='valid-input fa fa-check'></span>}

      <div className='input-error'>
        {showErrorMessage && (
          <div className='error'>
            {errorMessage}
          </div>
        )}

        {showWarningMessage && (
          <div className='warning'>
            {warningMessage}
          </div>
        )}
      </div>
    </div>
  )
}


