import React, { useState, useEffect } from 'react';
import { updateGroup } from '../../../api/groupApi';
import icon_close from '../../../assets/images/icon-close.svg';
import { validateGroupName } from '../../../utils/validators';
import GroupForm from '../../components/forms/GroupForm';
import ModalView from '../../components/ModalView';

export default ({group, isQueryPending, onClose, onUpdateGroup}) => {

  // Group name
  const [groupName, setGroupName] = useState('');
  const [errorMessageGroupName, setErrorMessageGroupName] = useState('');
  const [showErrorMessageGroupName, setShowErrorMessageGroupName] = useState(false);

  const save = (e) => {
    e.preventDefault();
    let validatedGroupName = checkGroupName(groupName);
    if (validatedGroupName && group) {
      const data = {
        data: {
          id: group.id,
          attributes: {
            "name": groupName,
            "fittings-count": group.attributes['fittings-count'],
            "faulty-fittings-count": group.attributes['faulty-fittings-count'],
            "created-at": group.attributes['created-at'],
            "commissioned-fittings-count": group.attributes['commissioned-fittings-count'],
            "not-commissioned-fittings-count": group.attributes['not-commissioned-fittings-count']
          },
          relationships: {
            site: group.relationships.site
          },
          type: "groups"
        }
      };
      updateGroup(group.id, data).then(response => {
        setErrorMessageGroupName('');
        setShowErrorMessageGroupName('');
        onClose();
        onUpdateGroup(groupName);
      })
    }
  }

  const checkGroupName = (input) => {
    const validateResult = validateGroupName(input);
    if (validateResult.validated) {
      setShowErrorMessageGroupName(false);
      setErrorMessageGroupName('');
    } else {
      setShowErrorMessageGroupName(true);
      setErrorMessageGroupName(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeGroupName = (e) => {
    setGroupName(e.target.value)
    checkGroupName(e.target.value)
  }

  useEffect(() => {
    if (group) {
      setGroupName(group.attributes['name']);
    }
  }, [group])

  return (
    <ModalView>
      <>
        <div className="modal-header" style={{textAlign: 'left'}}>
          <h2 className="form-heading" style={{textTransform: 'none', letterSpacing: 0}}>Edit Group Name</h2>
          <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
        </div>

        <div className="modal-content">
          <GroupForm group={groupName} showErrorMessage={showErrorMessageGroupName} errorMessage={errorMessageGroupName} onSaveGroup={save} canEdit={true} isQueryPending={isQueryPending} onChange={changeGroupName}/>
        </div>
      </>
    </ModalView>
  )
}
