import React, { useState, useEffect, useContext } from 'react';
import { addGroupsToTestEvent } from '../../../api/testEventApi';
import icon_alert from '../../../assets/images/icon-alert.svg';
import { store } from '../../../utils/store';

export default ({testEvent, missingGroups, showErrorMessage, isQueryPending, onAddGroups}) => {

  const { dispatch } = useContext(store);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [addGroupsOptions, setAddGroupsOptions] = useState([]);

  const changeSelectedGroups = (group) => {
    let addGroupsOptionsNew = JSON.parse(JSON.stringify(addGroupsOptions));
    if (group.id === 'all') {
      let allSelected = false;
      addGroupsOptionsNew.forEach(option => {
        if (option.id === 'all') {
          allSelected = option.checked
        }
      })
      addGroupsOptionsNew.forEach(option => {
        option.checked = !allSelected;
      })
    } else {
      addGroupsOptionsNew.forEach(option => {
        if (option.id === group.id) {
          if (option.checked) {
            option.checked = false;
          } else {
            option.checked = true;
          }
        }
      })
    }
    setAddGroupsOptions(addGroupsOptionsNew);
  }

  const addGroups = () => {
    const data = {
      data: selectedGroups
    }
    addGroupsToTestEvent(testEvent.id, data).then(response => {
      onAddGroups();
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Groups Added'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  useEffect(() => {
    let addGroupsOptions = [{
      label: 'All Groups',
      id: 'all',
      checked: false
    }];
    missingGroups.forEach(group => {
      addGroupsOptions.push({
        label: group.attributes['name'],
        id: group.id,
        checked: false
      })
    })
    setAddGroupsOptions(addGroupsOptions);
  }, [missingGroups])

  useEffect(() => {
    let selectedGroups = [];
    addGroupsOptions.forEach(groupOption => {
      if (groupOption.id !== 'all' && groupOption.checked) {
        selectedGroups.push({
          id: groupOption.id,
          type: 'groups'
        })
      }
    })
    setSelectedGroups(selectedGroups);
  }, [addGroupsOptions])

  return (
    <div className="test-event-groups-form form">
      <div className="form-instruction">
        Select a group(s) that you would like to <span className="boldFont">add</span> to this test event.
      </div>
      <div className={`form-content ${showErrorMessage && 'validation-error'}`}>
        <div id="groups-list" className="table">
          <table>
            <tbody className="multiselect-checkboxes">
            {addGroupsOptions.map(group => (
              <tr key={addGroupsOptions.indexOf(group)}>
                <td className='column group-name selecting-groups'>
                  <input type="checkbox" id={group.id} checked={group.checked} onChange={() => changeSelectedGroups(group)}/>
                  <label htmlFor={group.id}></label>
                  <div className="truncated">
                    {group.label}
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      {showErrorMessage && (
        <div className='error'>
          <img src={icon_alert} alt="Alert Icon" />
          Please select one or more groups.
        </div>
      )}
      <div className="form-actions">
        <button className="button small blue" type="button" disabled={isQueryPending} onClick={addGroups} >
          Add Groups
        </button>
      </div>
    </div>
  )
}