import { BASE_URL, API_VERSION, TESTS } from "../settings/apiUrls";
import api from "./api";

export const addTest = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + TESTS,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const removeTest = (testId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + TESTS + '/' + testId,
    }).then((response) => {
        return response.data;
    });
}

export const updateTest = (testId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + TESTS + '/' + testId,
        data: data
    }).then((response) => {
        return response.data;
    });
}