import { BASE_URL, API_VERSION, TEMPLATES } from "../settings/apiUrls";
import api from "./api";

export const addTemplate = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + TEMPLATES,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const getTemplates = () => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + TEMPLATES + '/1?user_id=1',
    }).then((response) => {
        return response.data;
    });
}

export const removeTemplate = (templateId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + TEMPLATES + '/' + templateId,
    }).then((response) => {
        return response;
    });
}