import { BASE_URL, API_VERSION, WHOLESALERS } from "../settings/apiUrls";
import api from "./api";

export const getWholesalers = (filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + WHOLESALERS + filters,
    }).then((response) => {
        return response.data;
    });
}