import React, { useState, useEffect } from 'react';
import ModalView from './ModalView';
import moment from 'moment';
import icon_close from '../../assets/images/icon-close.svg';
import TestEventForm from './forms/TestEventForm';
import QuickTestDefaultsForm from './forms/QuickTestDefaultsForm';
import { addTestEvent } from '../../api/testEventApi';

export default ({testEvent, site, isQueryPending, onCloseModal, onAddTestEvent, onAddTestEventError}) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isShowingTestEventForm, setIsShowingTestEventForm] = useState(true);

  const submitTestEventDetails = (e) => {
    e.preventDefault();
  }

  const submitQuickTestDefaults = (label, testDate, nextTestDate, requiredDuration, viewOption) => {
    const data = {
      data: {
        attributes: {
          "label": label,
          "start-date": moment(startDate).format('yyyy-MM-DD'),
          "end-date": moment(endDate).format('yyyy-MM-DD'),
          "groups-count": null,
          "archived": false,
          "default-test-date": moment(testDate).format('yyyy-MM-DD'),
          "default-run-time": null,
          "default-required-duration": parseInt(requiredDuration),
          "nexttestdate": moment(nextTestDate).format('yyyy-MM-DD'),
          "viewb": viewOption
        },
        relationships: {
          site: {
            data: {
              type: "sites",
              id: site.id
            }
          }
        },
        type: "test-events"
      }
    }

    addTestEvent(data).then(response => {
      onAddTestEvent(response.data);
    }).catch(error => {
      onAddTestEventError(error);
    })
  }

  const next = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsShowingTestEventForm(false);
  }

  return (
    <div id='test-event-modal' className="modal-view">
      <ModalView>
        <>
          <div className="modal-header">
            <h2 className="form-heading">
              {isShowingTestEventForm ? 'Add Logbook' : 'Set Logbook Template'}
            </h2>
            <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onCloseModal} />
          </div>
        
          <div className={`modal-content ${isShowingTestEventForm && 'test-event-details'}`}>
            {isShowingTestEventForm ? (
              <TestEventForm testEvent={testEvent} onSubmit={submitTestEventDetails} onNext={next}/>
            ) : (
              <QuickTestDefaultsForm 
                testEvent={testEvent} 
                onNext={next}
                onSubmit={submitQuickTestDefaults} 
                isQueryPending={isQueryPending} 
                isCreatingTestEvent={true}
              />
            )}
          </div>
        </>
      </ModalView>
    </div>
  )
}
