import React, { useState, useEffect, useContext } from 'react';
import { updateUser } from '../../../api/userApi';
import { store } from '../../../utils/store';
import { validateCurrentPassword, validatePassword, validatePasswordConfirmation } from '../../../utils/validators';
import ChangePasswordSuccess from '../../change-password/ChangePasswordSuccess';
import AuthValidatedInput from '../AuthValidatedInput';

export default ({isQueryPending}) => {

  const { dispatch, state } = useContext(store);

  const [hasChanged, setHasChanged] = useState(false);

  // Current Password
  const [currentPassword, setCurrentPassword] = useState('');
  const [isValidCurrentPassword, setIsValidCurrentPassword] = useState(false);
  const [errorMessageCurrentPassword, setErrorMessageCurrentPassword] = useState('');
  const [showErrorMessageCurrentPassword, setShowErrorMessageCurrentPassword] = useState(false);

  // Password
  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [showErrorMessagePassword, setShowErrorMessagePassword] = useState(false);

  // Confirm Password
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isValidPasswordConfirmation, setIsValidPasswordConfirmation] = useState(false);
  const [errorMessagePasswordConfirmation, setErrorMessagePasswordConfirmation] = useState('');
  const [showErrorMessagePasswordConfirmation, setShowErrorMessagePasswordConfirmation] = useState(false);

  const [didValidate, setDidValidate] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();
    let currentPasswordValidation = false,
      passwordValidation = false,
      passwordConfirmationValidation = false;
    if (checkCurrentPassword(currentPassword)) currentPasswordValidation = true;
    if (checkPassword(password)) passwordValidation = true;
    if (checkPasswordConfirmation(passwordConfirmation)) passwordConfirmationValidation = true;

    if (currentPasswordValidation &&
      passwordValidation &&
      passwordConfirmationValidation) {
        const data = {
          data: {
            id: state.user.id,
            attributes: {
              "current-password": currentPassword,
              "password": password,
              "password-confirmation": passwordConfirmation
            },
            relationships: state.user.relationships,
            type: state.user.type
          }
        }
        updateUser(data).then(() => {
          setCurrentPassword('');
          setPassword('');
          setPasswordConfirmation('');
          setHasChanged(true);
        }).catch(error => {
          dispatch({ type: 'SET POPUP', payload: 'danger' });
          dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
          setTimeout(() => {
            dispatch({ type: 'SET POPUP', payload: '' })
          }, 3000)
        })
      }
  }

  const checkCurrentPassword = (input) => {
    const validateResult = validateCurrentPassword(input);
    if (validateResult.validated) {
      setShowErrorMessageCurrentPassword(false);
      setErrorMessageCurrentPassword('');
      setIsValidCurrentPassword(true);
    } else {
      setShowErrorMessageCurrentPassword(true);
      setErrorMessageCurrentPassword(validateResult.message);
      setIsValidCurrentPassword(false);
    }
   return validateResult.validated
  }

  const checkPassword = (input) => {
    const validateResult = validatePassword(input);
    if (validateResult.validated) {
      setShowErrorMessagePassword(false);
      setErrorMessagePassword('');
      setIsValidPassword(true);
    } else {
      setShowErrorMessagePassword(true);
      setErrorMessagePassword(validateResult.message);
      setIsValidPassword(false);
    }
    return validateResult.validated
  }

  const checkPasswordConfirmation = (input) => {
    const validateResult = validatePasswordConfirmation(password, input);
    if (validateResult.validated) {
      setShowErrorMessagePasswordConfirmation(false);
      setErrorMessagePasswordConfirmation('');
      setIsValidPasswordConfirmation(true);
    } else {
      setShowErrorMessagePasswordConfirmation(true);
      setErrorMessagePasswordConfirmation(validateResult.message);
      setIsValidPasswordConfirmation(false);
    }
    return validateResult.validated
  }

  const changeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value)
    checkCurrentPassword(e.target.value)
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
    checkPassword(e.target.value)
  }

  const changePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value)
    checkPasswordConfirmation(e.target.value)
  }

  return (
    <div>
      {hasChanged ? <ChangePasswordSuccess /> : (
        <>
          <p className="subheading">To change your password, enter your current and new password below.</p>
          <div id='change-password-form' className="form auth-form internal-auth-form">
            <div className="row row-animated current-password">
              <AuthValidatedInput type='password' name='currentPassword' value={currentPassword} isValid={isValidCurrentPassword} errorMessage={errorMessageCurrentPassword} showErrorMessage={showErrorMessageCurrentPassword} labelText='Current Password' didValidate={didValidate} showPasswordToggle onChange={changeCurrentPassword} />
            </div>

            <div className="row row-animated password">
              <AuthValidatedInput type='password' name='password' value={password} isValid={isValidPassword} errorMessage={errorMessagePassword} showErrorMessage={showErrorMessagePassword} didValidate={didValidate} showPasswordToggle labelText='Password' onChange={changePassword} />
            </div>

            <div className="row row-animated confirm-password">
              <AuthValidatedInput type='password' name='passwordConfirmation' value={passwordConfirmation} isValid={isValidPasswordConfirmation} errorMessage={errorMessagePasswordConfirmation} showErrorMessage={showErrorMessagePasswordConfirmation} didValidate={didValidate} showPasswordToggle labelText='Confirm Password' onChange={changePasswordConfirmation} />
            </div>

            <div className="actions">
              <button type="submit" className="button green large submit" disabled={isQueryPending} onClick={resetPassword}>Reset Password</button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}