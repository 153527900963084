import React, { useState, useEffect, useContext } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import ModalView from '../components/ModalView';
import icon_close from '../../assets/images/icon-close.svg';
import ReportForm from '../components/forms/ReportForm';
import ModalSuccess from '../components/ModalSuccess';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

export default ({site, sitesGroups, user, testEvents, isQueryPending, onClose, onSend}) => {

  const [sendreporttxt, setSendreporttxt] = useState('Email Report');
  const [displayReportSuccessModal, setDisplayReportSuccessModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [numPages, setNumPages] = useState(0);

  const changeSendreporttxt = (text) => {
    setSendreporttxt(text);
  }

  const closeModal = () => {
    setSendreporttxt('Email Report');
    onClose();
  }

  const reportSent = () => {
    setDisplayReportSuccessModal(true);
  }

  const cancelPreview = () => {
    setPreview(false);
    setPdfUrl('');
  }

  const previewPdf = (url) => {
    setPreview(true);
    setPdfUrl(url);
  }

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }

  return (
    <ModalView>
      <div style={preview ? {height: '85vh', width: '90vw'}: null}>
        {displayReportSuccessModal ? (
          <ModalSuccess actionText='OK' onAction={closeModal}>
            {site.attributes['admin-email'] ? `Your report was sent to ${site.attributes['admin-email']}` : `Your report was sent to ${user.attributes['email']}`}
          </ModalSuccess>
        ) : (
          <div>
            <div className="modal-header">
              <h2 className="form-heading">{preview ? 'Preview report' : 'Report'}</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={preview ? cancelPreview : closeModal}/>
            </div>

            {preview && pdfUrl ? (
              <div className='preview'>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <div key={`page_${index + 1}`}>
                      <Page pageNumber={index + 1} width={window.innerWidth * 0.9} renderTextLayer={false} renderAnnotationLayer={false} />
                      <p>Page {index + 1} of {numPages}</p>
                    </div>
                  ))}
                </Document>
              </div>
            ) : (
              <div className="modal-content wide-content">
                <ReportForm site={site} groups={sitesGroups} testEvents={testEvents} sendreporttxt={sendreporttxt} isQueryPending={isQueryPending} onPreview={previewPdf} onChangeSendreporttxt={changeSendreporttxt} onSendReport={reportSent}/>
              </div>
            )}
          </div>
        )}
      </div>
    </ModalView>
  )
}