import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import SiteRequestTable from './components/SiteRequestTable';
import { store } from '../utils/store';

export default ({children, user, currentOrg, isQueryPending, infinityLoad, onSetCurrentUser}) => {

  const { dispatch } = useContext(store);
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      dispatch({ type: 'SET SITE REQUEST', payload: location.pathname + location.search });
    }
  }, [])

  return (
    <>
      {children}
      <div id="site-requests" className="page main-page">
        <div className="header">
          <h2>Site Requests</h2>
        </div>
        <SiteRequestTable currentOrg={currentOrg} user={user} isQueryPending={isQueryPending} infinityLoad={infinityLoad} onSetCurrentUser={onSetCurrentUser} />
      </div>
    </>
  )
}


