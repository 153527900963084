import { BASE_URL, API_VERSION, INVITES, INVITE_TRANSFER, ACCEPT_TRANSFER } from "../settings/apiUrls";
import api from "./api";

export const getInvite = (inviteId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + INVITES + '/' + inviteId + filters,
    }).then((response) => {
        return response.data;
    });
}

export const sendInvites = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + INVITES,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const cancelInvites = (inviteId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + INVITES + '/' + inviteId
    }).then((response) => {
        return response.data;
    });
}

export const acceptInvite = (inviteId, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + INVITES + '/' + inviteId + '/accept',
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const getTransferInvite = (inviteId, data) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + INVITE_TRANSFER + '/' + inviteId
    }).then((response) => {
        return response.data;
    });
}

export const acceptSiteTransfer = (inviteId, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + INVITE_TRANSFER + '/' + inviteId + ACCEPT_TRANSFER,
        data: data
    }).then((response) => {
        return response.data;
    });
}