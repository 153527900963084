import React, { useState, useEffect, useContext } from 'react';
import icon_back from '../assets/images/icon-back.svg';
import PartialTerms from './partials/Terms';

const Terms = ({ children, canView }) => {

  return (
    <>
      {children}
      <div id='terms'>
        {!canView.userManagement() && (
          <div className="back-bar">
            <a href='/sites' className="back header-action-styles">
              <img className="icon icon-back large" src={icon_back} alt="Back Icon"/>
              Back to Sites
            </a>
          </div>
        )}
        <div className="content">
          <h2 className="heading">Clevertronics licence agreement</h2>
          <PartialTerms />
        </div>
      </div>
    </>
  )
}

export default Terms;
