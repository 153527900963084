import { BASE_URL, SESSIONS, API_VERSION } from "../settings/apiUrls";
import api from "./api";

export const authenticate = (email, password) => {
    var data = {
        data: {
            attributes: {
                email,
                password,
                "authentication-token": null
            },
            type: "sessions"
        }
    };
  
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SESSIONS,
        data: data,
    }).then((response) => {
        const sessionToken = response.data.data.attributes['authentication-token'];
        const sessionId = response.data.data.id;

        localStorage.setItem('sessionToken', sessionToken);
        localStorage.setItem('sessionId', sessionId);

        return response.data;
    })
}
