import React, { useState, useEffect, useContext } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon_alert from '../../assets/images/icon-alert.svg';

export default ({children, id, name, labelText, type, value, hasBlock, showErrorMessage, errorMessage, showWarningMessage, warningMessage, onChange, disabled, isTextArea, maxDate, minDate, onFocus}) => {

  const [focused, setFocused] = useState(false);

  return (
    <>
      <div id={id} className={`input ${showErrorMessage && 'validation-error'} ${(value || focused) && 'focused'} ${!disabled && 'focused-border'} ${isTextArea && 'textarea'}`} >
        <label htmlFor={name} className='label label-animated'>{labelText}</label>
        {!hasBlock && type === 'date' && (
          <DatePicker name={name} selected={value} disabled={disabled} dateFormat="yyyy-MM-dd" maxDate={maxDate} minDate={minDate} onChange={onChange} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} />
        )}
        {!hasBlock && type === 'textarea' && (
          <textarea value={value} name={name} disabled={disabled} autoComplete='off' onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} onChange={onChange}/>
        )}
        {!hasBlock && type !== 'date' && type !== 'textarea' && (
          <input type={type} value={value} name={name} disabled={disabled} autoComplete='off' onFocus={() => {setFocused(true); if (onFocus) onFocus();}} onBlur={() => setFocused(false)} onChange={onChange}/>
        )}
        {children}
      </div>
      <div className='input-error'>
        {showErrorMessage && (
          <div className='error'>
            <img src={icon_alert} alt="Alert Icon" />
            {errorMessage}
          </div>
        )}
      
        {showWarningMessage && (
          <div className='warning'>
            {warningMessage}
          </div>
        )}
      </div>
    </>
  )
}