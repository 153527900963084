import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import SearchBar from './components/SearchBar';
import icon_add from '../assets/images/icon-add-white.svg';
import icon_close from '../assets/images/icon-close.svg';
import ModalView from './components/ModalView';
import SiteFormNew from './components/forms/SiteFormNew';
import SwitchAccountModal from './components/SwitchAccountModal';
import SiteTable from './components/SiteTable';
import { getSites, addSite, getSitesById } from '../api/siteApi';
import { getPlaces } from '../api/placeApi';
import { validateAddress, validateBusinessName } from '../utils/validators';
import { store } from '../utils/store';

const Sites = ({children, sites, currentOrg, site, postOnboardingTourNames, siteTotal, onLoadMore, onAddSite, onUpdateSites, onSearch, onSort}) => {

  const { dispatch } = useContext(store);
  const location = useLocation();

  const [isQueryPending, setIsQueryPending] = useState(false);
  const [hasSites, setHasSites] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [displayChooseBusinessModal, setDisplayChooseBusinessModal] = useState(false);
  const [displayAddSiteModal, setDisplayAddSiteModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [displayOrganisations, setDisplayOrganisations] = useState([]);
  const [hasJustLoggedIn, setHasJustLoggedIn] = useState(true);
  const [didValidate, setDidValidate] = useState(true);
  const [unit, setUnit] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');

  // Address
  const [address, setAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [errorMessageAddress, setErrorMessageAddress] = useState('');
  const [showErrorMessageAddress, setShowErrorMessageAddress] = useState(false);

  const [placeId, setPlaceId] = useState('');

  // Business name
  const [businessName, setBusinessName] = useState('');
  const [isValidBusinessName, setIsValidBusinessName] = useState(false);
  const [errorMessageBusinessName, setErrorMessageBusinessName] = useState('');
  const [showErrorMessageBusinessName, setShowErrorMessageBusinessName] = useState(false);

  const [buildingName, setBuildingName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [adminEmail, setAdminEmail] = useState('');

  const clearAddSiteForm = () => {
    setUnit('');
    setAddress('');
    setBusinessName('');
    setBuildingName('');
    setContactName('');
    setContactEmail('');
    setAdminEmail('');
    setPlaceId('');
    setErrorMessageBusinessName('');
    setShowErrorMessageBusinessName(false);
    setErrorMessageAddress('');
    setShowErrorMessageAddress(false);
  }

  const toggleAddSite = () => {
    setDisplayAddSiteModal(!displayAddSiteModal);
    clearAddSiteForm();
  }

  const parseState = (stateName) => {
    let abbrev = '';
    switch (stateName) {
      case 'New South Wales': 
        abbrev = 'nsw';
        break;
      case 'Australian Capital Territory': 
        abbrev = 'nsw';
        break;
      case 'ACT': 
        abbrev = 'nsw';
        break;
      case 'Queensland': 
        abbrev = 'qld';
        break;
      case 'Northern Territory': 
        abbrev = 'sa';
        break;
      case 'South Australia': 
        abbrev = 'sa';
        break;
      case 'NT': 
        abbrev = 'sa';
        break;
      case 'Tasmania': 
        abbrev = 'tas';
        break;
      case 'Victoria': 
        abbrev = 'vic';
        break;
      case 'Western Australia': 
        abbrev = 'wa';
        break;
      default:
        abbrev = stateName.toLowerCase();
    }
    return abbrev;
  }

  const confirmAddSite = () => {
    setSearchResults([]);

    let isAddressValidated = checkAddress();
    let isBusinessNameValidated = checkBusinessName(businessName);

    if (isAddressValidated && isBusinessNameValidated) {
      let relationships = {};
      if (currentOrg) relationships = {
        "site-owner": {
          data: {
            type: "organisations",
            id: currentOrg
          }
        }
      };
      const data = {
        data: {
          attributes: {
            "address": address,
            "state": state,
            "country": country,
            "admin-email": adminEmail,
            "building-name": buildingName,
            "business-name": businessName,
            "contact-email": contactEmail,
            "contact-name": contactName,
            "created-at": null,
            "nexttestdate": null,
            "permission-requested": false,
            "permitted": false,
            "place-id": placeId,
            "unit": unit
          },
          relationships: relationships,
          type: "sites"
        }
      }
  
      addSite(data).then(response => {
        setDisplayAddSiteModal(false);
        clearAddSiteForm();
        onAddSite([response.data, ...sites])
        dispatch({ type: 'SET POPUP', payload: 'success' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Added'] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      }).catch(error => {
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  const search = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  }

  const infinityLoad = () => {

  }

  const setSitesSortParam = (type) => {
    onSort(type);
  }

  const setCurrentOrganisation = (e) => {
    setSelectedAccount(e.target.value);
  }

  const closeChooseBusinessModal = () => {
    setDisplayChooseBusinessModal(false);
  }

  const checkBusinessName = (input) => {
    const validateResult = validateBusinessName(input);
    if (validateResult.validated) {
      setShowErrorMessageBusinessName(false);
      setErrorMessageBusinessName('');
      setIsValidBusinessName(true);
      return true;
    } 
    setShowErrorMessageBusinessName(true);
    setErrorMessageBusinessName(validateResult.message);
    setIsValidBusinessName(false);
  }

  const changeBusinessName = (e) => {
    const input = e.target.value;
    setBusinessName(input);
    checkBusinessName(input);
  }

  const checkAddress = () => {
    const validateResult = validateAddress(address, placeId);
    if (validateResult.validated) {
      setShowErrorMessageAddress(false);
      setErrorMessageAddress('');
      setIsValidAddress(true);
      return true;
    } 
    setShowErrorMessageAddress(true);
    setErrorMessageAddress(validateResult.message);
    setIsValidAddress(false);
  }

  const changeAddress = (e) => {
    setPlaceId('');
    const searchText = e.target.value;
    setAddress(searchText);
    getPlaces(searchText).then(response => {
      setSearchResults(response.data);
    });
  }

  const selectAddress = (selectedAddress, placeId, state, country) => {
    setAddress(selectedAddress);
    setState(state);
    setCountry(country);
    setPlaceId(placeId);
    setSearchResults([]);
    setErrorMessageAddress('');
    setShowErrorMessageAddress(false);
  }

  useEffect(() => {
    if (location.state && location.state.updated) {
      onUpdateSites();
    }
  }, [location.state]);

  return (
    <div>
      {children}
      <div id="sites" className="page main-page">
        <div className="header">
          <h2>Sites</h2>
          <div className="page-header-actions">
            {hasSites && (
              <button className="action button icon green" onClick={toggleAddSite}>
                <img className="icon" src={icon_add} alt="Add Icon"/>
                Add Site
              </button>
            )}
            <SearchBar placeholder={'Search'} searchText={searchText} onChange={search} />
          </div>
        </div>

        <div className='sequence-element sequence-element-sites-table'>
          <SiteTable 
            postOnboardingTourNames={postOnboardingTourNames}
            sites={sites}
            siteTotal={siteTotal}
            onLoadMore={onLoadMore}
            infinityLoad={infinityLoad}
            isQueryPending={isQueryPending}
            hasTestActivated={true}
            onAddSite={toggleAddSite}
            showSiteDetails='showSiteDetails'
            noSearchResults={noSearchResults}
            onSort={setSitesSortParam}
            onUpdateSites={onUpdateSites}
          />
        </div>
      </div>

      {displayChooseBusinessModal && (
        <ModalView>
          <SwitchAccountModal 
            headingText='Choose a business'
            messageText='Which business are you working for today?'
            buttonText='Go'
            selectedAccount={selectedAccount}
            displayOrganisations={displayOrganisations}
            hasJustLoggedIn={hasJustLoggedIn}
            didValidate={didValidate}
            onClose={closeChooseBusinessModal}
            onChange={setCurrentOrganisation}
            // onConfirm={confirmCurrentOrganisation}
          />
        </ModalView>
      )}
      
      {displayAddSiteModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Add New Site</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={toggleAddSite} />
            </div>

            <div className="modal-content">
              <SiteFormNew 
                site={site} 
                onSave={confirmAddSite} 
                isQueryPending={isQueryPending} 
                didValidate={didValidate} 
                unit={unit} 
                address={address} 
                searchResults={searchResults}
                businessName={businessName} 
                buildingName={buildingName} 
                contactName={contactName} 
                contactEmail={contactEmail} 
                adminEmail={adminEmail} 
                isValidAddress={isValidAddress}
                errorMessageAddress={errorMessageAddress}
                showErrorMessageAddress={showErrorMessageAddress}
                isValidBusinessName={isValidBusinessName}
                errorMessageBusinessName={errorMessageBusinessName}
                showErrorMessageBusinessName={showErrorMessageBusinessName}
                onChangeUnit={(e) => setUnit(e.target.value)}
                onChangeAddress={changeAddress}
                onClickAddressResult={selectAddress}
                onChangeBusinessName={changeBusinessName}
                onChangeBuildingName={(e) => setBuildingName(e.target.value)}
                onChangeContactName={(e) => setContactName(e.target.value)}
                onChangeContactEmail={(e) => setContactEmail(e.target.value)}
                onChangeAdminEmail={(e) => setAdminEmail(e.target.value)}
              />
            </div>
          </>
        </ModalView>
      )}
    </div>
  )
}

export default Sites;
