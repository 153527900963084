import React, { useState, useEffect } from 'react';
import OrganisationNewForm from './components/forms/OrganisationNewForm';

const NewOrganisation = ({children, onAddOrganisation}) => {

  return (
    <>
      {children}
      <div id='new-organisation'>
        <h3>Create a new business by entering information in the fields below</h3>
        <OrganisationNewForm onAddOrganisation={onAddOrganisation}/>
      </div>
    </>
  )
}

export default NewOrganisation;