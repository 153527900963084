import React, { useState, useEffect } from 'react';
import ChangePasswordForm from './components/forms/ChangePasswordForm';

const ChangePassword = ({children}) => {

  return (
    <>
      {children}
      <div id='change-password'>
        <ChangePasswordForm />
      </div>
    </>
  )
}

export default ChangePassword;