import React, { useState, useEffect } from 'react';
import FormValidatedInput from '../FormValidatedInput';

export default ({group, didValidate, errorMessage, showErrorMessage, onChange}) => {

  return (
    <div className="row row-animated" style={{textTransform: 'none', letterSpacing: 0, fontWeight: 400}}>
      <FormValidatedInput type='text' name='name' value={group} didValidate={didValidate} labelText='Group Name' errorMessage={errorMessage} showErrorMessage={showErrorMessage} onChange={onChange}/>
    </div>  
  )
}