import React, { useState, useEffect } from 'react';
import icon_red_dot from '../../assets/images/icon-red-dot.svg';
import illus_no_faults from '../../assets/images/illus-no-faults.svg';
import LoadMoreButton from './LoadMoreButton';

export default ({deletedFittings, deletedFittingTotal, infinityLoad, onShowFitting, onLoadMore}) => {

  return (
    <div id="deleted-fitting-table" className="table fitting-table">
      <table>
        <tbody>
          {deletedFittings.map(fitting => (
            <tr key={deletedFittings.indexOf(fitting)} onClick={() => onShowFitting(fitting)} >
              <td className="table-row">
                <div className='column device-reference'>
                  <div className="truncated">
                    {fitting.attributes['device-reference'] + ' - ' + fitting.attributes['fitting-type']}
                  </div>
                </div>
                <div className='column row-indicator'>
                  {fitting.attributes['faulty'] && <img src={icon_red_dot} alt="Faulty indicator"/>}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {deletedFittings.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_no_faults} alt="Blue Row Icon"/>
          </div>
          <div className="empty-message">
            There are no deleted fittings for this site.
          </div>
        </div>
      ) : (
        <div className="table-actions">
          <LoadMoreButton 
            canLoad={deletedFittings.length < deletedFittingTotal} 
            loadText='Load More Deleted Fittings' 
            numberOfItemsDisplayedText={`(Showing ${deletedFittings.length} of ${deletedFittingTotal})`} 
            onLoadMore={onLoadMore}
          />
        </div>
      )}
    </div>
  )
}