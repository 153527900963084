import React, { useState, useEffect } from 'react';

export default ({popupMessages, type,  onClick}) => {

    return (
        <div id='flash' onClick={onClick}>
            {popupMessages.map(message => (
              <div key={popupMessages.indexOf(message)} className={`alert alert-${type} active`}>
                {type === 'danger' && message.detail}
                {type === 'success' && message}
              </div>
            ))}
        </div>
    )
}