import React, { useState, useEffect } from 'react';
import icon_information from '../../assets/icons/icon-information.svg';

export default ({inline}) => {

    return (
        <div className='fitting-info-banner'>
            <div className=""><img className="icon" src={icon_information} alt="Information Icon" /></div>
            {inline ? (
                <p className="label">If you enter a CleverTest+ serial number, we’ll populate the product code and description for you.</p>
            ) : (
                <p className="label">If you enter a CleverTest+ serial number, we’ll<br/>populate the product code and description for<br/>you.</p>
            )}
        </div>
    )
}


