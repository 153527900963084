import React, { useState, useEffect } from 'react';

export default ({children, file, onChange}) => {

  return (
    <div className='file-uploader'>
      <label htmlFor="file-upload">
        <input type="file" id="file-upload" accept=".xlsx, .xls" value={file} onChange={onChange}/>
        {children}
      </label>
    </div>
  )
}
