import React, { useState, useEffect, useContext } from 'react';
import UserDetailsForm from './forms/UserDetailsForm';
import delete_icon from '../../assets/icons/delete.svg';

export default ({user, employment, onDeleteUser}) => {

  const updatePurchasingRights = () => {

  }

  return (
    <div>
      <div className="user-panel">
        <div className="user-panel-header">
          <div className="subheading">User Details</div>
        </div>
        <UserDetailsForm user={user} employment={employment} inputsDisabled={true} onUpdatePurchasingRights={updatePurchasingRights} />
        <div className="user-panel-actions delete-user">
          <button className="button small text-red" onClick={onDeleteUser} >
            <img className='icon delete-icon' src={delete_icon} alt="Delete Icon"/>
            Delete User
          </button>
        </div>
      </div>
    </div>
  )
}