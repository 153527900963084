import React, { useState, useEffect } from 'react';
import { getFaults } from '../../api/faultApi';
import FaultTable from '../components/FaultTable';
import Show from '../groups/fittings/Show';

export default ({site}) => {

  const [faultyFittings, setFaultyFittings] = useState([]);
  const [fitting, setFitting] = useState(null);
  const [faultPageNum, setFaultPageNum] = useState(1);
  const [faultTotal, setFaultTotal] = useState(0);

  const infinityLoad = () => {

  }

  const addOrderItem = () => {

  }

  const removeOrderItem = () => {

  }

  const backToFaultFittingsIndex = () => {

  }

  const refreshRoute = () => {

  }

  const fittingDeleted = () => {
    setFitting(null);
  }

  const showFittingDetails = (fitting) => {
    setFitting(fitting);
  }

  const updateFaults = () => {
    const faultsFilter = '?page[number]=' + faultPageNum + '&page[size]=10&site=' + site.id;
    getFaults(site.id, faultsFilter).then(response => {
      setFaultyFittings(faultyFittings.concat(response.data));
      setFaultTotal(response.meta['total-objects']);
    })
  }

  const loadMoreFaults = () => {
    if (Math.ceil(faultyFittings.length / 10) < faultTotal) {
      setFaultPageNum(faultPageNum + 1);
    }
  }

  useEffect(() => {
    updateFaults();
  }, [])

  useEffect(() => {
    updateFaults();
  }, [faultPageNum])

  return (
    <>
      <div className="header">
        <h2>Faulty Fittings</h2>
      </div>
      <div className="fittings-index">
        <div className="fittings-list">
          <FaultTable 
            faultyFittings={faultyFittings}
            faultTotal={faultTotal}
            infinityLoad={infinityLoad}
            addOrderItem={addOrderItem}
            removeOrderItem={removeOrderItem}
            backToFaultFittingsIndex={backToFaultFittingsIndex}
            refreshRoute={refreshRoute}
            onShowFitting={showFittingDetails}
            onLoadMore={loadMoreFaults}
          />
        </div>
        
        {fitting && (
          <Show 
            fitting={fitting}
            onUpdateFitting={showFittingDetails} 
            onFittingDeleted={fittingDeleted}
            onUpdateMaintenance={showFittingDetails}
          />
        )}
      </div>
    </>
  )
}