import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { durationOptions, statusOptions, testStatusOptions } from '../../../utils/variables';
import FormValidatedInput from '../FormValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';

export default ({
  test, 
  inputsDisabled, 
  didValidate,
  testStatus,
  testDate,
  runTime,
  requiredDuration,
  nextTestDate,
  batteryStatus,
  lampStatus,
  controlGearStatus,
  errorMessageTestStatus,
  showErrorMessageTestStatus,
  errorMessageTestDate,
  showErrorMessageTestDate,
  errorMessageRunTime,
  showErrorMessageRunTime,
  onChangeTestStatus,
  onChangeTestDate,
  onChangeRunTime,
  onChangeRequiredDuration,
  onChangeNextTestDate,
  onChangeBatteryStatus,
  onChangeLampStatus,
  onChangeControlGearStatus
}) => {

  useEffect(() => {
    
  }, [])

  return (
    <>
      <div className="header-label-row">
        <label>Test</label>
      </div>
      <div className="row row-animated testStatus">
        <FormValidatedSelect type='text' name='testStatus' options={testStatusOptions} value={testStatus} disabled={inputsDisabled} didValidate={didValidate} labelText='Test Status' showErrorMessage={showErrorMessageTestStatus} errorMessage={errorMessageTestStatus} onChange={onChangeTestStatus}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='date' name='testDate' value={testDate} disabled={inputsDisabled} didValidate={didValidate} labelText='Test Date' maxDate={new Date(moment())} showErrorMessage={showErrorMessageTestDate} errorMessage={errorMessageTestDate} onChange={onChangeTestDate}/>
      </div>
      <div className="row row-animated">
        <FormValidatedInput type='text' name='runTime' value={runTime} disabled={inputsDisabled} didValidate={didValidate} labelText='Test Run Time (In Minutes)' showErrorMessage={showErrorMessageRunTime} errorMessage={errorMessageRunTime} onChange={onChangeRunTime}/>
      </div>
      <div className="row row-animated">
        <FormValidatedSelect type='text' name='requiredDuration' options={durationOptions} value={requiredDuration} disabled={inputsDisabled} didValidate={didValidate} labelText='Required Duration' onChange={onChangeRequiredDuration}/>
      </div>
      <div className="row row-animated last-test-row">
        <FormValidatedInput type='date' name='nextTestDate' value={nextTestDate} disabled={inputsDisabled} didValidate={didValidate} labelText='Next Test Date' minDate={new Date(moment().add('d', 1))} onChange={onChangeNextTestDate}/>
      </div>
      <div className="header-label-row">
        <label>Status</label>
      </div>
      <div className="row row-animated">
        <FormValidatedSelect type='text' name='batteryStatus' options={statusOptions} value={batteryStatus} disabled={inputsDisabled} didValidate={didValidate} labelText='Battery Status' onChange={onChangeBatteryStatus}/>
      </div>
      <div className="row row-animated">
        <FormValidatedSelect type='text' name='lampStatus' options={statusOptions} value={lampStatus} disabled={inputsDisabled} didValidate={didValidate} labelText='Lamp Status' onChange={onChangeLampStatus}/>
      </div>
      <div className="row row-animated">
        <FormValidatedSelect type='text' name='controlGearStatus' options={statusOptions} value={controlGearStatus} disabled={inputsDisabled} didValidate={didValidate} labelText='Control Gear Status' onChange={onChangeControlGearStatus}/>
      </div>
    </>
  )
}