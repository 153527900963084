import React, { useState, useEffect, useContext } from 'react';
import ModalView from '../components/ModalView';
import icon_close from '../../assets/images/icon-close.svg';
import BatchDeleteConfirmation from '../components/BatchDeleteConfirmation';
import BatchMoveConfirmation from '../components/BatchMoveConfirmation';
import FittingTable from '../components/FittingTable';
import FittingsFormNew from '../components/forms/FittingsFormNew';
import { getAllFittings, getFittingDetails, getFittings } from '../../api/fittingApi';
import { store } from '../../utils/store';
import Show from './fittings/Show';

export default ({ site, sitesGroups, isQueryPending, group, onBack, onUpdateGroup, onDeleteGroup}) => {

  const { dispatch } = useContext(store);

  const [displayAddFittingModal, setDisplayAddFittingModal] = useState(false);
  const [isMovingFittings, setIsMovingFittings] = useState(false);
  const [selectedFittings, setSelectedFittings] = useState(null);
  const [commissioned, setCommissioned] = useState(false);
  const [isNotCommissioned, setIsNotCommissioned] = useState(true);
  const [isCommissioned, setIsCommissioned] = useState(false);
  const [isEmptyGroup, setIsEmptyGroup] = useState(true);
  const [hasNoRecords, setHasNoRecords] = useState(true);
  const [notCommissionedFittings, setNotCommissionedFittings] = useState([]);
  const [commissionedFittings, setCommissionedFittings] = useState([]);
  const [newFitting, setNewFitting] = useState(null);
  const [canDeleteGroup, setCanDeleteGroup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fitting, setFitting] = useState(null);

  const closeAddFitting = () => {
    setDisplayAddFittingModal(false);
  }

  const sortList = () => {

  }

  const batchDeleteFittings = () => {
    
  }

  const batchMoveFittings = () => {
    
  }

  const infinityLoad = () => {
    
  }

  const setIsCommissionedFilter = () => {
    
  }

  const setIsNotCommissionedFilter = () => {
    
  }

  const addOrderItem = () => {
    
  }

  const removeOrderItem = () => {
    
  }

  const backToFittingsIndex = () => {
    
  }

  const refreshRoute = () => {
    
  }

  const updatedFitting = (fitting) => {
    setFitting(fitting);
    refreshFittings();
  }

  const setCommissionedFilter = (commissioned) => {
    setIsCommissioned(commissioned);
    setIsNotCommissioned(!commissioned);
  }

  const openAddFitting = () => {
    setDisplayAddFittingModal(true);
  }

  const confirmAddFitting = (newFitting) => {
    if (newFitting.attributes['commissioned']) {
      setCommissionedFittings([newFitting, ...commissionedFittings]);
    } else {
      setNotCommissionedFittings([newFitting, ...notCommissionedFittings]);
    }

    // const filters = '?include=latest-test,maintenances,group';
    // getFittingDetails(newFitting.id, filters).then(response => {
    //   console.log(response)
    // })
    setDisplayAddFittingModal(false);
    dispatch({ type: 'SET POPUP', payload: 'success' });
    dispatch({ type: 'SET POPUP MESSAGES', payload: ['Fitting Added'] });
    setTimeout(() => {
      dispatch({ type: 'SET POPUP', payload: '' })
    }, 3000)
  }

  const showFittingDetails = (fitting) => {
    setFitting(fitting);
  }

  const fittingDeleted = (fittingId) => {
    setFitting(null);
    if (isNotCommissioned) {
      let newNotCommissionedFittings = notCommissionedFittings.filter(ft => {
        return ft.id !== fittingId;
      })
      setNotCommissionedFittings(newNotCommissionedFittings);
    }
    if (isCommissioned) {
      let newCommissionedFittings = commissionedFittings.filter(ft => {
        return ft.id !== fittingId;
      })
      setCommissionedFittings(newCommissionedFittings);
    }
    refreshFittings();
  }

  useEffect(() => {
    if (group) {
      loadFittings();
    } else {
      refreshFittings();
    }
  }, [])

  const refreshFittings = () => {
    const notCommissionedFilter = '?allfittingsb=allfittings&filter[commissioned]=false&page[number]=1&page[size]=10&siteid=' + site.id;
    const CommissionedFilter = '?allfittingsb=allfittings&filter[commissioned]=true&page[number]=1&page[size]=10&siteid=' + site.id;

    getAllFittings(site.id, notCommissionedFilter).then(response => {
      setNotCommissionedFittings(response.data);
    }).catch(errors => {
      console.log(errors);
    })

    getAllFittings(site.id, CommissionedFilter).then(response => {
      setCommissionedFittings(response.data);
    }).catch(errors => {
      console.log(errors);
    })
  }

  const loadFittings = () => {
    const notCommissionedFilter = '?filter[commissioned]=false&page[number]=1&page[size]=10&group=' + group.id;
    const CommissionedFilter ='?filter[commissioned]=true&page[number]=1&page[size]=10&group=' + group.id;

    getFittings(group.id, notCommissionedFilter).then(response => {
      setNotCommissionedFittings(response.data);
    }).catch(errors => {
      console.log(errors);
    })

    getFittings(group.id, CommissionedFilter).then(response => {
      setCommissionedFittings(response.data);
    }).catch(errors => {
      console.log(errors);
    })
  }

  useEffect(() => {
    if (group) {
      loadFittings();
    }

  }, [group])

  useEffect(() => {
    const count = commissionedFittings.length + notCommissionedFittings.length;
    setCanDeleteGroup(count === 0);
  }, [commissionedFittings, notCommissionedFittings])

  return (
    <div>
      <div className="fittings-index">
        <div className="fittings-list">
          <FittingTable 
            loading={loading}
            site={site}
            group={group}
            sitesGroups={sitesGroups}
            fittings={isCommissioned ? commissionedFittings : notCommissionedFittings}
            canDeleteGroup={canDeleteGroup}
            batchName='fittings'
            allfittings={true}
            isNotCommissioned={isNotCommissioned}
            isCommissioned={isCommissioned}
            isMovingFittings={isMovingFittings}
            selectedFittings={selectedFittings}
            isEmptyGroup={isEmptyGroup}
            hasNoRecords={hasNoRecords}
            notCommissionedFittingsCount={notCommissionedFittings.length}
            commissionedFittingsCount={commissionedFittings.length}
            sortList={sortList}
            batchDeleteFittings={batchDeleteFittings}
            batchMoveFittings={batchMoveFittings}
            infinityLoad={infinityLoad}
            setIsCommissionedFilter={setIsCommissionedFilter}
            setIsNotCommissionedFilter={setIsNotCommissionedFilter}
            addOrderItem={addOrderItem}
            removeOrderItem={removeOrderItem}
            backToFittingsIndex={backToFittingsIndex}
            refreshRoute={refreshRoute}
            onCommissionedFilter={setCommissionedFilter}
            onAddFitting={openAddFitting}
            onShowFitting={showFittingDetails}
            onBack={onBack}
            onUpdateGroup={onUpdateGroup}
            onChangeFittings={loadFittings}
            onDeleteGroup={onDeleteGroup}
          />
        </div>
        {fitting && (
          <Show 
            fitting={fitting}
            onUpdateFitting={updatedFitting} 
            onFittingDeleted={fittingDeleted}
          />
        )}
      </div>

      {displayAddFittingModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Add a Fitting</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeAddFitting}/>
            </div>
        
            <div className="modal-content">
              <FittingsFormNew 
                fitting={newFitting} 
                site={site}
                isQueryPending={isQueryPending} 
                addFitting="addFitting" 
                group={group}
                onAddFitting={confirmAddFitting}
              />
            </div>
          </>
        </ModalView>
      )}
    </div>
  )
}
