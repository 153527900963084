import React, { useState, useEffect } from 'react';
import icon_close from '../../assets/images/icon-close.svg';

export default ({children, batchLength, uppercaseBatchName, onClose}) => {

  const pluralizer = () => {

  }

  return (
    <div>
      <div className="modal-header">
        <h2 className="form-heading">Delete {pluralizer(batchLength, uppercaseBatchName)}</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
      </div>

      <div className="modal-content">
        <div className="inner">
          <p className="message">{children}</p>
        </div>
        <div className="modal-actions">
          <button className="button standard blue" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  )
}