import React, { useState, useEffect, useContext } from 'react';
import { getFittingDetails, removeFitting } from '../../../api/fittingApi';
import { store } from '../../../utils/store';
import FittingDeleteConfirmation from '../../components/FittingDeleteConfirmation';
import FittingPanel from '../../components/FittingPanel';
import FittingDetailsForm from '../../components/forms/FittingDetailsForm';
import MaintenanceForm from '../../components/forms/MaintenanceForm';
import TestAndStatusForm from '../../components/forms/TestAndStatusForm';
import MaintenanceRows from '../../components/MaintenanceRows';
import ModalView from '../../components/ModalView';

export default ({fitting, isDeleted, isQueryPending, onUpdateFitting, onFittingDeleted, onRefresh}) => {

  const { dispatch } = useContext(store);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tab, setTab] = useState('details');
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessConfirmation, setShowSuccessConfirmation] = useState(false);
  const [group, setGroup] = useState(null);
  const [test, setTest] = useState(null);
  const [maintenances, setMaintenances] = useState([]);

  const edit = () => {
    setInputsDisabled(false);
    setIsEditing(true);
  }

  const deleteFitting = () => {
    removeFitting(fitting.id).then(response => {
      onFittingDeleted(fitting.id);
      setShowDeleteModal(false);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Fitting Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  const showTestForm = () => {
    setInputsDisabled(false);
  }

  const cancelEditing = () => {
    setInputsDisabled(true);
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }

  const changeTab = (tab) => {
    setTab(tab);
    setInputsDisabled(true);
  }

  const openDeleteFitting = () => {
    setShowDeleteModal(true);
  }

  const updatingFitting = (fitting) => {
    onUpdateFitting(fitting);
    setInputsDisabled(true);
    setShowSuccessConfirmation(true);
    setTimeout(() => {
      setShowSuccessConfirmation(false);
    }, 3000);
  }

  const updatingTest = () => {
    showFittingDetails();
    setInputsDisabled(true);
    setShowSuccessConfirmation(true);
    setIsEditing(false);
    setTimeout(() => {
      setShowSuccessConfirmation(false);
    }, 3000);
  }

  const updatingMaintenance = () => {
    showFittingDetails();
    setInputsDisabled(true);
    setShowSuccessConfirmation(true);
    setTimeout(() => {
      setShowSuccessConfirmation(false);
    }, 3000);
  }

  const showFittingDetails = () => {
    const filters = '?include=latest-test,maintenances,group';
    setTest(null);
    setGroup(null);
    setMaintenances([]);
    getFittingDetails(fitting.id, filters).then(response => {
      let maintenances = [];
      response.included.forEach(data => {
        if (data.type === 'tests') {
          setTest(data);
        }
        if (data.type === 'groups') {
          setGroup(data);
        }
        if (data.type === 'maintenances') {
          maintenances.push(data);
        }
      })
      setMaintenances(maintenances);
    })
  }

  useEffect(() => {
    if (fitting) {
      setInputsDisabled(true);
      showFittingDetails();
    }
  }, [fitting])

  return (
    <div>
      <div className="fitting-details">
        <FittingPanel fitting={fitting} isDeleted={isDeleted} tab={tab} inputsDisabled={inputsDisabled} showSuccessConfirmation={showSuccessConfirmation} disableEdit={tab === 'maintenance'} onChangeTab={changeTab} onEdit={edit} onDelete={openDeleteFitting} onRefresh={onRefresh}>
          {tab === 'details' && <FittingDetailsForm fitting={fitting} group={group} inputsDisabled={inputsDisabled} onUpdate={updatingFitting} onCancel={cancelEditing}/>}
          {tab === 'testStatus' && <TestAndStatusForm fitting={fitting} test={test} inputsDisabled={inputsDisabled} isEditing={isEditing} isDeleted={isDeleted} onShowTestForm={showTestForm} onUpdateFitting={updatingFitting} onUpdateTest={updatingTest} onCancel={cancelEditing}/>}
          {tab === 'maintenance' && (
            <div>
              {maintenances && <MaintenanceRows fitting={fitting} isDeleted={isDeleted} rows={maintenances} onUpdate={updatingMaintenance}/>}
              <MaintenanceForm fitting={fitting} maintenances={maintenances} isDeleted={isDeleted} onUpdate={updatingMaintenance}/>
            </div>
          )}
        </FittingPanel>
      </div>

      {showDeleteModal && (
        <ModalView>
          <FittingDeleteConfirmation onDeleteFitting={deleteFitting} onClose={closeDeleteModal} isQueryPending={isQueryPending} />
        </ModalView>
      )}
    </div>
  )
}