import React, { useState, useEffect, useContext } from 'react';
import ModalView from './ModalView';
import icon_close from '../../assets/images/icon-close.svg';
import QuickTestDefaultsForm from './forms/QuickTestDefaultsForm';
import { updateTestEvent } from '../../api/testEventApi';
import { store } from '../../utils/store';

export default ({testEvent, isQueryPending, onClose, onUpdateTestEvent}) => {

  return (
    <ModalView>
      <div id="quick-test-defaults-modal">
        <div className="modal-header">
          <h2 className="form-heading">
            Edit Log Book Template
          </h2>
          <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
        </div>

        <div className="modal-content">
          <QuickTestDefaultsForm 
            testEvent={testEvent} 
            isQueryPending={isQueryPending} 
            onUpdateTestEvent={onUpdateTestEvent}
          />
        </div>
      </div>
    </ModalView>
  )
}
