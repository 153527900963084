import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { australianStates, countries, newZealandStates } from '../../../utils/variables';
import { validateFirstName, validateLastName, validateEmail, validateNumber, validateCountry, validateState, validatePassword, validatePasswordConfirmation, validateNumberOnly } from '../../../utils/validators';
import AuthValidatedInput from '../AuthValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';
import { signedRequest, uploadFile } from '../../../api/otherApi';
import { addUser } from '../../../api/userApi';
import { store } from '../../../utils/store';
import { authenticate } from '../../../api/authApi';

function UserSignupForm({onSignup}) {

  const { dispatch } = useContext(store);
  const navigate = useNavigate();

  const [didValidate, setDidValidate] = useState(false);
  const [sigimginfo, setSigimginfo] = useState('');
  const [imginfo, setImginfo] = useState('');
  const [hasConfirmedAuthorization, setHasConfirmedAuthorization] = useState(false);
  const [imgupload, setImgupload] = useState('');
  const [imgupload2, setImgupload2] = useState('');
  const [imgPreview, setImgPreview] = useState('');
  const [img2Preview, setImg2Preview] = useState('');

  // First Name
  const [firstName, setFirstName] = useState('');
  const [isValidFirstName, setIsValidFirstName] = useState(false);
  const [errorMessageFirstName, setErrorMessageFirstName] = useState('');
  const [showErrorMessageFirstName, setShowErrorMessageFirstName] = useState(false);

  // Last Name
  const [lastName, setLastName] = useState('');
  const [isValidLastName, setIsValidLastName] = useState(false);
  const [errorMessageLastName, setErrorMessageLastName] = useState('');
  const [showErrorMessageLastName, setShowErrorMessageLastName] = useState(false);

  // Email
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false);

  // Mobile
  const [phone, setPhone] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [errorMessagePhone, setErrorMessagePhone] = useState('');
  const [showErrorMessagePhone, setShowErrorMessagePhone] = useState(false);

  // User Country
  const [userCountry, setUserCountry] = useState('');
  const [isValidUserCountry, setIsValidUserCountry] = useState(false);
  const [errorMessageUserCountry, setErrorMessageUserCountry] = useState('');
  const [showErrorMessageUserCountry, setShowErrorMessageUserCountry] = useState(false);

  // User State
  const [userState, setUserState] = useState('');
  const [isValidUserState, setIsValidUserState] = useState(false);
  const [errorMessageUserState, setErrorMessageUserState] = useState('');
  const [showErrorMessageUserState, setShowErrorMessageUserState] = useState(false);

  // Postcode
  const [postcode, setPostcode] = useState('');

  // Password
  const [password, setPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [showErrorMessagePassword, setShowErrorMessagePassword] = useState(false);

  // Confirm Password
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isValidPasswordConfirmation, setIsValidPasswordConfirmation] = useState(false);
  const [errorMessagePasswordConfirmation, setErrorMessagePasswordConfirmation] = useState('');
  const [showErrorMessagePasswordConfirmation, setShowErrorMessagePasswordConfirmation] = useState(false);

  // Organisation Name
  const [organisationName, setOrganisationName] = useState('');

  // Organisation Country
  const [organisationCountry, setOrganisationCountry] = useState('');
  const [isValidOrganisationCountry, setIsValidOrganisationCountry] = useState(false);
  const [errorMessageOrganisationCountry, setErrorMessageOrganisationCountry] = useState('');
  const [showErrorMessageOrganisationCountry, setShowErrorMessageOrganisationCountry] = useState(false);

  // Organisation State
  const [organisationState, setOrganisationState] = useState('');
  const [isValidOrganisationState, setIsValidOrganisationState] = useState(false);
  const [errorMessageOrganisationState, setErrorMessageOrganisationState] = useState('');
  const [showErrorMessageOrganisationState, setShowErrorMessageOrganisationState] = useState(false);

  const [rfn, setRfn] = useState('');
  const [abn, setAbn] = useState('');
  const [qbcc, setQbcc] = useState('');

  // Report Phone
  const [reportPhone, setReportPhone] = useState('');
  const [isValidReportPhone, setIsValidReportPhone] = useState(false);
  const [errorMessageReportPhone, setErrorMessageReportPhone] = useState('');
  const [showErrorMessageReportPhone, setShowErrorMessageReportPhone] = useState(false);

  const inputImgRef = useRef(null);
  const inputImg2Ref = useRef(null);

  const checkFirstName = (input) => {
    const validateResult = validateFirstName(input);
    if (validateResult.validated) {
      setShowErrorMessageFirstName(false);
      setErrorMessageFirstName('');
      setIsValidFirstName(true);
    } else {
      setShowErrorMessageFirstName(true);
      setErrorMessageFirstName(validateResult.message);
      setIsValidFirstName(false);
    }
    return validateResult.validated;
  }

  const checkLastName = (input) => {
    const validateResult = validateLastName(input);
    if (validateResult.validated) {
      setShowErrorMessageLastName(false);
      setErrorMessageLastName('');
      setIsValidLastName(true);
    } else {
      setShowErrorMessageLastName(true);
      setErrorMessageLastName(validateResult.message);
      setIsValidLastName(false);
    }
    return validateResult.validated;
  }

  const checkEmail = (input) => {
    const validateResult = validateEmail(input);
    if (validateResult.validated) {
      setShowErrorMessageEmail(false);
      setErrorMessageEmail('');
      setIsValidEmail(true);
    } else {
      setShowErrorMessageEmail(true);
      setErrorMessageEmail(validateResult.message);
      setIsValidEmail(false);
    }
    return validateResult.validated;
  }

  const checkPhone = (input) => {
    const validateResult = validateNumber(input);
    if (validateResult.validated) {
      setShowErrorMessagePhone(false);
      setErrorMessagePhone('');
      setIsValidPhone(true);
    } else {
      setShowErrorMessagePhone(true);
      setErrorMessagePhone(validateResult.message);
      setIsValidPhone(false);
    }
    return validateResult.validated;
  }

  const checkPassword = (input) => {
    const validateResult = validatePassword(input);
    if (validateResult.validated) {
      setShowErrorMessagePassword(false);
      setErrorMessagePassword('');
      setIsValidPassword(true);
    } else {
      setShowErrorMessagePassword(true);
      setErrorMessagePassword(validateResult.message);
      setIsValidPassword(false);
    }
    return validateResult.validated;
  }
  
  const checkUserCountry = (input) => {
    const validateResult = validateCountry(input);
    if (validateResult.validated) {
      setShowErrorMessageUserCountry(false);
      setErrorMessageUserCountry('');
      setIsValidUserCountry(true);
    } else {
      setShowErrorMessageUserCountry(true);
      setErrorMessageUserCountry(validateResult.message);
      setIsValidUserCountry(false);
    }
    return validateResult.validated;
  }

  const checkUserState = (input) => {
    const validateResult = validateState(input);
    if (validateResult.validated) {
      setShowErrorMessageUserState(false);
      setErrorMessageUserState('');
      setIsValidUserState(true);
    } else {
      setShowErrorMessageUserState(true);
      setErrorMessageUserState(validateResult.message);
      setIsValidUserState(false);
    }
    return validateResult.validated;
  }

  const checkPasswordConfirmation = (input) => {
    const validateResult = validatePasswordConfirmation(password, input);
    if (validateResult.validated) {
      setShowErrorMessagePasswordConfirmation(false);
      setErrorMessagePasswordConfirmation('');
      setIsValidPasswordConfirmation(true);
    } else {
      setShowErrorMessagePasswordConfirmation(true);
      setErrorMessagePasswordConfirmation(validateResult.message);
      setIsValidPasswordConfirmation(false);
    }
    return validateResult.validated;
  }

  const checkReportPhone = (input) => {
    const validateResult = validateNumberOnly(input);
    if (validateResult.validated) {
      setShowErrorMessageReportPhone(false);
      setErrorMessageReportPhone('');
      setIsValidReportPhone(true);
    } else {
      setShowErrorMessageReportPhone(true);
      setErrorMessageReportPhone(validateResult.message);
      setIsValidReportPhone(false);
    }
    return validateResult.validated;
  }

  const checkOrganisationCountry = (input) => {
    const validateResult = validateCountry(input);
    if (validateResult.validated) {
      setShowErrorMessageOrganisationCountry(false);
      setErrorMessageOrganisationCountry('');
      setIsValidOrganisationCountry(true);
    } else {
      setShowErrorMessageOrganisationCountry(true);
      setErrorMessageOrganisationCountry(validateResult.message);
      setIsValidOrganisationCountry(false);
    }
    return validateResult.validated;
  }

  const checkOrganisationState = (input) => {
    const validateResult = validateState(input);
    if (validateResult.validated) {
      setShowErrorMessageOrganisationState(false);
      setErrorMessageOrganisationState('');
      setIsValidOrganisationState(true);
    } else {
      setShowErrorMessageOrganisationState(true);
      setErrorMessageOrganisationState(validateResult.message);
      setIsValidOrganisationState(false);
    }
    return validateResult.validated;
  }

  const changeFirstName = (e) => {
    setFirstName(e.target.value)
    checkFirstName(e.target.value)
  }

  const changeLastName = (e) => {
    setLastName(e.target.value)
    checkLastName(e.target.value)
  }

  const changeEmail = (e) => {
    setEmail(e.target.value)
    checkEmail(e.target.value)
  }

  const changePhone = (e) => {
    setPhone(e.target.value)
    checkPhone(e.target.value)
  }

  const changeUserCountry = (e) => {
    setUserCountry(e.target.value)
    checkUserCountry(e.target.value)
  }

  const changeUserState = (e) => {
    setUserState(e.target.value)
    checkUserState(e.target.value)
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
    checkPassword(e.target.value)
  }

  const changePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value)
    checkPasswordConfirmation(e.target.value)
  }

  const changeReportPhone = (e) => {
    setReportPhone(e.target.value)
    checkReportPhone(e.target.value)
  }

  const changeOrganisationName = (e) => {
    setOrganisationName(e.target.value);
    if (!e.target.value) {
      setShowErrorMessageOrganisationCountry(false);
      setShowErrorMessageOrganisationState(false);
    } 
  }

  const changeOrganisationCountry = (e) => {
    setOrganisationCountry(e.target.value)
    checkOrganisationCountry(e.target.value)
  }

  const changeOrganisationState = (e) => {
    setOrganisationState(e.target.value)
    checkOrganisationState(e.target.value)
  }
  
  const signup = async () => {
    let firstNameValidation = false,
      lastNameValidation = false,
      emailValidation = false,
      phoneValidation = false,
      userCountryValidation = false,
      userStateValidation = false,
      passwordValidation = false,
      passwordConfirmationValidation = false,
      reportPhoneValidation = false,
      organisationCountryValidation = false,
      organisationStateValidation = false;
    if (checkFirstName(firstName)) firstNameValidation = true;
    if (checkLastName(lastName)) lastNameValidation = true;
    if (checkEmail(email)) emailValidation = true;
    if (checkPhone(phone)) phoneValidation = true;
    if (checkUserCountry(userCountry)) userCountryValidation = true;
    if (checkUserState(userState)) userStateValidation = true;
    if (checkPassword(password)) passwordValidation = true;
    if (checkPasswordConfirmation(passwordConfirmation)) passwordConfirmationValidation = true;
    if (!organisationName || checkReportPhone(reportPhone)) reportPhoneValidation = true;
    if (!organisationName || checkOrganisationCountry(organisationCountry)) organisationCountryValidation = true;
    if (!organisationName || checkOrganisationState(organisationState)) organisationStateValidation = true;

    if (firstNameValidation &&
      lastNameValidation &&
      emailValidation &&
      phoneValidation &&
      userCountryValidation &&
      userStateValidation &&
      passwordValidation &&
      passwordConfirmationValidation &&
      reportPhoneValidation &&
      organisationCountryValidation &&
      organisationStateValidation) {

        let sigFile = null;
        let sigFileName = ''; 
        let signatureUrl = '';
        let logoFile = null;
        let logoFileName = ''; 
        let logoUrl = '';
        let blob = '';

        if (imgupload) {
          blob = imgupload.slice(0, imgupload.size, imgupload.type); 
          sigFileName = Math.floor(Math.random() * 100000) + 1 + imgupload.name;
          sigFile = new File([blob], sigFileName, {type: imgupload.type});
          const formDataSig = new FormData();
          formDataSig.append('file', sigFileName);
          formDataSig.append('type', imgupload.type);
          formDataSig.append('bucket', 'signature');
          let signatureUrlRaw = await signedRequest(formDataSig);
          uploadFile(sigFile, signatureUrlRaw);
          signatureUrl = signatureUrlRaw.slice(0, signatureUrlRaw.indexOf('?'));
        }

        if (imgupload2) {
          blob = imgupload2.slice(0, imgupload2.size, imgupload2.type); 
          logoFileName = Math.floor(Math.random() * 100000) + 1 + imgupload2.name;
          logoFile = new File([blob], logoFileName, {type: imgupload2.type});
          const formDataLogo = new FormData();
          formDataLogo.append('file', logoFileName);
          formDataLogo.append('type', imgupload2.type);
          formDataLogo.append('bucket', 'logo');
          let logoUrlRaw = await signedRequest(formDataLogo);
          uploadFile(logoFile, logoUrlRaw);
          logoUrl = logoUrlRaw.slice(0, logoUrlRaw.indexOf('?'));
        }
        
        const data = {
          data: {
            attributes: {
              "first-name": firstName,
              "last-name": lastName,
              "email": email,
              "phone": phone,
              "country": userCountry,
              "state": userState,
              "password": password,
              "current-password": null,
              "password-confirmation": passwordConfirmation,
              "wholesaler-authenticated": false,
              "admin": false,
              "postcode": postcode,
              "rfn": rfn,
              "abn": abn,
              "qbcc": qbcc,
              "reportphone": reportPhone,
              "imgurl": logoUrl,
              "editpage": false,
              "signatureurl": signatureUrl,
              "sigfilename": sigFileName,
              "wholesaler-local-branch": null,
              "cstatement": null
            },
            relationships: {
              organisations: {
                data: {}
              }
            },
            type: "users"
          }
        }

        addUser(data).then(response => {
          const user = response.data;
          localStorage.setItem('user', JSON.stringify(user));

          authenticate(email, password).then(() => {
            navigate('/onboarding');
            onSignup(user);
          })
        }).catch(error => {
          if (error) {
            dispatch({ type: 'SET POPUP', payload: 'danger' });
            dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
            setTimeout(() => {
              dispatch({ type: 'SET POPUP', payload: '' })
            }, 3000)
          }
        });
      }
  }

  useEffect(() => {
    if (!imgupload) {
      setImgPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(imgupload)
    setImgPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imgupload])

  useEffect(() => {
    if (!imgupload2) {
      setImg2Preview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(imgupload2)
    setImg2Preview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imgupload2])

  return (
    <form id='user-signup-form' className="form auth-form" onSubmit={e => {e.preventDefault(); signup();}}>

      <div className="sub-heading">
        <div className="section-number required">
          1
        </div>
        <h2 className="section-label">
          Enter your details below
        </h2>
      </div>

      <div className="row row-animated split">
        <div className="half first-name">
          <AuthValidatedInput name='firstName' value={firstName} isValid={isValidFirstName} errorMessage={errorMessageFirstName} showErrorMessage={showErrorMessageFirstName} didValidate={didValidate} labelText='First Name' onChange={changeFirstName} />
        </div>
        <div className="half last-name">
          <AuthValidatedInput name='lastName' value={lastName} isValid={isValidLastName} errorMessage={errorMessageLastName} showErrorMessage={showErrorMessageLastName} didValidate={didValidate} labelText='Last Name' onChange={changeLastName} />
        </div>
      </div>

      <div className="row row-animated email">
        <AuthValidatedInput name='email' value={email} isValid={isValidEmail} errorMessage={errorMessageEmail} showErrorMessage={showErrorMessageEmail} didValidate={didValidate} labelText='Email' onChange={changeEmail} />
      </div>

      <div className="row row-animated phone">
        <AuthValidatedInput name='phone' value={phone} isValid={isValidPhone} errorMessage={errorMessagePhone} showErrorMessage={showErrorMessagePhone} didValidate={didValidate} labelText='Mobile' onChange={changePhone} />
      </div>

      <div className="row row-animated userCountry">
        <FormValidatedSelect name='userCountry' value={userCountry} isValid={isValidUserCountry} errorMessage={errorMessageUserCountry} showErrorMessage={showErrorMessageUserCountry} options={countries} didValidate={didValidate} labelText='Country' onChange={changeUserCountry}/>
      </div>

      <div className="row row-animated userState">
        <FormValidatedSelect name='userState' value={userState} isValid={isValidUserState} errorMessage={errorMessageUserState} showErrorMessage={showErrorMessageUserState} options={userCountry === 'Australia' ? australianStates : userCountry === 'New Zealand' ? newZealandStates : []} didValidate={didValidate} labelText='State' onChange={changeUserState}/>
      </div>

      <div className="row row-animated userState">
        <AuthValidatedInput name='postcode' value={postcode} didValidate={didValidate} labelText='Postcode' onChange={e => setPostcode(e.target.value)} />
      </div>

      <div className="row row-animated password">
        <AuthValidatedInput name='password' value={password} isValid={isValidPassword} errorMessage={errorMessagePassword} showErrorMessage={showErrorMessagePassword} didValidate={didValidate} showPasswordToggle labelText='Password' onChange={changePassword} />
      </div>

      <div className="row row-animated confirm-password">
        <AuthValidatedInput name='passwordConfirmation' value={passwordConfirmation} isValid={isValidPasswordConfirmation} errorMessage={errorMessagePasswordConfirmation} showErrorMessage={showErrorMessagePasswordConfirmation} didValidate={didValidate} showPasswordToggle labelText='Confirm Password' onChange={changePasswordConfirmation} />
      </div>
      <div style={{textAlign: 'left'}}>
        Insert a digital signature to appear on report(optional)
      </div>
      
      <div style={{textAlign: 'left'}}>
        Upload Signature: {sigimginfo}
      </div>

      <div style={{textAlign: 'left', color: '#95c11f', display: 'flex', flexDirection: 'column'}}>
        <input type="file" accept=".png,.jpeg,.jpg" ref={inputImgRef} onChange={(e) => setImgupload(e.target.files[0])} style={{display: 'none'}} />
        {imgPreview && <img src={imgPreview} style={{maxWidth: '100%', maxHeight: '200px', objectFit: 'contain'}}/>}
        {imgPreview ? 'Image set and ready to upload' : (
          <div 
            onDragOver={e => {
              e.preventDefault();
            }} 
            onDrop={e => {
              e.preventDefault();
              [...e.dataTransfer.items].forEach((item, i) => {
                if (item.kind === 'file') {
                  const file = item.getAsFile();
                  setImgupload(file);
                }
              });
            }}
          >
            <b style={{borderStyle: 'solid', borderWidth: '3px'}} onClick={() => {inputImgRef.current.click()}}>
                Drag here or click to upload a file
            </b>
          </div>
        )}
      </div>
      <div className="sub-heading business-signup">
        <div className={`section-number ${organisationName ? 'required' : 'optional'}`}>
          2
        </div>
        <h2 className="section-label">
          Signup your business
        </h2>
        <div className="section-optional">
          Optional
        </div>
      </div>

      <div className="business-signup-instruction">
        If you’re the owner of a business using CleverSparky, create your business account below. <br />
        Otherwise, click <span className="signup-text">Sign Up</span> to continue.
      </div>

      <div className="row row-animated business-name">
        <AuthValidatedInput name='organisationName' value={organisationName} isValid={organisationName} didValidate={didValidate} labelText='Business Name' onChange={changeOrganisationName} />
      </div>

      <div className="row row-animated organisationCountry">
        <FormValidatedSelect name='organisationCountry' value={organisationCountry} isValid={organisationName && isValidOrganisationCountry} errorMessage={organisationName && errorMessageOrganisationCountry} showErrorMessage={organisationName && showErrorMessageOrganisationCountry} options={countries} didValidate={didValidate} labelText='Country' onChange={changeOrganisationCountry}/>
      </div>

      <div className="row row-animated organisationState">
        <FormValidatedSelect name='organisationState' value={organisationState} isValid={organisationName && isValidOrganisationState} errorMessage={organisationName && errorMessageOrganisationState} showErrorMessage={organisationName && showErrorMessageOrganisationState} options={organisationCountry === 'Australia' ? australianStates : organisationCountry === 'New Zealand' ? newZealandStates : []} didValidate={didValidate} labelText='State' onChange={changeOrganisationState}/>
      </div>

      <h2 style={{textAlign: 'left'}} className="section-label">
        Reports Section
      </h2>
      <div style={{textAlign: 'left'}} className="section-optional">
        Information included in this section will display in the header on your test reports
      </div>
      <div className="row row-animated business-name">
        <AuthValidatedInput name='rfn' value={rfn} didValidate={didValidate} labelText='Report Full Name' onChange={e => setRfn(e.target.value)} />
      </div>
      <div className="row row-animated business-name">
        <AuthValidatedInput name='abn' value={abn} didValidate={didValidate} labelText='ABN' onChange={e => setAbn(e.target.value)} />
      </div>
      <div className="row row-animated business-name">
        <AuthValidatedInput name='qbcc' value={qbcc} didValidate={didValidate} labelText='Contractor licence number' onChange={e => setQbcc(e.target.value)} />
      </div>
      <div className="row row-animated business-name">
        <AuthValidatedInput name='reportphone' value={reportPhone} errorMessage={errorMessageReportPhone} showErrorMessage={showErrorMessageReportPhone} didValidate={didValidate} labelText='Phone Number' onChange={changeReportPhone} />
      </div>
      <div style={{textAlign: 'left'}}>
        Upload Business Logo:  {imginfo}<br/>
      </div>

      <div style={{textAlign: 'left', color: '#95c11f', display: 'flex', flexDirection: 'column'}}>
        <input type="file" accept=".png,.jpeg,.jpg" ref={inputImg2Ref} onChange={(e) => setImgupload2(e.target.files[0])} style={{display: 'none'}} />
        {img2Preview && <img src={img2Preview} style={{maxWidth: '100%', maxHeight: '200px', objectFit: 'contain'}} />}
        {img2Preview ? 'Image set and ready to upload' : (
          <div 
            onDragOver={e => {
              e.preventDefault();
            }} 
            onDrop={e => {
              e.preventDefault();
              [...e.dataTransfer.items].forEach((item, i) => {
                if (item.kind === 'file') {
                  const file = item.getAsFile();
                  setImgupload2(file);
                }
              });
            }}
          >
            <b style={{borderStyle: 'solid', borderWidth: '3px'}} onClick={() => {inputImg2Ref.current.click()}}>Drag here or click to upload a file</b>
          </div>
        )}
      </div>
      
      <br/>  <br/>

      {organisationName && (
        <div className="row business-confirmation">
          <input type="checkbox" id='businessConfirmation' checked={hasConfirmedAuthorization} onChange={e => setHasConfirmedAuthorization(!hasConfirmedAuthorization)} />
          <label htmlFor='businessConfirmation' className="business-confirmation-label">I confirm I am authorised to create this business account.</label>
        </div>
      )}

      <div className="actions">
        <button type="submit" disabled={organisationName && !hasConfirmedAuthorization} className="button large green auth-submit">Sign Up</button>
      </div>

    </form>
  )

}

export default UserSignupForm;
