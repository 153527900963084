import { BASE_URL, API_VERSION, ORGANISATIONS, USERS, ME } from "../settings/apiUrls";
import axios from "axios";
import api from "./api";

export const getOrganisations = (filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + USERS + ME + ORGANISATIONS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const getOrganisation = (orgId) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + orgId,
    }).then((response) => {
        return response.data;
    });
}

export const addOrganisation = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + ORGANISATIONS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const getOrganisationsDetails = (orgArray) => {
    const sessionToken = localStorage.getItem('sessionToken');
    const sessionId = localStorage.getItem('sessionId');

    if (orgArray && sessionToken && sessionId) {
        const headers = {
            'X-Session-Token': sessionToken,
            'X-Session-Id': sessionId
        }
        return axios.all(orgArray.map(org => 
            axios.get(BASE_URL + API_VERSION + ORGANISATIONS + '/' + org.id, { headers })
        )).then(axios.spread((...responses) => {
            return responses.map(res => res.data);
        }))
    }
}

export const getOrganisationFiltered = (orgId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + orgId + filters,
    }).then((response) => {
        return response.data;
    });
}