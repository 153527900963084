import React, { useState, useEffect, useContext } from 'react';
import icon_close from '../../assets/images/icon-close.svg';

export default ({isQueryPending, onClose, onConfirm }) => {

  return (
    <div className="delete-account-modal">
      <div className="modal-header">
        <h2 className="form-heading">Delete Account</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose} />
      </div>

      <div className="modal-content">
        <div className="inner">
          <p className="message">Are you sure you want to delete your account? Deleting can’t be undone.</p>
        </div>
        <div className="modal-actions">
          <div className="button small red add-group-submit" disabled={isQueryPending} onClick={onConfirm}>
            Delete Account
          </div>
        </div>
      </div>
    </div>
  )
}
