import React, { useState, useEffect, useContext } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import icon_arrow_left_navy from '../assets/images/icon-arrow-left-navy.svg';
import icon_arrow_right_navy from '../assets/images/icon-arrow-right-navy.svg';
import illus_fittings from '../assets/images/illus-fittings.svg';
import illus_team from '../assets/images/illus-team.svg';
import illus_web_mobile from '../assets/images/illus-web-mobile.svg';
import OnboardingSlide from './components/OnboardingSlide';

export default ({children}) => {

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slideTo = (index) => swiper.slideTo(index);

  const previous = () => {
    if (currentSlide > 0) {
      slideTo(currentSlide - 1);
      setCurrentSlide(currentSlide - 1);
    }
  }

  const next = () => {
    if (currentSlide < 2) {
      slideTo(currentSlide + 1);
      setCurrentSlide(currentSlide + 1);
    }
  }

  const changeSlide = (swiper) => {
    const { realIndex } = swiper;
    setCurrentSlide(realIndex);
  }

  useEffect(() => {
    if (currentSlide === 0) {
      setIsFirstSlide(true);
    } else {
      setIsFirstSlide(false);
    }

    if (currentSlide === 2) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  }, [currentSlide])

  return (
    <div>
      {children}

      <div className="page onboarding">
        <div className={`pagination-control ${isFirstSlide ? 'hidden' : ''}`} onClick={previous}>
          <img src={icon_arrow_left_navy} alt="Left arrow" />
        </div>
        <Swiper
          className='swiper-container swiper-container-horizontal swiper-container-android'
          spaceBetween={50}
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          onSlideChange={changeSlide}
          onSwiper={setSwiper}
        >
          <SwiperSlide>
            <OnboardingSlide 
              imageSrc={illus_fittings}
              title='Introducing CleverSparky'
              text='CleverSparky is a compliance management and testing app for those installing or managing emergency lighting.'
              textStyleClass='wide'
              isLastSlide={isLastSlide}
            />
          </SwiperSlide>
          <SwiperSlide>
            <OnboardingSlide 
              imageSrc={illus_team}
              title='Setting up a team'
              text='If you would like to set up a team within CleverSparky to share site information with other users, you can do that from the menu in the top right of the screen.'
              textStyleClass='wide'
              isLastSlide={isLastSlide}
            />
          </SwiperSlide>
          <SwiperSlide>
            <OnboardingSlide 
              imageSrc={illus_web_mobile}
              title='Apps for web and mobile'
              text='The CleverSparky Web app helps you manage your sites and fittings remotely, including uploading CSV files to populate fittings quickly and easily. While on site, you can use your mobile device to scan the encoded LED on Clevertest Plus fittings or manually enter fitting, test and maintenance information.'
              textStyleClass='extra-wide'
              isLastSlide={isLastSlide}
            />
          </SwiperSlide>
        </Swiper>
        <div className={`pagination-control ${isLastSlide ? 'hidden' : ''}`} onClick={next}>
          <img src={icon_arrow_right_navy} alt="Right arrow" />
        </div>
      </div>
    </div>
  )
}
