import React, { useState, useEffect } from 'react';
import icon_back from '../assets/images/icon-back.svg';
import icon_email from '../assets/icons/icon-email.svg';
import icon_website from '../assets/icons/icon-web-site.svg';
import icon_phone from '../assets/icons/icon-phone.svg';

const Support = ({children, canView}) => {

  return (
    <>
      {children}
      <div id="support">
        <div className="content page">
          {!canView.userManagement() && (
            <div className="back-button">
              <a href='/sites' className="back-to-sites">
                <img className="icon large" src={icon_back} alt="Back Icon"/>
                  Back to Sites
              </a>
            </div>
          )}
          <h2 className="title">Support</h2>
          <div className="support-item">
            <span className='icon-container'><img src={icon_email} className='icon icon-email' alt="email icon"/></span><a href="mailto:support@cleversparky.com.au">support@cleversparky.com.au</a>
          </div>
          <div className="support-item">
            <span className='icon-container'><img src={icon_website} className='icon icon-web-site' alt="website icon"/></span><a href="http://clevertronics.com.au/cleversparky" target='_blank'>clevertronics.com.au/cleversparky</a>
          </div>
          <div className="support-item">
            <span className='icon-container phone'><img src={icon_phone} className='icon icon-phone' alt="phone icon"/></span><a href="https://itunes.apple.com/au/app/cleversparky/id1244793358?mt=8" target='_blank'>Download the mobile app</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Support;
