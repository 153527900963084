import React, { useState, useEffect, useContext } from 'react';
import white_logo from "../assets/icons/logo-white.svg";
import { validateEmail } from '../utils/validators';
import ForgotPasswordForm from './components/forms/ForgotPasswordForm';
import { resetPassword } from '../api/userApi';
import { store } from '../utils/store';

const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  const [didValidate, setDidValidate] = useState(false);
  const [isQueryPending, setIsQueryPending] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const { dispatch } = useContext(store);

  const requestPasswordReset = () => {
    setDidValidate(true);

    if (isValidEmail) {
      setIsQueryPending(true);

      const data = {
        data: {
          type: "passwords",
          attributes: {
            email
          }
        }
      }

      resetPassword(data).then(response => {
        setIsQueryPending(false);
        setEmailSent(true);
      }).catch(error => {
        setIsQueryPending(false);
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  const changeEmail = (e) => {
    setEmail(e.target.value)
    const validateResult = validateEmail(e.target.value);
    if (validateResult.validated) {
      setShowErrorMessageEmail(false);
      setErrorMessageEmail('');
      setIsValidEmail(true);
    } else {
      setShowErrorMessageEmail(true);
      setErrorMessageEmail(validateResult.message);
      setIsValidEmail(false);
    }
  }

  return (
    <div id="forgot-password" className="auth-page">
      <div className="content">
        <img src={white_logo} className="logo-large" />
        {emailSent ? (
          <div className="success">
            <p>We’ve sent a reset password email to {email}</p>
            <p className="confirmation">Please check your email to change your password.</p>
            <a href="/login" className="button green large auth-submit ember-view">OK</a>
          </div>
        ) : (
          <>
            <p className="instruction">Enter your email below and we’ll send you instructions on how to reset your password.</p>
            <ForgotPasswordForm 
              email={email}
              didValidate={didValidate}
              isQueryPending={isQueryPending}
              isValidEmail={isValidEmail}
              errorMessageEmail={errorMessageEmail}
              showErrorMessageEmail={showErrorMessageEmail}
              onChangeEmail={changeEmail} 
              onRequestPasswordReset={requestPasswordReset}
            />
          </>
        )}
       
      </div>
    </div>
  )
}

export default ForgotPassword;