import { BASE_URL, SIGNED, SIGNED_XLSX, SIGNED_SITE_DRAWINGS, API_VERSION, FITTING_LOOKUPS, ORGANISATIONS, ANALYTICS, SITE_PERMISSIONS, USERS, RESTRICTED_SITES, RELATIONSHIPS, PERMITTED_SITES, SITES, SITE_OWNER, XLSX } from "../settings/apiUrls";
import api from "./api";

export const signedRequest = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SIGNED,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
        return response.data;
    });
}

export const signedRequestXlsx = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SIGNED_XLSX,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
        return response.data;
    });
}

export const signedRequestSiteDrawings = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + SIGNED_SITE_DRAWINGS,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
        return response.data;
    });
}

export const uploadFile = (file, url, progressCB) => {
    const xhr = new XMLHttpRequest();
    if (progressCB) {
        xhr.upload.addEventListener("progress", progressCB, false);
    }
    xhr.open("PUT", url);
    xhr.send(file);
    return xhr;
}

export const postUploadXlsx = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + XLSX,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const lookupFittingType = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + FITTING_LOOKUPS,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const analytics = (organisationId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + organisationId + ANALYTICS + filters
    }).then((response) => {
        return response.data;
    });
}

export const getSitePermissions = (organisationId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + ORGANISATIONS + '/' + organisationId + SITE_PERMISSIONS + filters
    }).then((response) => {
        return response.data;
    });
}

export const getRestrictedSites = (userId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + USERS + '/' + userId + RESTRICTED_SITES + filters
    }).then((response) => {
        return response.data;
    });
}

export const permitSite = (userId, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + USERS + '/' + userId + RELATIONSHIPS + PERMITTED_SITES,
        data: data
    }).then((response) => {
        return response.data;
    });
}

export const removePermission = (permissionId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + SITE_PERMISSIONS + '/' + permissionId
    }).then((response) => {
        return response.data;
    });
}

export const transferSite = (siteId, data) => {
    return api({
        method: 'put',
        url: BASE_URL + API_VERSION + SITES + '/' + siteId + RELATIONSHIPS + SITE_OWNER,
        data: data
    }).then((response) => {
        return response.data;
    });
}