import React, { useState, useEffect, useContext } from 'react';
import icon_red_dot from '../../assets/images/icon-red-dot.svg';
import success_tick_white from '../../assets/icons/success-tick-white.svg';
import delete_icon from '../../assets/icons/delete.svg'
import FittingReinstateConfirmation from './FittingReinstateConfirmation';
import ModalView from './ModalView';
import { reinstateFitting } from '../../api/fittingApi';

export default ({children, fitting, isDeleted, tab, inputsDisabled, showSuccessConfirmation, disableEdit, onChangeTab, onEdit, onDelete, onRefresh}) => {

  const [showReinstateForm, setShowReinstateForm] = useState(false);

  const reinstate = () => {
    reinstateFitting(fitting.id).then(response => {
      setShowReinstateForm(false);
      onRefresh();
    }) 
  }


  return (
    <div className='fitting-panel'>
      <div className="fitting-header">
        <h2>{fitting.attributes['device-reference']}</h2>
        {inputsDisabled && !disableEdit && !isDeleted && <label className="edit" onClick={onEdit}>Edit</label>}
      </div>
      <div className="fitting-tab-menu">
        <a className={`tab-menu-action ${tab === 'details' && 'active'}`} onClick={() => onChangeTab('details')}>Fitting</a>
        <a className={`tab-menu-action ${tab === 'testStatus' && 'active'}`} onClick={() => onChangeTab('testStatus')}>
          Test & Status
          {fitting.attributes['faulty'] && <img className="faultyIndicator" src={icon_red_dot} alt="Faulty indicator"/>}
        </a>
        <a className={`tab-menu-action ${tab === 'maintenance' && 'active'}`} onClick={() => onChangeTab('maintenance')}>Maintenance</a>
      </div>
      <div className={`button green successConfirmation ${showSuccessConfirmation && 'show'}`}>
        <img className='icon' src={success_tick_white}  alt="Success Icon"/>
        Changes Saved
      </div>
      {children}
      {!isDeleted && inputsDisabled && (
        <div className="fitting-panel-actions delete-fitting">
          <div className="button small text-red" onClick={onDelete}>
            <img className='icon delete-icon' src={delete_icon}  alt="Delete Icon"/>
            Delete Fitting
          </div>
        </div>
      )}
      {isDeleted && (
        <div className="fitting-panel-actions" onClick={() => setShowReinstateForm(true)}>
          <div className="button small blue">
            Reinstate
          </div>
        </div>
      )}

      {showReinstateForm && 
        <ModalView>
          <FittingReinstateConfirmation onReinstateFitting={reinstate} onClose={() => setShowReinstateForm(false)} />
        </ModalView>
      }
    </div>
  )
}



