import React, { useState, useEffect, useContext } from "react";
import icon_back from "../../assets/images/icon-back.svg";
import groups_grey from "../../assets/icons/groups-grey.svg";
import icon_red_dot from "../../assets/images/icon-red-dot.svg";
import illus_fittings from "../../assets/images/illus-fittings.svg";
import icon_add_white from "../../assets/images/icon-add-white.svg";
import GroupDropdownMenu from "./GroupDropdownMenu";
import ReplacementOptionButton from "./ReplacementOptionButton";
import ModalView from "./ModalView";
import BatchDeleteConfirmation from "./BatchDeleteConfirmation";
import BatchMoveConfirmation from "./BatchMoveConfirmation";
import loading_icon from '../../assets/images/CleverSparky_Blink.gif';
import { pluralizer } from "../../utils/pluralizer";
import { moveFittingsToGroup, removeFittings } from "../../api/fittingApi";
import { validateGroupNone } from "../../utils/validators";
import { store } from "../../utils/store";

export default ({
  loading,
  fittings,
  fitting,
  site,
  group,
  sitesGroups,
  isOrderingReplacements,
  hasFaultyComponents,
  canDeleteGroup,
  viewFittingTourNames,
  isNotCommissioned,
  isCommissioned,
  notCommissionedFittingsCount,
  commissionedFittingsCount,
  isSelectAllFittings,
  isSelectAllComponents,
  isSelectAllDecals,
  fitleredbynamebool,
  isQueryPending,
  addOrderItem,
  removeOrderItem,
  isEmptyGroup,
  onCommissionedFilter,
  onAddFitting,
  onShowFitting,
  onBack,
  onUpdateGroup,
  onChangeFittings,
  onDeleteGroup,
}) => {
  const { dispatch, state } = useContext(store);

  const [displayFittings, setDisplayFittings] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [isSelectingFittings, setIsSelectingFittings] = useState(false);
  const [isMovingFittings, setIsMovingFittings] = useState(false);
  const [isDeletingFittings, setIsDeletingFittings] = useState(false);
  const [selectedFittings, setSelectedFittings] = useState([]);
  const [isActioningFittings, setIsActioningFittings] = useState(false);
  const [displayBatchConfirmationModal, setDisplayBatchConfirmationModal] =
    useState(false);
  const [
    displayBatchConfirmationSuccessModal,
    setDisplayBatchConfirmationSuccessModal,
  ] = useState(false);
  const [batchLength, setBatchLength] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [sorted, setSorted] = useState(false);
  const [filterText, setFilterText] = useState("");

  // Move to group
  const [moveGroup, setMoveGroup] = useState("");
  const [errorMessageMoveGroup, setErrorMessageMoveGroup] = useState("");
  const [showErrorMessageMoveGroup, setShowErrorMessageMoveGroup] =
    useState(false);

  const toggleDeleteFittings = () => {
    setIsActioningFittings(true);
    setIsSelectingFittings(true);
    setIsDeletingFittings(true);
  };

  const toggleMoveFittings = () => {
    setIsActioningFittings(true);
    setIsSelectingFittings(true);
    setIsMovingFittings(true);
  };

  const showOrderReplacements = () => {};

  const sortList = () => {
    let newDisplayFittings = JSON.parse(JSON.stringify(displayFittings));
    if (sorted) {
      newDisplayFittings = newDisplayFittings.sort((a, b) => {
        let textA = a.attributes['device-reference'].toLowerCase();
        let textB = b.attributes['device-reference'].toLowerCase();
        let newa = parseInt(a.attributes['device-reference'].match(/\d+/g));
        let newb =  parseInt(b.attributes['device-reference'].match(/\d+/g));
        if (newa && newb) {
          return (newa < newb) ? 1 : (newa > newb) ? -1 : 0;
        } else if (newa && !newb) {
          return -1;
        } else if (!newa && newb) {
          return 1;
        } else {
          return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
        }
      })
    } else {
      newDisplayFittings = newDisplayFittings.sort((a, b) => {
        let textA = a.attributes['device-reference'].toLowerCase();
        let textB = b.attributes['device-reference'].toLowerCase();
        let newa = parseInt(a.attributes['device-reference'].match(/\d+/g));
        let newb =  parseInt(b.attributes['device-reference'].match(/\d+/g));
        if (newa && newb) {
          return (newa < newb) ? -1 : (newa > newb) ? 1 : 0;
        } else if (newa && !newb) {
          return 1;
        } else if (!newa && newb) {
          return -1;
        } else {
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }
      })
    }
    setDisplayFittings(newDisplayFittings);
    setSorted(!sorted);
  };

  const cancelSelectFittings = () => {
    setIsActioningFittings(false);
    setIsSelectingFittings(false);
    setSelectedFittings([]);
    let fittingsNew = displayFittings;
    fittingsNew.forEach((f) => {
      f.checked = false;
    });
    setDisplayFittings(fittingsNew);
  };

  const moveFittings = () => {
    setDisplayBatchConfirmationModal(true);
  };

  const hideOrderReplacements = () => {};

  const confirmBatchDelete = () => {
    const data = { data: [] };
    selectedFittings.forEach((sf) => {
      data.data.push({
        type: "fittings",
        id: sf.id,
      });
    });
    removeFittings(data).then((response) => {
      setDisplayBatchConfirmationModal(false);
      setSelectedFittings([]);
      setIsDeletingFittings(false);
      setIsSelectingFittings(false);
      setIsActioningFittings(false);
      onChangeFittings();

      dispatch({ type: "SET POPUP", payload: "success" });
      dispatch({ type: "SET POPUP MESSAGES", payload: ["Fittings Deleted"] });
      setTimeout(() => {
        dispatch({ type: "SET POPUP", payload: "" });
      }, 3000);
    });
  };

  const deleteFittings = () => {
    setDisplayBatchConfirmationModal(true);
  };

  const closeBatchConfirmationModal = () => {
    setDisplayBatchConfirmationModal(false);
  };

  const checkMoveGroup = (input) => {
    const validateResult = validateGroupNone(input);
    if (validateResult.validated) {
      setShowErrorMessageMoveGroup(false);
      setErrorMessageMoveGroup("");
    } else {
      setShowErrorMessageMoveGroup(true);
      setErrorMessageMoveGroup(validateResult.message);
    }
    return validateResult.validated;
  };

  const changeMoveGroup = (e) => {
    setMoveGroup(e.target.value);
    checkMoveGroup(e.target.value);
  };

  const confirmBatchMoveFittings = () => {
    let validatedMoveGroup = checkMoveGroup(moveGroup);
    if (validatedMoveGroup) {
      let data = { data: [] };
      selectedFittings.forEach((sf) => {
        data.data.push({
          id: sf.id,
          type: "fittings",
        });
      });
      moveFittingsToGroup(moveGroup, data).then((response) => {
        setDisplayBatchConfirmationModal(false);
        setSelectedFittings([]);
        setIsMovingFittings(false);
        setIsSelectingFittings(false);
        setIsActioningFittings(false);
        onChangeFittings();

        dispatch({ type: "SET POPUP", payload: "success" });
        dispatch({
          type: "SET POPUP MESSAGES",
          payload: [`${pluralizer(batchLength, "Fitting")} Moved`],
        });
        setTimeout(() => {
          dispatch({ type: "SET POPUP", payload: "" });
        }, 3000);
      });
    }
  };

  const updateGroup = (group) => {
    setGroupName(group);
    onUpdateGroup(group);
  };

  const changeSelectedFittings = (fitting) => {
    let fittingsNew = fittings;
    fittingsNew.forEach((f) => {
      if (f.id === fitting.id) {
        f.checked = !fitting.checked;
      }
    });
    setDisplayFittings(fittingsNew);

    let selected = [];
    fittingsNew.forEach((f) => {
      if (f.checked) {
        selected.push(f);
      }
    });
    setSelectedFittings(selected);
  };

  const changeCommissionedFilter = (commissioned) => {
    setSelectedFittings([]);
    onCommissionedFilter(commissioned);
    let newFittings = displayFittings;
    newFittings.forEach((f) => {
      f.checked = false;
    });
    setDisplayFittings(newFittings);
  };

  const changeFilterText = (e) => {
    const filter = e.target.value;
    setFilterText(filter);
    if (!filter) setDisplayFittings(fittings);
    else {
      let newDisplayFittings = JSON.parse(JSON.stringify(displayFittings));
      newDisplayFittings = newDisplayFittings.filter((fitting) => {
        if (fitting.attributes["device-reference"].includes(filter))
          return true;
      });
      setDisplayFittings(newDisplayFittings);
    }
  };

  useEffect(() => {
    if (fittings) {
      setDisplayFittings(fittings);
    }
  }, [fittings]);

  useEffect(() => {
    setBatchLength(selectedFittings.length);
  }, [selectedFittings]);

  useEffect(() => {
    if (group) {
      let groupOptions = [];
      sitesGroups.forEach((sg) => {
        if (sg.id !== group.id) {
          groupOptions.push({
            label: sg.attributes["name"],
            value: sg.id,
          });
        }
      });
      setGroupOptions(groupOptions);
    }
  }, [sitesGroups]);

  return (
    <div>
      <div id="fitting-table" className="table">
        <table>
          <thead>
            {group && (
              <tr>
                <th className="table-row">
                  <div className="fitting-table-head">
                    <div className="inner">
                      <div className="back">
                        <a onClick={onBack}>
                          <img
                            src={icon_back}
                            className="icon-back"
                            alt="Back icon"
                          />
                        </a>
                        <img
                          src={groups_grey}
                          className="icon-groups-grey"
                          alt="Group icon grey"
                        />
                        {groupName ? groupName : group.attributes["name"]}
                      </div>
                    </div>
                  </div>

                  {isOrderingReplacements && hasFaultyComponents && (
                    <>
                      <div className="column replacement-fitting-option">
                        <div className="inner">Replace Fitting</div>
                      </div>
                      <div className="column replacement-faulty-component-option">
                        <div className="inner">Replace Faulty Component</div>
                      </div>
                      <div className="column replacement-decal-option">
                        <div className="inner">Replace Decal</div>
                      </div>
                    </>
                  )}
                  {isOrderingReplacements && !hasFaultyComponents && (
                    <>
                      <div className="column replacement-fitting-option">
                        <div className="inner">Replace Fitting</div>
                      </div>
                      <div className="column replacement-decal-option">
                        <div className="inner">Replace Decal</div>
                      </div>
                    </>
                  )}

                  <div className="dropdown-indicator">
                    {!isActioningFittings && (
                      <GroupDropdownMenu
                        fittings={fittings}
                        site={site}
                        group={group}
                        noFittingsOrGroups={true}
                        canDeleteGroup={canDeleteGroup}
                        onDeleteFittings={toggleDeleteFittings}
                        onToggleMoveFittings={toggleMoveFittings}
                        showOrderReplacements={showOrderReplacements}
                        onUpdateGroup={updateGroup}
                        onDeleteGroup={onDeleteGroup}
                      />
                    )}
                    <button style={{ paddingLeft: "10px" }} onClick={sortList}>
                      Sort List
                    </button>
                  </div>
                </th>
              </tr>
            )}

            {!group && (
              <tr>
                <th className="table-row">
                  <div className="dropdown-indicator">
                    <label
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      All Fittings
                    </label>
                  </div>

                  <div className="fitting-table-head">
                    <div className="inner"></div>
                  </div>

                  <div className="dropdown-indicator">
                    <button style={{ paddingLeft: "10px" }} onClick={sortList}>
                      Sort List
                    </button>
                  </div>
                </th>
              </tr>
            )}
          </thead>

          <tr>
            <td className="table-row filter-row">
              <div
                className={`filter-column ${isNotCommissioned && "active"}`}
                onClick={() => changeCommissionedFilter(false)}
              >
                Not Commissioned ({notCommissionedFittingsCount})
              </div>
              <div
                className={`filter-column ${isCommissioned && "active"}`}
                onClick={() => changeCommissionedFilter(true)}
              >
                Commissioned ({commissionedFittingsCount})
              </div>
            </td>
          </tr>

          {isOrderingReplacements && (
            <tr>
              <td className="table-row select-all-row">
                <div className="column device-reference" />
                <div className="select-all-column replace-all-fittings">
                  <input
                    type="checkbox"
                    id="fittings"
                    checked={isSelectAllFittings}
                  />
                  <label htmlFor="fittings" className="select-all-label" />
                </div>
                {hasFaultyComponents && (
                  <div
                    className={`select-all-column replace-all-components ${
                      isNotCommissioned && "hide"
                    }`}
                  >
                    <input
                      type="checkbox"
                      id="components"
                      checked={isSelectAllComponents}
                    />
                    <label htmlFor="components" className="select-all-label" />
                  </div>
                )}
                <div className="select-all-column replace-all-decals">
                  <input
                    type="checkbox"
                    id="decals"
                    checked={isSelectAllDecals}
                  />
                  <label htmlFor="decals" className="select-all-label" />
                </div>
                <div className="column row-indicator" />
              </td>
            </tr>
          )}

          <input
            type="text"
            id="filter"
            placeholder="Filter by Fitting ID"
            value={filterText}
            enter="textchangefilter"
            onChange={changeFilterText}
          />

          {fitleredbynamebool && fitting.deviceReference}

          {displayFittings && (
            <tbody className="multiselect-checkboxes">
              {displayFittings.map((fitting) => (
                <tr
                  key={displayFittings.indexOf(fitting)}
                  className={`${
                    isOrderingReplacements ? "order-replacement-row" : ""
                  }`}
                  onClick={
                    isActioningFittings ? null : () => onShowFitting(fitting)
                  }
                >
                  <div
                    className="sequence-element sequence-element-click-fitting-action"
                    stopName="clickFittingAction"
                    tourNames={viewFittingTourNames}
                  >
                    <td
                      className={`table-row ${
                        isSelectingFittings && "selecting-fittings"
                      }`}
                    >
                      <div className="column device-reference">
                        {isSelectingFittings && (
                          <>
                            <input
                              type="checkbox"
                              id={fitting.id}
                              checked={fitting.checked}
                              onChange={() => changeSelectedFittings(fitting)}
                            />
                            <label htmlFor={fitting.id}></label>
                          </>
                        )}
                        <div className="truncated">
                          {`${fitting.attributes["device-reference"]}${
                            fitting.attributes["location"]
                              ? " - " + fitting.attributes["location"]
                              : ""
                          }${
                            fitting.attributes["fitting-type"]
                              ? ` - ${fitting.attributes["fitting-type"]}`
                              : ""
                          }`}
                        </div>
                      </div>

                      {isOrderingReplacements && (
                        <div className="column replacement-fitting-option">
                          <ReplacementOptionButton
                            partType="fitting"
                            fitting={fitting}
                            addOrderItem={addOrderItem}
                            removeOrderItem={removeOrderItem}
                          />
                        </div>
                      )}

                      {isOrderingReplacements && hasFaultyComponents && (
                        <div className="column replacement-faulty-component-option">
                          <ReplacementOptionButton
                            partType="battery"
                            fitting={fitting}
                            addOrderItem={addOrderItem}
                            removeOrderItem={removeOrderItem}
                          />
                          <ReplacementOptionButton
                            partType="lamp"
                            fitting={fitting}
                            addOrderItem={addOrderItem}
                            removeOrderItem={removeOrderItem}
                          />
                          <ReplacementOptionButton
                            partType="control_gear"
                            fitting={fitting}
                            addOrderItem={addOrderItem}
                            removeOrderItem={removeOrderItem}
                          />
                        </div>
                      )}

                      {isOrderingReplacements && (
                        <div className="column replacement-decal-option">
                          <ReplacementOptionButton
                            partType="decal"
                            fitting={fitting}
                            addOrderItem={addOrderItem}
                            removeOrderItem={removeOrderItem}
                          />
                        </div>
                      )}

                      <div className="column row-indicator">
                        {fitting.attributes["faulty"] && (
                          <img src={icon_red_dot} alt="Faulty indicator" />
                        )}
                      </div>
                    </td>
                  </div>
                </tr>
              ))}
            </tbody>
          )}
        </table>

        {loading ? (
          <div className="loading">
            <img
              className="loading-icon"
              src={loading_icon}
              style={{ margin: "30px auto;" }}
            />
          </div>
        ) : fittings.length === 0 ? (
          <div className="empty-table">
            <div className="empty-image">
              <img src={illus_fittings} alt="Blue Row Icon" />
            </div>
            <div className="empty-message">
              {isEmptyGroup
                ? "To start populating this group, click on the add fitting button below."
                : "Add a fitting using the button below."}
            </div>
            <div className="empty-actions">
              <button
                className="action button icon green"
                onClick={onAddFitting}
              >
                <img className="icon" src={icon_add_white} alt="Add Icon" />
                Add Fitting
              </button>
            </div>
          </div>
        ) : (
          <div className="table-actions">
            <div className="left"></div>

            <div className="right">
              {isSelectingFittings && (
                <button
                  className="button standard blue cancel"
                  onClick={cancelSelectFittings}
                >
                  Cancel
                </button>
              )}

              {isSelectingFittings && isDeletingFittings && (
                <button
                  className="button standard red delete delete-multiple-fittings"
                  disabled={selectedFittings.length === 0}
                  onClick={deleteFittings}
                >
                  Delete Fittings
                </button>
              )}

              {isSelectingFittings && isMovingFittings && (
                <button
                  className="button standard green move move-multiple-fittings"
                  disabled={selectedFittings.length === 0}
                  onClick={moveFittings}
                >
                  Select Group
                </button>
              )}

              {!isSelectingFittings && isOrderingReplacements && (
                <button
                  className="button standard blue cancel"
                  onClick={hideOrderReplacements}
                >
                  Done
                </button>
              )}

              {!isSelectingFittings && !isOrderingReplacements && (
                <button
                  className="action button icon green"
                  onClick={onAddFitting}
                >
                  <img className="icon" src={icon_add_white} alt="Add Icon" />
                  Add Fitting
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {displayBatchConfirmationModal && (
        <ModalView>
          <>
            {isDeletingFittings && (
              <BatchDeleteConfirmation
                displayBatchConfirmationSuccessModal={
                  displayBatchConfirmationSuccessModal
                }
                batch={selectedFittings}
                batchName="fitting"
                onConfirm={confirmBatchDelete}
                closeBatchConfirmationModal={closeBatchConfirmationModal}
                isQueryPending={isQueryPending}
                onClose={closeBatchConfirmationModal}
              >
                Are you sure you want to delete{" "}
                {pluralizer(batchLength, "this")}{" "}
                {pluralizer(batchLength, "fitting")}?
              </BatchDeleteConfirmation>
            )}

            {isMovingFittings && (
              <BatchMoveConfirmation
                selectedGroup={moveGroup}
                groupOptions={groupOptions}
                batch={selectedFittings}
                batchLength={batchLength}
                isQueryPending={isQueryPending}
                displayBatchConfirmationSuccessModal={
                  displayBatchConfirmationSuccessModal
                }
                errorMessageMoveGroup={errorMessageMoveGroup}
                showErrorMessageMoveGroup={showErrorMessageMoveGroup}
                onConfirm={confirmBatchMoveFittings}
                closeBatchConfirmationModal={closeBatchConfirmationModal}
                onChange={changeMoveGroup}
                onClose={closeBatchConfirmationModal}
              >
                Select a group to move {pluralizer(batchLength, "this")}{" "}
                {pluralizer(batchLength, "fitting")} to and then tap the move{" "}
                {pluralizer(batchLength, "fitting")} button below.
              </BatchMoveConfirmation>
            )}
          </>
        </ModalView>
      )}
    </div>
  );
};
