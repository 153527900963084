import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { updateEmployment } from '../../../api/employmentApi';
import FormValidatedInput from '../FormValidatedInput';
import { store } from '../../../utils/store';

export default ({user, employment, inputsDisabled}) => {

  const { dispatch } = useContext(store);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [roleLabel, setRoleLabel] = useState('');
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [showGrantPurchasingRights, setShowGrantPurchasingRights] = useState(false);

  const toggleCheckboxSelected = () => {
    let employ = _.cloneDeep(employment);
    employ.attributes['checkout-permission'] = !isCheckboxSelected;
    let data = { data: employ };
    updateEmployment(employment.id, data).then(() => {
      setIsCheckboxSelected(!isCheckboxSelected);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Purchasing rights has been updated'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.attributes['first-name']);
      setLastName(user.attributes['last-name']);
      setEmail(user.attributes['email']);
      setPhone(user.attributes['phone']);
      setCountry(user.attributes['country']);
      setState(user.attributes['state']);
    }
    if (employment) {
      setRoleLabel(employment.attributes['role']);
      setIsCheckboxSelected(employment.attributes['checkout-permission'])
      if (employment.attributes['role'] === 'technician') setShowGrantPurchasingRights(true);
    }
  }, [user, employment])


  return (
    <form id="user-details-form" className="form">
      <div className="form-content">
        <div className="row row-animated split">
          <div className="half">
            <FormValidatedInput type="text" name='firstName' value={firstName} disabled={inputsDisabled} labelText='First Name' />
          </div>
          <div className="half">
            <FormValidatedInput type="text" name='lastName' value={lastName} disabled={inputsDisabled} labelText='Last Name' />
          </div>
        </div>
        <div className="row row-animated">
          <FormValidatedInput type="text" name='email' value={email} disabled={inputsDisabled} labelText='Email' />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type="text" name='phone' value={phone} disabled={inputsDisabled} labelText='Mobile' />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type="text" name='country' value={country} disabled={inputsDisabled} labelText='Country' />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type="text" name='state' value={state} disabled={inputsDisabled} labelText='State' />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type="text" name='roleLabel' value={roleLabel} disabled={inputsDisabled} labelText='User Type' />
        </div>

        {showGrantPurchasingRights && (
          <div className="row grant-purchasing-rights">
            <input type="checkbox" id='grant-purchasing-rights-checkbox' checked={isCheckboxSelected} onChange={toggleCheckboxSelected} />
            <label htmlFor='grant-purchasing-rights-checkbox'/>
            <label className="grant-purchasing-rights-label">{isCheckboxSelected ? 'Purchasing rights granted' : 'Grant purchasing rights'}</label>
          </div>
        )}
      </div>
    </form>
  )
}