import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { getPlaces } from '../../../api/placeApi';
import { updateSite } from '../../../api/siteApi';
import { store } from '../../../utils/store';
import { validateAddress, validateBusinessName } from '../../../utils/validators';
import SiteFormRows from './SiteFormRows';

export default ({site, isQueryPending, canEdit, onCancel, onSave}) => {

  const { dispatch } = useContext(store);

  const [didValidate, setDidValidate] = useState(true);
  const [unit, setUnit] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Address
  const [address, setAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [errorMessageAddress, setErrorMessageAddress] = useState('');
  const [showErrorMessageAddress, setShowErrorMessageAddress] = useState(false);

  const [placeId, setPlaceId] = useState('');

  // Business name
  const [businessName, setBusinessName] = useState('');
  const [isValidBusinessName, setIsValidBusinessName] = useState(false);
  const [errorMessageBusinessName, setErrorMessageBusinessName] = useState('');
  const [showErrorMessageBusinessName, setShowErrorMessageBusinessName] = useState(false);

  const confirmUpdateSite = () => {
    setSearchResults([]);

    let isAddressValidated = checkAddress();
    let isBusinessNameValidated = checkBusinessName(businessName);

    if (isAddressValidated && isBusinessNameValidated) {
      const data = {
        data: {
          id: site.id,
          attributes: {
            "unit": unit,
            "address": address,
            "business-name": businessName,
            "building-name": buildingName,
            "contact-name": contactName,
            "contact-email": contactEmail,
            "admin-email": adminEmail,
            "place-id": placeId,
            "created-at": site.attributes['created-at'],
            "permitted": site.attributes['permitted'],
            "permission-requested": site.attributes['permission-requested'],
            "nexttestdate": site.attributes['nexttestdate']
          },
          relationships: {
            "site-owner": site.relationships['site-owner'],
            "current-order": site.relationships['current-order']
          },
          type: "sites"
        }
      }

      updateSite(site.id, data).then(response => {
        dispatch({ type: 'SET POPUP', payload: 'success' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: ['Site Updated'] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
        onSave(response.data);
      })
    }
  }


  const checkBusinessName = (input) => {
    const validateResult = validateBusinessName(input);
    if (validateResult.validated) {
      setShowErrorMessageBusinessName(false);
      setErrorMessageBusinessName('');
      setIsValidBusinessName(true);
      return true;
    } 
    setShowErrorMessageBusinessName(true);
    setErrorMessageBusinessName(validateResult.message);
    setIsValidBusinessName(false);
  }

  const changeBusinessName = (e) => {
    const input = e.target.value;
    setBusinessName(input);
    checkBusinessName(input);
  }

  const checkAddress = () => {
    const validateResult = validateAddress(address, placeId);
    if (validateResult.validated) {
      setShowErrorMessageAddress(false);
      setErrorMessageAddress('');
      setIsValidAddress(true);
      return true;
    } 
    setShowErrorMessageAddress(true);
    setErrorMessageAddress(validateResult.message);
    setIsValidAddress(false);
  }

  const changeAddress = (e) => {
    setPlaceId('');
    const searchText = e.target.value;
    setAddress(searchText);
    getPlaces(searchText).then(response => {
      setSearchResults(response.data);
    });
  }

  const selectAddress = (selectedAddress, placeId) => {
    setAddress(selectedAddress);
    setPlaceId(placeId);
    setSearchResults([]);
    setErrorMessageAddress('');
    setShowErrorMessageAddress(false);
  }

  useEffect(() => {
    if (site) {
      setUnit(site.attributes['unit']);
      setAddress(site.attributes['address']);
      setBusinessName(site.attributes['business-name']);
      setBuildingName(site.attributes['building-name']);
      setContactName(site.attributes['contact-name']);
      setContactEmail(site.attributes['contact-email']);
      setAdminEmail(site.attributes['admin-email']);
      setPlaceId(site.attributes['place-id']);
    }

  }, [])


  return (
    <form id="site-form" className="form">
      <SiteFormRows 
        unit={unit} 
        address={address} 
        businessName={businessName} 
        buildingName={buildingName} 
        contactName={contactName} 
        contactEmail={contactEmail} 
        adminEmail={adminEmail} 
        placeId={placeId} 
        searchResults={searchResults}
        inputsDisabled={!canEdit} 
        didValidate={didValidate}
        isValidAddress={isValidAddress}
        errorMessageAddress={errorMessageAddress}
        showErrorMessageAddress={showErrorMessageAddress}
        isValidBusinessName={isValidBusinessName}
        errorMessageBusinessName={errorMessageBusinessName}
        showErrorMessageBusinessName={showErrorMessageBusinessName}
        onClickResult={selectAddress}
        onChangeUnit={(e) => setUnit(e.target.value)}
        onChangeAddress={changeAddress}
        onChangeBusinessName={changeBusinessName}
        onChangeBuildingName={(e) => setBuildingName(e.target.value)}
        onChangeContactName={(e) => setContactName(e.target.value)}
        onChangeContactEmail={(e) => setContactEmail(e.target.value)}
        onChangeAdminEmail={(e) => setAdminEmail(e.target.value)}
      />

      <div className="actions">
        {canEdit && (
          <>
            <button className='button standard blue' type="button" name="Cancel" onClick={onCancel} >Cancel</button>
            <button className='button standard green space-right' type="button" name="Save" disabled={isQueryPending} onClick={confirmUpdateSite}>Save</button>
          </>
        )}
      </div>
    </form>
  )
}