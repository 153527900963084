import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { duplicateTestEvent, updateTestEvent } from '../../../api/testEventApi';
import { store } from '../../../utils/store';
import { validateEndDate, validateStartDate } from '../../../utils/validators';
import FormValidatedInput from '../FormValidatedInput';

export default ({testEvent, type, isQueryPending, onNext, onUpdateTestEvent}) => {

  const { dispatch } = useContext(store);

  const [isDuplicateMode, setIsDuplicateMode] = useState(false);
  const [didValidate, setDidValidate] = useState(false);

  // Start date
  const [startDate, setStartDate] = useState('');
  const [errorMessageStartDate, setErrorMessageStartDate] = useState('');
  const [showErrorMessageStartDate, setShowErrorMessageStartDate] = useState(false);

  // End date
  const [endDate, setEndDate] = useState('');
  const [errorMessageEndDate, setErrorMessageEndDate] = useState('');
  const [showErrorMessageEndDate, setShowErrorMessageEndDate] = useState(false);

  const changeStartDate = (value) => {
    checkStartDate(value);
    setStartDate(value);
  }

  const checkStartDate = (input) => {
    const validateResult = validateStartDate(input);
    if (validateResult.validated) {
      setShowErrorMessageStartDate(false);
      setErrorMessageStartDate('');
    } else {
      setShowErrorMessageStartDate(true);
      setErrorMessageStartDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeEndDate = (value) => {
    checkEndDate(value);
    setEndDate(value);
  }

  const checkEndDate = (input) => {
    const validateResult = validateEndDate(startDate, input);
    if (validateResult.validated) {
      setShowErrorMessageEndDate(false);
      setErrorMessageEndDate('');
    } else {
      setShowErrorMessageEndDate(true);
      setErrorMessageEndDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const next = (e) => {
    e.preventDefault();
    let isValidStartDate = false, isValidEndDate = false;
    if (checkStartDate(startDate)) {
      isValidStartDate = true;
    }
    if (checkEndDate(endDate)) {
      isValidEndDate = true;
    }
    if (isValidStartDate && isValidEndDate) {
      onNext(startDate, endDate);
    }
  }

  const save = (e) => {
    e.preventDefault();

    let newTestEvent = JSON.parse(JSON.stringify(testEvent));
    if (type === 'edit') {
      newTestEvent.attributes['start-date'] = moment(startDate).format('yyyy-MM-DD');
      newTestEvent.attributes['end-date'] = moment(endDate).format('yyyy-MM-DD');
      
      let data = {
        data: newTestEvent
      }
      updateTestEvent(testEvent.id, data).then(response => {
        onUpdateTestEvent(newTestEvent);
        dispatch({ type: 'SET POPUP', payload: 'success' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: ['Date Range Updated'] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      }).catch(error => {
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: [error.response.data.errors[0]] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
    
    if (type === 'duplicate') {
      newTestEvent.attributes['start-date'] = moment(startDate).format('yyyy-MM-DD');
      newTestEvent.attributes['end-date'] = moment(endDate).format('yyyy-MM-DD');
      delete newTestEvent.id;
      delete newTestEvent.relationships;
      delete newTestEvent.type;

      let data = {
        data: newTestEvent
      }
      duplicateTestEvent(testEvent.id, data).then(response => {
        setTimeout(() => onUpdateTestEvent(response.data), 1000);
        dispatch({ type: 'SET POPUP', payload: 'success' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: ['Test Event Successfully Duplicated'] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      }).catch(error => {
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: [error.response.data.errors[0]] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  useEffect(() => {
    if (testEvent) {
      setStartDate(new Date(moment(testEvent.attributes['start-date'])));
      setEndDate(new Date(moment(testEvent.attributes['end-date'])));
    }
  }, [testEvent])

  return (
    <form id="test-event-form" className="form">
      <div className="form-content">
        <div className="row row-animated split">
          <div className="half start-date">
            <div className="date-picker-label">
              {isDuplicateMode ? 'Select a new start date' : 'Select a start date'}
            </div>
            <FormValidatedInput type='date' name='startDate' value={startDate} didValidate={didValidate} labelText='Start Date' errorMessage={errorMessageStartDate} showErrorMessage={showErrorMessageStartDate} onChange={changeStartDate}/>
          </div>
          <div className="half end-date">
            <div className="date-picker-label">
              {isDuplicateMode ? 'Select a new end date' : 'Select an end date'}
            </div>
            <FormValidatedInput type='date' name='endDate' value={endDate} didValidate={didValidate} labelText='End Date' errorMessage={errorMessageEndDate} showErrorMessage={showErrorMessageEndDate} onChange={changeEndDate}/>
          </div>
        </div>
      </div>
      <div className="form-actions">
        {(type === 'edit' || type === 'duplicate') ? (
          <button className="button standard blue add-group-submit" disabled={isQueryPending} onClick={save}>
            {type === 'edit' ? 'Update Range' : 'Create Test Event'}
          </button>
        ) : (
          <button className="button standard blue" disabled={isQueryPending} onClick={next}>
            Next
          </button>
        )}
      </div>
    </form>
  )
}



