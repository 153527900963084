import React, { useState, useEffect, useContext } from 'react';
import MaintenanceRow from './MaintenanceRow';

export default ({rows, testEvent, testEventFitting, isDeleted, fitting, save, update, isQueryPending, onUpdate}) => {

  return (
    <div className='maintenance-rows form'>
      {rows.map(row => (
        <MaintenanceRow key={rows.indexOf(row)} testEvent={testEvent} isDeleted={isDeleted} testEventFitting={testEventFitting} maintenance={row} fitting={fitting} edit='edit' save={save} update={update} cancel='cancel' isQueryPending={isQueryPending} onUpdate={onUpdate}/>
    ))}
    </div>
  )
}