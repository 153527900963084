import React, { useState, useEffect, useContext } from 'react';
import FormValidatedSelect from '../FormValidatedSelect';
import FormValidatedInput from '../FormValidatedInput';
import { durationOptions, failTestStatusOptions, passTestStatusOptions } from '../../../utils/variables';
import { validateRepairDate, validateTestStatus } from '../../../utils/validators';
import { validateRunTime } from '../../../utils/validators';
import moment from 'moment';
import { addTest } from '../../../api/testApi';
import { store } from '../../../utils/store';


export default ({testEvent, fittings, isQuickTestPassForm, isQueryPending, onSubmitForm}) => {

  const [testStatus, setTestStatus] = useState('');
  const [showErrorMessageTestStatus, setShowErrorMessageTestStatus] = useState(false);
  const [errorMessageTestStatus, setErrorMessageTestStatus] = useState('');

  const [repairDate, setRepairDate] = useState('');
  const [errorMessageRepairDate, setErrorMessageRepairDate] = useState('');
  const [showErrorMessageRepairDate, setShowErrorMessageRepairDate] = useState(false);

  const [runTime, setRunTime] = useState('');
  const [errorMessageRunTime, setErrorMessageRunTime] = useState('');
  const [showErrorMessageRunTime, setShowErrorMessageRunTime] = useState(false);

  const [nextTestDate, setNextTestDate] = useState('');
  const [requiredDuration, setRequiredDuration] = useState('');

  const submit = (e) => {
    e.preventDefault();
    let testStatusValidated = checkTestStatus(testStatus);
    let testDateValidated = checkRepairDate(repairDate);
    let runTimeValidated = checkRunTime(runTime);
    if (testStatusValidated && testDateValidated && runTimeValidated) {
      let dataSet = [];
      fittings.forEach(fitting => {
        if (fitting.checked) {
          dataSet.push({
            attributes: {
              "test-status": testStatus,
              "test-date": moment(repairDate).format('YYYY-MM-DD'),
              "run-time": requiredDuration,
              "next-test-date": nextTestDate ? moment(nextTestDate).format('YYYY-MM-DD') : '',
              "required-duration": requiredDuration
            },
            relationships: {
              fitting: {
                data: {
                  type: "fittings",
                  id: fitting.id
                }
              }
            },
            "type": "tests"
          })
        }
      })
      const data = {
        data: dataSet
      }
      addTest(data).then(response => {
        onSubmitForm();
      })
    }
  }

  const checkTestStatus = (input) => {
    const validateResult = validateTestStatus(input);
    if (validateResult.validated) {
      setShowErrorMessageTestStatus(false);
      setErrorMessageTestStatus('');
    } else {
      setShowErrorMessageTestStatus(true);
      setErrorMessageTestStatus(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeTestStatus = (e) => {
    setTestStatus(e.target.value)
    checkTestStatus(e.target.value)
  }

  const changeRepairDate = (value) => {
    setRepairDate(value)
    checkRepairDate(value)
  }

  const checkRepairDate = (input) => {
    const validateResult = validateRepairDate(input);
    if (validateResult.validated) {
      setShowErrorMessageRepairDate(false);
      setErrorMessageRepairDate('');
    } else {
      setShowErrorMessageRepairDate(true);
      setErrorMessageRepairDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const checkRunTime = (input) => {
    const validateResult = validateRunTime(input);
    if (validateResult.validated) {
      setShowErrorMessageRunTime(false);
      setErrorMessageRunTime('');
    } else {
      setShowErrorMessageRunTime(true);
      setErrorMessageRunTime(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeRunTime = (e) => {
    setRunTime(e.target.value)
    checkRunTime(e.target.value)
  }

  const changeNextTestDate = (value) => {
    setNextTestDate(value)
  }

  const changeRequiredDuration = (e) => {
    setRequiredDuration(e.target.value);
  }

  useEffect(() => {
    setRepairDate(new Date(moment(testEvent.attributes['start-date'])));
    setRequiredDuration(testEvent.attributes['default-required-duration']);
    if (isQuickTestPassForm) {
      setTestStatus(passTestStatusOptions[0].value);
    }
  }, [])

  return (
    <form id="quick-test-form" className="form"  style={{marginTop: -25}}>
      <div className="form-content" >
        <div className="header-label-row">
          <label>Test</label>
        </div>
        <div className="row row-animated test-status-row">
          <FormValidatedSelect type='text' name='testStatus' options={isQuickTestPassForm ? passTestStatusOptions : failTestStatusOptions} value={testStatus} disabled={isQuickTestPassForm} showErrorMessage={showErrorMessageTestStatus} errorMessage={errorMessageTestStatus} labelText='Test Status' onChange={changeTestStatus}/>
        </div>
        <div className="row row-animated test-date-row">
          <FormValidatedInput type='date' name='testDate' value={repairDate} labelText='Test Collection Date' showErrorMessage={showErrorMessageRepairDate} errorMessage={errorMessageRepairDate} onChange={changeRepairDate} />
        </div>
        <div className="row row-animated run-time-row" style={{display: 'none'}}>
          <FormValidatedInput type='text' name='runTime' value={runTime} labelText='Test Run Time (In Minutes)'showErrorMessage={showErrorMessageRunTime} errorMessage={errorMessageRunTime} onChange={changeRunTime} />
        </div>
        <div className="row row-animated last-test-row">
          <FormValidatedInput type='date' name='nextTestDate' value={nextTestDate} labelText='Next Test Date' onChange={changeNextTestDate} minDate={new Date(moment())}/>
        </div>
        <div className="row row-animated required-duration-row">
          <FormValidatedSelect type='text' name='requiredDuration' options={durationOptions} value={requiredDuration} labelText='Required Duration' onChange={changeRequiredDuration}/>
        </div>
    
      </div>
      <div className="form-actions">
        <button className="button standard blue" disabled={isQueryPending} onClick={submit}>
          Save
        </button>
      </div>
    </form>
  )
}


