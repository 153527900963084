import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import ResetPasswordForm from './components/forms/ResetPasswordForm';
import logo_white from "../assets/icons/logo-white.svg";
import success_tick from "../assets/icons/success-tick-white.svg";
import { store } from '../utils/store';

export default () => {

  const { dispatch, state } = useContext(store);
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [resetToken, setResetToken] = useState('');

  useEffect(() => {
    setResetToken(queryParameters.get('reset_password_token'));
  }, []);

  return (
    <div id='reset-password' className="auth-page">
      <div className="content">
        <a href="/login">
          <img src={logo_white} className="logo-large" />
        </a>
        {passwordUpdated ? (
          <div id="success">
            <h1 className="success-heading"><img src={success_tick} className='success-tick' alt="Success tick white"/>Sucess!</h1>
            <h3 className="success-message">Your password has been updated. </h3>
            <p className="subheading">You can now use your new password to login to the CleverSparky Web and Mobile app.</p>
            <a href='/login' tagName='button' className='button large green auth-submit'>Log In</a>
          </div>
        ) : (
          <ResetPasswordForm resetToken={resetToken} onPasswordUpdated={() => setPasswordUpdated(true)}/>
        )}
      </div>
    </div>
  )
}