import { BASE_URL, API_VERSION, REPORTS } from "../settings/apiUrls";
import api from "./api";

export const createReport = (reportType, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + REPORTS + '/' + reportType,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const getReport = (reportType, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + REPORTS + '/' + reportType,
        data: data,
    }).then((response) => {
        return response;
    });
}