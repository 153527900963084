import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import FittingInfoBanner from '../FittingInfoBanner';
import icon_alert_blue from '../../../assets/images/icon-alert-blue.svg'
import FittingDetailsFormRows from './FittingDetailsFormRows';
import { validateDeviceReference } from '../../../utils/validators';
import { updateFitting } from '../../../api/fittingApi';
import { getGroup } from '../../../api/groupApi';

export default ({fitting, group, isQueryPending, inputsDisabled, onUpdate, onCancel}) => {

  const [shouldShowTestEventWarning, setShouldShowTestEventWarning] = useState(false);

  const [serialNumber, setSerialNumber] = useState('');
  const [fittingType, setFittingType] = useState('');
  const [description, setDescription] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupID, setGroupID] = useState('');
  const [location, setLocation] = useState('');
  const [installDate, setInstallDate] = useState('');
  const [building, setBuilding] = useState('');
  const [level, setLevel] = useState('');
  const [switchboard, setSwitchboard] = useState('');
  const [circuit, setCircuit] = useState('');
  const [assetCode, setAssetCode] = useState('');

  // Device reference
  const [deviceReference, setDeviceReference] = useState('');
  const [isValidDeviceReference, setIsValidDeviceReference] = useState(false);
  const [errorMessageDeviceReference, setErrorMessageDeviceReference] = useState('');
  const [showErrorMessageDeviceReference, setShowErrorMessageDeviceReference] = useState(false);

  const changeDeviceReference = (e) => {
    setDeviceReference(e.target.value)
    checkDeviceReference(e.target.value)
  }

  const checkDeviceReference = (input) => {
    const validateResult = validateDeviceReference(input);
    if (validateResult.validated) {
      setShowErrorMessageDeviceReference(false);
      setErrorMessageDeviceReference('');
      setIsValidDeviceReference(true);
    } else {
      setShowErrorMessageDeviceReference(true);
      setErrorMessageDeviceReference(validateResult.message);
      setIsValidDeviceReference(false);
    }
    return validateResult.validated;
  }

  const save = (e) => {
    e.preventDefault();
    let deviceReferenceValidated = checkDeviceReference(deviceReference);
    if (deviceReferenceValidated) {
      const data = {
        data: {
          id: fitting.id,
          attributes: {
            "serial-number": serialNumber,
            "fitting-type": fittingType,
            "description": description,
            "device-reference": deviceReference,
            "location": location,
            "building": building,
            "level": level,
            "switchboard": switchboard,
            "circuit": circuit,
            "asset-code": assetCode,
            "install-date": moment(installDate).format('YYYY-MM-DD')
          },
          relationships: fitting.relationships,
          type: "fittings"
        }
      }
      updateFitting(fitting.id, data).then(response => {
        onUpdate(response.data);
      })
    }
  }

  const cancel = () => {
    onCancel();
    getOriginalInputs();
  }

  const getOriginalInputs = () => {
    if (fitting) {
      setSerialNumber(fitting.attributes['serial-number'] ? fitting.attributes['serial-number'] : '');
      setFittingType(fitting.attributes['fitting-type'] ? fitting.attributes['fitting-type'] : '');
      setDescription(fitting.attributes['description'] ? fitting.attributes['description'] : '');
      
      setDeviceReference(fitting.attributes['device-reference'] ? fitting.attributes['device-reference'] : '');
      setLocation(fitting.attributes['location'] ? fitting.attributes['location'] : '');
      setInstallDate(new Date(fitting.attributes['install-date']));
      setBuilding(fitting.attributes['building'] ? fitting.attributes['building'] : '');
      setLevel(fitting.attributes['level'] ? fitting.attributes['level'] : '');
      setSwitchboard(fitting.attributes['switchboard'] ? fitting.attributes['switchboard'] : '');
      setCircuit(fitting.attributes['circuit'] ? fitting.attributes['circuit'] : '');
      setAssetCode(fitting.attributes['asset-code'] ? fitting.attributes['asset-code'] : '');
    }
    if (group) {
      setGroupID(group.id);
      setGroupName(group.attributes['name'] ? group.attributes['name'] : '');
    }
  }

  useEffect(() => {
    getOriginalInputs();
  }, [fitting, group])

  return (
    <form id="fitting-details-form" className="form fitting-form">
      <div className="form-content">
        {!inputsDisabled && <FittingInfoBanner />}
        {shouldShowTestEventWarning && (
          <div className="test-event-warning">
            <img src={icon_alert_blue} alt="Alert Icon" />
            The install date falls outside of this test event.
          </div>
        )}
        <FittingDetailsFormRows 
          fitting={fitting} 
          inputsDisabled={inputsDisabled}
          serialNumber={serialNumber}
          fittingType={fittingType}
          description={description}
          groupName={groupName} 
          groupID={groupID} 
          deviceReference={deviceReference} 
          location={location} 
          installDate={installDate} 
          building={building} 
          level={level} 
          switchboard={switchboard} 
          circuit={circuit} 
          assetCode={assetCode}
          didValidate={true}
          isValidDeviceReference={isValidDeviceReference}
          showErrorMessageDeviceReference={showErrorMessageDeviceReference}
          errorMessageDeviceReference={errorMessageDeviceReference}
          onChangeSerialNumber={e => setSerialNumber(e.target.value)}
          onChangeFittingType={e => setFittingType(e.target.value)}
          onChangeDescription={e => setDescription(e.target.value)}
          onChangeGroupName={e => setGroupName(e.target.value)}
          onChangeGroupID={e => setGroupID(e.target.value)}
          onChangeDeviceReference={changeDeviceReference}
          onChangeLocation={e => setLocation(e.target.value)}
          onChangeInstallDate={value => setInstallDate(value)}
          onChangeBuilding={e => setBuilding(e.target.value)}
          onChangeLevel={e => setLevel(e.target.value)}
          onChangeSwitchboard={e => setSwitchboard(e.target.value)}
          onChangeCircuit={e => setCircuit(e.target.value)}
          onChangeAssetCode={e => setAssetCode(e.target.value)}
        />
      </div>
      {!inputsDisabled && (
        <div className="fitting-actions">
          <button className="button small blue edit-actions" disabled={isQueryPending} onClick={save}>
            Save
          </button>
          <div className="button small text-blue edit-actions" onClick={cancel}>
            Cancel
          </div>
        </div>
      )}
    </form>
  )
}