import React, { useState, useEffect } from 'react';
import close_white from '../../assets/icons/close-white.svg';
import icon_add_blue from '../../assets/images/icon-add-blue.svg';

export default ({isSelected, partTypeObj}) => {

  const buttonClicked = () => {

  }

  return (
    <button  className={`button border-grey ${isSelected ? 'selected blue' : 'unselected light-blue text-blue'}`} onClick={buttonClicked}>
      <img className="icon" src={isSelected ? close_white : icon_add_blue} alt="button icon"/>
      {partTypeObj.label}
    </button>
  )
}


