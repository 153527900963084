import React, { useState, useEffect, useContext } from 'react';
import icon_search from '../../assets/images/icon-search.svg';

export default ({searchText, placeholder, onChange}) => {
    return (
        <div className='search-bar'>
            <img src={icon_search} alt="Search Icon"/>
            <input type="text" value={searchText} className="search-input" placeholder={placeholder} key-up='query' onChange={onChange} />
        </div>
    )
}