import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

export default ({triggerContent}) => {

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const trigger = () => {
    setIsOpen(true);
  }

  const transitionTo = (route) => {
    navigate('/' + route);
  }

  return (
    <div className='dropdown-menu users-dropdown-menu' tabIndex="9999" onBlur={() => setIsOpen(false)}>
      <div className='dropdown-menu-trigger' onClick={trigger} >
        {triggerContent}
      </div>

      {isOpen && (
        <div className="users-dropdown-menu-list">
          <div className="dropdown-menu-list-item users" onClick={() => transitionTo('users')} >
            Users
          </div>
          <div className="dropdown-menu-list-item site-requests" onClick={() => transitionTo('site-requests')} >
            Site Requests
          </div>
        </div>
      )}
    </div>
  )
}



