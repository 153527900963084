import React, { useState, useEffect, useRef } from 'react';
import icon_alert from '../../assets/images/icon-alert.svg';

export default ({name, labelText, value, isValid, options, showErrorMessage, errorMessage, showWarningMessage, warningMessage, disabled, onChange}) => {

  return (
    <>
      <div className={`input ${showErrorMessage && 'validation-error'} ${value && 'focused'} ${!disabled && 'focused-border'}`}>
        <label htmlFor={name} className="label label-animated">{labelText}</label>

        <select 
          name={name} 
          value={value} 
          onChange={onChange} 
          disabled={disabled} 
          className={`${showErrorMessage && 'error'} ${isValid && 'valid'}`}
        >
          {options.map(option => (
            <option key={options.indexOf(option)} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      
      <div className='input-error'>
        {showErrorMessage && (
          <div className='error'>
            <img src={icon_alert} className='alert-icon' alt="Alert Icon" />
            {errorMessage}
          </div>
        )}
      
        {showWarningMessage && (
          <div className='warning'>
            {warningMessage}
          </div>
        )}
      </div>
    </>
  )
}