import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import success_tick from '../../assets/icons/success-tick.svg';

export default () => {

    const navigate = useNavigate();

    const backToSites = () => {
        navigate('/sites');
    }

    return (
        <div className="change-password-success">
            <img src={success_tick} alt="Success tick" />
            <h3 className="success-text">Success!</h3>
            <p className="subheading">Your password has been successfully changed. </p>
            <button className='button large green' onClick={backToSites}> Back to Sites </button>
        </div>
    )
}