import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import icon_back from '../../assets/images/icon-back.svg';
import icon_share from '../../assets/images/icon-share.svg';
import icon_download from '../../assets/images/icon-download.svg';
import icon_report from '../../assets/images/icon-report.svg';
import FileUploadProgressBar from './FileUploadProgressBar';
import FileUploader from './FileUploader';
import { fittingsUpload } from '../../api/fittingApi';
import { postUploadXlsx, signedRequestXlsx, uploadFile } from '../../api/otherApi';
import { BASE_URL } from '../../settings/apiUrls';

export default ({ user, site, siteName, address, viewSiteTourNames, noGroups, onReportModal, onFileUploadError }) => {

  const navigate = useNavigate();

  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [xhr, setXhr] = useState(null);

  const goBack = () => {
    navigate('/sites');
  }
  
  const changeUploadFile = async (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFilePath(e.target.value);
      setFileName(uploadedFile.name);
      let blob = uploadedFile.slice(0, uploadedFile.size, uploadedFile.type); 
      let uploadFileName = Math.floor(Math.random() * 100000) + 1 + uploadedFile.name;
      const xlsxFile = new File([blob], uploadFileName, {type: uploadedFile.type});
      const formDataXlsx = new FormData();
      formDataXlsx.append('file', uploadFileName);
      formDataXlsx.append('type', uploadedFile.type);
      formDataXlsx.append('userid', user.id);
      let xlsxRaw = await signedRequestXlsx(formDataXlsx);
      let xlsxParsed = xlsxRaw.split('?')[0];
      let xhr = uploadFile(xlsxFile, xlsxRaw, (event) => {
        let percent = (event.loaded / event.total) * 100;
        setUploadPercent(Math.floor(percent));
        if (percent === 100) {
          setTimeout(() => {
            setUploadPercent(0);
            setUploadSuccess(true);
            const data = {
              data: {
                  attributes: {
                      site_id: site.id,
                      url: xlsxParsed
                  },
                  type: "fileupload"
              }
            }
            postUploadXlsx(data).then(response => {
              if (response === 'Update successful') {
                window.location.reload(false);
              } else {
                onFileUploadError(response);
                dismissProgressBar();
              }
            })
          }, 2000);
        }
      });
      setXhr(xhr);
    }
  }

  const dismissProgressBar = () => {
    setFilePath('');
    setFileName('');
    setUploadPercent(0);
    setUploadSuccess(false);
  }

  const cancelUpload = () => {
    xhr.abort();
    setFilePath('');
    setFileName('');
    setUploadPercent(0);
  }

  return (
    <div className="site-header">
      <div className="back-button">
        <a className="site-header-action" onClick={goBack}>
          <img className="icon large" src={icon_back} alt="Back Icon"/>
            Back to Sites
        </a>
      </div>
      <div className="site-info-header">
        <div className="site-info">
          <h4>{siteName}</h4>
          <label className="label address-label">{address}</label>
        </div>
    
        <div className="site-header-actions">
          <div className="site-header-action">
            <div stopName='csvDownloadTemplateAction' tourNames={viewSiteTourNames}>
              <a href="https://s3.ap-southeast-2.amazonaws.com/cleversparky.assets.com/files/Newexceltemplate.xlsx" id='download' className='site-header-action text-blue button white'>
                <img className="icon" src={icon_download} alt="Download Icon"/>
                Spreadsheet Upload Template
              </a>
            </div>
          </div>
          <div className="site-header-action">
            <div id='upload' className="site-header-action text-blue">
              <FileUploader file={filePath} onChange={changeUploadFile}>
                <div>
                  <img className="icon" src={icon_share} alt="Upload Icon"/>
                  {' Upload Spreadsheet'}
                 </div>
              </FileUploader>
            </div>
          </div>
          <div className="site-header-action">
            <div>
              <button id='report' disabled={noGroups} className="site-header-action text-blue button white" onClick={onReportModal}>
                <img className="icon" src={icon_report} alt="Report Icon"/>
                Report
              </button>
            </div>
          </div>
        </div>

        {filePath && (
          <FileUploadProgressBar 
            progress={uploadPercent}
            fileName={fileName}
            uploadSuccess={uploadSuccess}
            onCancelUpload={cancelUpload}
            onClose={dismissProgressBar}
          />
        )}
      </div>
    </div>
  )
}