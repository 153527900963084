import React, { useState, useEffect, useContext } from 'react';
import SiteFormRows from './SiteFormRows';
import icon_add from '../../../assets/images/icon-add-white.svg';

export default ({
    site, 
    unit, 
    address, 
    businessName, 
    buildingName, 
    contactName, 
    contactEmail, 
    adminEmail, 
    isValidAddress,
    errorMessageAddress,
    showErrorMessageAddress,
    isValidBusinessName,
    errorMessageBusinessName,
    showErrorMessageBusinessName,
    placeId, 
    canEdit, 
    didValidate, 
    searchResults,
    isQueryPending, 
    onSave,
    onClickAddressResult,
    onChangeUnit,
    onChangeAddress,
    onChangeBusinessName,
    onChangeBuildingName,
    onChangeContactName,
    onChangeContactEmail,
    onChangeAdminEmail
  }) => {

  return (
    <form id="site-form-new" className="form">
      <div className="form-content">
        <SiteFormRows 
          site={site} 
          unit={unit} 
          address={address} 
          businessName={businessName} 
          buildingName={buildingName} 
          contactName={contactName} 
          contactEmail={contactEmail} 
          adminEmail={adminEmail} 
          placeId={placeId} 
          canEdit={canEdit} 
          searchResults={searchResults}
          didValidate={didValidate} 
          isValidAddress={isValidAddress}
          errorMessageAddress={errorMessageAddress}
          showErrorMessageAddress={showErrorMessageAddress}
          isValidBusinessName={isValidBusinessName}
          errorMessageBusinessName={errorMessageBusinessName}
          showErrorMessageBusinessName={showErrorMessageBusinessName}
          onClickResult={onClickAddressResult}
          onChangeUnit={onChangeUnit}
          onChangeAddress={onChangeAddress}
          onChangeBusinessName={onChangeBusinessName}
          onChangeBuildingName={onChangeBuildingName}
          onChangeContactName={onChangeContactName}
          onChangeContactEmail={onChangeContactEmail}
          onChangeAdminEmail={onChangeAdminEmail}
        />
      </div>
      <div className="form-actions">
        <button className="button small blue" type="button" disabled={isQueryPending} onClick={onSave}>
          <img className="icon" src={icon_add} alt="Add Icon" />
          Add Site
        </button>
      </div>
    </form>
  )
}