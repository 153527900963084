import React, { useState, useEffect, useContext } from 'react';
import FittingInfoBanner from '../FittingInfoBanner';
import FormValidatedInput from '../FormValidatedInput';
import moment from 'moment';
import FormValidatedSelect from '../FormValidatedSelect';
import icon_alert_blue from '../../../assets/images/icon-alert-blue.svg';
import icon_add_white from '../../../assets/images/icon-add-white.svg';
import { addFitting } from '../../../api/fittingApi';
import { getSiteGroups } from '../../../api/siteApi';
import { validateGroup, validateSerialNumber, validateDeviceReference } from '../../../utils/validators';
import { store } from '../../../utils/store';

export default ({isQueryPending, site, group, onAddFitting}) => {

  const { dispatch, state } = useContext(store);
  
  const [didValidate, setDidValidate] = useState(false);
  const [displayGroups, setDisplayGroups] = useState([]);
  const [shouldShowTestEventWarning, setShouldShowTestEventWarning] = useState(false);

  // Serial Number
  const [serialNumber, setSerialNumber] = useState('');
  const [errorMessageSerialNumber, setErrorMessageSerialNumber] = useState('');
  const [showErrorMessageSerialNumber, setShowErrorMessageSerialNumber] = useState(false);

  // Device Reference
  const [deviceReference, setDeviceReference] = useState('');
  const [errorMessageDeviceReference, setErrorMessageDeviceReference] = useState('');
  const [showErrorMessageDeviceReference, setShowErrorMessageDeviceReference] = useState(false);

  const [installDate, setInstallDate] = useState('');

  // Group
  const [organisationId, setOrganisationId] = useState('');
  const [errorMessageGroup, setErrorMessageGroup] = useState('');
  const [showErrorMessageGroup, setShowErrorMessageGroup] = useState(false);

  const confirmAddFitting = (e) => {
    e.preventDefault();
    if (displayGroups.length === 0) {
      dispatch({ type: 'SET POPUP', payload: 'danger' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: [{detail: 'Please create a group first before adding a fitting'}] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
      return;
    }

    let serialNumberValidation = false, deviceReferenceValidation = false, groupValidation = false;
    if (checkSerialNumber(serialNumber)) {
      serialNumberValidation = true;
    }
    if (checkDeviceReference(deviceReference)) {
      deviceReferenceValidation = true;
    }
    if (serialNumberValidation && deviceReferenceValidation) {
      const data = {
        data: {
          attributes: {
            "serial-number": serialNumber,
            "fitting-type": null,
            "description": null,
            "device-reference": deviceReference,
            "location": null,
            "building": null,
            "level": null,
            "switchboard": null,
            "circuit": null,
            "asset-code": null,
            "battery-status": null,
            "control-gear-status": null,
            "lamp-status": null,
            "install-date": installDate ? moment(installDate).format('yyyy-MM-DD') : null,
            "faulty": false,
            "deleted-at": null,
            "commissioned": false
          },
          relationships: {
            group: {
              data: {
                id: organisationId,
                type: "groups"
              }
            }
          },
          type: "fittings"
        }
      }
      addFitting(data).then(response => {
        onAddFitting(response.data);
      }).catch(error => {
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  const changeSerialNumber = (e) => {
    setSerialNumber(e.target.value)
    checkSerialNumber(e.target.value)
  }

  const checkSerialNumber = (input) => {
    const validateResult = validateSerialNumber(input);
    if (validateResult.validated) {
      setShowErrorMessageSerialNumber(false);
      setErrorMessageSerialNumber('');
      return true;
    } else {
      setShowErrorMessageSerialNumber(true);
      setErrorMessageSerialNumber(validateResult.message);
      return false;
    }
  }

  const changeDeviceReference = (e) => {
    setDeviceReference(e.target.value)
    checkDeviceReference(e.target.value)
  }

  const checkDeviceReference = (input) => {
    const validateResult = validateDeviceReference(input);
    if (validateResult.validated) {
      setShowErrorMessageDeviceReference(false);
      setErrorMessageDeviceReference('');
      return true;
    } else {
      setShowErrorMessageDeviceReference(true);
      setErrorMessageDeviceReference(validateResult.message);
      return false;
    }
  }

  const changeGroup = (e) => {
    setOrganisationId(e.target.value)
    checkGroup(e.target.value)
  }

  const checkGroup = (input) => {
    const validateResult = validateGroup(input);
    if (validateResult.validated) {
      setShowErrorMessageGroup(false);
      setErrorMessageGroup('');
      return true;
    } else {
      setShowErrorMessageGroup(true);
      setErrorMessageGroup(validateResult.message);
      return false;
    }
  }

  useEffect(() => {
    getSiteGroups(site.id).then(response => {
      let options = [];
      response.data.forEach(option => {
        options.push({
          label: option.attributes.name,
          value: option.id
        })
      })
      setDisplayGroups(options);
    }).catch(errors => {
      console.log(errors);
    })

    if (group) {
      setOrganisationId(group.id);
    }

  }, []);

  return (
    <form id="fitting-form" className="form fitting-form">
      <FittingInfoBanner />
      <div className="form-content">
        <div className="row row-animated">
          <FormValidatedInput type='text' name='serialNumber' value={serialNumber} didValidate={didValidate} labelText='Serial Number' errorMessage={errorMessageSerialNumber} showErrorMessage={showErrorMessageSerialNumber} onChange={changeSerialNumber} />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type='text' name='deviceReference' value={deviceReference} didValidate={didValidate} labelText='Fitting ID' errorMessage={errorMessageDeviceReference} showErrorMessage={showErrorMessageDeviceReference} onChange={changeDeviceReference} />
        </div>
        <div className="row row-animated">
          <FormValidatedInput type='date' name='installDate' value={installDate} didValidate={didValidate} labelText='Install Date' maxDate={new Date(moment())} onChange={date => setInstallDate(date)} />
        </div>

        {!group && (
          <div className="row row-animated">
            <FormValidatedSelect type='text' name='selectedGroup' options={displayGroups} value={organisationId} allowNullValue={true} didValidate={didValidate} labelText='Group Select' errorMessage={errorMessageGroup} showErrorMessage={showErrorMessageGroup} onChange={changeGroup} />
          </div>
        )}

        {shouldShowTestEventWarning && (
          <div className="test-event-warning">
            <img src={icon_alert_blue} alt="Alert Icon" />
            The install date falls outside of this test event.
          </div>
        )}
      </div>
      <div className="form-actions">
        <button className="button small blue" disabled={isQueryPending} onClick={confirmAddFitting} >
          <img className="icon" src={icon_add_white} alt="Add Icon" />
          Add Fitting
        </button>
      </div>
    </form>
  )
}