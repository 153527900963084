import { BASE_URL, API_VERSION, SITES, TEST_EVENTS, TEST_EVENT_GROUPS, RELATIONSHIPS, GROUPS, TEST_EVENT_FITTINGS, DUPLICATE, ARCHIVE, MISSING_GROUPS } from "../settings/apiUrls";
import api from "./api";

export const getTestEvents = (siteId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + SITES + "/" + siteId + TEST_EVENTS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const addTestEvent = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + TEST_EVENTS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const updateTestEvent = (testEventId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const deleteTestEvents = (data) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + TEST_EVENTS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const duplicateTestEvent = (testEventId, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId + DUPLICATE,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const archiveTestEvent = (testEventId) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId + ARCHIVE,
    }).then((response) => {
        return response.data;
    });
}

export const addGroupsToTestEvent = (testEventId, data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId + RELATIONSHIPS + GROUPS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeGroupsFromTestEvent = (testEventId, data) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId + RELATIONSHIPS + GROUPS,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const getTestEventGroups = (testEventId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId + TEST_EVENT_GROUPS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const getTestEventMissingGroups = (testEventId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + TEST_EVENTS + '/' + testEventId + MISSING_GROUPS + filters,
    }).then((response) => {
        return response.data;
    });
}

export const getTestEventFittings = (groupId, filters) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + TEST_EVENT_GROUPS + '/' + groupId + TEST_EVENT_FITTINGS + filters,
    }).then((response) => {
        return response.data;
    });
}