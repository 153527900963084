import React, { useState, useEffect, useContext } from 'react';
import { confirmResetPassword } from '../../../api/userApi';
import { validatePassword, validatePasswordConfirmation } from '../../../utils/validators';
import AuthValidatedInput from '../AuthValidatedInput';
import { store } from '../../../utils/store';

export default ({ resetToken, onPasswordUpdated }) => {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidNewPassword, setIsValidNewPassword] = useState(false);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
  const [errorMessageNewPassword, setErrorMessageNewPassword] = useState('');
  const [errorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState('');
  const [showErrorMessageNewPassword, setShowErrorMessageNewPassword] = useState(false);
  const [showErrorMessageConfirmPassword, setShowErrorMessageConfirmPassword] = useState(false);
  const [isQueryPending, setIsQueryPending] = useState(false);

  const { dispatch } = useContext(store);
  

  const checkNewPassword = (newPassword) => {
    const validateResult = validatePassword(newPassword);
    if (validateResult.validated) {
      setShowErrorMessageNewPassword(false);
      setErrorMessageNewPassword('');
    } else {
      setShowErrorMessageNewPassword(true);
      setErrorMessageNewPassword(validateResult.message);
    }
    return validateResult.validated;
  }

  const checkConfirmPassword = (newPassword, confirmPassword) => {
    const validateResult = validatePasswordConfirmation(newPassword, confirmPassword);
    if (validateResult.validated) {
      setShowErrorMessageConfirmPassword(false);
      setErrorMessageConfirmPassword('');
    } else {
      setShowErrorMessageConfirmPassword(true);
      setErrorMessageConfirmPassword(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeNewPassword = (e) => {
    setNewPassword(e.target.value);
    checkNewPassword(e.target.value);
  }

  const changeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    checkConfirmPassword(newPassword, e.target.value);
  }

  const resetPasswordRequest = (e) => {
    e.preventDefault();
    let validatedPasswords = checkNewPassword(newPassword) && checkConfirmPassword(newPassword, confirmPassword);
    if (validatedPasswords) {
      const data = {
        data: {
          attributes: {
            "reset-password-token": resetToken,
            "password": newPassword,
            "passwordConfirmation": confirmPassword
          }
        }
      }
      setIsQueryPending(true);

      confirmResetPassword(data).then(response => {
        onPasswordUpdated();
        setIsQueryPending(false);
      }).catch(error => {
        setIsQueryPending(false);
        dispatch({ type: 'SET POPUP', payload: 'danger' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: error.response.data.errors });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  return (
    <form id='reset-password-form' className="form auth-form reset-password-form">
      <div className="row row-animated password">
        <AuthValidatedInput 
          value={newPassword}
          labelText='Password' 
          name='password'
          isValid={isValidNewPassword}
          errorMessage={errorMessageNewPassword}
          showErrorMessage={showErrorMessageNewPassword}
          showPasswordToggle
          onChange={changeNewPassword}
        />
      </div>
      <div className="row row-animated passwordConfirmation">
        <AuthValidatedInput 
          value={confirmPassword}
          labelText='Confirm Password' 
          name='passwordConfirmation'
          isValid={isValidConfirmPassword}
          errorMessage={errorMessageConfirmPassword}
          showErrorMessage={showErrorMessageConfirmPassword}
          showPasswordToggle
          onChange={changeConfirmPassword}
        />
      </div>
      <div className="actions">
        <button type="submit" className="button green large  auth-submit" disabled={isQueryPending} onClick={resetPasswordRequest}>Reset Password</button>
      </div>
    </form>
  )
}