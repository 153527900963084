import React, { useState, useEffect, useContext } from 'react';
import arrow_down_blue from '../../assets/icons/arrow-down-blue.svg'

export default ({canLoad, numberOfItemsDisplayedText, loadText, onLoadMore}) => {

  return (
    <div className="button white load-more-button">
      <p className="items-displayed-text footnote">{numberOfItemsDisplayedText}</p>
    
      {canLoad && (
        <p className="load-more-text" onClick={onLoadMore} >
          <img src={arrow_down_blue} className='icon-arrow-down-blue' alt="Icon arrow down blue" />
          {' ' + loadText}
        </p>
      )}
    
    </div>
  )
}