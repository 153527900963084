import React, { useState, useEffect, useContext } from 'react';
import { removeGroup } from '../../../api/groupApi';
import icon_close from '../../../assets/images/icon-close.svg';
import { store } from '../../../utils/store';
import ModalView from '../../components/ModalView';

export default ({group, canDeleteGroup, isQueryPending, onDelete, onClose}) => {

  const { dispatch, state } = useContext(store);

  const deleteGroup = () => {
    removeGroup(group.id).then(response => {
      onClose();
      onDelete();
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Group Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    }) 
  }

  return (
    <ModalView>
      <div style={{textTransform: 'none', letterSpacing: 0}}>
        <div className="modal-header" style={{textAlign: 'left'}}>
          <h2 className="form-heading">Delete Group</h2>
          <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
        </div>

        {canDeleteGroup ? (
          <div className="modal-content" style={{fontFamily: 'Rubik', fontSize: "16px", fontWeight: '400', lineHeight: '20px'}}>
            <div className="inner">
              <p>Are you sure you want to delete this group? Deleting can't be undone.</p>
            </div>
            <div className="modal-actions add-action">
              <button className="button small red add-group-submit" disabled={isQueryPending} onClick={deleteGroup}>
                Delete Group
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-content" style={{fontFamily: 'Rubik', fontSize: "16px", fontWeight: '400', lineHeight: '20px'}}>
            <div className="inner">
              <p>To delete this group, you’ll first need to move its fittings into other groups. </p>
            </div>
            <div className="modal-actions add-action">
              <div className='button standard blue' tagName='button' onClick={onClose}>OK</div>
            </div>
          </div>
        )}
      </div>
    </ModalView>
  )
}