import React, { useState, useEffect } from 'react';
import GroupFormRows from './GroupFormRows';
import icon_add_white from '../../../assets/images/icon-add-white.svg';

export default ({group, onSaveGroup, didValidate, canEdit, isQueryPending, errorMessage, showErrorMessage, onChange}) => {

  return (
    <form id="group-form" className="form">
      <div className="form-content">
        <GroupFormRows group={group} didValidate={didValidate} errorMessage={errorMessage} showErrorMessage={showErrorMessage} onChange={onChange}/>
      </div>
      <div className="form-actions">
        <button className="button standard blue add-group-submit" disabled={isQueryPending} onClick={onSaveGroup} >
          {canEdit ? 'Save' : (
            <>
              <img className="icon" src={icon_add_white} alt="Alert Icon" />
              Add Group 
            </>
          )}
        </button>
      </div>
    </form>
  )
}