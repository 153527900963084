import React, { useState, useEffect, useContext } from 'react';
import { sendInvites } from '../../../api/inviteApi';
import { store } from '../../../utils/store';
import { validateEmail } from '../../../utils/validators';
import FormValidatedInput from '../FormValidatedInput';

export default ({currentOrg, didValidate, isQueryPending, onSentInvites}) => {

  const { dispatch, state } = useContext(store);

  const [email, setEmail] = useState('');
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false);

  const checkEmail = (input) => {
    const validateResult = validateEmail(input);
    if (validateResult.validated) {
      setShowErrorMessageEmail(false);
      setErrorMessageEmail('');
    } else {
      setShowErrorMessageEmail(true);
      setErrorMessageEmail(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeEmail = (e) => {
    setEmail(e.target.value);
    checkEmail(e.target.value);
  }

  const invite = (e) => {
    e.preventDefault();
    let validatedEmail = checkEmail(email);
    if (validatedEmail) {
      const data = {
        data: {
          attributes: {
            "email": email,
            "status": null
          },
          relationships: {
            organisation: {
              data: {
                type: "organisations",
                id: currentOrg
              }
            }
          },
          type: "invites"
        }
      }
      sendInvites(data).then(response => {
        onSentInvites();
        dispatch({ type: 'SET POPUP', payload: 'success' });
        dispatch({ type: 'SET POPUP MESSAGES', payload: ['Invitation Sent'] });
        setTimeout(() => {
          dispatch({ type: 'SET POPUP', payload: '' })
        }, 3000)
      })
    }
  }

  return (
    <form id="invite-user-form" className="form">
      <div className="form-content">
        <div className="row row-animated">
          <FormValidatedInput type='text' name='email' value={email} didValidate={didValidate} labelText='Email' errorMessage={errorMessageEmail} showErrorMessage={showErrorMessageEmail} onChange={changeEmail} />
        </div>
      </div>
      <div className="form-actions">
        <button className="button small blue" disabled={isQueryPending} onClick={invite} >
          Send Invitation
        </button>
      </div>
    </form>
  )
}