import React, { useState, useEffect, useContext } from 'react';
import AuthValidatedInput from "../AuthValidatedInput";

export default ({email, password, isValidEmail, isValidPassword, errorMessageEmail, errorMessagePassword, showErrorMessageEmail, showErrorMessagePassword, onChangeEmail, onChangePassword, onSubmit }) => {

  return (
    <form id='login-form' className="form auth-form" onSubmit={e => {e.preventDefault(); onSubmit();}}>
      <div className="row row-animated email">
        <AuthValidatedInput 
          value={email} 
          labelText='Email' 
          name='email'
          isValid={isValidEmail}
          errorMessage={errorMessageEmail}
          showErrorMessage={showErrorMessageEmail}
          onChange={onChangeEmail} 
        />
      </div>
      <div className="row row-animated password">
        <AuthValidatedInput 
          value={password}
          labelText='Password' 
          name='password'
          isValid={isValidPassword}
          errorMessage={errorMessagePassword}
          showErrorMessage={showErrorMessagePassword}
          showPasswordToggle
          onChange={onChangePassword}
        />
      </div>
      <div className="forgot-password label">
        <a href='/forgot-password'>Forgot password?</a>
      </div>
      <div className="actions">
        <button type="submit" className="button green large auth-submit">Log In</button>
      </div>
    </form>
  )
}