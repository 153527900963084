import React, { useState, useEffect, useContext } from 'react';
import ModalView from './ModalView';
import icon_close from '../../assets/images/icon-close.svg';

export default ({isQueryPending, onClose, onDeleteTemplate}) => {
  
  return (
    <ModalView>
      <div>
        <div className="modal-header">
          <h2 className="form-heading">Delete Template</h2>
          <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose} />
        </div>

        Are you sure you would like to delete the selected template?
        <br/>
        <br/>
        <button className="button small red" disabled={isQueryPending} onClick={onDeleteTemplate} >
          Delete Template
        </button>
      </div>
    </ModalView>
  )
}
