import React, { useState, useEffect, useContext } from 'react';
import close_white from '../../assets/icons/close-white.svg';
import arrow_up from '../../assets/icons/arrow-up.svg';
import icon_alert from '../../assets/images/icon-alert.svg';

export default ({name, labelText, selection, disabled, selectionModel, options, isValid, errorMessage, warningMessage, showErrorMessage, showWarningMessage, onSelectionsChanged, onRemoveItem}) => {

  const [focused, setFocused] = useState(false);

  const toggleShowSelection = () => {
    setFocused(!focused);
  }

  let value = Array.from(selection, option => (option.value));
  if (options.length === value.length) value.push('all');

  return (
    <div className='validated-input has-success'>
      <div className={`input validated-multi-select ${showErrorMessage && 'validation-error'} ${focused && 'focused active'} ${!disabled && 'focused-border'}`} onClick={focused ? null : toggleShowSelection}>
        <label htmlFor={name} className="label label-animated">{labelText}</label>
      
        {focused && (
          <>
            <img src={arrow_up} className='arrow-up' alt="Arrow Up Icon" onClick={toggleShowSelection} />
            <div className="selection">
              {selection.map(item => (
                <div key={selection.indexOf(item)} className="selected-item button small green" onClick={() => onRemoveItem(item)} >
                  {item.label} <img className="icon-close" src={close_white} alt="Close Icon"/>
                </div>
              ))}
            </div>
          </>
        )}
      
        <select name={name} multiple tabIndex="0" className={`${showErrorMessage && 'error'} ${isValid && 'valid'} select`} disabled={disabled} value={value} onChange={onSelectionsChanged}>
          {options && options.length > 0 && (
            <option value="all" className="x-option" >All {selectionModel}</option>
          )}
          {options.map(option => (
            <option key={options.indexOf(option)} value={option.value} className="x-option">{option.label}</option>
          ))}
        </select>
      </div>
      
      <div className='input-error'>
        {showErrorMessage && (
          <div className='error'>
            <img src={icon_alert} className='alert-icon' alt="Alert Icon" />
            {errorMessage}
          </div>
        )}
      
        {showWarningMessage && (
          <div className='warning'>
            {warningMessage}
          </div>
        )}
      </div>
    </div>
  )
}

