import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { removeMe } from '../../api/userApi';
import { store } from '../../utils/store';
import DeleteAccountModal from '../components/DeleteAccountModal';
import UserForm from '../components/forms/UserForm';
import ModalView from '../components/ModalView';

export default ({user}) => {

  const { dispatch, state } = useContext(store);
  const navigate = useNavigate();

  const [displayDeleteAccountModal, setDisplayDeleteAccountModal] = useState(false);
  const [isQueryPending, setIsQueryPending] = useState(false);

  const openDeleteAccountModal = (e) => {
    e.preventDefault();
    setDisplayDeleteAccountModal(true);
  }

  const closeDeleteAccountModal = () => {
    setDisplayDeleteAccountModal(false);
  }

  const confirmDeleteAccountModal = () => {
    setDisplayDeleteAccountModal(false);
    removeMe().then(response => {
      dispatch({ type: 'SET USER', payload: null });
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Account Deleted'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
      localStorage.clear();
      
      navigate('/');
    })
  }


  return (
    <div>
      <UserForm user={user} editPage onDeleteAccountModal={openDeleteAccountModal}/>
      {displayDeleteAccountModal && (
        <ModalView>
          <DeleteAccountModal 
            isQueryPending={isQueryPending} 
            onClose={closeDeleteAccountModal}
            onConfirm={confirmDeleteAccountModal}
          />
        </ModalView>
      )}
    </div>
  )
}
