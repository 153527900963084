import React, { useState, useEffect } from 'react';
import close_icon from '../../assets/images/icon-close.svg';
import ModalView from './ModalView';
import QuickTestForm from './forms/QuickTestForm';

export default ({testEvent, fittings, headingText, isQuickTestPassForm, onClose, onSubmitForm}) => {

  return (
    <ModalView>
      <div id='quick-test-modal'>
        <div className="modal-header">
          <h2 className="form-heading">{headingText}</h2>
          <img className="modal-close" src={close_icon} alt="Close Icon" onClick={onClose}/>
        </div>

        <div className="modal-content" >
          <QuickTestForm testEvent={testEvent} fittings={fittings} isQuickTestPassForm={isQuickTestPassForm} onSubmitForm={onSubmitForm} />
        </div>
      </div>
    </ModalView>
  )
}
