import React, { useState, useEffect, useContext } from 'react';
import white_logo from '../../assets/images/white-logo.svg';
import DropdownMenu from './DropdownMenu';
import UsersDropdownMenu from './UsersDropdownMenu';

export default ({
    currentEmployment, 
    canView, 
    postOnboardingTourNames, 
    currentOrg,
    transitionAction, 
    isAnalyticsEnabled, 
    triggerContent, 
    currentOrganisation, 
    openSwitchAccountModal, 
    resetTooltips,
    onSwitchAccount
  }) => {

  return (
    <div id="nav-bar">
      <div className="inner">
        <div className="left">
          <div className="nav-bar-item nav-bar-logo">
            <a href='/sites'>
              <img alt="CleverSparky logo" src={white_logo}/>
            </a>
          </div>

          <div className="nav-bar-item nav-bar-link">
            <a href='/sites'>Sites</a>
          </div>

          <div className="nav-bar-item nav-bar-link">
            <a href='/dashboard'>Dashboard</a>
          </div>

          {currentOrg && (
            <>
              <div stopName='usersNavItem' tourNames={postOnboardingTourNames}>
                <div className="nav-bar-item nav-bar-link users">
                  <UsersDropdownMenu triggerContent='Users' transitionAction={transitionAction}/>
                </div>
              </div>

              {isAnalyticsEnabled && (
                <div stopName='analyticsNavItem' tourNames={postOnboardingTourNames}>
                  <div className="nav-bar-item nav-bar-link">
                    <a href='/analytics'>Analytics</a>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
    
        <div className="right">
          <div className="nav-bar-item">
            <DropdownMenu 
              triggerContent={triggerContent}
              currentOrganisation={currentOrganisation}
              currentEmployment={currentEmployment}
              onSwitchAccount={onSwitchAccount}
              transitionAction={transitionAction}
              isGuidedTourEnabled={true}
              resetTooltips={resetTooltips}
              canModify={{businessProfile: () => {}}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


