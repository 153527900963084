import { BASE_URL, USER_ME, API_VERSION, PLACES } from "../settings/apiUrls";
import api from "./api";


export const getPlaces = (searchText) => {
    return api({
        method: 'get',
        url: BASE_URL + API_VERSION + PLACES + '?filter[name]=' + searchText,
    }).then((response) => {
        return response.data;
    });
}