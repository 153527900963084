import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import icon_alert_blue from '../../../assets/images/icon-alert-blue.svg';
import illus_fittings from '../../../assets/images/illus-fittings.svg';
import icon_blue_add from '../../../assets/images/icon-blue-add.svg';
import icon_close from '../../../assets/images/icon-close.svg';
import { repairOptions } from '../../../utils/variables';
import FormValidatedInput from '../FormValidatedInput';
import FormValidatedSelect from '../FormValidatedSelect';
import ModalView from '../ModalView';
import UpdateFittingForm from './UpdateFittingForm';
import { validateRepairDate, validateRepaired } from '../../../utils/validators';
import { addMaintenance } from '../../../api/maintenanceApi';

export default ({fitting, didValidate, isDeleted, isQueryPending, maintenances, onUpdate}) => {

  const [isShowingForm, setIsShowingForm] = useState(false);
  const [shouldShowTestEventWarning, setShouldShowTestEventWarning] = useState(false);
  const [showUpdateFittingModal, setShowUpdateFittingModal] = useState(false);

  // Repair date
  const [repairDate, setRepairDate] = useState('');
  const [errorMessageRepairDate, setErrorMessageRepairDate] = useState('');
  const [showErrorMessageRepairDate, setShowErrorMessageRepairDate] = useState(false);

  // Repaired
  const [repair, setRepair] = useState('');
  const [errorMessageRepair, setErrorMessageRepair] = useState('');
  const [showErrorMessageRepair, setShowErrorMessageRepair] = useState(false);

  const [notes, setNotes] = useState('');

  const save = () => {
    let validatedRepairDate = checkRepairDate(repairDate);
    let validatedRepair = checkRepair(repair);
    if (validatedRepairDate && validatedRepair) {
      if (repair === 'fitting') {
        setShowUpdateFittingModal(true);
      } else {
        const maintenanceData = {
          data: {
            attributes: {
              "repair-date": moment(repairDate).format('YYYY-MM-DD'),
              "repair": repair,
              "notes": notes
            },
            relationships: {
              fitting: {
                data: {
                  type: "fittings",
                  id: fitting.id
                }
              }
            },
            type: "maintenances"
          }
        }
        addMaintenance(maintenanceData).then(res => {
          onUpdate();
          setRepairDate('');
          setRepair('');
          setNotes('');
          setIsShowingForm(false);
        })
      }
    }
  }

  const cancel = () => {
    setRepairDate('');
    setRepair('');
    setNotes('');
    setIsShowingForm(false);
    setErrorMessageRepair('');
    setErrorMessageRepairDate('');
    setShowErrorMessageRepair(false);
    setShowErrorMessageRepairDate(false);
  }

  const showMaintenance = () => {
    setIsShowingForm(true);
  }

  const closeUpdateFittingModal = () => {
    setShowUpdateFittingModal(false);
  }

  const checkRepairDate = (input) => {
    const validateResult = validateRepairDate(input);
    if (validateResult.validated) {
      setShowErrorMessageRepairDate(false);
      setErrorMessageRepairDate('');
    } else {
      setShowErrorMessageRepairDate(true);
      setErrorMessageRepairDate(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeRepairDate = (value) => {
    setRepairDate(value)
    checkRepairDate(value)
  }

  const checkRepair = (input) => {
    const validateResult = validateRepaired(input);
    if (validateResult.validated) {
      setShowErrorMessageRepair(false);
      setErrorMessageRepair('');
    } else {
      setShowErrorMessageRepair(true);
      setErrorMessageRepair(validateResult.message);
    }
    return validateResult.validated;
  }

  const changeRepair = (e) => {
    setRepair(e.target.value)
    checkRepair(e.target.value)
  }

  const updateMaintenance = () => {
    onUpdate();
    setIsShowingForm(false);
    setShowUpdateFittingModal(false);
  }

  useEffect(() => {
    setRepairDate('');
    setRepair('');
    setNotes('');
    setIsShowingForm(false);
    setErrorMessageRepair('');
    setErrorMessageRepairDate('');
    setShowErrorMessageRepair(false);
    setShowErrorMessageRepairDate(false);
  }, [fitting])

  return (
    <div>
      <form id="maintenance-form" className="form">
        <div className="form-content">
          {isShowingForm ? (
              <div>
                <div className="header-label-row">
                  <label>New Maintenance</label>
                </div>
                {shouldShowTestEventWarning && (
                  <div className="test-event-warning">
                    <img src={icon_alert_blue} alt="Alert Icon" />
                    The repair date falls outside of this test event.
                  </div>
                )}
                <div className="row row-animated white">
                  <FormValidatedInput type='date' name='repairDate' value={repairDate} didValidate={didValidate} labelText='Repair Date' showErrorMessage={showErrorMessageRepairDate} errorMessage={errorMessageRepairDate} onChange={changeRepairDate} maxDate={new Date(moment())}/>
                </div>
                <div className="row row-animated white">
                  <FormValidatedSelect type='text' name='repair' options={repairOptions} value={repair} didValidate={didValidate} labelText='Repaired' showErrorMessage={showErrorMessageRepair} errorMessage={errorMessageRepair} onChange={changeRepair} />
                </div>
                <div className="row row-animated white last-maintenance-row">
                  <FormValidatedInput type='textarea' name='notes' value={notes} didValidate={didValidate} labelText='Notes' onChange={e => setNotes(e.target.value)} />
                </div>
                <div className="fitting-actions">
                  <div className="button small blue edit-actions" disabled={isQueryPending} onClick={save}>
                    Save
                  </div>
                  <div className="button small text-blue edit-actions" onClick={cancel}>
                    Cancel
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {maintenances.length === 0 && (
                  <div className="no-maintenance-rows">
                    <div className="empty-image">
                      <img src={illus_fittings} alt="Blue Row Icon"/>
                    </div>
                    <div className={`empty-message ${!maintenances && 'long'}`}>
                      {maintenances ? 
                        <div>
                          There are no maintenance<br />
                          reports against this fitting yet.
                        </div>
                        : "To add a new maintenance report, click on the 'Add Maintenance' button below."}
                    </div>
                  </div>
                )}
                {!isDeleted && (
                  <div className="add-action">
                    <button className="button small text-blue" onClick={showMaintenance} >
                      <img className="icon" src={icon_blue_add} alt="Add Icon"/>
                      Add Maintenance
                    </button>
                  </div>
                )}
              </div>
            )
          }
        </div>
      </form>
      {showUpdateFittingModal && (
        <ModalView>
          <>
            <div className="modal-header">
              <h2 className="form-heading">Update Fitting Details</h2>
              <img className="modal-close" src={icon_close} alt="Close Icon" onClick={closeUpdateFittingModal}/>
            </div>
        
            <div className="modal-content">
              <UpdateFittingForm fitting={fitting} repairDate={repairDate} repair={repair} notes={notes} isQueryPending={isQueryPending} newMaintenance={true} onUpdate={updateMaintenance}/>
            </div>
          </>
        </ModalView>
      )}
    </div>
  )
}



