import React, { useState, useEffect } from 'react';
import group_dropdown_menu_trigger from '../../assets/icons/group-dropdown-menu-trigger.svg';

export default ({canView, onEdit, onSiteDrawings, onOpenTransferSiteToUserModal, onOpenTransferSiteModal, onDeleteSite}) => {

  const [isOpen, setIsOpen] = useState(false);

  const trigger = () => {
    setIsOpen(true);
  }

  const blurMenu = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 150)
  }

  
  return (
    <div className='dropdown-menu site-details-dropdown-menu' >
      <div className='dropdown-menu-trigger' onClick={trigger} tabIndex="9999" onBlur={blurMenu}>
        <img src={group_dropdown_menu_trigger} className='drop-trig' alt="Group icon blue"/>
      </div>

      {isOpen && (
        <div className="dropdown-menu-list" >

          <button className="dropdown-menu-list-item label edit-site-details" onClick={onEdit}>
            Edit Site Details
          </button>

          {/* 
          <button className="dropdown-menu-list-item label edit-site-details" onClick={onSiteDrawings}>
            Upload Site Drawings
          </button>
          */}
      
          {canView['transfer-site'] && (
            <>
              <button className="dropdown-menu-list-item label transfer-site" onClick={onOpenTransferSiteToUserModal}>
                Transfer Site Ownership
              </button>
        
              <button className="dropdown-menu-list-item label transfer-site" onClick={onOpenTransferSiteModal}>
                Move Site
              </button>
            </>
          )}
      
          {canView['delete-site'] && (
            <button className="dropdown-menu-list-item label delete delete-site" onClick={onDeleteSite}>
              Delete Site
            </button>
          )}
      
        </div>
      )}
    </div>
  )
}