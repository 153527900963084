import React, { useState, useEffect, useContext } from 'react';
import { archiveTestEvent } from '../../api/testEventApi';
import icon_close from '../../assets/images/icon-close.svg';
import { store } from '../../utils/store';

export default ({testEvent, isQueryPending, onClose, onUpdateTestEvent}) => {

  const { dispatch } = useContext(store);

  const archive = (e) => {
    e.preventDefault();

    archiveTestEvent(testEvent.id).then(response => {
      onUpdateTestEvent(response.data);
      dispatch({ type: 'SET POPUP', payload: 'success' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: ['Test Event Archived'] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    }).catch(error => {
      dispatch({ type: 'SET POPUP', payload: 'danger' });
      dispatch({ type: 'SET POPUP MESSAGES', payload: [error.response.data.errors[0]] });
      setTimeout(() => {
        dispatch({ type: 'SET POPUP', payload: '' })
      }, 3000)
    })
  }

  return (
    <div>
      <div className="modal-header">
        <h2 className="form-heading">Archive Logbook</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
      </div>

      <div className="modal-content">
        <div className="inner confirmation-message">
          <p>Are you sure you want to archive this test event? Archiving can't be undone.</p>
        </div>
        <div className="modal-actions">
          <button className="button small blue" disabled={isQueryPending} onClick={archive}>
            Archive
          </button>
        </div>
      </div>
    </div>
  )
}