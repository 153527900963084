import React, { useState, useEffect } from 'react';

export default ({isOpen}) => {

  const [hasNoFaultyFittings, setHasNoFaultyFittings] = useState(false);

  const trigger = () => {

  }

  const orderReplacements = () => {

  }

  return (
    <div>
      <div className='dropdown-menu-trigger' onClick={trigger}>
        <img src="/assets/icons/group-dropdown-menu-trigger.svg" className='drop-trig' alt="Group icon blue"/>
      </div>

      {isOpen && (
        <div className="dropdown-menu-list">
          <button className="dropdown-menu-list-item label order-replacements" disabled={{hasNoFaultyFittings}} onClick={orderReplacements}>
            Order Replacements
          </button>
        </div>
      )}
    </div>
  )
}
