import React, { useState, useEffect, useContext } from 'react';
import success_tick from '../../assets/icons/success-tick.svg';

export default ({children, actionText, onAction}) => {

  return (
    <div className="modal-success">
      <img src={success_tick} alt="Success tick" />
      <h3 className="success-text">{children}</h3>
      <div className="modal-success-action">
        <button className="button standard blue" onClick={onAction}>{actionText}</button>
      </div>
    </div>
  )
}
