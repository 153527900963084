import React, { useState, useEffect, useContext } from 'react';
import icon_close from '../../assets/images/icon-close.svg';
import ModalView from './ModalView';

export default ({csvTemplatePath, uploadErrors, onClose}) => {

  return (
    <ModalView>
      <div id="file-upload-error-modal">
        <div className="modal-header">
          <h2 className="form-heading">Spreadsheet Upload Error</h2>
          <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose} />
        </div>

        <div className="modal-content">
          <div className="inner">
            <p className="message error-message">There were some errors in your spreadsheet. All other fittings have been created.</p>

            <ul className="errors-list">
              {uploadErrors.map(error => (
                <li key={uploadErrors.indexOf(error)} className="error">{error}</li>
              ))}
            </ul>
          </div>
          <p className="template-download">You can download the upload template <a href={csvTemplatePath} className='green'>here</a>.</p>

          <div className="modal-actions add-action">
            <button className="button standard blue add-group-submit" onClick={onClose} >
              OK
            </button>
          </div>
        </div>
      </div>
    </ModalView>
  )
}