import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { authenticate } from '../api/authApi';
import { store } from '../utils/store';
import download_icon from '../assets/images/icon-download.svg';
import loading_icon from '../assets/images/CleverSparky_Blink.gif';
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getComplianceDashboard, getComplianceDashboardDetails, getDiffSites } from '../api/complianceApi';
import { FITTINGS_INSTALLED, OVERDUE_SITES, PERCENTAGE, SUMMARY, UPCOMING_TESTS } from '../settings/apiUrls';
import { exportSites } from '../api/siteApi';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);


const Compliance = ({currentOrg, orgSetup, children}) => {

  const [tab, setTab] = useState('dashboard');
  const [stateFilter, setStateFilter] = useState('all');
  const [gaugeData, setGaugeData] = useState({ overall: 0, nsw: 0, qld: 0, tas: 0, vic: 0, sa: 0, wa: 0 });
  const [summaryData, setSummaryData] = useState({});
  const [fittingsInstalledTab, setFittingsInstalledTab] = useState('monthly');
  const [fittingsInstalledTotal, setFittingsInstalledTotal] = useState(0);
  const [fittingsInstalledYearTotal, setFittingsInstalledYearTotal] = useState(0);
  const [fittingsInstalledData, setFittingsInstalledData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [fittingsInstalledYearData, setFittingsInstalledYearData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [upcomingTestsTotal, setUpcomingTestsTotal] = useState(0);
  const [upcomingTestsData, setUpcomingTestsData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [overdueSitesData, setOverdueSitesData] = useState([]);
  const [diffCount, setDiffCount] = useState(0);

  const [summaryLoading, setSummaryLoading] = useState(false);
  const [fittingInstalledLoading, setFittingInstalledLoading] = useState(false);
  const [upcomingTestsLoading, setUpcomingTestsLoading] = useState(false);
  const [percentageSiteLoading, setPercentageSiteLoading] = useState(false);
  const [overdueSitesLoading, setOverdueSitesLoading] = useState(false);

  const [showCaution, setShowCaution] = useState(false);
  const [showNumSites, setShowNumSites] = useState(false);
  const [showNumFittings, setShowNumFittings] = useState(false);
  const [showWarranty, setShowWarranty] = useState(false);
  const [showTestsScheduled, setShowTestsScheduled] = useState(false);
  const [showOverdueSites, setShowOverdueSites] = useState(false);
  const [showNoScheduled, setShowNoScheduled] = useState(false);
  const [showFittingsInstalled, setShowFittingsInstalled] = useState(false);
  const [showUpcomingTests, setShowUpcomingTests] = useState(false);
  const [showDials, setShowDials] = useState(false);

  const { dispatch, state } = useContext(store);
  const {
    user,
  } = state;

  const navigate = useNavigate();

  const exportOverdueSites = (fileFormat) => {
    let data = {
        data: {
            attributes: { 
                state: stateFilter,
                csvorpdf: fileFormat
            }
        }
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    exportSites(data).then(url => {
        fetch(url).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                if (fileFormat === 'pdf') {
                  alink.download = 'overdue_sites.pdf';
                } else {
                  alink.download = 'overdue_sites.csv';
                }
                alink.click();
            })
        })
    })
  }

  const percentageSiteTestComplianceDueDate = (state) => {
    let data = {
        data: {
            attributes: {
                function: "percentageofsitetestcompliancexduedate"
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    setPercentageSiteLoading(true);
    getComplianceDashboardDetails(PERCENTAGE, data).then(response => {
        setPercentageSiteLoading(false);
        let all, vic, nsw, qld, wa, tas, sa = '';
        if (response) {
            all = response.all;
            vic = response.vic;
            nsw = response.nswact;
            qld = response.qld;
            wa = response.wa;
            tas = response.tas;
            sa = response.sant;
            setGaugeData({
                overall: all,
                nsw,
                qld,
                tas,
                vic,
                sa,
                wa
            })
        }
    })
  }

  const summary = (state) => {
    let data = {
        data: {
            attributes: {
                function: "summary"
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    setSummaryLoading(true);
    getComplianceDashboardDetails(SUMMARY, data).then(response => {
        setSummaryLoading(false);
        let sData = {};
        if (response.length > 0) {
            response.forEach(data => {
                sData = {...data, ...sData};
            })
            setSummaryData(sData)
        }
    })
  }

  const fittingsInstalledYear = (state) => {
    let data = {
        data: {
            attributes: {
                function: "fittingsinstalledyearly"
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    setFittingInstalledLoading(true);
    getComplianceDashboardDetails(FITTINGS_INSTALLED, data).then(response => {
        setFittingInstalledLoading(false);
        if (response) {
            let yearlyData = [
                response.elevenyear,
                response.tenyear,
                response.nineyear,
                response.eightyear,
                response.sevenyear,
                response.sixyear,
                response.fiveyear,
                response.fouryear,
                response.threeyear,
                response.twoyear, 
                response.oneyear, 
                response.thisyear,
            ]
            setFittingsInstalledYearTotal(yearlyData.reduce((partialSum, a) => partialSum + a, 0));
            setFittingsInstalledYearData(yearlyData);
        }
    })
  }

  const fittingsInstalled = (state) => {
    let data = {
        data: {
            attributes: {
                function: "fittingsinstalled"
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    setFittingInstalledLoading(true);
    getComplianceDashboardDetails(FITTINGS_INSTALLED, data).then(response => {
        setFittingInstalledLoading(false);
        if (response) {
            let monthlyData = [
                response.elevenmonth,
                response.tenmonth,
                response.ninemonth,
                response.eightmonth,
                response.sevenmonth,
                response.sixmonth,
                response.fivemonth,
                response.fourmonth,
                response.threemonth,
                response.twomonth, 
                response.onemonth,
                response.thismonth, 
            ]
            setFittingsInstalledTotal(monthlyData.reduce((partialSum, a) => partialSum + a, 0));
            setFittingsInstalledData(monthlyData);
        }
    })
  }

  const upcomingTests = (state) => {
    let data = {
        data: {
            attributes: {
                function: "upcomingtests"
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    setUpcomingTestsLoading(true);
    getComplianceDashboardDetails(UPCOMING_TESTS, data).then(response => {
        setUpcomingTestsLoading(false);
        if (response) {

            let monthlyData = [];

            if (state === 'nsw' || state === 'sa') {
                if (state === 'nsw') {
                    data.data.attributes.state = 'act';
                } else if (state === 'sa') {
                    data.data.attributes.state = 'nt';
                }
                getComplianceDashboardDetails(UPCOMING_TESTS, data).then(response2 => {
                    if (response2) {
                        monthlyData = [
                            response.lastmonth + response2.lastmonth,
                            response.thismonth + response2.thismonth, 
                            response.onemonth + response2.onemonth, 
                            response.twomonth + response2.twomonth, 
                            response.threemonth + response2.threemonth, 
                            response.fourmonth + response2.fourmonth, 
                            response.fivemonth + response2.fivemonth,
                            response.sixmonth + response2.sixmonth,
                        ];
                        setUpcomingTestsTotal(monthlyData.reduce((partialSum, a) => partialSum + a, 0));
                        setUpcomingTestsData(monthlyData);
                    }
                })
            } else {
                monthlyData = [
                    response.lastmonth,
                    response.thismonth, 
                    response.onemonth, 
                    response.twomonth, 
                    response.threemonth, 
                    response.fourmonth, 
                    response.fivemonth,
                    response.sixmonth,
                ];
                setUpcomingTestsTotal(monthlyData.reduce((partialSum, a) => partialSum + a, 0));
                setUpcomingTestsData(monthlyData);
            }
        }
        
    })
  }

  const overdueSites = (state) => {
    let data = {
        data: {
            attributes: {
                function: "overduesites",
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    setOverdueSitesLoading(true);
    getComplianceDashboardDetails(OVERDUE_SITES, data).then(response => {
        setOverdueSitesLoading(false);
        if (response && response.length > 0) {
            setOverdueSitesData(response);
        } else {
            setOverdueSitesData([]);
        }
    })
  }

  const diffSites = (state, format) => {
    let data = {
        data: {
            attributes: {
                state: 'all',
                csvorpdf: format, 
                function: 'fileget'
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    getDiffSites(data).then(url => {
        fetch(url).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                if (format === 'pdf') {
                  alink.download = 'Error sites list.pdf';
                } else {
                  alink.download = 'Error sites list.csv';
                }
                alink.click();
            })
        })
    })
  }

  const diffSiteCount = (state, format) => {
    let data = {
        data: {
            attributes: {
                state: 'all',
                csvorpdf: format, 
                function: 'counting'
            }
        }
    }
    if (state) {
        data.data.attributes.state = state;
    }
    if (currentOrg) {
        data.data.attributes.organisation_id = currentOrg;
    }
    getDiffSites(data).then(response => {
        setDiffCount(response);
    })
  }

  const getCountSum = () => {
    let sum = 0;
    if (summaryData) {
        sum = (summaryData.sitescountnsw ? summaryData.sitescountnsw : 0) +
            (summaryData.sitescountact ? summaryData.sitescountact : 0) +
            (summaryData.sitescountqld ? summaryData.sitescountqld : 0) +
            (summaryData.sitescountsa ? summaryData.sitescountsa : 0) +
            (summaryData.sitescountnt ? summaryData.sitescountnt : 0) +
            (summaryData.sitescounttas ? summaryData.sitescounttas : 0) +
            (summaryData.sitescountvic ? summaryData.sitescountvic : 0) +
            (summaryData.sitescountwa ? summaryData.sitescountwa : 0);
    }
    return sum;
  }

  const getFittingsSum = () => {
    let sum = 0;
    if (summaryData) {
        sum = (summaryData.tfittingsnsw ? summaryData.tfittingsnsw : 0) +
            (summaryData.tfittingsact ? summaryData.tfittingsact : 0) +
            (summaryData.tfittingsqld ? summaryData.tfittingsqld : 0) +
            (summaryData.tfittingssa ? summaryData.tfittingssa : 0) +
            (summaryData.tfittingsnt ? summaryData.tfittingsnt : 0) +
            (summaryData.tfittingstas ? summaryData.tfittingstas : 0) +
            (summaryData.tfittingsvic ? summaryData.tfittingsvic : 0) +
            (summaryData.tfittingswa ? summaryData.tfittingswa : 0);
    }
    return sum;
  }

  const getWarrantyFittingsSum = () => {
    let sum = 0;
    if (summaryData) {
        sum = (summaryData.tWfittingsnsw ? summaryData.tWfittingsnsw : 0) +
            (summaryData.tWfittingsact ? summaryData.tWfittingsact : 0) +
            (summaryData.tWfittingsqld ? summaryData.tWfittingsqld : 0) +
            (summaryData.tWfittingssa ? summaryData.tWfittingssa : 0) +
            (summaryData.tWfittingsnt ? summaryData.tWfittingsnt : 0) +
            (summaryData.tWfittingstas ? summaryData.tWfittingstas : 0) +
            (summaryData.tWfittingsvic ? summaryData.tWfittingsvic : 0) +
            (summaryData.tWfittingswa ? summaryData.tWfittingswa : 0);
    }
    return sum;
  }

  const getTestsScheduledSum = () => {
    let sum = 0;
    if (summaryData) {
        sum = (summaryData.tLogbooksNTDnsw ? summaryData.tLogbooksNTDnsw : 0) +
            (summaryData.tLogbooksNTDact ? summaryData.tLogbooksNTDact : 0) +
            (summaryData.tLogbooksNTDqld ? summaryData.tLogbooksNTDqld : 0) +
            (summaryData.tLogbooksNTDsa ? summaryData.tLogbooksNTDsa : 0) +
            (summaryData.tLogbooksNTDnt ? summaryData.tLogbooksNTDnt : 0) +
            (summaryData.tLogbooksNTDtas ? summaryData.tLogbooksNTDtas : 0) +
            (summaryData.tLogbooksNTDvic ? summaryData.tLogbooksNTDvic : 0) +
            (summaryData.tLogbooksNTDwa ? summaryData.tLogbooksNTDwa : 0);
    }
    return sum;
  }


  const getTestsOverdueSum = () => {
    let sum = 0;
    if (summaryData) {
        sum = (summaryData.testcountnsw ? summaryData.testcountnsw : 0) +
            (summaryData.testcountact ? summaryData.testcountact : 0) +
            (summaryData.testcountqld ? summaryData.testcountqld : 0) +
            (summaryData.testcountsa ? summaryData.testcountsa : 0) +
            (summaryData.testcountnt ? summaryData.testcountnt : 0) +
            (summaryData.testcounttas ? summaryData.testcounttas : 0) +
            (summaryData.testcountvic ? summaryData.testcountvic : 0) +
            (summaryData.testcountwa ? summaryData.testcountwa : 0);
    }
    return sum;
  }

  const getNoTestsSiteSum = () => {
    let sum = 0;
    if (summaryData) {
        sum = (summaryData.notestcountnsw ? summaryData.notestcountnsw : 0) +
            (summaryData.notestcountact ? summaryData.notestcountact : 0) +
            (summaryData.notestcountqld ? summaryData.notestcountqld : 0) +
            (summaryData.notestcountsa ? summaryData.notestcountsa : 0) +
            (summaryData.notestcountnt ? summaryData.notestcountnt : 0) +
            (summaryData.notestcounttas ? summaryData.notestcounttas : 0) +
            (summaryData.notestcountvic ? summaryData.notestcountvic : 0) +
            (summaryData.notestcountwa ? summaryData.notestcountwa : 0);
    }
    return sum;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                font: {
                    size: 6.1,
                }
            }
        },
        y: {
            grid: {
                display: false
            },
            ticks: {
                min: 0,
                max: 10,
                stepSize: 5
            },
        },
      }
  };

  const showSite = (siteId) => {
    navigate('/sites/' + siteId, {
      state: {
        siteId
      }
    });
  }

  const getLastTwelveMonths = () => {
    const day = moment().format('D');
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonth = moment().format('MMM');
    const currentIndex = months.indexOf(currentMonth);
    let newMonths = [];
    for(let i = currentIndex + 1; i < 12; i++) {
        // newMonths.push(months[i] + `'${day}`);
        newMonths.push(months[i]);
    }
    for (let i = 0; i < currentIndex + 1; i++) {
        // newMonths.push(months[i] + `'${day}`);
        newMonths.push(months[i]);
    }
    return newMonths;
  }

  const getLastTwelveYears = () => {
    const year = new Date().getFullYear();
    return Array.from({length: 12}, (v, i) => year - 12 + i + 1);
  }

  const getNextSixMonths = () => {
    const day = moment().format('D');
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonth = moment().format('MMM');
    const currentIndex = months.indexOf(currentMonth);
    let newMonths = [];
    for(let i = currentIndex - 1; i < 12; i++) {
        newMonths.push(months[i]);
    }
    for (let i = 0; i < currentIndex; i++) {
        newMonths.push(months[i]);
    }
    newMonths.splice(8, 12);
    return newMonths;
  }

  const downloadErrorList = () => {
    diffSites(state, 'csv');
    // diffSites(state, 'pdf');
  }

  useEffect(() => {

    const script = document.createElement('script');

    let scriptToInject = `
        google.charts.load('current', {
            'packages': ['gauge']
        });
        google.charts.setOnLoadCallback(drawChart);
        
        function drawChart() {
        
            var overallData = google.visualization.arrayToDataTable([
                ['Label', 'Value'],
                ['Overall', ${gaugeData.overall}]
            ]);
        
            var overallOptions = {
                width: 160,
                height: 160,
                greenFrom: 90,
                greenTo: 100,
                yellowFrom: 80,
                yellowTo: 90,
                redFrom: 0,
                redTo: 80,
                minorTicks: 5
            };
        
            var overallChart = new google.visualization.Gauge(document.getElementById('overall'));
            overallChart.draw(overallData, overallOptions);

            var states = {
                nsw: 'NSW/ACT',
                qld: 'QLD',
                tas: 'TAS',
                vic: 'VIC',
                sa: 'SA/NT',
                wa: 'WA'
            };

            var stateData = google.visualization.arrayToDataTable([
                ['Label', 'Value'],
                ['NSW/ACT', ${gaugeData.nsw}],
                ['QLD', ${gaugeData.qld}],
                ['TAS', ${gaugeData.tas}],
                ['VIC', ${gaugeData.vic}],
                ['SA/NT', ${gaugeData.sa}],
                ['WA', ${gaugeData.wa}]
            ]);

            var selectedState = '${stateFilter}';

            if (selectedState && selectedState !== 'all') {
                stateData = google.visualization.arrayToDataTable([
                    ['Label', 'Value'],
                    [states[selectedState], ${gaugeData[stateFilter]}],
                ]);
            }
        
            var stateOptions = {
                width: 1000,
                height: 90,
                greenFrom: 90,
                greenTo: 100,
                yellowFrom: 80,
                yellowTo: 90,
                redFrom: 0,
                redTo: 80,
                minorTicks: 5
            };

            var stateChart = new google.visualization.Gauge(document.getElementById('by-state'));
            stateChart.draw(stateData, stateOptions);
        }
    `;

    var scriptCode = document.createTextNode(scriptToInject);
    script.appendChild(scriptCode); 
    script.async = true;
    document.body.appendChild(script);

    setTimeout(() => {
        const overallNode = document.querySelector('#overall table tbody tr td div div div svg g g text');
        if (!overallNode.innerHTML.includes('%')) {
            overallNode.innerHTML = overallNode.innerHTML + '%';
        }
        const statesNodes = document.querySelectorAll('#by-state table tbody tr td div div div svg g g text');
        statesNodes.forEach(node => {
            if (!node.innerHTML.includes('%')) {
                node.innerHTML = node.innerHTML + '%';
            }
        })
    }, 1500)

    return () => {
        document.body.removeChild(script);
    }
  }, [gaugeData])


  const updateStateFilter = (state) => {
    percentageSiteTestComplianceDueDate(state);
    fittingsInstalled(state);
    fittingsInstalledYear(state);
    overdueSites(state);
    diffSiteCount(state, 'csv');
    if (state === 'nsw') {
        upcomingTests('nswact');
    } else if (state === 'sa') {
        upcomingTests('sant');
    } else {
        upcomingTests(state);
    }
    setStateFilter(state);
  }

  useEffect(() => {
    if (orgSetup) {
        summary(stateFilter);
        updateStateFilter(stateFilter);
    }

  }, [orgSetup])

  let currentState = 'All states';
  switch (stateFilter) {
    case 'nsw': 
        currentState = 'NSW/ACT';
        break;
    case 'qld': 
        currentState = 'QLD';
        break;
    case 'sa': 
        currentState = 'SA/NT';
        break;
    case 'tas': 
        currentState = 'TAS';
        break;
    case 'vic': 
        currentState = 'VIC';
        break;
    case 'wa': 
        currentState = 'WA';
        break;
    default:
        currentState = 'All states';
  }
 
  return (
    <div>
        {children}
        <div className="compliance-page">
            <div className="left-panel">
                <div className='general'>
                    <span className='label'>End User<span className='hyphen'> - </span><span className='text'>{user && `${user.attributes['first-name']} ${user.attributes['last-name']}`}</span></span>
                </div>
                <div className='general'>
                    <span className='label'>Date<span className='hyphen'> - </span><span className='text'>{moment().format('DD/MM/YYYY')}</span></span>
                </div>

                <div className='states-container'>
                    <div className={`state ${stateFilter === 'nsw' && 'active'}`} onClick={() => updateStateFilter('nsw')}>
                        <span>NSW/ACT</span>
                    </div>
                    <div className={`state ${stateFilter === 'qld' && 'active'}`} onClick={() => updateStateFilter('qld')}>
                        <span>QLD</span>
                    </div>
                    <div className={`state ${stateFilter === 'sa' && 'active'}`} onClick={() => updateStateFilter('sa')}>
                        <span>SA/NT</span>
                    </div>
                    <div className={`state ${stateFilter === 'tas' && 'active'}`} onClick={() => updateStateFilter('tas')}>
                        <span>TAS</span>
                    </div>
                    <div className={`state ${stateFilter === 'vic' && 'active'}`} onClick={() => updateStateFilter('vic')}>
                        <span>VIC</span>
                    </div>
                    <div className={`state ${stateFilter === 'wa' && 'active'}`} onClick={() => updateStateFilter('wa')}>
                        <span>WA</span>
                    </div>
                    <div className={`state show-all ${stateFilter === 'all' && 'active'}`} onClick={() => updateStateFilter('all')}>
                        <span>Show All Sites</span>
                    </div>
                </div>

                {diffCount > 0 && (
                    <div className='diff-sites'>
                        <span onMouseEnter={() => setShowCaution(true)} onMouseLeave={() => setShowCaution(false)}>Caution</span>
                        <span onClick={downloadErrorList}>Download Error List</span>
                        {showCaution && (
                            <div className='error-message'>
                                <p>Accurate state address information is missing, preventing {diffCount} {diffCount === 1 ? 'site' : 'sites'} from being display on dashboard.</p>
                            </div>
                        )}
                    </div>
                )}

                <div className='sites-overdue'>
                    <div className='title-container'>
                        <span><span className='underline'>{overdueSitesData.length}</span> SITES overdue</span>
                    </div>
                    <div className='content'>
                        <div className='center'>
                            <div className='all-states'>
                                <span className='label'>{currentState}<span className='hyphen'> - </span><span className='text'>Total {overdueSitesData.length}</span></span>
                            </div>
                            <div className='export-group'>
                                <div className='export' onClick={() => exportOverdueSites('pdf')}>
                                    <img src={download_icon} />
                                    <span className='label'>Export PDF</span>
                                </div>
                                <div className='export' onClick={() => exportOverdueSites('csv')}>
                                    <img src={download_icon} />
                                    <span className='label'>Export CSV</span>
                                </div>
                            </div>
                        </div>
                        <div className='items'>
                            {overdueSitesData.map(site => (
                                <div key={overdueSitesData.indexOf(site)} className='row'>
                                    <p onClick={() => showSite(site[0])}>{site[1]}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/*
                <div className='logbooks-overdue'>
                    <div className='title-container'>
                        <span><span className='underline'>12</span> LOGBOOKS overdue</span>
                    </div>
                    <div className='content'>
                        <div className='row center'>
                            <div className='all-states'>
                                <span className='label'>{currentState}<span className='hyphen'> - </span><span className='text'>Total 74</span></span>
                            </div>
                        </div>
                        <div className='items'>
                            <div className='row'>
                                <p>1009 WW Metro: Knightsbridge, NSW</p>
                            </div>
                            <div className='row'>
                                <p>1009 WW Metro: Knightsbridge, VIC</p>
                            </div>
                            <div className='row'>
                                <p>1009 WW Metro: Knightsbridge, QLD</p>
                            </div>
                            <div className='row'>
                                <p>1009 WW Metro: Knightsbridge, NSW</p>
                            </div>
                            <div className='row'>
                                <p>1009 WW Metro: Knightsbridge, NSW</p>
                            </div>
                        </div>
                    </div>
                </div>
                */}
            </div>

            <div className="right-section">
                {(summaryLoading || fittingInstalledLoading || upcomingTestsLoading || percentageSiteLoading || overdueSitesLoading) && (
                    <div>
                        <img className='loading-icon' src={loading_icon} />
                        <span className='loading-text'>Loading</span>
                    </div>
                )}
                    
                
                <h1 className='dashboard-title'>Emergency Lighting Management Dashboard</h1>

                {/*
                <div className='tab-container'>
                    <div className={`tab ${tab === 'dashboard' && 'active'}`} onClick={() => setTab('dashboard')}>
                        <span>Dashboard</span>
                    </div>
                    <div className={`tab ${tab === 'upcomingTests' && 'active'}`} onClick={() => setTab('upcomingTests')}>
                        <span>Upcoming Tests</span>
                    </div>
                </div>
                */}

                
                <div className='tab-content' style={tab === 'dashboard' ? {display: 'block'} : {display: 'none'}}>
                    <div className='summary'>
                        <div className='title-container'>
                            <span>Summary</span>
                        </div>

                        <div className='content'>
                            <div className='column'>
                                <div className='item'>
                                    <span><br/><br/></span>
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <span>NSW/ACT</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <span>QLD</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <span>SA/NT</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <span>TAS</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <span>VIC</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <span>WA</span>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>Totals</strong>
                                    </div>
                                )}
                            </div>

                            <div className='column'>
                                <div className='item' onMouseEnter={() => setShowNumSites(true)} onMouseLeave={() => setShowNumSites(false)}>
                                    <strong># of<br/>Sites</strong>
                                    {showNumSites && (
                                        <div className='tooltip'>
                                            <p>The number of sites matching current filter.</p>
                                        </div>
                                    )}
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <span>{(summaryData.sitescountnsw ? summaryData.sitescountnsw : 0) + (summaryData.sitescountact ? summaryData.sitescountact : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <span>{summaryData.sitescountqld ? summaryData.sitescountqld : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <span>{(summaryData.sitescountsa ? summaryData.sitescountsa : 0) + (summaryData.sitescountnt ? summaryData.sitescountnt : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <span>{summaryData.sitescounttas ? summaryData.sitescounttas : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <span>{summaryData.sitescountvic ? summaryData.sitescountvic : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <span>{summaryData.sitescountwa ? summaryData.sitescountwa : 0}</span>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>{getCountSum()}</strong>
                                    </div>
                                )}
                            </div>

                            <div className='column'>
                                <div className='item' onMouseEnter={() => setShowNumFittings(true)} onMouseLeave={() => setShowNumFittings(false)}>
                                    <strong># of<br/>Fittings</strong>
                                    {showNumFittings && (
                                        <div className='tooltip'>
                                            <p>The number of fittings matching the current filter.</p>
                                        </div>
                                    )}
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <span>{(summaryData.tfittingsnsw ? summaryData.tfittingsnsw : 0) + (summaryData.tfittingsact ? summaryData.tfittingsact : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <span>{summaryData.tfittingsqld ? summaryData.tfittingsqld : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <span>{(summaryData.tfittingssa ? summaryData.tfittingssa : 0) + (summaryData.tfittingsnt ? summaryData.tfittingsnt : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <span>{summaryData.tfittingstas ? summaryData.tfittingstas : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <span>{summaryData.tfittingsvic ? summaryData.tfittingsvic : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <span>{summaryData.tfittingswa ? summaryData.tfittingswa : 0}</span>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>{getFittingsSum()}</strong>
                                    </div>
                                )}
                            </div>

                            <div className='column extra-width'>
                                <div className='item' onMouseEnter={() => setShowWarranty(true)} onMouseLeave={() => setShowWarranty(false)}>
                                    <strong>Clevertronics’<br/>Fittings in Warranty</strong>
                                    {showWarranty && (
                                        <div className='tooltip'>
                                            <p>The number of Clevertronics fittings are in warranty. Refer to Clevertronics warranty terms.</p>
                                        </div>
                                    )}
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <span>{(summaryData.tWfittingsnsw ? summaryData.tWfittingsnsw : 0) + (summaryData.tWfittingsact ? summaryData.tWfittingsact : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <span>{summaryData.tWfittingsqld ? summaryData.tWfittingsqld : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <span>{(summaryData.tWfittingssa ? summaryData.tWfittingssa : 0) + (summaryData.tWfittingsnt ? summaryData.tWfittingsnt : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <span>{summaryData.tWfittingstas ? summaryData.tWfittingstas : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <span>{summaryData.tWfittingsvic ? summaryData.tWfittingsvic : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <span>{summaryData.tWfittingswa ? summaryData.tWfittingswa : 0}</span>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>{getWarrantyFittingsSum()}</strong>
                                    </div>
                                )}
                            </div>

                            <div className='column'>
                                <div className='item' onMouseEnter={() => setShowTestsScheduled(true)} onMouseLeave={() => setShowTestsScheduled(false)}>
                                    <strong>Scheduled<br/>Tests</strong>
                                    {showTestsScheduled && (
                                        <div className='tooltip'>
                                            <p>The number of sites have scheduled tests.</p>
                                        </div>
                                    )}
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <span>{(summaryData.tLogbooksNTDnsw ? summaryData.tLogbooksNTDnsw : 0) + (summaryData.tLogbooksNTDact ? summaryData.tLogbooksNTDact : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <span>{summaryData.tLogbooksNTDqld ? summaryData.tLogbooksNTDqld : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <span>{(summaryData.tLogbooksNTDsa ? summaryData.tLogbooksNTDsa : 0) + (summaryData.tLogbooksNTDnt ? summaryData.tLogbooksNTDnt : 0)}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <span>{summaryData.tLogbooksNTDtas ? summaryData.tLogbooksNTDtas : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <span>{summaryData.tLogbooksNTDvic ? summaryData.tLogbooksNTDvic : 0}</span>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <span>{summaryData.tLogbooksNTDwa ? summaryData.tLogbooksNTDwa : 0}</span>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>{getTestsScheduledSum()}</strong>
                                    </div>
                                )}
                            </div>

                            <div className='column'>
                                <div className='item label' onMouseEnter={() => setShowOverdueSites(true)} onMouseLeave={() => setShowOverdueSites(false)}>
                                    <strong>OVERDUE<br/>TESTS</strong>
                                    {showOverdueSites && (
                                        <div className='tooltip'>
                                            <p>The number of sites have overdue tests (older than 30 days).</p>
                                        </div>
                                    )}
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <div className='red'>
                                            <span>{(summaryData.testcountnsw ? summaryData.testcountnsw : 0) + (summaryData.testcountact ? summaryData.testcountact : 0)}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <div className='red'>
                                            <span>{summaryData.testcountqld ? summaryData.testcountqld : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <div className='red'>
                                            <span>{(summaryData.testcountsa ? summaryData.testcountsa : 0) + (summaryData.testcountnt ? summaryData.testcountnt : 0)}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <div className='red'>
                                            <span>{summaryData.testcounttas ? summaryData.testcounttas : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <div className='red'>
                                            <span>{summaryData.testcountvic ? summaryData.testcountvic : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <div className='red'>
                                            <span>{summaryData.testcountwa ? summaryData.testcountwa : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>{getTestsOverdueSum()}</strong>
                                    </div>
                                )}
                            </div>

                            <div className='column'>
                                <div className='item label' onMouseEnter={() => setShowNoScheduled(true)} onMouseLeave={() => setShowNoScheduled(false)}>
                                    <strong>SITES NO TEST SCHEDULED</strong>
                                    {showNoScheduled && (
                                        <div className='tooltip'>
                                            <p>The number of sites without a logbook created.</p>
                                        </div>
                                    )}
                                </div>
                                {(stateFilter === 'all' || stateFilter === 'nsw') && (
                                    <div className='item'>
                                        <div className='orange'>
                                            <span>{(summaryData.notestcountnsw ? summaryData.notestcountnsw : 0) + (summaryData.notestcountact ? summaryData.notestcountact : 0)}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'qld') && (
                                    <div className='item'>
                                        <div className='orange'>
                                            <span>{summaryData.notestcountqld ? summaryData.notestcountqld : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'sa') && (
                                    <div className='item'>
                                        <div className='orange'>
                                            <span>{(summaryData.notestcountsa ? summaryData.notestcountsa : 0) + (summaryData.notestcountnt ? summaryData.notestcountnt : 0)}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'tas') && (
                                    <div className='item'>
                                        <div className='orange'>
                                            <span>{summaryData.notestcounttas ? summaryData.notestcounttas : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'vic') && (
                                    <div className='item'>
                                        <div className='orange'>
                                            <span>{summaryData.notestcountvic ? summaryData.notestcountvic : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {(stateFilter === 'all' || stateFilter === 'wa') && (
                                    <div className='item'>
                                        <div className='orange'>
                                            <span>{summaryData.notestcountwa ? summaryData.notestcountwa : 0}</span>
                                        </div>
                                    </div>
                                )}
                                {stateFilter === 'all' && (
                                    <div className='item'>
                                        <strong>{getNoTestsSiteSum()}</strong>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='mid'>
                        <div className='fittings-installed-panel'>
                            <div className='title-container' onMouseEnter={() => setShowFittingsInstalled(true)} onMouseLeave={() => setShowFittingsInstalled(false)}>
                                <h1>Fittings Installed</h1>
                                <h2>(total replaced last 12 {fittingsInstalledTab === 'monthly' ? 'months' : 'years'})</h2>
                                {showFittingsInstalled && (
                                    <div className='tooltip'>
                                        <p>The total number of fittings replaced by month or year.</p>
                                    </div>
                                )}
                            </div>

                            <div className='content'>
                                <div className='tabs'>
                                    <div className={`tab${fittingsInstalledTab === 'monthly' ? ' active' : ''}`} onClick={() => setFittingsInstalledTab('monthly')}>
                                        <span>Monthly</span>
                                    </div>
                                    <div className={`tab${fittingsInstalledTab === 'yearly' ? ' active' : ''}`} onClick={() => setFittingsInstalledTab('yearly')}>
                                        <span>Yearly</span>
                                    </div>
                                </div>
                                <div className='all-states' style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                                    <span>{currentState}, Total {fittingsInstalledTab === 'monthly' ? fittingsInstalledTotal : fittingsInstalledYearTotal}</span>
                                </div>
                                <p className='y-label'>No. of Fittings</p>
                                {fittingsInstalledTab === 'monthly' && (
                                    <>
                                        <Bar options={options} data={{
                                            labels: getLastTwelveMonths(),
                                            datasets: [{
                                                data: fittingsInstalledData,
                                                backgroundColor: '#3366CC',
                                            }],
                                        }} />
                                        <p>Months</p>
                                    </>
                                )}
                                {fittingsInstalledTab === 'yearly' && (
                                    <>
                                        <Bar options={options} data={{
                                            labels: getLastTwelveYears(),
                                            datasets: [{
                                                data: fittingsInstalledYearData,
                                                backgroundColor: '#3366CC',
                                            }],
                                        }} />
                                        <p>Years</p>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className='upcoming-tests-panel'>
                            <div className='title-container' onMouseEnter={() => setShowUpcomingTests(true)} onMouseLeave={() => setShowUpcomingTests(false)}>
                                <h1>Upcoming Tests</h1>
                                {showUpcomingTests && (
                                    <div className='tooltip'>
                                        <p>The number of sites have scheduled future tests including the tests scheduled in the past 30 days.</p>
                                    </div>
                                )}
                            </div>

                            <div className='content' style={{paddingTop: 33}}>
                                <div className='all-states'>
                                    <span>{currentState}, Total {upcomingTestsTotal}</span>
                                </div>
                                <p className='y-label'>No. of Tests</p>
                                <Bar options={options} data={{
                                    labels: getNextSixMonths(),
                                    datasets: [
                                      {
                                        data: upcomingTestsData,
                                        backgroundColor: '#3366CC',
                                      }
                                    ],
                                }} />
                                <p>Months</p>
                            </div>
                        </div>
                    </div>

                    <div className='gauge-container'>
                        <div className='title-container' onMouseEnter={() => setShowDials(true)} onMouseLeave={() => setShowDials(false)}>
                            <span>Testing Compliance % By Due Date</span>
                            {showDials && (
                                <div className='tooltip'>
                                    <p>The percentage of sites that have been tested or are less than 30 days over due</p>
                                </div>
                            )}
                        </div>

                        <div id="overall"></div>
                        <div id="by-state" className='state-gauges'></div>
                    </div>
                </div>
                
                
                <div className='tab-content' style={tab === 'upcomingTests' ? {display: 'block'} : {display: 'none'}}>
                    <div className='upcoming-tests'>
                        <div className='title-container'>
                            <h1>Upcoming Tests</h1>
                        </div>

                        <div className='content'>
                            <div className='column first-column'>
                                <div className='item start first'>
                                    <strong>Report Name</strong>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                                <div className='item start'>
                                    <span>Report name here</span>
                                </div>
                            </div>

                            <div className='column'>
                                <div className='item first'>
                                    <strong>Report Date</strong>
                                </div>
                                <div className='item'>
                                    <span>10/01/2023</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                                <div className='item'>
                                    <span>19/12/2022</span>
                                </div>
                            </div>

                            <div className='column'>
                                <div className='item first'>
                                    <strong>Report</strong>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                                <div className='item'>
                                    <a>download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Compliance;
