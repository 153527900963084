import { BASE_URL, API_VERSION, MAINTENANCES } from "../settings/apiUrls";
import api from "./api";

export const addMaintenance = (data) => {
    return api({
        method: 'post',
        url: BASE_URL + API_VERSION + MAINTENANCES,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const updateMaintenance = (maintenanceId, data) => {
    return api({
        method: 'patch',
        url: BASE_URL + API_VERSION + MAINTENANCES + "/" + maintenanceId,
        data: data,
    }).then((response) => {
        return response.data;
    });
}

export const removeMaintenance = (maintenanceId) => {
    return api({
        method: 'delete',
        url: BASE_URL + API_VERSION + MAINTENANCES + "/" + maintenanceId,
    }).then((response) => {
        return response.data;
    });
}