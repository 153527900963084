import React, { useState, useEffect } from 'react';
import ModalView from './ModalView';
import icon_close from '../../assets/images/icon-close.svg';

export default ({children, type, onClose}) => {

  let label = '';
  if (type === 'edit') label = 'Edit Date Range';
  else if (type === 'duplicate') label = 'Duplicate Logbook';
  else label = 'Add Logbook';

  return (
    <ModalView>
      <div id="test-event-modal">
        <div className="modal-header">
          <h2 className="form-heading">
            {label}
          </h2>
          <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose}/>
        </div>

        <div className="modal-content">
          {children}
        </div>
      </div>
    </ModalView>
  )
}