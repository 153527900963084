import React, { useState, useEffect } from 'react';
import FaultsDropdownMenu from './FaultsDropdownMenu';
import ReplacementOptionButton from './ReplacementOptionButton';
import icon_red_dot from '../../assets/images/icon-red-dot.svg';
import illus_no_faults from '../../assets/images/illus-no-faults.svg';
import LoadMoreButton from './LoadMoreButton';

export default ({faultyFittings, faultTotal, onShowFitting, onLoadMore}) => {

  const [isShoppingCartEnabled, setIsShoppingCartEnabled] = useState(false);
  const [isOrderingReplacements, setIsOrderingReplacements] = useState(false);
  const [isSelectAllFittings, setIsSelectAllFittings] = useState(false);
  const [isSelectAllComponents, setIsSelectAllComponents] = useState(false);
  const [isSelectAllDecals, setIsSelectAllDecals] = useState(false);
  const [addOrderItem, setAddOrderItem] = useState(null);
  const [removeOrderItem, setRemoveOrderItem] = useState(null);
  const [infinityLoad, setInfinityLoad] = useState(false);

  const showOrderReplacements = () => {

  }

  const hideOrderReplacements = () => {

  }

  return (
    <div id="fault-table" className="table">
      <table>
        {isShoppingCartEnabled && (
          <thead>
            <tr>
              <th className="table-row">
                <div className="column device-reference">
                  <div className="inner">
                    Device Reference
                  </div>
                </div>
                {isOrderingReplacements ? (
                  <>
                    <div className='column replacement-fitting-option'>
                      <div className="inner">
                        Replace Fitting
                      </div>
                    </div>
                    <div className='column replacement-faulty-component-option'>
                      <div className="inner">
                        Replace Faulty Component
                      </div>
                    </div>
                    <div className='column replacement-decal-option'>
                      <div className="inner">
                        Replace Decal
                      </div>
                    </div>
                    <div className='column row-indicator' />
                  </>
                ) : (
                  <div className="dropdown-indicator">
                    <FaultsDropdownMenu faultyFittings={faultyFittings} showOrderReplacements={showOrderReplacements}/>
                  </div>
                )}
              </th>
            </tr>
          </thead>
        )}
          
        {isOrderingReplacements && (
          <tr>
            <td className="table-row select-all-row">
              <div className="column device-reference" />
              <div className="select-all-column replace-all-fittings">
                <input type="checkbox" id='fittings' checked={isSelectAllFittings} />
                <label for="fittings" className="select-all-label" />
              </div>
              <div className="select-all-column replace-all-components">
                <input type="checkbox" id='components' checked={isSelectAllComponents} />
                <label for="components" className="select-all-label"/>
              </div>
              <div className="select-all-column replace-all-decals">
                <input type="checkbox" id='decals' checked={isSelectAllDecals} />
                <label for="decals" className="select-all-label"/>
              </div>
              <div className='column row-indicator' />
            </td>
          </tr>
        )}

        <tbody>
          {faultyFittings.map(fitting => (
            <tr key={faultyFittings.indexOf(fitting)} className={isOrderingReplacements && 'order-replacement-row'} onClick={!isOrderingReplacements && (() => onShowFitting(fitting))} >
              <td className="table-row">
                <div className='column device-reference'>
                  <div className="truncated">
                    {fitting.attributes['device-reference'] + ' - ' + fitting.attributes['fitting-type']}
                  </div>
                </div>
                {isOrderingReplacements && (
                  <>
                    <div className='column replacement-fitting-option'>
                      <ReplacementOptionButton 
                        partType='fitting'
                        fitting={fitting}
                        addOrderItem={addOrderItem}
                        removeOrderItem={removeOrderItem}
                      />
                    </div>
                    <div className='column replacement-faulty-component-option'>
                      <ReplacementOptionButton 
                        partType='battery'
                        fitting={fitting}
                        addOrderItem={addOrderItem}
                        removeOrderItem={removeOrderItem}
                      />
                      <ReplacementOptionButton 
                        partType='lamp'
                        fitting={fitting}
                        addOrderItem={addOrderItem}
                        removeOrderItem={removeOrderItem}
                      />
                      <ReplacementOptionButton 
                        partType='control_gear'
                        fitting={fitting}
                        addOrderItem={addOrderItem}
                        removeOrderItem={removeOrderItem}
                      />
                    </div>
                    <div className='column replacement-decal-option'>
                      <ReplacementOptionButton 
                        partType='decal'
                        fitting={fitting}
                        addOrderItem={addOrderItem}
                        removeOrderItem={removeOrderItem}
                      />
                    </div>
                  </>
                )}
                <div className='column row-indicator'>
                  {fitting.attributes['faulty'] && (
                    <img src={icon_red_dot} alt="Faulty indicator"/>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {faultyFittings.length === 0 ? (
        <div className="empty-table">
          <div className="empty-image">
            <img src={illus_no_faults} alt="Blue Row Icon"/>
          </div>
          <div className="empty-message">
            There are no faulty fittings for this site.
          </div>
        </div>
      ) : (
        <div className="table-actions">
          <div className="left">
            <LoadMoreButton 
              canLoad={faultyFittings.length < faultTotal} 
              loadText='Load More Faulty Fittings' 
              numberOfItemsDisplayedText={`(Showing ${faultyFittings.length} of ${faultTotal})`} 
              onLoadMore={onLoadMore}
            />
          </div>
          <div className="right">
            {isOrderingReplacements && (
              <button className='button standard blue cancel' onClick={hideOrderReplacements}>OK</button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}