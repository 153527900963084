import React, { useState, useEffect, useContext, useRef } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { getOrganisationsDetails } from './api/organisationApi';
import { getSites, getSitesById } from './api/siteApi';
import { getMe } from './api/userApi';
import Analytics from './templates/Analytics';
import ChangePassword from './templates/ChangePassword';
import Compliance from './templates/Compliance';
import FlashMessage from './templates/components/FlashMessage';
import ModalView from './templates/components/ModalView';
import NavBar from "./templates/components/NavBar";
import SwitchAccountModal from './templates/components/SwitchAccountModal';
import Edit from "./templates/Edit";
import ForgotPassword from "./templates/ForgotPassword";
import Invite from './templates/Invite';

// Components
import Login from './templates/Login';
import NewOrganisation from './templates/NewOrganisation';
import Onboarding from './templates/Onboarding';
import Signup from "./templates/Signup";
import Site from './templates/Site';
import SiteRequests from './templates/SiteRequests';
import Sites from "./templates/Sites";
import Support from './templates/Support';
import Terms from "./templates/Terms";
import User from './templates/User';
import Users from './templates/Users';

import { store } from "./utils/store";
import ProtectedRoute from './utils/ProtectedRoute';
import ResetPassword from './templates/ResetPassword';

// ----- Init ------------------------------------
const App = () => {

  const navigate = useNavigate();
  const [sites, setSites] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryRef = useRef('');
  const [sort, setSort] = useState('');
  const [displaySwitchAccountModal, setDisplaySwitchAccountModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [hasJustLoggedIn, setHasJustLoggedIn] = useState(true);
  const [didValidate, setDidValidate] = useState(true);
  const [navBarTriggerContent, setNavBarTriggerContent] = useState('');
  const [currentOrg, setCurrentOrg] = useState('');
  const [sitePageNum, setSitePageNum] = useState(1);
  const [siteTotal, setSiteTotal] = useState(0);
  const [switchAcc, setSwitchAcc] = useState(false);
  const [orgSetup, setOrgSetup] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  const { dispatch, state } = useContext(store);
  const {
    user,
    loaded,
    popupType,
    popupMessages
  } = state;

  useEffect(() => {
    searchQueryRef.current = searchQuery;
  }, [searchQuery]);
  
  const setCurrentUser = (user, organisation, refresh, updatedOrgs) => {
    dispatch({ type: 'SET USER', payload: user });
    dispatch({ type: 'SET LOAD', payload: true });
    let selecedAcc = organisation ? organisation : user.id;
    setSelectedAccount(selecedAcc);
    let navBarTriggerContent = '';
    if (!organisation) {
      navBarTriggerContent = user.attributes['first-name'] + ' ' + user.attributes['last-name'];
      setCurrentOrg('');
      setOrgSetup(true);
    } else {
      let selectedOrgName = ''
      let orgs = [];
      orgs = updatedOrgs ? updatedOrgs : organisations;
      orgs.forEach(org => {
        if (org.value === selecedAcc) {
          selectedOrgName = org.label;
          setCurrentOrg(org.value);
          setOrgSetup(true);
        }
      })
      navBarTriggerContent = user.attributes['first-name'] + ' ' + user.attributes['last-name'].slice(0, 1) + ' | ' + selectedOrgName;
    }
    setNavBarTriggerContent(navBarTriggerContent);
    getCurrentSites(selecedAcc, user, refresh);
  }

  const handleLogin = (user, organisations) => {
    setDisplaySwitchAccountModal(true);
    setCurrentUser(user);
    setOrganisations(organisations);
    updateSites();
  }
  
  const handleUpdateOrgs = (organisations) => {
    setOrganisations(organisations);
  }

  const handleSignup = (user) => {
    setCurrentUser(user);
    const exist = organisations.find(org => org.value === user.id);
    if (!exist) {
      let newOrganisations = [...organisations, {
        label: user.attributes['first-name'] + ' ' + user.attributes['last-name'],
        value: user.id
      }];
      setOrganisations(newOrganisations);
    }
  }

  const openSwitchAccountModal = () => {
    setDisplaySwitchAccountModal(true);
  }

  const closeSwitchAccountModal = () => {
    setDisplaySwitchAccountModal(false);
  }

  const confirmSwitchAccount = () => {
    setDisplaySwitchAccountModal(false);
    setSitePageNum(1);
    if (user && user.id === selectedAccount) {
      setCurrentUser(user, '', true);
      localStorage.setItem('currentOrganisation', '');
    } 
    else {
      setCurrentUser(user, selectedAccount, true);
      localStorage.setItem('currentOrganisation', selectedAccount);
    } 
    navigate('/sites');
  }



  const getCurrentSites = (selecedAcc, user, refresh) => {
    if (!selecedAcc || (user && (selecedAcc === user.id))) {
      const filter = '?include=test_events,fittings&currentOrganisationId=&filter[query]=' + searchQuery + '&page[number]=' + sitePageNum + '&page[size]=30&sort=' + sort;
      getSites(filter).then(response => {
        if (refresh) {
          if ((response.links['filters-requested'] && response.links['filters-requested'].query === searchQueryRef.current) || !response.links['filters-requested']) {
            setSites(response.data);
            setSiteTotal(response.meta['total-objects']);
          }
        }
        else {
          if ((response.links['filters-requested'] && response.links['filters-requested'].query === searchQueryRef.current) || !response.links['filters-requested']) {
            setSites(sites.concat(response.data));
            setSiteTotal(response.meta['total-objects']);
          }
        }
      })
    } else {
      const filter = '?include=test_events,fittings&currentOrganisationId=' + selecedAcc + '&filter[query]=' + searchQuery + '&page[number]=' + sitePageNum + '&page[size]=30&sort=' + sort;
      getSitesById(selecedAcc, filter).then(response => {
        if (refresh) {
          if ((response.links['filters-requested'] && response.links['filters-requested'].query === searchQueryRef.current) || !response.links['filters-requested']) {
            setSites(response.data);
            setSiteTotal(response.meta['total-objects']);
          }
        }
        else {
          if ((response.links['filters-requested'] && response.links['filters-requested'].query === searchQueryRef.current) || !response.links['filters-requested']) {
            setSites(sites.concat(response.data));
            setSiteTotal(response.meta['total-objects']);
          }
        }
      })
    }
  }

  const changeSort = (type) => {
    setSitePageNum(1);
    if (!sort) {
      setSort(type);
    } else {
      if (!sort.includes(type)) {
        setSort(type);
      } else {
        if (sort.startsWith('-')) {
          setSort(type);
        } else {
          setSort('-' + type);
        }
      }
    }
  }

  const updateSites = async () => {
    let currentOrganisation = await localStorage.getItem('currentOrganisation');
    if (currentOrganisation) getCurrentSites(currentOrganisation, user, true);
    else getCurrentSites(selectedAccount, user, true);
  }

  const getMoreSites = () => {
    getCurrentSites(selectedAccount, user, false);
  }

  const setCurrentOrganisation = (e) => {
    setSelectedAccount(e.target.value);
  }

  const removeMessage = () => {
    dispatch({ type: 'SET POPUP', payload: '' })
  }

  const loadMoreSites = () => {
    if (Math.ceil(sites.length / 30) < siteTotal) {
      setSitePageNum(sitePageNum + 1);
    }
  }

  const handleConfirmAddSite = () => {
    updateSites()
  }

  const handleAddOrganisation = (newOrganisation) => {
    setSelectedAccount(newOrganisation.value);
    let updatedOrgs = organisations;
    const exist = organisations.find(org => org.value === newOrganisation.value);
    if (!exist) {
      updatedOrgs = [...organisations, newOrganisation];
    }
    updatedOrgs.sort((a, b) => {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    setOrganisations(updatedOrgs);
    setCurrentOrg(newOrganisation.value);
    localStorage.setItem('organisations', JSON.stringify(updatedOrgs));
    localStorage.setItem('currentOrganisation', newOrganisation.value);
    let navBarTriggerContent = user.attributes['first-name'] + ' ' + user.attributes['last-name'].slice(0, 1) + ' | ' + newOrganisation.label;
    getCurrentSites(newOrganisation.value, user, true);
    setTimeout(() => {
      setNavBarTriggerContent(navBarTriggerContent);
    }, 1000);
  }

  const navBar = <NavBar 
                  canView={{userManagement: () => false}} 
                  currentOrg={currentOrg}
                  triggerContent={navBarTriggerContent} 
                  isAnalyticsEnabled={false}
                  onSwitchAccount={openSwitchAccountModal}
                />;

  useEffect(() => {
    let currentOrganisation = localStorage.getItem('currentOrganisation');
    let organisations = localStorage.getItem('organisations') ? JSON.parse(localStorage.getItem('organisations')) : [];
    setOrganisations(organisations);

    let localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (localUser) {
      getMe().then(response => {
        setCurrentUser(response.data, currentOrganisation, true, organisations);
      }).catch(error => {
        navigate('/login');
        localStorage.removeItem('user');
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('currentOrganisation');
        localStorage.removeItem('organisations');
      })
    } else if (user) {
      dispatch({ type: 'SET LOAD', payload: true });
      setCurrentUser(localUser, currentOrganisation, true);
    } else {
      dispatch({ type: 'SET LOAD', payload: true });
    }
  }, [])

  useEffect(() => {
    if (sitePageNum === 1 && switchAcc) updateSites();
    else if (sitePageNum > 1) getMoreSites();
  }, [sitePageNum])

  useEffect(() => {
    setSwitchAcc(true);
  }, [selectedAccount])

  useEffect(() => {
    updateSites();
  }, [searchQuery])

  useEffect(() => {
    updateSites();
  }, [sort])

  return (
      <React.Fragment>
        {displaySwitchAccountModal && (
          <ModalView>
            <SwitchAccountModal 
              headingText='Switch Account'
              messageText='Which account are you working on today?'
              buttonText='Switch'
              selectedAccount={selectedAccount}
              displayOrganisations={organisations}
              hasJustLoggedIn={hasJustLoggedIn}
              didValidate={didValidate}
              onClose={closeSwitchAccountModal}
              onChange={setCurrentOrganisation}
              onConfirm={confirmSwitchAccount}
            />
          </ModalView>
        )}
        {popupType && (
          <FlashMessage popupMessages={popupMessages} type={popupType} onClick={removeMessage} />
        )}
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login onLoggedIn={handleLogin} onUpdateOrgs={handleUpdateOrgs} />} />
          <Route path="/signup/terms" element={<Signup step="terms" />} />
          <Route path="/signup/user" element={<Signup step="user" onSignup={handleSignup}/>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/invite" element={<Invite onSetCurrentUser={setCurrentUser} onAddNewOrg={handleAddOrganisation}/>} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<ProtectedRoute user={user} loaded={loaded} />}>
            <Route path="/sites" element={<Sites sites={sites} siteTotal={siteTotal} currentOrg={currentOrg} onLoadMore={loadMoreSites} onAddSite={handleConfirmAddSite} onUpdateSites={updateSites} onSearch={setSearchQuery} onSort={changeSort}>{navBar}</Sites>} />
            <Route path="/sites/:id" element={<Site>{navBar}</Site>} />
            <Route path="/edit/profile" element={<Edit canView={{userManagement: () => {}}} >{navBar}</Edit>} />
            <Route path="/terms" element={<Terms canView={{userManagement: () => {}}} >{navBar}</Terms>} />
            <Route path="/new/organisation" element={<NewOrganisation onAddOrganisation={handleAddOrganisation}>{navBar}</NewOrganisation>} />
            <Route path="/support" element={<Support canView={{userManagement: () => {}}}>{navBar}</Support>} />
            <Route path="/change-password" element={<ChangePassword>{navBar}</ChangePassword>} />
            <Route path="/users" element={<Users currentOrg={currentOrg}>{navBar}</Users>} />
            <Route path="/users/:id" element={<User currentOrg={currentOrg}>{navBar}</User>} />
            <Route path="/site-requests" element={<SiteRequests user={user} currentOrg={currentOrg} onSetCurrentUser={setCurrentUser}>{navBar}</SiteRequests>} />
            {false && <Route path="/analytics" element={<Analytics currentOrg={currentOrg}>{navBar}</Analytics>} />}
            <Route path="/onboarding" element={<Onboarding>{navBar}</Onboarding>} />
            <Route path="/dashboard" element={<Compliance currentOrg={currentOrg} orgSetup={orgSetup}>{navBar}</Compliance>} />
          </Route>
        </Routes>
      </React.Fragment>
  );
}

export default App;
