import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import back from "../assets/icons/back.svg";
import white_logo from "../assets/icons/logo-white.svg";
import UserSignupForm from './components/forms/UserSignupForm';
import Terms from './partials/Terms';

const Signup = ({ step, onSignup }) => {

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }

  const accept = () => {
    navigate('/signup/user');
    window.scrollTo(0, 0);
  }

  return (
    <div id='signup' className="auth-page">
      <div className="back-bar">
        <div className="back" onClick={goBack}>
          <img src={back} className='icon-back' alt="Back icon"/>Back
        </div>
      </div>
      <div className={`content ${step === 'terms' ? 'terms-content' : ''}`}>
        <a href='/login'>
          <img src={white_logo} className="logo-large" />
        </a>
        {step === 'terms' && (
          <div id='signup-terms'>
            <h2 className="heading">Terms and Conditions</h2>
            <Terms />
            <div className="actions">
              <div className="inner">
                <button type="button" name="button" className="button large green auth-submit terms-submit" onClick={accept}>I Agree</button>
              </div>
            </div>
          </div>
        )}
        {step === 'user' && (
          <UserSignupForm onSignup={onSignup}/>
        )}
      </div>
    </div>
  )
}

export default Signup;
