import React, { useState, useEffect, useContext } from 'react';
import icon_close from '../../assets/images/icon-close.svg';
import FormValidatedSelect from './FormValidatedSelect';

export default ({headingText, messageText, onClose, displayOrganisations, selectedAccount, didValidate, buttonText, onChange, onConfirm }) => {
  
  return (
    <div className="switch-account-modal">
      <div className="modal-header">
        <h2 className="form-heading">{headingText}</h2>
        <img className="modal-close" src={icon_close} alt="Close Icon" onClick={onClose} />
      </div>

      <div className="modal-content">
        <div className="inner">
          <p className="message">{messageText}</p>

          <div className="form">
            <div className="row row-animated">
              <FormValidatedSelect type='text' name='selectedAccount' options={displayOrganisations} value={selectedAccount} onChange={onChange} allowNullValue={true} didValidate={didValidate} labelText='Account' />
            </div>
          </div>

        </div>
        <div className="modal-actions add-action">
          <div className="button standard blue add-group-submit" onClick={onConfirm}>
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  )
}
