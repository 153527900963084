import React, { useState, useEffect, useContext } from 'react';

export default ({children, overlayColor, closeOnOverlayClick, tetherModal, onCloseModal}) => {

  return (
    <div className={`modal-overlay ${overlayColor}`} onClick={closeOnOverlayClick && onCloseModal} >
      {tetherModal && children}
      {!tetherModal && (
        <div className='container'>
          {children}
        </div>
      )}
    </div>
  )
}

